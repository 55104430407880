import React from 'react';
import moment from 'moment';

export default function BetListTableBmx(props) {
    const { betList } = props;
    return (

        <div className="overflow-x-auto w-full pb-2 border-color">
            <table className="min-w-full capitalize border-color">
                <tr className="w-full table-header black-text text-[13px] font-semibold text-center rounded-b">
                    <th colSpan={4} className="px-3 py-1 border-color whitespace-nowrap text-center">Team</th>
                    {/* <th className="px-3 py-1 whitespace-nowrap">Odds</th> */}
                    <th className="px-3 py-1 border-color whitespace-nowrap">Mode</th>
                    <th className="px-3 py-1 border-color whitespace-nowrap">Rate</th>
                    <th className="px-3 py-1 border-color whitespace-nowrap">Amount</th>
                    <th className="px-3 py-1 border-color whitespace-nowrap">Result</th>
                    <th className="px-3 py-1 border-color whitespace-nowrap">Date & Time</th>
                </tr>
                {betList && betList.length > 0 ? betList.map((tempData) => (
                    <tr className="w-full text-black bg-white text-[13px] font-semibold text-center rounded-b">
                        <td colSpan={4} className="px-1.5 py-1 border-color whitespace-nowrap text-center">{tempData.playerName} <br />({tempData.roundId})</td>
                        {/* <td className="px-3 py-1 whitespace-nowrap">{tempData.amount}</td> */}
                        <td className="px-3 py-1 whitespace-nowrap border-color">{tempData.betType === "L" ? "Back" : "Lay"}</td>
                        <td className="px-3 py-1 whitespace-nowrap border-color">{tempData.odds}</td>
                        <td className="px-3 py-1 whitespace-nowrap border-color">
                            {Number.parseFloat(Math.abs(tempData.amount)).toFixed(2).replace(/\.?0+$/, "")}
                        </td>
                        <td className={`px-3 py-1 whitespace-nowrap border-color ${tempData.profitLoss < 0 ? "text-red-500" : tempData.profitLoss > 0 ? "text-green-800" : "text-black"}`}>{tempData.profitLoss}
                            <br />
                            {tempData.isDeclare === 1 ?
                                <small>({tempData.showResult})</small> : null
                            }
                        </td>
                        {/* <td className={`px-3 py-1 whitespace-nowrap border-color`}>
                            {tempData.isDeclare === 1 ?
                                <>{tempData.showResult}</> :
                                "Not Declare"
                            }
                        </td> */}
                        <td className="px-3 py-1 whitespace-nowrap border-color"> {tempData && tempData.createdAt ? moment(tempData.createdAt).utcOffset("+05:30").format("DD-MM-YYYY hh:mm:ss") : ''}</td>
                    </tr>)) :
                    (
                        <tr className="w-full text-[#212529] bg-[#F2F2F2] text-[13px] font-[400] text-center rounded-b">
                            <td colSpan={9} className="px-3 py-1 w-full text-center border-color"> No placed bet found !</td>
                        </tr>)
                }
            </table>
        </div>

    );
}