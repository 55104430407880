import React, { useEffect, useState } from "react";
import { MdKeyboardArrowUp } from "react-icons/md";
import AOS from "aos";
import "aos/dist/aos.css";
import { httpPost } from "../../../middelware/Http";
import moment from "moment";
import { userAction } from "../../../redux/action/userAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NavigatePathComponent from "../../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent";


function ProfitLoss() {

    const [active, setActive] = useState(false);
    const [payloadData, setPayloadData] = useState({
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().subtract(7, 'days').format('YYYY-MM-DD')
    });
    const dispatch = useDispatch();
    const { getUserLedger } = useSelector(state => state.authentication);

    useEffect(() => {
        AOS.init();
        AOS.refresh();
        dispatch(userAction.getUserLedger());
    }, [dispatch]);

    const toggleAccordion = (index) => {
        setActive(prevState => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const groupedData = {};
    getUserLedger?.ledgerData.forEach(item => {
        const dateKey = moment(item.date).format("YYYY-MM-DD");
        groupedData[dateKey] = groupedData[dateKey] || [];
        groupedData[dateKey].push(item);
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setPayloadData({
            ...payloadData,
            [name]: value
        });
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        // const res = await httpPost('reports/profitLossForAllExch',payloadData);
    };



    // const getData = async () => {
    //     const res = await httpPost('reports/profitLossForAllExch');
    //     if (res) {
    //         setUserLedger(res.data);
    //     }
    // }

    return (
        <>
            <section className="w-full bg-black">
                <NavigatePathComponent showPathName={"Profit and Loss"} />
                <div className="relative mx-auto text-center w-full max-w-6xl shadow-xl ring-1 ring-gray-900/5 sm:rounded-md py-4">
                    <div className="px-2 space-y-1 h-auto afterFooter">
                        <form onSubmit={handleSubmit}>
                            <div className="flex justify-start relative lg:gap-3 gap-1">
                                <input
                                    type="date"
                                    name="toDate"
                                    id="toDate"
                                    value={payloadData.toDate}
                                    onChange={handleChange}
                                    className="border bg-white rounded-full px-2 py-1"
                                />
                                <input
                                    type="date"
                                    name="fromDate"
                                    id="fromDate"
                                    value={payloadData.fromDate}
                                    onChange={handleChange}
                                    className="border bg-white rounded-full px-2 py-1"
                                />
                                <button type="submit" className="bg-[#f36c21] text-white text-xs px-4 py-0.5 lg:w-72 w-56 rounded-full">Search</button>
                            </div>
                        </form>
                        <div className="py-4 space-y-1">
                            {Object.entries(groupedData).map(([date, data], index) => (
                                <div className=" md:flex w-full" key={index}>
                                    <div className="flex-1">
                                        <div className="bg-orange-500 text-white flex items-center justify-between text-start py-2 px-3 rounded-t-2xl" onClick={() => toggleAccordion(index)}>
                                            <span className="text-sm font-normal">
                                                {moment(date).format("MMMM Do YYYY")}
                                            </span>
                                            <span className={`absolute right-6  rounded-full  transition-transform transform duration-200 ${active[index] ? 'rotate-180' : ''}`}>
                                                <MdKeyboardArrowUp size={25} />
                                            </span>
                                        </div>
                                        {active[index] && (
                                            <div className="text-start bg-white w-full max-w-6xl  transition duration-700 ease-in-out border-[#7A3C1D]">
                                                <div className="py-2 overflow-x-auto  text-black">
                                                    <div className="w-full text-sm text-gray-500 dark:text-gray-400 divide-y ">
                                                        {data?.map((item, index) => (
                                                            <div className="flex py-2 px-3">
                                                                <div className="text-gray-700 w-2/3 space-y-1">
                                                                    <div className="text-sm font-normal capitalize text-gray-600">{item.ledgerType}</div>
                                                                    <Link to={"/app/profitloss/details/" + item.marketId}>
                                                                        <div className="text-sm font-normal capitalize text-blue-600 underline cursor-pointer">{item.eventName}</div>
                                                                    </Link>
                                                                    <div>
                                                                        <span className="text-sm font-semibold capitalize text-gray-700 pr-1"> Settled Date: </span>
                                                                        <span>{moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss a")}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span className="text-sm font-semibold capitalize text-gray-700 pr-1">Winner: </span>

                                                                        {item.remark}</div>
                                                                </div>
                                                                <div className="text-black/80 w-1/3 flex justify-between">
                                                                    <div className="space-y-1.5">
                                                                        <div className="text-xs font-semibold text-gray-700 capitalize">Comm: </div>
                                                                        <div className="text-xs font-semibold text-gray-700 capitalize">Net Win: </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className={`text-xs font-semibold  capitalize ${item.balance >= 0 ? "text-green-500" : "text-red-500"}`}>{item.balance}</div>
                                                                        <div className={`text-xs font-semibold  capitalize ${item.amount >= 0 ? "text-green-500" : "text-red-500"}`}>{item.amount}</div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProfitLoss;
