import cardsjudgement from '../../../Assest/images/livecasino/cardsjudgement.webp'
import amarakbaranthony from '../../../Assest/images/livecasino/amarakbaranthony.webp'
import bollywoodcasino from '../../../Assest/images/livecasino/bollywoodcasino.webp'
import cardcasino32 from '../../../Assest/images/livecasino/cardcasino32.webp'
import cardteenpatti2 from '../../../Assest/images/livecasino/cardteenpatti2.webp'
import muflisteen from '../../../Assest/images/livecasino/muflisteen.webp'
import race217 from '../../../Assest/images/livecasino/race2-17.webp'
import superover from '../../../Assest/images/livecasino/superover.webp'


function Livecard() {
    const sliderImage = [
        { imgs: cardsjudgement, title: "cardsjudgement" },
        { imgs: amarakbaranthony, title: "amarakbaranthony" },
        { imgs: bollywoodcasino, title: "bollywoodcasino" },
        { imgs: cardcasino32, title: "cardcasino32" },
        { imgs: cardteenpatti2, title: "cardteenpatti2" },
        { imgs: muflisteen, title: "muflisteen" },
        { imgs: race217, title: "race217" },
        { imgs: superover, title: "superover" },
        { imgs: cardsjudgement, title: "cardsjudgement" },
        { imgs: amarakbaranthony, title: "amarakbaranthony" },
        { imgs: bollywoodcasino, title: "bollywoodcasino" },
        { imgs: cardcasino32, title: "cardcasino32" },
        { imgs: cardteenpatti2, title: "cardteenpatti2" },
        { imgs: muflisteen, title: "muflisteen" },
        { imgs: race217, title: "race217" },
        { imgs: superover, title: "superover" },
        { imgs: cardsjudgement, title: "cardsjudgement" },
        { imgs: amarakbaranthony, title: "amarakbaranthony" },
        { imgs: bollywoodcasino, title: "bollywoodcasino" },
        { imgs: cardcasino32, title: "cardcasino32" },
        { imgs: cardteenpatti2, title: "cardteenpatti2" },
        { imgs: muflisteen, title: "muflisteen" },
        { imgs: race217, title: "race217" },
        { imgs: superover, title: "superover" }

    ]
    
    return (
        <div className=' w-full h-full flex justify-center items-center' >
            <div className='w-[95%]   m-5 afterFooter'>
                <div className='bg-[#f36c21] flex justify-between p-2 my-2 items-center text-white font-bold text-sm'>
                    <p>Min bat :100</p>
                    <p>Max bat :100</p>
                    <p>Max Win :100</p>
                </div>
                <div className='grid grid-cols-3 lg:grid-cols-6 gap-2'>

                {sliderImage.map((image, index) => (
                    <div key={index} className="">
                                            <img src={image.imgs} alt={image.title} className='w-[500px] lg:w-[480px] rounded-md border-2 border-yellow-200 border-solid'/>
                                        </div>
                                    ))}

                                    </div>
            </div>


        </div>
    )
}

export default Livecard
