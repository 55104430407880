import React, { useEffect, useState } from "react";
import { SubHeaderMenu, SubHeaderMobile } from "./subheaderConstent";
// import MoreGame from "../../moregame/moreGame";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const SubHeader = ({ setIsGame }) => {
  const { sportmatchlist } = useSelector((state) => state.authentication);
  const [sportIdsWithLength, setSportIdsWithLength] = useState([]);

  const localMatchlist = JSON.parse(localStorage.getItem('MatchListData'));

  useEffect(() => {
    const MatchListData = sportmatchlist ? sportmatchlist : localMatchlist?.sportlist;
    if (MatchListData && MatchListData?.data) {
      const filteredDataBySportId = {};
      MatchListData?.data?.forEach(item => {
        const sportId = item.sportId;
        if (!filteredDataBySportId[sportId]) {
          filteredDataBySportId[sportId] = [];
        }
        filteredDataBySportId[sportId].push(item);
      });
      const sportIdsLength = Object.keys(filteredDataBySportId).map(sportId => ({
        sportId,
        length: filteredDataBySportId[sportId].length
      }));
      setSportIdsWithLength(sportIdsLength);
    }
  }, [sportmatchlist]);

  const navigate = useNavigate();

  const handleClickNavigate = (url) => {
    navigate(url);
  }

  const { t } = useTranslation();

  return (
    <>
      <div className="w-full ">
        <div className="lg:flex lg:justify-center border-t border-t-gray-700 bg-gradient-to-t from-black bg-opacity-50 to-gray-800 overflow-x-hidden hidden">
          <div className="max-w-screen-xl flex flex-wrap items-center lg:justify-center mx-auto ">
            <div className="items-center justify-between w-full md:flex md:w-auto py-2">
              <ul className="flex lg:gap-7 h-10 gap-2 ">
                {SubHeaderMenu?.map((item, index) => (
                  <li
                    key={index}
                    className={`border-0 rounded-full h-full lg:w-28 w-20 flex items-center justify-center group gap-x-2 hover:border-[#f36c21] hover:border`}
                  >
                    <div
                      onClick={() => handleClickNavigate(item.link)}
                      className={`h-10 flex items-center py-2 md:text-white md:p-0 group-hover:text-[#f36c21] ${item.count === 2003 && "blink"}`}
                    >
                      <img
                        src={item.icon}
                        alt={item.text}
                        className="mx-2 w-5 h-5"
                      />

                      {sportIdsWithLength.map((count, i) => {
                        if (item.count === count.sportId) {
                          return (
                            <sup key={`sup-${item.count}-${i}`}>
                              <div className="w-5 h-5 bg-orange-600 rounded-full flex justify-center items-center -ml-4">
                                <span className="text-sm font-bold text-gray-300 flex justify-center items-center">
                                  {count.length}
                                </span>
                              </div>
                            </sup>
                          );
                        }
                        return null;
                      })}
                      <span className="text-sm font-normal capitalize whitespace-nowrap">
                        {item.text}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="px-2 py-3 w-full lg:hidden block">
          <div className="border-0 rounded-2xl bg-[#3d3d3d] flex justify-center items-center">
            <ul className="w-full flex justify-center items-center">
              {SubHeaderMobile.map((item, index) => (
                <li
                  key={index}
                  className={`md:px-5 px-3 md:py-2 py-1 h-14 group hover:border-[#f36c21] w-full ${index < 5 ? "border-r border-r-[#bbbaba]" : ""}`}
                  onClick={() => {
                    if (index === SubHeaderMobile.length - 1) {
                      setIsGame(true);
                    }
                  }}
                  data-drawer-target="drawer-bottom-example"
                  data-drawer-show="drawer-bottom-example"
                  data-drawer-placement="bottom"
                  aria-controls="drawer-bottom-example"
                >
                  <Link
                    to={item.link}
                    className="md:text-white md:p-0 flex justify-center group-hover:text-[#f36c21]"
                  >
                    <img
                      src={item.icon}
                      alt={item.text}
                      className="w-6 h-6"
                    />
                    {sportIdsWithLength.map((count, i) => {
                      if (item.count === count.sportId) {
                        return (
                          <sup key={`sup-mobile-${item.count}-${i}`}>
                            <div className="h-6 w-6 bg-orange-500 rounded-full flex justify-center items-center -ml-3">
                              <span className="text-xs font-bold text-white flex justify-center items-center">
                                {count.length}
                              </span>
                            </div>
                          </sup>
                        );
                      }
                      return null;
                    })}
                  </Link>

                  <span className="text-xs w-full flex justify-center py-1 text-white whitespace-nowrap">
                    {item.text}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubHeader;
