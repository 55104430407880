import { authHeader } from '../middelware/Auth'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function onerrorlogout() {
  localStorage.removeItem('clientUser');
  window.location.href = '/login' 
  // Redirect to login page if needed
}

function logoutTemp() {
  localStorage.removeItem('clientUser');
  window.location.href = '/login' 
  // Redirect to login page if needed
}

let domainSetting = (localStorage.getItem('clientdomainSetting') && localStorage.getItem('clientdomainSetting') != "undefined" && localStorage.getItem('clientdomainSetting') != "null" ) ?  JSON.parse(localStorage.getItem('clientdomainSetting')) : null;
export const CONST = {
  
  BACKEND_URL: 'https://api.sikander777.com/v1/',
  BACKEND_SOCKET_URL: 'https://api.sikander777.com/',
  BASE_DOMAIN: domainSetting && domainSetting.domainUrl ? domainSetting.domainUrl : 'https://sikander777.com',
  BASE_IMAGE_DOMAIN: domainSetting && domainSetting.imgBaseUrl ? domainSetting.imgBaseUrl : 'https://sikander777.com/images1',


  //   BACKEND_URL: 'https://api.urbet99.com/v1/',
  // BACKEND_SOCKET_URL: 'https://api.urbet99.com/',
  // BASE_DOMAIN: domainSetting && domainSetting.domainUrl ? domainSetting.domainUrl : 'https://api.urbet99.com',
  // BASE_IMAGE_DOMAIN: domainSetting && domainSetting.imgBaseUrl ? domainSetting.imgBaseUrl : 'https://api.urbet99.com/images1',

  // BACKEND_URL: 'https://api.crickexpo.net/v1/',
  // BACKEND_SOCKET_URL: 'https://api.crickexpo.net/',
  // BASE_DOMAIN: domainSetting && domainSetting.domainUrl ? domainSetting.domainUrl : 'https://crickexpo.net',
  // BASE_IMAGE_DOMAIN: domainSetting && domainSetting.imgBaseUrl ? domainSetting.imgBaseUrl : 'https://crickexpo.net/images1',

  // BACKEND_URL: 'http://192.168.1.17:5001/v1/',
  // BACKEND_SOCKET_URL: 'http://192.168.1.17:5001',

};

async function login(data) {
  const header = {
    'Content-Type': 'application/json',
    'Authorization': authHeader().Authorization
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `user/login`, requestOptions);
    const user = await handleResponse(response);
    if (user) {
      localStorage.setItem('clientUser', JSON.stringify(user));
      localStorage.setItem('clientbetChipsData', JSON.stringify(user?.data?.betChipsData));
      return { userinfo: user };
    }
  } catch (error) {
    console.error("Login error:", error);
    return Promise.reject(error);
  }
}

function logout() {
  localStorage.removeItem('clientUser');
}


async function sportMatchList(data) {
  const header = {
    'Content-Type': 'application/json',
    // 'Authorization': authHeader().Authorization
  };
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  };
  try {
    const response = await fetch(CONST.BACKEND_URL + `sports/matchList`, requestOptions);
    const user = await handleResponse(response);
    return { sportlist: user };

  } catch (error) {

    console.error("Sport Match List", error);
    return Promise.reject(error);
  }
}


function changePassword(data) {
  let header = new Headers({
    'Content-Type': 'application/json',
    "Authorization": authHeader().Authorization
  });

  const requestOptions = {
    method: "PATCH",
    headers: header,
    body: JSON.stringify(data)
  }
  return fetch(CONST.BACKEND_URL + `user/updateUserPassword`, requestOptions)
    .then(handleResponse)
    .then(data => {

      let userObj = {
        changePassword: data.message
      }

      return userObj;
    });
}

function userUpdate(data) {
  let header = new Headers({
    'Content-Type': 'application/json',
    "Authorization": authHeader().Authorization
  });
  const requestOptions = {
    method: "PATCH",
    headers: header,
    body: JSON.stringify(data)
  }
  return fetch(CONST.BACKEND_URL + `user/userUpdate`, requestOptions)
    .then(handleResponse)
    .then(data => {
      let userObj = {
        userUpdate: data
      }

      return userObj;
    });
}

function userBalance(data) {
  let header = new Headers({
    'Content-Type': 'application/json',
    "Authorization": authHeader().Authorization
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  }
  return fetch(CONST.BACKEND_URL + `user/userBalance`, requestOptions)
    .then(handleResponse)
    .then(data => {

      let userObj = {
        userBalance: data.data
      }

      if (data) {
        localStorage.setItem('clientBalance', JSON.stringify(data.data.coins));
        localStorage.setItem('clientExposure', JSON.stringify(data.data.exposure));
      }
      return userObj;
    });
}


function save_bet(data) {
  let header = new Headers({
    'Content-Type': 'application/json',
    "Authorization": authHeader().Authorization
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  }
  return fetch(CONST.BACKEND_URL + `sports/oddBetPlaced`, requestOptions)
    .then(handleResponse)
    .then(data => {

      let userObj = {
        errorData: data,
        save_bet: data.data,
        responseData: JSON.parse(requestOptions.body)
      }
      if (data) {
        localStorage.setItem('clientBalance', JSON.stringify(data.data.totalCoins));
        localStorage.setItem('clientexposure', JSON.stringify(data.data.exposure));
        localStorage.setItem('clientposition', JSON.stringify(data.data.matchPosition));
      }
      return userObj;
    });
}

function save_bet_fancy(data) {
  let header = new Headers({
    'Content-Type': 'application/json',
    "Authorization": authHeader().Authorization
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  }
  return fetch(CONST.BACKEND_URL + `sports/sessionBetPlaced`, requestOptions)
    .then(handleResponse)
    .then(data => {

      let userObj = {
        errorDataFancy: data,
        save_betFancy: data.data,
        responseDataFancy: JSON.parse(requestOptions.body)
      }
      if (data) {
        // let finalBalance = {
        //     mainBlance: data.data.totalCoins,
        //     exposureBalance: data.data.exposure
        // }
        // localStorage.setItem('finalBalance', JSON.stringify(finalBalance));
        localStorage.setItem('clientBalance', JSON.stringify(data.data.totalCoins));
        localStorage.setItem('clientexposure', JSON.stringify(data.data.exposure));
      }
      return userObj;
    });
}


function userPositionByMarketId(data) {
  let header = new Headers({
    'Content-Type': 'application/json',
    "Authorization": authHeader().Authorization
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  }
  return fetch(CONST.BACKEND_URL + `sports/userPositionByMarketId`, requestOptions)
    .then(handleResponse)
    .then(data => {

      let userObj = {
        userPositionByMarketId: data.data,

      }
      return userObj;
    });
}

function domainSettingByDomainName(data) {
  let header = new Headers({
    'Content-Type': 'application/json',
    "Authorization": authHeader().Authorization
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  }
  return fetch(CONST.BACKEND_URL + `website/domainSettingByDomainName`, requestOptions)
    .then(handleResponse)
    .then(responseData => {
      let userObj = {
        domainSettingByDomainName: responseData.data
      }
      if (data && data.data && data.data != undefined && data.data != null) {
         localStorage.setItem('clientdomainSetting', JSON.stringify(data.data));
      }
      return userObj;
    });
}


function getUserLedger(data) {
  let header = new Headers({
    'Content-Type': 'application/json',
    "Authorization": authHeader().Authorization
  });
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify(data)
  }
  return fetch(CONST.BACKEND_URL + `user/userLedger`, requestOptions)
    .then(handleResponse)
    .then(data => {

      let userObj = {
        getUserLedger: data.data
      }

      // if (data) {
      //     localStorage.setItem('userLedger', JSON.stringify(data.data.ledgerData));
      // }
      return userObj;
    });
}

function accountStatement(data) {
  let header = new Headers({
      'Content-Type': 'application/json',
      "Authorization": authHeader().Authorization
  });
  const requestOptions = {
      method: "POST",
      headers: header,
      body: JSON.stringify(data)
  }
  return fetch(CONST.BACKEND_URL + `user/userStatement`, requestOptions)
      .then(handleResponse)
      .then(data => {
          let userObj = {
            accountStatement: data.data
          }
          return userObj;
      });
}




async function handleResponse(response) {
  const text = await response.text();
  const data = text && JSON.parse(text);
  if (!response.ok) {
    if (response.status === 401) {
      logoutTemp();
    }
    const error = (data && data.message) || response.statusText;
    throw error; // Throwing error to be caught in the catch block of login function
  }
  if (data.error) {
    if (data.code === 3) {
      onerrorlogout();
    }
    const error = (data && data.message) || response.statusText;
    throw error; // Throwing error to be caught in the catch block of login function
  }
  return data;
}


export const userApis = {
  login,
  logout,
  sportMatchList,
  changePassword,
  userUpdate,
  userBalance,
  save_bet,
  save_bet_fancy,
  userPositionByMarketId,
  domainSettingByDomainName,
  getUserLedger,
  accountStatement
};


