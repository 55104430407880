import { userConstent } from '../constent/userConstent';

let user = JSON.parse(localStorage.getItem('clientUser'));
const initialState = user ? { loggedIn: true, user } : {};


export function authentication(state = initialState, action) {


  switch (action.type) {

    case userConstent.USER_LOGIN_REQUEST:
      return {
        ...state,
        loggingIn: false
      };
    case userConstent.USER_LOGIN_SUCCESS:
      return {
        ...state,
        // otpSent: true,
        loggingIn: true,
        userDetails: action.user.userinfo
      };
    case userConstent.USER_LOGIN_FAILEAR:
      return {};
    case userConstent.LOGOUT:
      return {};
    // MATCHlIST REDUCER
    case userConstent.SPORT_MATCHLIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstent.SPORT_MATCHLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        sportmatchlist: action.user.sportlist

      };
    case userConstent.SPORT_MATCHLIST_FAILURE:

    // Update password 

    case userConstent.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstent.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        addUserSuccess: false
      };
    case userConstent.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstent.USER_UPDATE_REQUEST:
      return {
        ...state,
        loading: false
      };
    case userConstent.USER_UPDATE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
      };
    case userConstent.USER_UPDATE_FAILURE:

    case userConstent.USER_BALANCE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstent.USER_BALANCE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        userBalance: action.users.userBalance,
      };
    case userConstent.USER_BALANCE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstent.USER_POSITION_BY_MARKETID_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case userConstent.USER_POSITION_BY_MARKETID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        getUserPositionItem: action.users.userPositionByMarketId,
      };
    case userConstent.USER_POSITION_BY_MARKETID_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstent.SAVE_BET_REQUEST:
      return {
        ...state,
        betLoading: true,
        betStatus: false,
      };
    case userConstent.SAVE_BET_SUCCESS:
      return {
        ...state,
        betLoading: false,
        addUserSuccess: true,
        betStatus: true,
        errorData: action.users.errorData,
        savebetItems: action.users.save_bet,
        responseDataItem: [action.users.responseData],

      };
    case userConstent.SAVE_BET_FAILURE:
      return {
        ...state,
        error: action.error,
        betLoading: false,
        betStatus: false,
      };

    case userConstent.SAVE_BET_FANCY_REQUEST:
      return {
        ...state,
        betLoading: true
      };
    case userConstent.SAVE_BET_FANCY_SUCCESS:
      return {
        ...state,
        betLoading: false,
        addUserSuccess: true,
        errorDataFancy: action.users.errorDataFancy,
        savebetItemsFancy: action.users.save_betFancy,
        responseDataItemFancy: [action.users.responseDataFancy],
      };
    case userConstent.SAVE_BET_FANCY_FAILURE:
      return {
        ...state,
        error: action.error,
        betLoading: false,
      };

    case userConstent.DOMAIN_SETTING_BY_DOMAIN_NAME_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case userConstent.DOMAIN_SETTING_BY_DOMAIN_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        domainSettingByDomainName: action.users.domainSettingByDomainName,
      };
    case userConstent.DOMAIN_SETTING_BY_DOMAIN_NAME_FAILURE:


    case userConstent.USER_LEDGER_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case userConstent.USER_LEDGER_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        getUserLedger: action.users.getUserLedger,
      };
    case userConstent.USER_LEDGER_FAILURE:
      return {
        ...state,
        error: action.error
      };

      case userConstent.USER_ACCOUNT_DETAILS_REQUEST:
        return {
          ...state,
          loading: true
        };
      case userConstent.USER_ACCOUNT_DETAILS_SUCCESS:
        return {
          ...state,
          loading: false,
          addUserSuccess: false,
          accountStatement: action.users.accountStatement,
        };
      case userConstent.USER_ACCOUNT_DETAILS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };


    default:
      return state
  }
}
