import aboutus from '../../../Assest/images/sidebar/about-us.webp'
import market from '../../../Assest/images/sidebar/market.webp'
import marketWhite from '../../../Assest/images/sidebar/market-white.webp'
import news from '../../../Assest/images/sidebar/news.webp'
import affiliate from '../../../Assest/images/sidebar/affiliate.webp'
import faqs from '../../../Assest/images/sidebar/faqs.webp'
import help from '../../../Assest/images/sidebar/help.webp'
import loyalty from '../../../Assest/images/sidebar/loyalty.webp'
import onlinebettingapp from '../../../Assest/images/sidebar/online-betting-app.webp'
import privacy from '../../../Assest/images/sidebar/privacy.webp'
import promotions from '../../../Assest/images/sidebar/promotions.webp'
import termsandconditions from '../../../Assest/images/sidebar/terms-and-conditions.webp'
import aboutuswhite from '../../../Assest/images/sidebar/about-us-white.webp'
import affiliatewhite from '../../../Assest/images/sidebar/affiliate-white.webp'
import faqswhite from '../../../Assest/images/sidebar/faqs-white.webp'
import helpwhite from '../../../Assest/images/sidebar/help-white.webp'
import loyaltywhite from '../../../Assest/images/sidebar/loyalty-white.webp'
import newswhite from '../../../Assest/images/sidebar/news-white.webp'
import privacywhite from '../../../Assest/images/sidebar/privacy-white.webp'
import promotionswhite from '../../../Assest/images/sidebar/promotions-white.webp'
import termsandconditionswhite from '../../../Assest/images/sidebar/terms-and-conditions-white.webp'
import AboutUs from '../../../pages/Public/AboutUs/AboutUs';




export const DrawerList = [
   {
    title: 'About us',
    link: '/app/about-us',
    icon1: aboutus,
    icon2: aboutuswhite,
  },

  {
    title: 'Privacy Policy',
    link: '/app/privacy-policy',
    icon1: privacy,
    icon2: privacywhite
  },
  {
    title: 'Faqs',
    link: "/app/faqs",
    icon1: faqs,
    icon2: faqswhite
  },
  {
    title: 'Pramotion',
    link: '/app/promotions',
    icon1: promotions,
    icon2: promotionswhite
  },
  {
    title: 'Loyalty',
    link: '/app/loyalty',
    icon1: loyalty,
    icon2: loyaltywhite,
  },
  {
    title: 'Affilate',
    link: '/app/affillate-partner',
    icon1: affiliate,
    icon2: affiliatewhite,
  },
  {
    title: 'T&C',
    link: '/app/term-conditon',
    icon1: termsandconditions,
    icon2: termsandconditionswhite
  },
  {
    title: 'Download App',
    link: '#',
    icon1: onlinebettingapp,
    icon2: onlinebettingapp
  },
  //.........................................

  // {
  //   icon: Rg_icon,
  //   title: "RG Casino",
  //   text: "RG Casino",
  //   link: 0,
  // },
  // {
  //   icon: ezugI,
  //   title: "Ezugi",
  //   text: "Ezugi",
  //   link: 0,
  // },
  // {
  //   icon: SEXY_ICON,
  //   title: "Wheel",
  //   text: "Wheel",
  //   link: 0,
  // },
  // {
  //   icon: Rg_icon,
  //   title: "RG Casino",
  //   text: "RG Casino",
  //   link: 0,
  // },
  // {
  //   icon: Rg_icon,
  //   title: "RG Casino",
  //   text: "RG Casino",
  //   link: 0,
  // },

];