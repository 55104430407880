
import React, { createContext, useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { getSocket, initSocket } from '../SocketConnection/SocketConnection';


const BalanceContext = createContext();

export const BalanceProvider = ({ children }) => {
  const [balance, setBalance] = useState({
    coins: JSON.parse(localStorage.getItem("clientBalance")) || "",
    exposure: JSON.parse(localStorage.getItem("clientExposure")) || ""
  });

  const dispatch = useDispatch()
  useEffect(() => {
    updateSocket();

    function updateSocket() {
      let userID = JSON.parse(localStorage.getItem("clientUser"));
      let token_Id = userID?.token;
      let socket = getSocket();

      if (!socket || socket == null) {
        socket = initSocket(token_Id);
      }

      const loginData = {
        userId: userID?.data?.userId,
        token: token_Id,
      };

      socket.emit(`login`, loginData);

      socket.on("userLoggedOut", (data) => {
        console.warn('userLoggedOut socket');
        if (data == userID?.data?.userId){
          // return dispatch(userSignOut()) ff
          localStorage.clear()
          window.location.href = '/login'
        }
      });

      socket.on("userLoggedIn", (data) => {
        console.warn('userLoggedIn socket');
      });


      socket.on("coinUpdate", (data) => {
        console.log("coinUpdate socket");
        localStorage.setItem("clientBalance", JSON.stringify(data?.coins));
        localStorage.setItem("clientExposure", JSON.stringify(data?.exposure));
        setBalance({
          coins: data.coins,
          exposure: data.exposure,
        });
      });

      socket.on("fetchCasinoBetList", (data) => {
        console.log("fetchCasinoBetList scoket");
      });


    }
  }, []);

  return (
    <BalanceContext.Provider value={{ balance, setBalance }}>
      {children}
    </BalanceContext.Provider>
  );
};

export const useBalance = () => {
  return useContext(BalanceContext);
};
