import React from "react";
import astropay from "../../Assest/images/payment/astropay.png";
import bankdeposit from "../../Assest/images/payment/bank-deposit.png";
import bitcoin from "../../Assest/images/payment/bitcoin.png";
import ethereum from "../../Assest/images/payment/ethereum.png";
import gpay from "../../Assest/images/payment/gpay.png";
import paypal from "../../Assest/images/payment/paypal.png";
import paytm from "../../Assest/images/payment/paytm.png";
import phonepe from "../../Assest/images/payment/phonepe.png";
import tether from "../../Assest/images/payment/tether.png";
import upi from "../../Assest/images/payment/upi.png";

const Payment = () => {
  const paymentImage = [
    bankdeposit,
    gpay,
    paytm,
    phonepe,
    upi,
    paypal,
    ethereum,
    bitcoin,
    tether,
    // astropay,
  ];

  return (
    <div className="flex justify-center">
      <div className="grid grid-cols-5 px-5 py-5 gap-3 md:grid-cols-10 sm:grid-cols-7 lg:grid-cols-5 xl:grid-cols-7 w-full">
        {paymentImage.map((imageUrl, index) => (
          <div
            key={index}
            className={`bg-white border-0 rounded-md ${
              index === 7 ? "xl:col-start-3" : ""
            }`}
          >
            <div className="flex justify-center items-center md:px-2 py-4 px-1 lg:h-28 h-6 ">
              <img src={imageUrl} alt={`Payment ${index + 1}`} className="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Payment;
