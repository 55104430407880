import React from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import banner1 from '../../Assest/images/banner/staticBanner.webp'
import banner2 from '../../Assest/images/banner/staticBanner2.webp'
import banner3 from '../../Assest/images/banner/main-banner2.webp'


const staticBannerImg = [
    banner1,
    banner2,
    banner3
]


const Banner = () => {

    const settings = {
        autoplay: true,
        autoplaySpeed: 2500,
        arrows: false,
        centerMode: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: true,
                    infinite: true,

                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: true,
                    infinite: true,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    initialSlide: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    autoplay: true,
                    slidesToScroll: 1
                }
            }
        ]
    };

    let domainSetting = JSON.parse(localStorage.getItem('clientdomainSetting'));


    return (
        <>
            <div className="slider">
                {domainSetting?.banner?.length > 0 ? (
                    <Slider {...settings}>
                        {domainSetting.banner?.sort((a, b) => a.priority - b.priority)?.map((req, index) => (
                            <div key={index} className="border-0 rounded-lg">
                                <img
                                    src={req.image}
                                    alt={req.name}
                                    className="border-0 rounded-lg w-[97%] 2xl:h-64 lg:h-52 sm:h-48 h-32"
                                />
                            </div>
                        ))}
                    </Slider>
                ) :
                    <Slider {...settings}>
                        {staticBannerImg?.map((req, index) => (
                            <div key={index} className="border-0 rounded-lg">
                                <img
                                    src={req}
                                    alt={req + 1}
                                    className="border-0 rounded-lg w-[97%] 2xl:h-64 lg:h-52 sm:h-48 h-32"
                                />
                            </div>
                        ))}
                    </Slider>
                }
            </div>

        </>
    )
}

export default Banner;