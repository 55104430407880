import React from 'react';
import { FaLock } from "react-icons/fa";

export default function BetLockedRoundedBmx() {
  return (
    <div className="absolute top-0 bg-black/70 flex justify-center items-center w-full h-full cursor-pointer">
      {/* <img src='/casino-images/padlock.png' alt='Lock' /> */}
      <FaLock size={16} className="text-white" />
    </div>
  );
}

