import React, { useEffect, useState } from "react";

import { PromotionJson } from "./PromotionsJson";
import { BsDot } from "react-icons/bs";
import NavigatePathComponent from "../../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent";
import FAQ from "../../../component/Common/Faqs/Faqs";
import { websiteName } from "../../../globleConstent/membership/membership"


const Promotions = () => {

  const [faqs, setFaqs] = useState([])
  useEffect(() => {
    setFaqs(PromotionJson);
  }, [])

  const toggleFAQ = index => {

    setFaqs(
      faqs?.map((faq, i) => {

        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <div className="slider hota hai wo hatan hai bootm se  wo header se hai">

      <NavigatePathComponent showPathName={"Pramotion"} />
      <section class="w-full h-full max-w-7xl mx-auto justify-center items-center mt-5 mb-20">
        <div>
          <div class="faqs">
            {faqs.map((faq, index) => (
              <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
            ))}
          </div>

          <div class="text-white text-center lg:px-2 px-4">
            <span class="flex justify-center py-2 items-center text-2xl font-medium">Rules And Regulations</span>
            <div class=" w-full py-2 flex justify-center items-center">
              <div class="flex w-full max-w-2xl px-2 pt-5 pb-10 bg-[#1E1E1E] text-start  border rounded-xl border-[#F4DA7B]">
                <span className=""><BsDot className="text-white" size={50} /></span>
                <span>{websiteName} withholds the rights to terminate or alter the offered promotions, turnover logics and all things related without any prior notification.</span>

              </div>
            </div>
          </div>
        </div>
      </section>

    </div>

  );
};
export default Promotions;
