import cricket from '../../../Assest/images/game/cricket.webp'
import soccer from '../../../Assest/images/game/soccer.webp'
import tennis from '../../../Assest/images/game/tennis.webp'
import Election from "../../../Assest/images/SubHeaderImage/Election.webp";

// import cardicon from '../../Assest/images/game/cardicon.webp'
// import sloticon from '../../Assest/images/game/sloticon.webp'
// import formula1 from '../../Assest/images/game/formula1.webp'
// import basketball from '../../Assest/images/game/basketball.webp'
// import batmental from '../../Assest/images/game/batmental.webp'
// import golf from '../../Assest/images/game/golf.webp'
// import counterstrick from '../../Assest/images/game/counterstrick.webp'
// import baseball from '../../Assest/images/game/baseball.webp'
// import rougs from '../../Assest/images/game/rougs.webp'
// import boxing from '../../Assest/images/game/boxing.webp'
// import fifa from '../../Assest/images/game/fifa.webp'
// import bollyball from '../../Assest/images/game/bollyball.webp'
// import fifa22 from '../../Assest/images/game/fifa22.webp'
// import dota from '../../Assest/images/game/dota.webp'
// import ecricket from '../../Assest/images/game/ecricket.webp'
// import icehockey from '../../Assest/images/game/ice-hockey.webp'
// import handball from '../../Assest/images/game/handball.webp'
// import cycling from '../../Assest/images/game/cycling.webp'
// import darts from '../../Assest/images/game/darts.webp'
// import efigting from '../../Assest/images/game/efigting.webp'
// import snooker from '../../Assest/images/game/snooker.webp'
// import americanfootbal from '../../Assest/images/game/american-footbal.webp'
// import nba from '../../Assest/images/game/nba.webp'
// import waterpolo from '../../Assest/images/game/waterpolo.webp'
// import bols from '../../Assest/images/game/bols.webp'
// import tabletennis from '../../Assest/images/game/table-tennis.webp'
// import motercycle from '../../Assest/images/game/motercycle.webp'

// import {}  from '../../Assest/images/game/'
// import {}  from '../../Assest/images/game/'



export const SportlistCostent = [

    {
        icon: cricket,
        title: "Cricket",
        text: "Cricket",
        count: "9",
        link: 4,
    },
    {
        icon: soccer,
        title: "Soccer",
        text: "Soccer",
        count: "32",
        link: 1,
    },
    {
        icon: tennis,
        title: "Tennis",
        text: "Tennis",
        count: "7",
        link: 2,
    },
    // {
    //     icon: cardicon,
    //     title: "Live Card",
    //     text: "Live Card",
    //     link: "#",

    // },
    // {
    //     icon: sloticon,
    //     title: "Slot Games",
    //     text: "Slot Games",
    //     link: "#",

    // },
    {
        icon: Election,
        title: "Election",
        text: "Election",
        link: 2003,

    },
    // {
    //     icon: basketball,
    //     title: "Ezugi",
    //     text: "Ezugi",
    //     link: 201153,

    // },
    // {
    //     icon: batmental,
    //     title: "Wheel",
    //     text: "Wheel",
    //     link: 100000,

    // },
    // {
    //     icon: golf,
    //     title: "Ludo",
    //     text: "Ludo",
    //     link: 600113,

    // },
    // {
    //     icon: counterstrick,
    //     title: "Aviato",
    //     text: "Aviato",
    //     link: 201206,

    // },
    // {
    //     icon: baseball,
    //     title: "Supernova",
    //     text: "Supernova",
    //     link: 500001,

    // },
    // {
    //     icon: rougs,
    //     title: "AE Sexy",
    //     text: "AE Sexy",
    //     link: 400000,

    // },
    // {
    //     icon: boxing,
    //     title: "Jilli",
    //     text: "Jilli",
    //     link: 600000,

    // },
    // {
    //     icon:fifa,
    //     title:"Fifa",
    //     text:"Fifa",

    // },
    // {
    //     icon:bollyball,
    //     title:"Volleball",
    //     text:"Volleball",

    // },
    // {
    //     icon:fifa22,
    //     title:"Fifa22volta",
    //     text:"Fifa22-volta-",

    // },
    // {
    //     icon:dota,
    //     title:"Dota2",
    //     text:"Dota2",

    // },
    // {
    //     icon:ecricket,
    //     title:"Ecricket",
    //     text:"Ecricket",

    // },
    // {
    //     icon:icehockey,
    //     title:"Ice-hockey",
    //     text:"Ice-hockey",

    // },
    // {
    //     icon:handball,
    //     title:"Handball",
    //     text:"Handball",

    // },
    // {
    //     icon:cycling,
    //     title:"Cycling",
    //     text:"Cycling",

    // },
    // {
    //     icon:darts,
    //     title:"Darts",
    //     text:"Darts",

    // },
    // {
    //     icon:efigting,
    //     title:"Efighting",
    //     text:"Efighting",

    // },
    // {
    //     icon:snooker,
    //     title:"Snooker",
    //     text:"Snooker",

    // },
    // {
    //     icon:americanfootbal,
    //     title:"Americalfootball",
    //     text:"Americal-football",

    // },
    // {
    //     icon:nba,
    //     title:"NBA2K19",
    //     text:"NBA2K19",

    // },

    // {
    //     icon:waterpolo,
    //     title:"Waterpolo",
    //     text:"Waterpolo",

    // },
    // {
    //     icon:bols,
    //     title:"Bowls",
    //     text:"Bowls",

    // },

    // {
    //     icon:tabletennis,
    //     title:"Tabletenis",
    //     text:"Table-tenis",

    // },
    // {
    //     icon:motercycle,
    //     title:"Motorcycleracing",
    //     text:"Motorcycle-racing",

    // },

]