import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { httpPost } from "../../../middelware/Http";
import 'react-toastify/dist/ReactToastify.css';
import NavigatePathComponent from "../../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent";
import moment from 'moment';


function DepositWidrawal() {
    const [depositWidrawalList, setDepositWidrawalList] = useState();


    const [payloadData, setPayloadData] = useState({
        fromDate: moment().format('YYYY-MM-DD'),
        toDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        status: 'all'
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPayloadData({
            ...payloadData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const res = await httpPost('website/getDepositWithdrawList', payloadData);
        // if (res) {
        //     setDepositWidrawalList(res.data)
        // }
    };


    useEffect(() => {
        getDepositWithdrawList();
    }, [])

    async function getDepositWithdrawList() {
        // let resData = {
        //     "status": "pending",
        //     "fromDate": "2024-05-05",
        //     "toDate": "2024-05-12"
        // }
        const res = await httpPost('website/getDepositWithdrawList')
        if (res) {
            setDepositWidrawalList(res);
        }
    }

    return (
        <>

            <NavigatePathComponent showPathName={"Deposit Widrawal Transaction"} />
            <section className="w-full bg-black max-w-7xl mx-auto h-fit flex justify-center py-4">
                <div className="relative mx-auto text-center w-full max-w-5xl bg-trasparent afterFooter  shadow-xl ring-1 ring-gray-900/5 sm:rounded-md">
                    <form onSubmit={handleSubmit} >
                        <div className="flex justify-start relative lg:gap-3 gap-1">
                          
                            <input
                                type="date"
                                name="toDate"
                                id="toDate"
                                value={payloadData.toDate}
                                onChange={handleChange}
                                className="border bg-white rounded-full px-2 py-1"
                            />
                              <input
                                type="date"
                                name="fromDate"
                                id="fromDate"
                                value={payloadData.fromDate}
                                onChange={handleChange}
                                className="border bg-white rounded-full px-2 py-1"
                            />
                            <select name="status"
                                value={payloadData.status}
                                onChange={handleChange}
                                id="countries"
                                className="bg-gray-50 rounded-full max-w-[14rem] border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option valeue='all' className="rounded-full">All</option>
                                <option value="pending">Pending</option>
                                <option value="approve">Approve</option>
                                <option value="cancel">Cancel</option>
                            </select>

                            <button type="submit" className="bg-[#f36c21] text-white text-xs px-4 py-0.5 lg:w-72 w-56 rounded-full">Search</button>
                        </div>
                    </form>



                    {depositWidrawalList && depositWidrawalList.data && depositWidrawalList.data.length > 0 ? (
                        <div className="py-3 overflow-y-auto">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                                <thead className="text-xs text-gray-500 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr className="bg-[#1E1E1E] text-center">
                                        <th scope="col" className="px-6 lg:py-3 py-1.5">Date</th>
                                        <th scope="col" className="px-6 lg:py-3 py-1.5">Amount</th>
                                        <th scope="col" className="px-6 lg:py-3 py-1.5">Type</th>
                                        <th scope="col" className="px-6 lg:py-3 py-1.5">UTR</th>
                                        <th scope="col" className="px-6 lg:py-3 py-1.5">Desc</th>
                                        <th scope="col" className="px-6 lg:py-3 py-1.5">Status</th>
                                        <th scope="col" className="px-6 lg:py-3 py-1.5">Ip</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {depositWidrawalList && depositWidrawalList.data && depositWidrawalList.data.length > 0 ? depositWidrawalList.data.map((element, index) => (
                                        <tr key={index} className="bg-white border-b text-center dark:bg-gray-800 dark:border-gray-700 space-y-2">
                                            <td className="text-sm font-bold uppercase text-gray-500">{element && element.createdAt ? moment(element.createdAt).utcOffset("+05:30").format("DD-MM-YYYY HH:mm:ss A") : ''}</td>
                                            <td className={`${element.status === "accept" && element.reqType === "deposit" ? "text-red-600" : element.status === "accept" && element.reqType === "withdraw" ? "text-green-600" : "text-gray-500"}`}>
                                                <span className="text-sm font-bold text-center uppercase">{element.amount}</span>
                                            </td>
                                            <td className={`px-2 py-2 text-start ${element.reqType === "withdraw" ? "text-green-700" : "text-red-600"}`}>
                                                <span className="text-sm font-bold text-center uppercase">{element.reqType}</span>
                                            </td>
                                            <td className="text-sm font-bold uppercase text-gray-500">{element.utrNo}</td>
                                            <td className="text-sm font-bold uppercase text-gray-500">{element.remark}</td>
                                            <td className={`px-2 py-2 text-start ${element.status === "pending" ? "text-yellow-500" : element.status === "accept" ? "text-green-600" : "text-red-600"}`}>
                                                <span className="text-sm font-bold text-center uppercase">{element.status}</span>
                                            </td>
                                            <td>{element.ip}</td>
                                        </tr>
                                    )) : null}

                                </tbody>
                            </table>
                        </div>
                    ) : (null)}

                </div>
            </section >
        </>
    )
}

export default DepositWidrawal