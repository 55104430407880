import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IoIosPlay } from 'react-icons/io';
import { userAction } from '../../../redux/action/userAction';

function Leadues() {
    let { sportId } = useParams();
    const [matchNames, setMatchNames] = useState([]);
    const { sportmatchlist } = useSelector((state) => state.authentication);

    useEffect(() => {
        dispatch(userAction.sportMatchList());
    }, []);

    useEffect(() => {
        if (sportmatchlist && sportmatchlist.data) {

            if (sportId) {
                const filteredAndMappedData = sportmatchlist.data?.filter(item => item.sportId == sportId);
                const sortedMatches = filteredAndMappedData.sort((a, b) => new Date(a.matchDate) - new Date(b.matchDate));
                return setMatchNames(sortedMatches);
            }

            const sortedMatches = sportmatchlist?.data.sort((a, b) => new Date(a.matchDate) - new Date(b.matchDate));

            setMatchNames(sortedMatches);
        }
    }, [sportmatchlist, sportId]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate("/");
    };
    const handelClickViewMatch = (element) => {
        navigate(`/app/viewMatchDetail/${element.marketId}/${element.eventId}`);
        window.location.reload()
    };
    return (
        <section className='w-full max-w-7xl px-4 rounded-t-xl bg-[#0E0E0E]'>
            {matchNames && matchNames.length > 0 ? matchNames.map((element, index) => (
                <div key={index} className={`py-2 hover:bg-[#313131] text-sm font-base ${index !== matchNames.length - 1 ? "border-b border-gray-500" : ""}`}>
                    <span className="truncate px-4 py-1">
                        {element && (element.matchName !== null || element.matchName !== undefined) ? element.matchName : element.sportName}
                    </span>
                </div>
            )) : null}
        </section>

    )
}

export default Leadues