import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import {
    MdOutlineKeyboardArrowLeft,
} from "react-icons/md";
import 'react-loading-skeleton/dist/skeleton.css'
import SportListSidebar from "../../../component/Auth/SportListSidebar/SportListSidebar.js";
import 'react-toastify/dist/ReactToastify.css';
import OpenBets from "../../../component/Auth/OpenBet/OpenBet.js";
import Leadues from "../../../component/Auth/Leadues/Leadues.js";
import LiveUpcomingMatch from '../../../component/Auth/LiveUpcomingMatch/LiveUpcomingMatch.js';

const SportsbookPage = () => {
    let { sportId, eventId } = useParams();
    let [liveUpcomingModal, setLiveUpcomingModal] = useState(true);
    let [LeaguesModal, setLeaguesModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, [sportId])

    const handleOnClick = () => {
        navigate(-1);
    };

    const openModaliveUpcoming = () => {
        setLiveUpcomingModal(true);
        setLeaguesModal(false)
    }

    const openModalLeagues = () => {
        setLiveUpcomingModal(false);
        setLeaguesModal(true);
    }
    return (
        <section>
            <div className="bg-[#272727] w-full px-3 flex justify-center">
                <div className="lg:w-3/4  flex items-center px-4 h-10 ">

                    <div className="lg:w-3/4  flex justify-center text-xs items-center gap-2 px-4 cursor-pointer">
                        <div className="hover:bg-[#372D27] hover:rounded-full">
                            <MdOutlineKeyboardArrowLeft
                                className="text-[#f36c21] font-bold cursor-pointer"
                                size={25}
                                onClick={() => handleOnClick()}
                            />
                        </div>
                        <div className="px-4 h-full text-xs font-bold text-gray-500 flex items-center gap-2 cursor-pointer">
                            <span className={` h-10 hover:bg-[#4b4848] flex items-center px-3 ${liveUpcomingModal ? "border-b-2  border-orange-500 text-orange-600" : ""}`} onClick={() => openModaliveUpcoming()}>LIVE & UPCOMING </span>
                            <span className={`hover:bg-[#4b4848] flex justify-center px-3 h-10 items-center gap-1 ${LeaguesModal ? "border-b-2 border-orange-500 text-orange-600" : ""}`} onClick={() => openModalLeagues()}>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M3 5h6v6H3zm2 2v2h2V7zm6 0h10v2H11zm0 8h10v2H11zm-6 5l-3.5-3.5l1.41-1.41L5 17.17l4.59-4.58L11 14z" /></svg>
                                </span>
                                <p>LEAGUES</p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full relative bg-black py-1">
                <div className="flex w-full">
                    <div className="lg:w-1/6  w-full hidden lg:block py-2">
                        <div className="w-full bg-black flex justify-center">
                            <div className="w-11/12 border-2 border-[#f36c21]  rounded-3xl">
                                <SportListSidebar sportId={sportId} />
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-7/12 w-full h-auto lg:px-1">
                        <div className=" w-full text-white ">
                            {liveUpcomingModal &&
                                <div data-aos="fade-right">
                                    <LiveUpcomingMatch sportId={sportId} />
                                </div>
                            }
                            {LeaguesModal &&
                                <div data-aos="fade-left">
                                    <Leadues sportId={sportId} />
                                </div>
                            }
                        </div>
                    </div>

                    <div className={`lg:w-3/12 px-2 py-2 lg:block hidden right-1 overflow-hidden`}>
                            <OpenBets />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SportsbookPage;

