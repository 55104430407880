import React, { useState, useEffect } from "react";
import { userAction } from "../../../redux/action/userAction";
import { useDispatch } from "react-redux";
import NavigatePathComponent from "../../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent";

export const betChipsData = {
  "1000": 1000,
  "2000": 2000,
  "5000": 5000,
  "10000": 10000,
  "20000": 20000,
  "50000": 50000,
  "100000": 100000,
  "250000": 250000,
};

const EditStack = () => {
  const [isActive, setIsActive] = useState(false);
  const [isFormEnabled1, setIsFormEnabled1] = useState(false);
  const [isFormEnabled2, setIsFormEnabled2] = useState(false);
  const [keyValues1, setKeyValues1] = useState([]);

  // ========================
  const dispatch = useDispatch();
  useEffect(() => {
    const betChipsDataItems = JSON.parse(localStorage.getItem('clientbetChipsData'));

    let betChips = {};
    if (betChipsDataItems != null) {
      betChips = betChipsDataItems;
    }
    else {
      betChips = betChipsData;
    }
    const updatedKeyValues = Object.entries(betChips).map(([key, value]) => ({
      key,
      value: parseInt(value),
    }));
    setKeyValues1(updatedKeyValues);
  }, []);

  const handleInputChange = (index, key, value) => {
    const updatedKeyValues = [...keyValues1];
    if (key !== undefined) {
      updatedKeyValues[index].key = key;
    }
    if (value !== undefined) {
      updatedKeyValues[index].value = Number(value);
    }
    setKeyValues1(updatedKeyValues);
  };
  const submitValue = () => {
    const newBetChipsObject = {};
    keyValues1.forEach((item) => {
      newBetChipsObject[item.key] = item.value;
    });
    const user = JSON.parse(localStorage.getItem("clientUser"));
    const Id = user?.data?.userId;
    const dataValue = {
      userId: Id,
      betChipsData: newBetChipsObject,
    };

    dispatch(userAction.userUpdate(dataValue));
    localStorage.setItem("clientbetChipsData", JSON.stringify(newBetChipsObject)
    );
  };

  const toggleActive = () => {
    setIsActive(!isActive);
  };


  const toggleForm1 = () => {
    setIsFormEnabled1(!isFormEnabled1);
  };

  const toggleForm2 = () => {
    setIsFormEnabled2(!isFormEnabled2);
  };

  return (
    <>
      <NavigatePathComponent showPathName={"Settings"} />
      <section className="w-full  max-w-7xl mx-auto flex justify-center py-2">
        <div className="relative mx-auto w-full max-w-6xl  shadow-xl md:px-1 px-3 ring-1 ring-gray-900/5 sm:rounded-md afterFooter">

          <div className="py-1 border-b border-b-[#464646] flex justify-between">
            <div>
              <span className="text-lg font-bold text-[#f36c21]">One Click Betting </span>
            </div>
            <div className="">
              <label className="inline-flex items-center cursor-pointer">
                <input type="checkbox" value=""
                  className="sr-only peer"
                  onChange={toggleActive}
                  checked={isActive} />
                <div className="relative w-14 h-7 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-[#18381A] rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-[#4CAF50] after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-[#4CAF50]   after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-[#18381A]"></div>
              </label>
            </div>
          </div>

          <div className="w-full h-full my-2 md:flex justify-between gap-2">


            {/* <div className="md:w-1/2 w-full ">
              <span className="text-lg font-bold text-[#f36c21]">Edit One-click Stakes</span>
              <div className={`${isActive ? "bg-gray-300" : "bg-gray-300"} border-2 border-[#f36c21] space-x-4 rounded-xl relative`}>
                <div className={`absolute inset-0 ${isActive ? "hidden" : "block"} bg-gray-500 opacity-50 rounded-xl`}></div>
                <div className="grid grid-cols-3 px-4 py-2 gap-2">
                  {keyValues1.map((item, index) => (
                    <div className={`form py-1 ${isFormEnabled1 ? 'form--enabled' : 'form--disabled'}`} key={index}>
                      <input
                        type="text"
                        name="buttonValue"
                        value={`+${item.value}`}
                        onChange={(e) => handleInputChange(index, undefined, e.target.value)}
                        disabled={!isFormEnabled1}
                        className={`block w-full text-sm font-light text-black text-center  rounded-lg border border-gray-300 focus:border-gray-400 focus:outline-none ${isFormEnabled1 ? "py-2" : "py-0.5"}`}
                      />
                    </div>
                  ))}
                </div>
                <div className="px-4 py-2  flex items-center gap-2">
                  <span className=" text-center  text-sm bg-white border border-gray-400 py-3 cursor-pointer w-1/2" onClick={() => toggleForm1()}>
                    {isFormEnabled1 ? "Cancel" : "Edit"}
                  </span>
                  <span
                    // onClick={() => {
                    //   submitValue();
                    //   if (isFormEnabled1) {
                    //     toggleForm1();
                    //   }
                    // }}
                    disabled={!isFormEnabled1}
                    className={`block  text-sm  py-3 w-1/2 rounded-md text-center focus:outline-none hover:opacity-100 transition-opacity ${!isFormEnabled1
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-[#f36c21] text-white cursor-pointer hover:bg-gray-800"
                      }`}> save  </span>
                </div>
              </div>
            </div> */}

            <div className=" md:w-1/2 w-full">
              <span className="text-lg font-bold text-[#f36c21]">
                {" "}
                Edit Stakes
              </span>
              <div className=" bg-gray-300  border-2 border-[#f36c21] rounded-xl">
                <div className="grid grid-cols-3 px-4  py-2 gap-2">
                  {keyValues1.map((item, index) => (
                    <div className={`form py-1 ${isFormEnabled2 ? 'form--enabled  ' : 'form--disabled '}`} key={index}>
                      <input
                        type="text"
                        name="buttonValue"
                        value={`+${item.value}`}
                        onChange={(e) => handleInputChange(index, undefined, e.target.value)}
                        disabled={!isFormEnabled2}
                        className={`block w-full text-sm font-light text-black text-center  rounded-lg border border-gray-300 focus:border-gray-400 focus:outline-none ${isFormEnabled2 ? "py-2" : "py-0.5"}`}
                      />
                    </div>
                  ))}
                </div>
                <div className="px-4 py-2 flex items-center gap-2">
                  <span className=" text-center border text-sm bg-white border-gray-400 py-3 cursor-pointer w-1/2" onClick={() => toggleForm2()}>
                    {isFormEnabled2 ? "Cancel" : "Edit"}
                  </span>

                  <span onClick={() => {
                    submitValue();
                    if (isFormEnabled2) {
                      toggleForm2();
                    }
                  }}
                    disabled={!isFormEnabled2}
                    className={`block  text-sm  py-3 w-1/2 rounded-md text-center focus:outline-none hover:opacity-100 transition-opacity ${!isFormEnabled2
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-[#f36c21] text-white cursor-pointer hover:bg-gray-800"
                      }`}>
                    save
                  </span>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditStack;
