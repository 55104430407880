import React, { useEffect, useState } from "react";
import Drawer from "../../../component/Drawer/Drawer";
import { MdOutlineKeyboardArrowLeft, MdKeyboardArrowDown } from "react-icons/md";
import { FaRupeeSign } from "react-icons/fa";
import superGiftImg from '../../../Assest/images/superGiftImg.png'
import { websiteName } from "../../../globleConstent/membership/membership"


import AOS from "aos";
import "aos/dist/aos.css";
import { DepositListFirst, DepositListThree, DepositListTwo } from "../../../pages/Auth/Deposit/DepositJson";
import { Link } from "react-router-dom";
import NavigatePathComponent from "../Header/NavigatePathComponent/NavigatePathComponent";

function Wallet() {

  const [isOpen, setIsOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [isOpenRightSidebar, setIsOpenRightSidebar] = useState(false);



  let Balance = JSON.parse(localStorage.getItem('clientBalance'));
  let Exposure = JSON.parse(localStorage.getItem('clientExposure'));
  let user = JSON.parse(localStorage.getItem('clientUser'));


  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const toggleAccordion = () => {
    setActive(!active);
  };
  return (
    <>
      <NavigatePathComponent showPathName={"Wallet"} />
      <section className="w-full bg-black max-w-7xl mx-auto h-fit flex justify-center py-4">
        <div className="relative mx-auto text-center w-full  lg:px-1 px-2 max-w-5xl bg-trasparent  afterFooter shadow-xl ring-1 ring-gray-900/5 sm:rounded-md">
          <div className="w-full max-w-3xl mx-auto border-2 border-[#F4DA7B] rounded-lg">
            <div className="bg-[#1E1E1E] rounded-lg flex divide-x w-full lg:p-3 p-2 divide-slate-600 ">
              <div className="w-1/2 py-4">
                <div className="text-white font-normal text-sm decoration-inherit flex-nowrap pr-2 space-y-2">
                  <div className="py-1 flex justify-between">
                    <span className="text-start">Wallet Amount<br />
                      <span className="text-[10px] ">(Inclusive bonus)</span>
                    </span>
                    <span className={`flex gap-1 items-center ${Balance > 0 ? "text-green-500" : "text-red-500"}`} ><FaRupeeSign />{Balance}</span>
                  </div>
                  <div className="py-1 flex justify-between">
                    <span>Net Exposure </span>
                    <span className={`flex gap-1 items-center ${Exposure > 0 ? "text-green-500" : "text-red-500"}`}><FaRupeeSign />{Exposure}</span>
                  </div>
                  <div className="py-1 flex justify-between">
                    <span>Net Profit Loss</span>
                    <span className={`flex gap-1 items-center ${Balance >= 0 ? "text-green-500" : "text-red-500"}`}><FaRupeeSign />0</span>
                  </div>

                </div>

              </div>
              <div className="w-1/2 py-2 lg:px-4 px-1 space-y-2">
                <Link to={"/app/wallet/deposit"}>
                  <div className="bg-[#EF7834] py-1.5 px-3 rounded-full flex justify-between items-center cursor-pointer">
                    <div className="text-white text-sm font-medium uppercase">deposit</div>
                    <div className="h-6 w-6 rounded-full bg-white text-black justify-center items-center">+</div>
                  </div>
                </Link>
                <div className="border-2 py-1 border-[#f4da7b] rounded-xl">
                  <div className="bg-[#EF7834] py-1.5 px-3 rounded-full flex justify-between items-center cursor-pointer ">
                    <div className="text-white lg:text-sm text-xs font-medium uppercase">Instent Withdrawal</div>
                    <div className="h-6 w-6 rounded-full bg-white text-black justify-center items-center">-</div>
                  </div>
                </div>
                <Link to={"/app/wallet/withdrawal"}>
                  <div className="bg-[#EF7834] py-1.5 px-3 rounded-full flex justify-between items-center cursor-pointer">
                    <div className="text-white text-sm font-medium uppercase">Withdrawal</div>
                    <div className="h-6 w-6 rounded-full bg-white text-black justify-center items-center">-</div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="bg-gradient-to-r from-gray-900 via-gray-400 to-gray-900 mt-10 h-[1px] w-full max-w-3xl mx-auto  rounded-lg"></div>

          <div className="px-2 space-y-6 py-4">
            <div className="text-white text-4xl font-medium">
              Your {websiteName} Gift Card
            </div>

            <div className="flex h-64 justify-center">
              <div className="rounded-lg w-full max-w-xl relative">
                <div className="absolute inset-0 bg-cover bg-no-repea bg-left rounded-lg" style={{ backgroundImage: `url(${superGiftImg})` }}></div>
                <div className="relative z-5 flex h-full mt-12 justify-start items-center">
                  <div className="w-full max-w-sm flex gap-4 justify-center mt-6">
                    <div className="max-w-xs bg-yellow-500 drop-shadow-xl drop-shadow-gray-700 rounded-md ">
                      <input type="text" id="success" className="uppercase bg-green-50 border border-gray-200  placeholder-gray-500 text-sm rounded-md   block w-full p-2" placeholder="Enter Gift Code" />
                      
                    </div>
                    <span className=" px-3 py-1 rounded-sm flex items-center justify-center text-xs uppercase bg-[#EF7834] font-bold text-white">redeem</span>
                  </div>
                </div>
              </div>
            </div>




            <div className="py-3 md:flex justify-center w-full">
              <div className="flex-1 p-2 bg-[#1D1D1E]" >
                <div className="rounded-t-lg flex items-center lg:justify-center px-3 py-3" onClick={() => toggleAccordion()}>
                  <span className="text-white text-lg leading-4 uppercase font-bold text-center">condition</span>
                  <span className={`absolute right-6  transition-transform transform duration-200 ${active ? 'rotate-180' : ''}`}>
                    <MdKeyboardArrowDown size={20} className="text-white" />
                  </span>
                </div>
                {active && (
                  <>
                    <div className="duration-200  ease-out transition transform origin-bottom py-2 px-2">
                      <ul className="text-xs font-medium tracking-wide list-disc list-inside text-start text-white leading-4 space-y-2">
                        <li>Bonus is granted only on the first deposit of each registered user.</li>
                        <li>Criteria for bonus activation:</li>
                        <ul className="pl-4 py-2">
                          <li>To activate the bonus the user must place session/ PSB bets worth X of the bonus/ deposit amount. Eg: if the bonus awarded is ₹1000 and the turnover criteria to redeem the bonus is 2x of the bonus amount, the user must place session and PSB bets worth 2x (1000) = 20,000.</li>
                          <li>Please Note:</li>
                          <ul style={{ listStyle: "circle" }} className="py-2 px-4">
                            <li>The turnover criteria to redeem the bonus is different for each type of bonus.</li>
                            <li>The turnover criteria is subject to change. Please check the Promotions page on the site for updated info.</li>
                          </ul>
                        </ul>

                        <li>If a user only deposits and attempts to withdraw the money without placing a single bet, 100% of the amount will be withheld due to suspicious activity. If this is repeated, no withdrawal will be given to the user</li>

                        <li>Turnover in SPORTSBOOK and PREMIUM CRICKET will be considered only on bets above 1.35 odds.</li>

                        <li>Turnover on session bets will only be considered on the "NO" option.</li>
                      </ul>
                    </div>
                  </>
                )}

              </div>


            </div>


          </div>

        </div>
      </section>
    </>
  )
}

export default Wallet