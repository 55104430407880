import Facebook from '../../Assest/images/social/facebook.svg'
import Twitter from '../../Assest/images/social/twitter.svg'
import Telegram from '../../Assest/images/social/telegram.svg'
import Instagram from '../../Assest/images/social/instagram.svg'
import Linkedin from '../../Assest/images/social/linkedin.svg'
import Youtube from '../../Assest/images/social/youtube.svg'


export const FooterNavSocial = [

    {
        
        title: "facebook",
        link: "#",
        icon:Facebook,
        alt:'facebook'
    },
    { 
        title: "twitter",
        link: "#",
        icon:Twitter,
        alt:'twitter'
    },
    { 
        title: "telegram",
        link: "#",
        icon:Telegram,
        alt:'telegram'
    },
    { 
        title: "instagram",
        link: "#",
        icon:Instagram,
        alt:'instagram'
    },
    
    { 
        title: "linkedin",
        link: "#",
        icon:Linkedin,
        alt:'linkedin'
    },
    { 
        title: "youtube",
        link: "#",
        icon:Youtube,
        alt:'youtube'
    },
]


export const FooterNavGame = [
    {
        text: "Online Sports Betting",
        link: "#",
    },
    {
        text: "Cricket Betting",
        link: "#",
    },
    {
        text: "IPL Betting",
        link: "#",
    },
    {
        text: "Football Betting",
        link: "#",
    },
    {
        text: "Tennis Betting",
        link: "#",
    },
    {
        text: "Live Casino Betting",
        link: "#",
    },
    {
        SattaMatka: [
            {
                text: "Worli Matka",
                link: "#",
            },
            {
                text: "Kalyan Satta Matka",
                link: "#",
            },
        ],
    },
    {
        OnlineBetting: [
            {
                text: "Formula1",
                link: "#",
            },
            {
                text: "Basketball",
                link: "#",
            },
            {
                text: "Baseball",
                link: "#",
            },
            {
                text: "Dota2",
                link: "#",
            },
            {
                text: "Volleyball",
                link: "#",
            },
            {
                text: "Rugby",
                link: "#",
            },
            {
                text: "Boxing",
                link: "#",
            },
            {
                text: "Badminton",
                link: "#",
            },
            {
                text: "Golf",
                link: "#",
            },
        ]
    },

    {
        esports:[
            {
                text: "Virtual Sports",
                link: "#",
            },
            {
                text: "FIFA Esports",
                link: "#",
            },
            {
                text: "Counter Strike",
                link: "#",
            },
        ]
    },
    {
        liveCards :[


            {
                text: "3 Cards Judgements",
                link: "#",
            },
            {
                text: "29 Cards Baccarat",
                link: "#",
            },
            {
                text: "Andar Bahar",
                link: "#",
            },
            {
                text: "Teen Patti T20",
                link: "#",
            },
            {
                text: "Baccarat",
                link: "#",
            },
            {
                text: "Trio",
                link: "#",
            },
            {
                text: "Sic Bo",
                link: "#",
            },
            {
                text: "Casino Meter",
                link: "#",
            },
            {
                text: "Muflis Teen Patti",
                link: "#",
            },
            {
                text: "Roulette",
                link: "#",
            },

            {
                text: "Race 20-20",
                link: "#",
            },
            {
                text: "3 Patti Live",
                link: "#",
            },
            {
                text: "7 Up 7 Down",
                link: "#",
            },
            {
                text: "Dragon Tiger",
                link: "#",
            },
            {
                text: "32 Card Casino",
                link: "#",
            },
            {
                text: "Hi Lo",
                link: "#",
            },
            {
                text: "Casino War",
                link: "#",
            },
            {
                text: "Amar Akbar Anthony",
                link: "#",
            },
            {
                text: "Online Poker",
                link: "#",
            },
            {
                text: "Race To 17",
                link: "#",
            },
            {
                text: "2 Card Teen Patti",
                link: "#",
            },
            {
                text: "Teen Patti Test",
                link: "#",
            },
            {
                text: "Teen Patti Open",
                link: "#",
            },
            {
                text: "Bollywood Casino",
                link: "#",
            },
            {
                text: "Poker 20-20",
                link: "#",
            },

        ]
    },
    {
        text: "Cricl Expo Online Betting",
        link: "#",
    },
];
export const FooterNavCompany = [
    {
        text: "About Us",
        link: "#",
    },

    {
        text: "Rules & Regulation",
        link: "#",
    },
    {
        text: "Privacy Policy",
        link: "#",
    },
    {
        text: "Sitemap",
        link: "#",
    },
    {
        text: "Responsible Gambling",
        link: "#",
    },
    {
        text: "Terms And Conditions",
        link: "#",
    },

]

export const FooterNavResources = [

    {
        text: "Blogs",
        link: "#",
    },
    {
        text: "sikander",
        link: "#",
    },
    {
        text: "Sportsbook Betting",
        link: "#",
    },
    {
        text: "Affiliate",
        link: "#",
    },


]

export const FooterNavFaq = [

    {
        text: "FAQ",
        link: "#",
    },
    {
        text: "Tutorials",
        link: "#",
    },
    {
        text: "Market",
        link: "#",
    },
    {
        text: "Loyalty",
        link: "#",
    },
    {
        text: "Contact Us",
        link: "#",
    },

]



