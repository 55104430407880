import React from 'react';
import BgSkyCardTheme2 from '../casino_components/BgSkyCardTheme2';
import BgSkyButtonTheme2 from '../casino_components/BgSkyButtonTheme2';
import BetLockedRoundedBmx from '../casino_components/BetLockedRoundedBmx';

export default function GameCard(props) {
    const { Data, Name, handleBackOpen, section1Ref, posArray, oddsDifference } = props;

    return (
        <div className="w-full">
            <div className="flex justify-center items-center ">
               <p className="uppercase text-[16px] px-2 gray-text font-semibold">{Data && Data.rate ? (Data.rate - oddsDifference).toFixed(2) : 0}</p>
            </div>
            <div className="relative">
                <BgSkyCardTheme2 ele={Name} data={Data} />
                {Data.gstatus === "1" ?
                    <BgSkyButtonTheme2 ele={Name}
                        handleBackOpen={handleBackOpen}
                        data={Data}
                        section1Ref={section1Ref}
                    /> :
                    <BetLockedRoundedBmx />
                }
            </div>
            <div className={`${posArray[Data.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[14px] font-bold pt-1`}>
                {posArray[Data.sid] ?? 0}
            </div>
        </div>
    );
}