import React from 'react';

export default function BgSkyCardTheme2(props) {
    const { ele } = props;

    return (
        <div className="even-background w-full text-center text-[16px] py-3 font-semibold rounded-[4px] leading-4 text-white uppercase cursor-pointer flex justify-center items-center">
            {ele}
        </div>
    );
}