import React, { useState, useEffect } from "react";

import FAQ from "../../../component/Common/Faqs/Faqs";
import { FaqsJsonPage } from "./FaqsPageJson";
import { MdKeyboardArrowUp } from "react-icons/md";
import { FiPlus, FiMinus } from "react-icons/fi";
import NavigatePathComponent from "../../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent";

const FaqsPage = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [faqsModal, setFaqsModal] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    setFaqs(FaqsJsonPage);
  }, []);

  const handaleClickFaqs = (index) => {
    setFaqsModal((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const toggleAccordion = (index) => {

    setActive((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (

    <div className="bg-black h-screen overflow-y-auto flex flex-col">
    

        <NavigatePathComponent showPathName={"Faqs"} />
    
      {/* <Drawer isOpen={isOpen} setIsOpen={setIsOpen} /> */}

      <section className="w-full h-screen max-w-7xl mx-auto justify-center items-center mt-5">
        <div className="grid lg:grid-cols-2  grid-cols-1 gap-6 px-4 mx-auto max-w-screen-xl lg:px-6">
          <div className="w-full ">
            {faqs.slice(0, 3).map((faq) => (
              <div key={faq.id} className="mb-6 bg-[#1e1e1e] rounded-md">
                <span
                  className="flex items-center relative p-2 bg-[#1E1E1E] rounded-md shadow border border-[#2F2F2F] dark:bg-gray-800 dark:border-gray-700"
                  onClick={() => handaleClickFaqs(faq.id)}
                >
                  <h5 className={`text-lg font-normal tracking-tight ${faqsModal[faq.id] ? "text-[#f36c21]" : "text-white"}`}>
                    {faq.title}
                  </h5>
                  <span className="absolute right-2 text-white">
                    {faqsModal[faq.id] ? <FiMinus /> : <FiPlus />}
                  </span>
                </span>
                {faqsModal[faq.id] && (
                  <div className="">
                    {faq.data?.map((data, index) => (
                      <div className="w-full">
                        <button
                          className=" w-full"
                          onClick={() => toggleAccordion(index)}
                        >
                          <div className="">
                            <div className="text-start hover:bg-[#252525] text-white flex items-center p-2 relative">
                              <span className="w-11/12">
                                <h4 className="quon-style">
                                  {data.question}
                                </h4>
                              </span >
                              <span className={`absolute right-3  h-5 w-5 rounded-full bg-[#f36c21] transition-transform transform duration-200 ${active[index] ? 'rotate-180' : ''}`}>
                                <MdKeyboardArrowUp size={20} />
                              </span>
                            </div>

                            {active[index] && (
                              <div className="text-start w-full px-6 py-3 border-[#7A3C1D] border rounded-md transition-transform transform duration-200">
                                {data.answer?.map((ans, index) => (
                                  <div key={index} className="text-white text-sm text-justify font-light">
                                    <p className="">{ans && ans.ansTitle ? ans.ansTitle : null}</p>

                                    {/* list-inside */}
                                    <ul className="list-disc text-white p-3" key={index}>
                                      {ans?.anslist?.map((item, index) => (
                                        <li key={index} className="py-1 text-justify">{item ? item : null}</li>

                                      ))}
                                    </ul>

                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="w-full ">
            {faqs.slice(3).map((faq) => (
              <div key={faq.id} className="mb-6 bg-[#1e1e1e] rounded-md">
                <span
                  className="flex items-center relative border p-2 bg-[#1E1E1E] rounded-md shadow border-[#2F2F2F]  dark:bg-gray-800 dark:border-gray-700"
                  onClick={() => handaleClickFaqs(faq.id)}
                >
                  <h5 className={`text-lg font-normal tracking-tight ${faqsModal[faq.id] ? "text-[#f36c21]" : "text-white"}`}>
                    {faq.title}
                  </h5>
                  <span className="absolute right-2 text-white">
                    {faqsModal[faq.id] ? <FiMinus /> : <FiPlus />}
                  </span>
                </span>
                {faqsModal[faq.id] && (
                  <div className="">
                    {faq.data?.map((data, index) => (
                      <div className="w-full">
                        <button
                          className=" w-full"
                          onClick={() => toggleAccordion(index)}
                        >
                          <div className="">
                            <div className="text-start hover:bg-[#252525] text-white flex items-center p-2 relative">
                              <span className="w-11/12">
                                <h4 className="quon-style">
                                  {data.question}
                                </h4>
                              </span >
                              <span className={`absolute right-3  h-5 w-5 rounded-full bg-[#f36c21] transition-transform transform duration-200 ${active[index] ? 'rotate-180' : ''}`}>
                                <MdKeyboardArrowUp size={20} />
                              </span>
                            </div>

                            {active[index] && (
                              <div className="text-start w-full px-6 py-3 border-[#7A3C1D] border rounded-md transition-transform transform duration-200">
                                {data.answer?.map((ans, index) => (
                                  <div key={index} className="text-white text-sm text-justify font-light">
                                    <p className="">{ans && ans.ansTitle ? ans.ansTitle : null}</p>

                                    {/* list-inside */}
                                    <ul className="list-disc text-white p-3" key={index}>
                                      {ans?.anslist?.map((item, index) => (
                                        <li key={index} className="py-1 text-justify">{item ? item : null}</li>

                                      ))}
                                    </ul>

                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </div >

  );
};
export default FaqsPage;
