import React, { useEffect, useState } from 'react';
import { casinoListJson } from "./CasinoConstent";
import { useNavigate } from 'react-router-dom';
// import { useHistory } from "react-router";

const Casino = () => {
    const [casinoList, setCasinoList] = useState([]);
    // const history = useHistory();
    const navigate = useNavigate()
  

    useEffect(() => {
        setCasinoList(casinoListJson);
    }, [casinoList]);

    const handleResponseCasino = (product) => {
        // history.push(`/app/${product.shortName}/${product.eventId}`)
        navigate(`/app/${product.shortName}/${product.eventId}`)
    }

    return (
        <section className='py-0 my-1' >
            <div className='capitalize font-bold text-lg py-0.5'>our casino</div>
            <div className='flex justify-center'>
                <div className="w-[95%] mx-auto grid grid-cols-2 sm:grid-cols-3  lg:grid-cols-6 gap-2 py-1">

                    {casinoList.map((product, index) => (
                        <div key={index} onClick={() => handleResponseCasino(product)} className="shadow  relative border-2 overflow-hidden border-yellow-200 rounded-sm  duration-500 sw-full h-[200px]" style={{ backgroundImage: `url(${product.img})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                            <div className="block w-full h-full cursor-pointer">
                                <div className="px-2 py-1 rounded-sm  absolute -bottom-[-0.05rem] w-full shadow-lg red-header">
                                    <p className="md:text-xs text-[12px] font-[600] text-white truncate flex items-center justify-center uppercase">{product.title}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Casino;
