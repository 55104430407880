import React, { useEffect, useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md';
import { BiSolidError } from 'react-icons/bi';
import { httpPost } from '../../middelware/Http';
import moment from 'moment';
import NavigatePathComponent from '../Auth/Header/NavigatePathComponent/NavigatePathComponent';


function Bets() {
    const [active, setActive] = useState(false);
    const [betShow, setBetShow] = useState(false);
    const [fancyBetData, setFancyBetData] = useState([]);
    const [oddsBetData, setOddsBetData] = useState([]);


    useEffect(() => {
        fetchBetLists()
    }, [])

    const toggleAccordion = (index) => {
        setActive((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const openBets = () => {
        setBetShow(true);
    };

    const openBetsClose = () => {
        setBetShow(false);
    };


    const fetchBetLists = async (marketId) => {
        try {
            const BetListData = {
                fancyBet: true,
                isDeclare: false,
                oddsBet: true,
                // marketId: "1.228209816",
            };

            const userBetHistory = await httpPost('sports/betsList', BetListData);
            if (userBetHistory && userBetHistory.data) {
                const { fancyBetData, oddsBetData } = userBetHistory.data;
                const filteredFancyBetData = fancyBetData ? fancyBetData.filter(element => element.isDeclare === 0).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
                const sortedOddsBetData = oddsBetData ? oddsBetData.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
                setFancyBetData(filteredFancyBetData)
                setOddsBetData(sortedOddsBetData)
                return { fancyBetData: filteredFancyBetData, oddsBetData: sortedOddsBetData };
            }
        } catch (error) {
            console.error('Error fetching bet lists:', error);
            throw error;
        }
    };

    return (

        <>
            <NavigatePathComponent showPathName={"Open Bets"} />
            <section className="w-full bg-black max-w-7xl mx-auto h-fit flex justify-center">
                <div className="relative mx-auto text-center w-full max-w-6xl py-1 bg-[#1E1E1E] afterFooter  shadow-xl ring-1 ring-gray-900/5">
                    <div className='text-white flex gap-4 justify-center items-center'>
                        <span className={`py-4 cursor-pointer px-2 uppercase text-xs hover:bg-[#51311F] ${!betShow ? "border-b-2 border-b-[#f36c21] text-[#f36c21]" : ""}`} onClick={() => openBetsClose()}>My Bets</span>
                        <span className={`py-4 cursor-pointer px-2 uppercase text-xs hover:bg-[#51311F] ${betShow ? "border-b-2 border-b-[#f36c21] text-[#f36c21]" : ""}`} onClick={() => openBets()}>My Markets</span>
                    </div>

                    {!betShow && (
                        <div className="px-3 space-y-3 uppercase">
                        <div className=" justify-between  w-full  border-[#7A3C1D] border-2 rounded-t-2xl transition-transform transform duration-200">
                            <div className="w-full text-start flex px-2 py-2 text-sm font-normal">
                                <span className="text-white">Odds Bets</span>
                                <span className={`absolute right-3  h-5 w-5 rounded-full bg-[#f36c21] transition-transform transform duration-200 ${active[1] ? 'rotate-180' : ''}`} onClick={() => toggleAccordion(1)}>
                                    <MdKeyboardArrowDown size={20} className="text-white" />
                                </span>
                            </div>
                            {active[1] && (
                                <div className="table-responsive active">
                                    <table className="bet_list_table bet_list_header_div w-[100%]  ">
                                        <thead >
                                            <tr className="bet_list_header px-2 text-xs text-gray-400 text-left">
                                                <th >Runner</th>
                                                <th>Rate</th>
                                                <th>Stake</th>
                                                <th>Profit/Loss</th>
                                                <th>Time/Date</th>
                                            </tr>
                                        </thead>
                                        <tbody className="odd_table_body divide-y">
                                        {oddsBetData
                                                    .filter(element => element.oddsType === "matchOdds")
                                                    .map((element, index) => (
                                                <tr key={index} className={element.type === "K" ? 'bg-[#F8D0CE] m-bet-table rounded-b-md ' : 'bg-[#A5D9FE] m-bet-table rounded-b-md'}>
                                                    <td className="font-bold">{element.teamName} &#10564; {element.oddsType}</td>
                                                    <td className="">
                                                        {element && element.oddsType === "matchOdds" ?
                                                            parseFloat(Number(element.odds) + 1).toFixed(2).replace(/\.?0+$/, "") :
                                                            (element && (element.oddsType === "bookmaker" || element.oddsType === "toss") ?
                                                                (parseFloat(element.odds * 100)).toFixed(2).replace(/\.?0+$/, "") :
                                                                parseFloat(element.odds).toFixed(2).replace(/\.?0+$/, ""))
                                                        }
                                                    </td>
                                                    <td>{parseFloat(element.amount).toFixed(2).replace(/\.?0+$/, '')}</td>
                                                    <td>{element.positionInfo && element.selectionId && element.positionInfo[element.selectionId] !== undefined ?
                                                        parseFloat(element.positionInfo[element.selectionId]).toFixed(2).replace(/\.?0+$/, "") : 0}
                                                    </td>
                                                    <td>{element && element.createdAt ? moment(element.createdAt).utcOffset("+05:30").format("DD-MM-YYYY HH:mm:ss") : ''}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                            )}
                        </div>

                        <div className=" justify-between  w-full  border-[#7A3C1D] border-2 rounded-t-2xl transition-transform transform duration-200">
                            <div className="w-full text-start flex px-2 py-2 text-sm font-normal">
                                <span className="text-white">BookMaker Bets</span>
                                <span className={`absolute right-3  h-5 w-5 rounded-full bg-[#f36c21] transition-transform transform duration-200 ${active[3] ? 'rotate-180' : ''}`} onClick={() => toggleAccordion(3)}>
                                    <MdKeyboardArrowDown size={20} className="text-white" />
                                </span>
                            </div>
                            {active[3] && (
                                <div className="table-responsive active">
                                    <table className="bet_list_table bet_list_header_div w-[100%]  ">
                                        <thead >
                                            <tr className="bet_list_header px-5 text-xs text-gray-400 ">
                                                <th >Runner</th>
                                                <th>Rate</th>
                                                <th>Stake</th>
                                                <th>Profit/Loss</th>
                                                <th>Time/Date</th>
                                            </tr>
                                        </thead>
                                        <tbody className="odd_table_body">
                                            {oddsBetData
                                                .filter(element => element.oddsType !== "matchOdds")
                                                .map((element, index) => (
                                              
                                                        <tr key={index} className={element.type === "N" ? 'bg-[#F8D0CE] m-bet-table rounded-b-md' : 'bg-[#A5D9FE] m-bet-table rounded-b-md'}>
                                                            <td className="font-bold">{element.teamName} &#10564; {element.oddsType}</td>
                                                            <td className="">
                                                                {element && element.oddsType === "matchOdds" ?
                                                                    parseFloat(Number(element.odds) + 1).toFixed(2).replace(/\.?0+$/, "") :
                                                                    (element && (element.oddsType === "bookmaker" || element.oddsType === "toss") ?
                                                                        (parseFloat(element.odds * 100)).toFixed(2).replace(/\.?0+$/, "") :
                                                                        parseFloat(element.odds).toFixed(2).replace(/\.?0+$/, ""))
                                                                }
                                                            </td>
                                                            <td>{parseFloat(element.amount).toFixed(2).replace(/\.?0+$/, '')}</td>
                                                            <td>{element.positionInfo && element.selectionId && element.positionInfo[element.selectionId] !== undefined ?
                                                                parseFloat(element.positionInfo[element.selectionId]).toFixed(2).replace(/\.?0+$/, "") : 0}
                                                            </td>
                                                            <td>{element && element.createdAt ? moment(element.createdAt).utcOffset("+05:30").format("DD-MM-YYYY HH:mm:ss") : ''}</td>
                                                        </tr>
                                                   
                                                ))}
                                        </tbody>
                                    </table>
                                </div>

                            )}
                        </div>

                        <div className="justify-between w-full border-[#7A3C1D] border-2 rounded-t-2xl transition-transform transform duration-200" >
                            <div className="w-full text-start flex px-2 py-2 text-sm font-normal">
                                <span className="text-white">Fancy Bets</span>
                                <span className={`absolute right-3 h-5 w-5 rounded-full bg-[#f36c21] transition-transform transform duration-200 ${active[2] ? 'rotate-180' : ''}`} onClick={() => toggleAccordion(2)}>
                                    <MdKeyboardArrowDown size={20} className="text-white" />
                                </span>
                            </div>
                            {active[2] && (
                                <>
                                    {fancyBetData && fancyBetData.length > 0 ? (
                                        <>
                                            <div className="px-2 flex justify-start items-center gap-1 pb-2">
                                                <input type="checkbox" id="checkbox" class="h-3 w-3  bg-transparent border border-gray-300 rounded-md " />

                                                <label className="text-white text-xs "> Average Odds</label>
                                            </div>

                                            <div className="bet_list_header_div">
                                                {/* <h2 className="text-white text-xs font-bold pt-2 px-2">LokSabha Election 2024</h2> */}
                                                <table className="bet_list_table w-[100%]">
                                                    <thead>
                                                        <tr className="bet_list_header text-xs text-gray-400">
                                                            <th className="flex justify-center items-center">Runner</th>
                                                            <th>Run</th>
                                                            <th>Stake</th>
                                                            <th>Profit/Loss</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {fancyBetData.map((element, index) => (
                                                            <tr key={index} className={element.type === "N" ? 'bg-[#F8D0CE] m-bet-table' : 'bg-[#A5D9FE] m-bet-table'}>
                                                                <td>{element.sessionName}</td>
                                                                <td>{parseFloat(element.run).toFixed(2).replace(/\.?0+$/, "")}/{parseFloat(element.odds * 100).toFixed(2).replace(/\.?0+$/, "")}</td>
                                                                <td>{parseFloat(element.amount).toFixed(2).replace(/\.?0+$/, '')}</td>
                                                                <td>{Number.parseFloat(Math.abs(element && element.loss ? element.loss : 0)).toFixed(2).replace(/\.?0+$/, '')}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="rounded-b-lg text-center p-6">
                                            <div className="flex justify-center">
                                                <BiSolidError size={60} className="text-[#F36C21]" />
                                            </div>
                                            <div className="text-center text-sm font-bold">
                                                <span className="text-[#F36C21]">No Unmatched Bets!</span>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        {/* <div className=" justify-between  w-full  border-[#7A3C1D] border-2 rounded-2xl transition-transform transform duration-200" onClick={() => toggleAccordion(3)}>
                            <div className="w-full text-start flex px-2 py-2 text-sm font-normal">
                                <span className="text-white">Premium Bookmaker</span>
                                <span className={`absolute right-3  h-5 w-5 rounded-full bg-[#f36c21] transition-transform transform duration-200 ${active[3] ? 'rotate-180' : ''}`}>
                                    <MdKeyboardArrowDown size={20} className="text-white" />
                                </span>
                            </div>
                        </div>
                        <div className=" justify-between  w-full  border-[#7A3C1D] border-2 rounded-2xl transition-transform transform duration-200" onClick={() => toggleAccordion(4)}>
                            <div className="w-full text-start flex px-2 py-2 text-sm font-normal">
                                <span className="text-white">Premium SportBook</span>
                                <span className={`absolute right-3  h-5 w-5 rounded-full bg-[#f36c21] transition-transform transform duration-200 ${active[4] ? 'rotate-180' : ''}`}>
                                    <MdKeyboardArrowDown size={20} className="text-white" />
                                </span>
                            </div>
                        </div> */}
                    </div>
                    )}

                    {betShow && (
                        <>
                            <div className='h-56  items-center flex justify-center '>
                                <div className='flex-col justify-center items-center space-y-2 text-[#F36C21]'>
                                    <div className='flex justify-center items-center'>
                                        <BiSolidError size={60} />
                                    </div>
                                    <div className='text-xs font-bold uppercase leading-4 py-2'>
                                        Your markets will be shown here.
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                </div>
            </section>
        </>

    )
}

export default Bets