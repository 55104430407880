


import React from "react";
import tenpattiImage from "../../../src/Assest/images/liveCasinoimg/teenpatti (1).webp"
import hiilowImage from "../../../src/Assest/images/liveCasinoimg/hi-lo.webp"
import AnderBaharImage from "../../../src/Assest/images/liveCasinoimg/andar-bahar.webp"
import cardteenpatti2Image from "../../../src/Assest/images/liveCasinoimg/2-card-teenpatti.webp"
import amarakbaranthonyImage from "../../../src/Assest/images/liveCasinoimg/amar-akbar.webp"
import cardcasino32Image from "../../../src/Assest/images/liveCasinoimg/32-card-casino.webp"
import RouletteImage from "../../../src/Assest/images/liveCasinoimg/roulette.webp"
import lightlingImage from "../../../src/Assest/images/liveCasinoimg/lightning-dice.webp"
import crazyTimesImage from "../../../src/Assest/images/liveCasinoimg/crazy-time.webp"
import DealNoDealImage from "../../../src/Assest/images/liveCasinoimg/deal-no-deal.webp"
import MoneyWheelImage from "../../../src/Assest/images/liveCasinoimg/money-wheel.webp"
import DragonTigerImage from "../../../src/Assest/images/liveCasinoimg/dragon-tiger.webp"
import bgImage from "../../../src/Assest/images/liveCasinoimg/lcards.c25b9b0.png"

const Livecasino = () => {
    return (
        <>
            <section className="lg:flex lg:space-y-0 space-y-2 w-full px-1 py-2">
                <div className="lg:h-44 h-40 lg:w-1/2 w-full border  rounded-lg  bg-no-repeat bg-origin-content bg-right-bottom bg-[#0C0710]  backdrop-blur  bg-[length:300px_100px] " style={{ backgroundImage: `url(${bgImage})` }}>
                    <div className="relative py-2 w-full flex">
                        <h2 className="relative text-white w-10/12 text-center font-extrabold text-[22px] not-italic">
                            Live Cards
                            <span className="absolute text-center font-extrabold top-0 transform translate-x-[-100%] text-[22.5px]  text-[#f37415] after:content-['Cards']"></span>
                        </h2>
                        <h2 className="w-2/12 text-[#f37415]">
                            {/* <a href="/login" class="text-decoration-none"> */}
                                More ...
                                {/* </a> */}
                        </h2>
                    </div>
                    <div className="py-2 grid grid-cols-3 lg:gap-2 gap-4 px-1 ">
                        <a href="#" className="lg:h-10 h-8 shadow-md flex flex-initial rounded-full border-2  shadow-[#8154f1]  bg-gradient-to-r from-[#130c2d] to-[#1904e5] relative lg:p-2 p-1">
                            <span className="w-full flex items-center  relative transition-opacity lg:px-1 px-2">
                                <span className="w-3/4 text-ellipsis items-center text-white lg:text-sm text-[.6rem]">TeenPatti</span>
                                <span className="w-1/4 absolute  right-0 flex justify-center">
                                    <img src={tenpattiImage} height={30} width={30} />
                                </span>
                            </span>
                        </a>
                        <a href="#" className=" lg:h-10 h-8 shadow-md flex flex-initial rounded-full border-2   shadow-[#f37415]  bg-gradient-to-r from-[#130c2d] to-[#1904e5] relative lg:p-2 p-1 ">
                            <span className="w-full flex items-center  relative transition-opacity lg:px-1 px-2">
                                <span className="w-3/4 text-ellipsis items-center text-white lg:text-sm text-[.6rem]">Hii Low</span>
                                <span className="w-1/4 absolute  right-0 flex justify-center">
                                    <img src={hiilowImage} height={30} width={30} />
                                </span>
                            </span>
                        </a>

                        <a href="#" className=" lg:h-10 h-8 shadow-md flex flex-initial rounded-full border-2  shadow-[#8154f1]  bg-gradient-to-r from-[#130c2d] to-[#1904e5] relative lg:p-2 p-1 ">
                            <span className="w-full flex items-center  relative transition-opacity lg:px-1 px-2">
                                <span className="w-3/4 text-ellipsis items-center text-white lg:text-sm text-[.6rem]">Ander Bahar</span>
                                <span className="w-1/4 absolute  right-0 flex justify-center">
                                    <img src={AnderBaharImage} height={30} width={30} />
                                </span>
                            </span>
                        </a>

                        <a href="#" className=" lg:h-10 h-8 shadow-md flex flex-initial rounded-full border-2  shadow-[#f37415]  bg-gradient-to-r from-[#130c2d] to-[#1904e5] relative lg:p-2 p-1 ">
                            <span className="w-full flex items-center  relative transition-opacity lg:px-1 px-2">
                                <span className="w-3/4 text-ellipsis items-center text-white lg:text-sm text-[.6rem]">2 Cards TeenPatti</span>
                                <span className="w-1/4 absolute  right-0 flex justify-center">
                                    <img src={cardteenpatti2Image} height={30} width={30} />
                                </span>
                            </span>
                        </a>

                        <a href="#" className=" lg:h-10 h-8 shadow-md flex flex-initial rounded-full border-2  shadow-[#8154f1]  bg-gradient-to-r from-[#130c2d] to-[#1904e5] relative lg:p-2 p-1 ">
                            <span className="w-full flex items-center  relative transition-opacity lg:px-0 px-2">
                                <span className="w-3/4 text-ellipsis items-center text-white lg:text-sm text-[.6rem]">Amar Akbar Antony</span>
                                <span className="w-1/4 absolute  right-0 flex justify-center">
                                    <img src={amarakbaranthonyImage} height={30} width={30} />
                                </span>
                            </span>
                        
                        </a>
                        <a href="#" className="lg:h-10 h-8 shadow-md flex flex-initial rounded-full border-2  shadow-[#f37415]  bg-gradient-to-r from-[#130c2d] to-[#1904e5] relative lg:p-2 p-1 ">
                            <span className="w-full flex items-center  relative transition-opacity lg:px-1 px-2">
                                <span className="w-3/4 text-ellipsis items-center text-white lg:text-sm text-[.6rem]">32 Cards Casino</span>
                                <span className="w-1/4 absolute  right-0 flex justify-center">
                                    <img src={cardcasino32Image} height={30} width={30} />
                                </span>
                            </span>
                        </a>
                    </div>
                </div>

                <div className="lg:h-44 h-40 lg:w-1/2 w-full border  rounded-lg  bg-no-repeat bg-origin-content bg-right bg-[#0C0710]  backdrop-blur  bg-[length:400px_200px]" style={{ backgroundImage: `url(${bgImage})` }}>
                    <div className="relative py-2 w-full flex">
                        <h2 className="relative text-white w-10/12 text-center font-extrabold text-[22px] not-italic">
                            Live Casino
                            <span className="absolute text-center font-extrabold top-0 transform translate-x-[-100%] text-[22.5px]  text-[#f37415] after:content-['Casino']"></span>
                        </h2>
                        <h2 className="w-2/12 text-[#f37415]">
                            {/* <a href="/login" class="text-decoration-none"> */}
                                More ...
                                {/* </a> */}
                        </h2>
                    </div>

                    <div className="py-2 grid grid-cols-3 lg:gap-2 gap-4 px-1 ">
                        <a href="#" className="lg:h-10 h-8 shadow-md flex flex-initial rounded-full border-2  shadow-[#8154f1]  bg-gradient-to-r from-[#130c2d] to-[#1904e5] relative lg:p-2 p-1">
                            <span className="w-full flex items-center  relative transition-opacity lg:px-1 px-2">
                                <span className="w-3/4 text-ellipsis items-center text-white lg:text-sm text-[.6rem]">Roulette</span>
                                <span className="w-1/4 absolute  right-0 flex justify-center">
                                    <img src={RouletteImage} height={30} width={30} />
                                </span>
                            </span>
                        </a>

                        <a href="#" className=" lg:h-10 h-8 shadow-md flex flex-initial rounded-full border-2   shadow-[#f37415]  bg-gradient-to-r from-[#130c2d] to-[#1904e5] relative lg:p-2 p-1 ">
                            <span className="w-full flex items-center  relative transition-opacity lg:px-1 px-2">
                                <span className="w-3/4 text-ellipsis items-center text-white lg:text-sm text-[.6rem]">Lightening Dice</span>
                                <span className="w-1/4 absolute  right-0 flex justify-center">
                                    <img src={lightlingImage} height={30} width={30} />
                                </span>
                            </span>
                        </a>

                        <a href="#" className=" lg:h-10 h-8 shadow-md flex flex-initial rounded-full border-2  shadow-[#8154f1]  bg-gradient-to-r from-[#130c2d] to-[#1904e5] relative lg:p-2 p-1 ">
                            <span className="w-full flex items-center  relative transition-opacity lg:px-1 px-2">
                                <span className="w-3/4 text-ellipsis items-center text-white lg:text-sm text-[.6rem]">Crazy Times</span>
                                <span className="w-1/4 absolute  right-0 flex justify-center">
                                    <img src={crazyTimesImage} height={30} width={30} />
                                </span>
                            </span>
                        </a>

                        <a href="#" className=" lg:h-10 h-8 shadow-md flex flex-initial rounded-full border-2  shadow-[#f37415]  bg-gradient-to-r from-[#130c2d] to-[#1904e5] relative lg:p-2 p-1 ">
                            <span className="w-full flex items-center  relative transition-opacity lg:px-1 px-2">
                                <span className="w-3/4 text-ellipsis items-center text-white lg:text-sm text-[.6rem]">Deal No Deal</span>
                                <span className="w-1/4 absolute  right-0 flex justify-center">
                                    <img src={DealNoDealImage} height={30} width={30} />
                                </span>
                            </span>
                        </a>

                        <a href="#" className=" lg:h-10 h-8 shadow-md flex flex-initial rounded-full border-2  shadow-[#8154f1]  bg-gradient-to-r from-[#130c2d] to-[#1904e5] relative lg:p-2 p-1 ">
                            <span className="w-full flex items-center  relative transition-opacity lg:px-0 px-2">
                                <span className="w-3/4 text-ellipsis items-center text-white lg:text-sm text-[.6rem]">Money Wheel</span>
                                <span className="w-1/4 absolute  right-0 flex justify-center">
                                    <img src={MoneyWheelImage} height={30} width={30} />
                                </span>
                            </span>
                        
                        </a>
                        <a href="#" className="lg:h-10 h-8 shadow-md flex flex-initial rounded-full border-2  shadow-[#f37415]  bg-gradient-to-r from-[#130c2d] to-[#1904e5] relative lg:p-2 p-1 ">
                            <span className="w-full flex items-center  relative transition-opacity lg:px-1 px-2">
                                <span className="w-3/4 text-ellipsis items-center text-white lg:text-sm text-[.6rem]">Dragon Tiger</span>
                                <span className="w-1/4 absolute  right-0 flex justify-center">
                                    <img src={DragonTigerImage} height={30} width={30} />
                                </span>
                            </span>
                        </a>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Livecasino;

//