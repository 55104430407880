export const FaqsJsonPage = [
  {
    title: "General",
    id: 1,
    data: [
      {
        question: "Q1.Why crick expo ?",
        answer: [
          {
            ansTitle:
              "crick expo is India’s biggest and most trusted betting exchange that offers a wide range of options to bet on- from maximum sports events to live casino and live card games. crick expo offers the best odds in the market which ensures that a crick expo Club member makes more profit on crick expo than anywhere else in the market. crick expo Club members avail benefits such as:",
            anslist: [
              "300% bonus on the first deposit",
              "wwwwwwwwwwwwww% bonus on the first deposit",
              "300vvvvvvvvvvvvvvvvv% bonus on the first deposit",
              "3qqqqqqqqqqqqqq00% bonus on the first deposit",
              "3lllllllll00% bonus on the first deposit",
            ],
          },
        ],
        ids: 11,
      },

      {
        question: "Q2.What type of games does crick expo offer?",
        answer: [
          {

            anslist: [
              "Sportsbetting(Cricket / Football / Tennis / Horse racing)",
              "Popular live dealer Casino games like Roulette, Baccarat, Andar Bahar, Dragon Tiger and Poker.",
              "Progressive slot games.",
              "A variety of Indian card games live such as Teenpatti, Andar Bahar, Lucky 7, 32 card poker and Amar Akbar Anthony.",
              "crick expo also offers Elections stakes.",
              "Sports book: 30 + new sports including water polo, table tennis, soccer, baseball and a range of virtual games.",
            ],
          },
        ],
        ids: 22,
      },

      {
        question: "Q3.Is crick expo licensed and legitimate?",
        answer: [
          {
            ansTitle:
              "crick expo Club is licensed by the authority of Curacao and is certified to operate and offer online gambling globally.",

          },
        ],
        ids: 33,
      },

      {
        question: "Q4.What do I do if/when my game isn’t loading? ",
        answer: [
          {
            ansTitle:
              "Clear your browser cache, cookies and restart the internet browser!",

          },
        ],
      },
      {
        question: "Q5.What is the affiliate program and where can I find more information?",
        answer: [
          {
            ansTitle: "Head to the crick expo Affiliate Program page for all information regarding our affiliate program and the respective persons of contact for the same.",
          },
        ],
      },

      {
        question: "Q6.What happens if/ when I lose internet connectivity in the middle of a game?",
        answer: [
          {
            ansTitle: "All the rounds in all games are logged and the successful bets are still valid. Please refer to crick expo’s disconnection policy for more information.",
          },
        ],
      },
      {
        question: "Q7. An unknown error or a technical issue came up while playing. what should I do?",
        answer: [
          {
            ansTitle: "Please do not panic, contact our Client Service team and provide the following information:",
            anslist: [
              "User credentials (Email ID), game details",
              "Approximate time when the game was played (as specific as possible)",
              "Stake amount and account balance before & after the game.",
              "Error message received/ reason given for the issue.",
              "A screenshot of the problem (wherever possible)",
            ],
          },

        ],
      },
      {
        question: "Q8.The video streaming is freezing, what do I do?",
        answer: [
          {
            ansTitle: "Close any other running programs. You can also try reducing the screen resolution or resizing the browser, it’s possible a low network reception could be the problem.",

          },

        ],
      },

      {
        question: "Q9.The live dealer made a mistake, what now?",
        answer: [
          {
            ansTitle: "In the event of a live dealer making a mistake, the round will be temporarily paused as the dealer notifies the pit boss. The players will be notified via chatbox and if an immediate resolution is not possible, the game round in question will be canceled and a refund will be processed.",

          },

        ],
      },

      {
        question: "Q10.I have a doubt, can I ask the dealers or the pit boss?",
        answer: [
          {
            ansTitle: "No! Get in touch with our Client Service team.",

          },

        ],
      },
      {
        question: "Q11.Where can I find the bet limits for live casino games?",
        answer: [
          {
            ansTitle: "The minimum and maximum bet limits are displayed under every table’s name.",

          },

        ],
      },

      {
        question: "Q12. What are Crash Games ?",
        answer: [
          {
            ansTitle: "Crash Games on crick expo bring the thrill of over 50+ games right to your fingertips. From navigating the intensity of crash games to discovering a variety of instant win experiences, our games are designed for non-stop excitement and big wins!",

          },

        ],
      },

      {
        question: "Q13. Will turnover be calculated on these Crash Games?",
        answer: [
          {
            ansTitle: "No, turnover will not be calculated on these Crash Games.",

          },

        ],
      },
      {
        question: "Q14. How is the conversion rate calculated in crick expo's Crash Games?",
        answer: [
          {
            ansTitle: "In Crash Games, the conversion rate is : 100 INR equals 1 point.",

          },

        ],
      },



    ],
  },
  {
    title: "Privacy and Security",
    id: 2,
    data: [
      {
        question: "Q1.Is crick expo secure?",
        answer: [
          {
            ansTitle: "Yes, crick expo is absolutely secure. It abides by strict policies meant to safeguard its players’ right to privacy. All user data is encrypted to protect the confidentiality of its users.",

          },

        ],
      },
      {
        question: "Q2.Are crick expo’s games fair?",
        answer: [
          {
            ansTitle: "Yes, crick expo’s games and odds are transparent and fair. The odds are based on the volume of player bets. The games’ winners are announced through a certified RNG (Random Number Generator) to have a fair outcome and to rule out giving an unfair advantage to any party.",

          },

        ],
      },
      {
        question: "Q3.Does crick expo use cookies?",
        answer: [
          {
            ansTitle: "Yes, crick expo may use cookies to provide the best gaming experience to our players.",

          },

        ],
      },
      {
        question: "Q4.What is my personal information used for?",
        answer: [
          {
            anslist: [
              "To create your personal crick expo account",
              "Deposit/ withdrawal validation",
              "Identity verification process",
              "To prevent identity theft ie, to prevent third party fraud",
              "To keep you updated with latest information related to crick expo",
            ],
          },
        ],
      },

    ],
  },
  {
    title: "Bonus",
    id: 3,
    data: [
      {
        question: "Q1.Do I need to make a turnover to avail every bonus?",
        answer: [
          {
            ansTitle: "The mentioned turnover must be completed in order to activate the bonus. Please note that the turnover requirements are different for each bonus offered on crick expo. .",
          },
        ],
      },
      {
        question: "Q2.Is the bonus/ coupon transferrable?",
        answer: [
          {
            ansTitle: "No, only one person can avail the bonus/ coupon once unless mentioned otherwise.",
          },
        ],
      },
      {
        question: "Q3.When will my bonus be credited?",
        answer: [
          {
            ansTitle: "Bonus will be credited to the crick expo wallet within 24 hours of the turnover being completed.",
          },
        ],
      },
      {
        question: "Q4.Are there any withdrawal charges?",
        answer: [
          {
            ansTitle: "There are no charges for normal withdrawals. However, there are 3% charges for the first instant withdrawal and 5% for the rest.",

            anslist: [
              "Net Banking (IMPS/ NEFT)",
              "uCrypto Transfer",
              "Payment gateways",
              "We also offer cash transactions, please get in touch with Client Services for more details",
            ],
          },
        ],
      },
      {
        question: "First deposit bonus",
        answer: [
          {
            ansTitle: "Maximum Bonus you can receive for First Deposit Bonus is 50000 Note-Users that have made 3 or more withdrawals in a week will not be eligible for the bonus.",

            anslist: [
              "Upto 300% bonus is awarded to every new Club member on his first deposit on crick expo.",
              "Eligibility: 30x turnover of the amount you have deposited must be completed to unlock the bonus.",
            ],
          },
        ],
      },
      
      {
        question: "Second Time deposit Bonus",
        answer: [
          {
            ansTitle: "Maximum Bonus you can receive for Second Deposit Bonus is 50000. Note:Users that have made 3 or more withdrawals in a week will not be eligible for the bonus.",

            anslist: [
              "Second time lucky, only on crick expo! Now get a 50% bonus on your second deposit as a crick expo Club member..",
              "Eligibility: 15x turnover of the amount you have deposited must be completed to unlock the bonus.",
            ],
          },
        ],
      },
      
      {
        question: "Loyalty bonus",
        answer: [
          {
        

            anslist: [
              "Loyalty bonus is awarded to Club members on every deposit once they have entered the said Loyalty stage.",
             " Silver: 3%",
             " Gold: 6%",
             " Platinum: 9%",
             " Eligibility: Applicable to the Club member when he has met the turnover requirements to move on to the next stage of Loyalty.",
              "In casino and card games, the bonus will not be awarded if a player places bets worth the same amount on the opposite bet in the same hand.",
              "Note-Users that have made 3 or more withdrawals in a week will not be eligible for the bonus.",
            ],
          },
        ],
      },
      
      {
        question: "Q6.Why was my Sportsbook bet not counted in the turnover?",
        answer: [
          {
            ansTitle: "If a user places a bet worth upto 35p in the Sportsbook, it is not counted in the turnover criteria and qualifies for bonus abuse.",

          },
        ],
      },
      
      {
        question: "Q7.Are all bets in SportsBook counted for bonus turnover?",
        answer: [
          {
            ansTitle: "Turnover will be considered in Sportsbook only on the bets placed above 1.35 odds.",

          },
        ],
      },
      
      {
        question: "Q8.Are all session bets counted for bonus turnover?",
        answer: [
          {
            ansTitle: "Turnover on session bets will only be considered on the NO option.",

          },
        ],
      },
      
      {
        question: "Q9.Why did I not receive my First Deposit Bonus?",
        answer: [
          {
            ansTitle: "A New User will not receive the First Deposit Bonus if the same Device (computer, laptop, mobile) is used to make multiple accounts at crick expo Club. Also, users that have made 3 or more withdrawals in a week will not be eligible for the bonus.",

          },
        ],
      },
     
    ],
  },
  {
    title: "Banking",
    id: 4,
    data: [
      {
        question: "Q1.What are the methods to deposit money on crick expo?",
        answer: [
          {
            ansTitle: "The following methods are accepted:",

            anslist: [
              "Net Banking (IMPS/ NEFT)",
              "uCrypto Transfer",
              "Payment gateways",
              "We also offer cash transactions, please get in touch with Client Services for more details",
            ],
          },
        ],
      },
      {
        question: "Q2.What is the process of making a deposit?",
        answer: [
          {
            // ansTitle: "The following methods are accepted:",

            anslist: [
              "Log in to your account",
              "Click on the ‘Wallet’ button at the top right",
              " Click on ‘Deposit’",
              " Choose method of payment",
              "Enter your desired amount and a screenshot of your payment(only for bank deposits) Submit",
            ],
          },
        ],
      },
      {
        question: "Q3.What is the minimum and maximum deposit amount?",
        answer: [
          {
            ansTitle: "The minimum and maximum deposit amount depends on the selected deposit method:",

            anslist: [
              "METHOD	LIMITS PER TRANSACTION",

              "Payment Gateway	300	500000",
              "Bank Transfer		500000",

            ],
          },
        ],
      },
      {
        question: "Q4.What is the maximum and minimum withdrawal amount?",
        answer: [
          {
            ansTitle: "The minimum and maximum deposit amount depends on the selected deposit method:",

            anslist: [
              "METHOD	LIMITS PER TRANSACTION",
              "Payment Gateway	300	500000",
              "Bank Transfer		500000",

            ],
          },
        ],
      },
      {
        question: "Q5.What currencies are accepted on crick expo?",
        answer: [
          {
            ansTitle: "Mainly INR, however we do provide the facility for International debit and credit cards for overseas players along with wallets such as PayPal, Astropay etc that allow foreign currencies. We also accept crypto currency.",
          },
        ],

      },
      {
        question: "Q7.How do I withdraw my money from the crick expo wallet?",
        answer: [
          {
            anslist: [
              "Log in to your crick expo account",
              "Click on the Wallet icon on the top right",
              "Click on ‘Withdrawal’",
              " Click on “Add new”",
              " An OTP will be sent to the registered Mobile Number",
              "Enter the OTP",
              "Add your bank account",
              "Enter the amount you wish to withdraw from your wallet",
              "Click on Submit",
            ],
          },
        ],

      },
      {
        question: "Q8.Is it safe to deposit and withdraw money to and from crick expo?",
        answer: [
          {
            ansTitle: "Yes, it is safe because crick expo only uses certified and secured payment methods only.",

          }

        ],
      },
      {
        question: "Q10.How can I cancel my withdrawal?",
        answer: [
          {
            ansTitle: "You can cancel the withdrawal before it goes into processing.",

          }

        ],
      },
      {
        question: "Q11.How long does it take to process the deposit?",
        answer: [
          {
            ansTitle: "Deposits take upto 1 hour to be credited in your crick expo wallet. Please note that in case of a technical issue it may take longer.",

          }

        ],
      },
      {
        question: "Q12. How long does it take to process a withdrawal?",
        answer: [
          {
            ansTitle: "It takes up to 45 mins for the instant withdrawal to credit in your bank account. In case the withdrawal is pending from the bank, it may take up to 72 banking hours for the withdrawal to reflect in your bank account. Please note that a withdrawal request placed at night will be processed at 9am the next morning when the banks are operational and will be thus credited within 12 hours of the same.",

          }

        ],
      },
      {
        question: "Q13.Can someone make a deposit on my behalf?",
        answer: [
          {
            ansTitle: "No, crick expo does not accept payments from a third party."
          }

        ],
      },
      {
        question: "Q14.Why was my withdrawal canceled?",
        answer: [
          {
            anslist: [
              "The betting requirements were not met",
              "Banking information was incorrect",
              "Withdrawal was requested to a bank account that is already linked with another crick expo account.",
              "ID verification documents were requested by crick expo. In case of failure to provide documents within 72 hours, withdrawal requests are canceled and the said amount will be refunded to the crick expo wallet."
            ],
          }

        ],
      },
      {
        question: "Q16.When will I receive my bonus?",
        answer: [
          {
            anslist: [
              "Bonus will be credited to your crick expo wallet within 24 hours of the required turnover criteria being completed.",
            ],
          }

        ],

      },
      {
        question: "Q17. How long is the bonus valid for?",
        answer: [
          {
            ansTitle: "The bonus is valid for 21 days from the time of receival. Please complete your turnover to activate it!"
          }

        ],
      },
      {
        question: "Q18.Deposit transaction status says failed/ canceled but my money has been debited. what should I do?",
        answer: [
          {
            ansTitle: "When a payment status says failed or canceled it means that your transaction has failed on the payment gateway. The money goes from the bank to the payment gateway and then from the gateway sends it to us. Sometimes the transaction fails on the gateway end if you exit the window before it's completed or if there is any issue with their server. So if it fails on the gateway, they issue the refund to you up to 14 banking days as per their refund policy."
          }

        ],
      },

      {
        question: "Q19. Why was my withdrawal withheld?",
        answer: [
          {
            ansTitle: "If a user only deposits and attempts to withdraw the money without placing a single bet, the site holds the right to withhold upto 100% of the amount."
          }

        ],
      },
    ],
  },
  {
    title: "SportsExch",
    id: 5,
    data: [
      {
        question: "Q1.What is sports betting?",
        answer: [
          {
            ansTitle: "Wagering on the outcome of a sport based on logical calculations or luck, predicting sports events results is referred to as Sports Betting. You can choose from a range of sports such as cricket, football, tennis, horse racing and many more in the pipeline.",

          },
        ]
      },
      {
        question: "Q2. Can I place my bets online?",
        answer: [
          {
            ansTitle: "Yes! crick expo has brought easy, accessible sports betting within the reach of every gambling enthusiast looking for a trustworthy option to place their bets with.",

          },
        ],
      },
      {
        question: "Q3.How do I place a bet?",
        answer: [
          {
            anslist: [
              "Open the ‘SportsExch’ tab.",
              "Select the game and the match.",
              "Click on the odd numbers corresponding to the selected outcome for that bet.",
              "Enter your stake.",
              "Double check the details before clicking on the “Place Bet” button.",
            ],
          },

        ],


      },
      {
        question: "Q4.What is the meaning of back and lay?",
        answer: [
          {
            ansTitle: "Back is when you are betting on the team to win. A Lay bet is when you’re betting on the team to lose.",

          },
        ]
      },

    ],
  },
  {
    title: "Registration and Login",
    id: 6,
    data: [
      {
        question: "Q1.How do I register?",
        answer: [
          {
            anslist: [
              "Click on “Join Now”",
              " Fill out the form",
              "Enter the verification code you receive",
              "Enter your referral code (if any)",
              "  Read and accept the terms and conditions",
              " Click on “Register”, congratulations you are now a crick expo Club member!",
            ],
          },
        ],
      },
      {
        question: "Q2.I’m having trouble signing up!",
        answer: [
          {
            ansTitle: "Our system will highlight the fields that are missing. Please verify the following: If you have cross checked all of the above and still face an issue, please report it to our Client Service team.",
            anslist: [
              "Have you filled all the mandatory fields?",
              "Is your email address a valid one?",
              "Do you have an existing account?",
            ],
          },
        ],
      },
      {
        question: "Q3.Forgot your password?",
        answer: [
          {
            ansTitle: "Reset your password using the “Forgot Password” function below the field. Enter your registered email address. A link to reset the password will be sent to that mailbox. Enter the code into the provided field and create a new password.",

          },
        ],
      },
      {
        question: "Q4.How old do I have to be to be eligible for crick expo Club membership?",
        answer: [
          {
            ansTitle: "18+ adults only.",

          },
        ],
       
      },
      {
        question: "Q5.Do I need to be an Indian citizen to register on crick expo?",
        answer: [
          {
            ansTitle: "No, you can be a crick expo Club member from anywhere in the world.",

          },
        ],
      },

      {
        question: "Q6.Can I open or operate an account for a friend or relative?",
        answer: [
          {
            ansTitle: "No. An account can and must be accessed only by one person and the login credentials must not be shared with a second person.",

          },
        ],
      },

      {
        question: "Q7.I’m unable to register because it says my credentials already exist. What do I do?",
        answer: [
          {
            ansTitle: "Contact Client Services for assistance.",

          },
        ],

      },

      {
        question: "Q8.Can I hold more than one account on crick expo? ",
        answer: [
          {
            ansTitle: "No. One user is only allowed one account and if found violating the same, all his accounts will be subject to suspension immediately.",

          },
        ],
      },

      {
        question: "Q9. My account got locked, Why and What do I do?",
        answer: [
          {
            ansTitle: "Passwords are case sensitive and after three consecutive wrong passwords, the account will get locked. Contact Client Services for resolution of the same.",

          },
        ],
      },
    ],
  },
];
