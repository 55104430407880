import React, { useState, useEffect } from "react";
import businessLogo from "../../../Assest/images/business-logo.png";

import { headerIcon } from "./headerconstent";
import { FaUser, FaRupeeSign } from "react-icons/fa";
import { RiMenu2Fill } from "react-icons/ri";
import SubHeader from "./Subheader/SubHeader";
// import MoreGame from "../moregame/moreGame";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../../../redux/action/userAction";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../../Translation/index";
import { IoWallet } from "react-icons/io5";
import { getSocket, initSocket } from "../../Auth/Socket/SocketConn";
import { MdRefresh } from "react-icons/md";
import Spinner from "../../Spinner/Spinner";
import { MarqueeNotifection } from "../../marquee/marquee";
import { B2C } from "../../../globleConstent/membership/membership";
import { useBalance } from "../ContextApi";

export const HeaderAuth = ({ setIsOpen, loggingIn, user, otpSent, users,
  setIsOpenRightSidebar }) => {
  const [isGame, setIsGame] = React.useState(false);

  // const [loading, setLoading] = useState(false);
  const { i18n, t } = useTranslation();


  const [refresh, setRefresh] = useState(false);
  const [balance, setBalance] = useState({
    coins: '',
    exposure: ''
  })

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userBalance, loading } = useSelector(state => state.authentication);
  // const { balance, setBalance } = useBalance();


  useEffect(() => {
    // dispatch(userAction.userBalance());
    const intervalId = setInterval(() => {
      dispatch(userAction.userBalance());
    }, 5000);
    // dispatch(userAction.userBalance());
    // Update socket connection
    if (isGame) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      clearInterval(intervalId);
      document.body.classList.remove("overflow-hidden");
    };

  }, [isGame])

  // useEffect(() => {
  //   dispatch(userAction.userBalance());

  //   const initData = localStorage.getItem("MatchListData");

  //   if (!initData) {
  //     dispatch(userAction.sportMatchList());
  //   } else {
  //     const intervalId = setInterval(() => {
  //       localStorage.removeItem("MatchListData");
  //       dispatch(userAction.sportMatchList());

  //     }, 300000);

  //     return () => clearInterval(intervalId);
  //   }
  // }, [dispatch]);


  useEffect(() => {

    const initData = localStorage.getItem("MatchListData");
    const intervalId = setInterval(() => {
      localStorage.removeItem("MatchListData");
    }, 1000 * 60 * 5);
    if (!initData) {
      dispatch(userAction.sportMatchList());
    }
    return () => clearInterval(intervalId);
  }, [dispatch]);

  // socket ke time remove karna hai
  useEffect(() => {
    // updateSockte();
    setTimeout(() => {
      let Balance = JSON.parse(localStorage.getItem('clientBalance'));
      let clientExposure = JSON.parse(localStorage.getItem('clientExposure'));
      setBalance({
        coins: Balance,
        exposure: clientExposure
      });
    }, 1000);


  }, [balance])

  // console.log(userBalanceData, "userBalanceDatauserBalanceData");


  const handleClick = () => {
    dispatch(userAction.userBalance());
  };


  function updateSockte() {
    let userID = JSON.parse(localStorage.getItem('clientUser'))
    let token_Id = userID?.token
    let socket = getSocket()

    if (!socket || socket == null) {
      socket = initSocket(token_Id);
      const loginData = {
        userId: userID?.data?.userId,
        token: token_Id
      }
      socket.emit(`login`, loginData);
    }
    // socket.on('coinUpdate', (data) => {
    //   localStorage.setItem("clientBalance", JSON.stringify(data.coins));
    //   localStorage.setItem("clientExposure", JSON.stringify(data.exposure));
    //   setBalance({
    //     coins: data.coins,
    //     exposure: data.exposure
    //   });
    // });

    // socket.on('userLoggedOut', (data) => {
    //   localStorage.removeItem('clientspuser')
    //   history.push('#/login')
    //   window.location.reload();
    // })

  }


  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };
// socket Time OPen KArna hai
  // let Balance = JSON.parse(localStorage.getItem('clientBalance'));
  // let clientExposure = JSON.parse(localStorage.getItem('clientExposure'));


  return (
    <>
      <header>
        <MarqueeNotifection />
        <nav className="border-gray-200 px-2 lg:px-4 py-1 bg-black">
          <div className="flex items-center mx-auto py-1">
            <div className="2xl:w-3/5  w-2/5 flex items-center ">
              <span className="flex items-center gap-2">
                <RiMenu2Fill
                  size={25}
                  color="white"
                  onClick={() => setIsOpen(true)}
                  className="flex items-center gap-2"
                />
                <Link to={"/app/dashboard"}>
                  <img
                    src={businessLogo}
                    alt="logo"
                    title="business-log"
                    className="mr-3 h-9 sm:h-9"
                  />
                </Link>
              </span>

            </div>

            <div className="flex 2xl:w-2/5 w-3/5 items-center justify-end gap-2">
              <div class=" flex justify-end text-sm  gap-1 px-1 items-center xl:w-[65%] w-full ">
                <Link to="/app/bets" >
                  <div className="md:px-4 px-1 lg:block hidden md:py-2 py-1 rounded-full border border-[#f36c21] hover:bg-[#2C1306] text-center text-white uppercase">
                    <span>Open&nbsp;</span>
                    <span>Bets</span>
                  </div>
                </Link>
                {B2C === true && (
                  <Link to="/app/wallet/deposit" >
                    <div className="md:px-4 px-1 md:py-2 py-1 flex items-center gap-1 hover:bg-green-700 rounded-full border border-green-500 text-center text-white uppercase">
                      <span className="md:block hidden"><IoWallet /></span>
                      <span className="lg:text-sm text-xs">Deposit</span>
                      <span className="md:block hidden">Now</span>
                    </div>
                  </Link>
                )}
                <div className="md:px-4 px-1 md:py-2 py-1 flex gap-1 justify-center hover:bg-[#2C1306] items-center rounded-full border border-[#f36c21] text-center text-white uppercase">

                  <> {B2C === true ? (
                    <Link to="/app/wallet" className="flex items-center">
                      <IoWallet className="md:block hidden mr-1" />
                      <FaRupeeSign />
                      <span className="lg:text-sm text-xs">
                        {/* {balance && balance.coins ? parseFloat(balance.coins).toFixed(2) : parseFloat(Balance).toFixed(2)} */}
                        {balance && balance.coins ? parseFloat(balance.coins).toFixed(2) : 0}
                      </span>
                    </Link>
                  ) : (
                    <div className="flex items-center">
                      <IoWallet className="md:block hidden mr-1" />
                      <FaRupeeSign />
                      <span className="lg:text-sm text-xs">
                        {/* {balance && balance.coins ? parseFloat(balance.coins).toFixed(2) : parseFloat(clientExposure).toFixed(2)} */}
                        {balance && balance.coins ? parseFloat(balance.coins).toFixed(2) : 0}
                      </span>
                    </div>
                  )}
                  </>




                  <MdRefresh size={20} onClick={() => handleClick()} />
                </div>
                <div onClick={() => setIsOpenRightSidebar(true)}
                  className="md:px-4 px-2 cursor-pointer md:py-2 py-1 hover:bg-gray-600 rounded-full border border-white flex justify-center items-center gap-1 text-center text-white uppercase">
                  <span><FaUser /></span>
                  <span className="sm:block hidden">Profile</span>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <SubHeader setIsGame={setIsGame} />

      </header>
    </>
  );
};
