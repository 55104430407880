import React, { useState } from "react";
import { DrawerList } from './drawerConstent'

export default function Drawer({ children, isOpen, setIsOpen }) {

    const [hoveredIndex, setHoveredIndex] = useState(null);
    // Handal Hover Mouse Change icon 
    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };
    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };
    const lastItemIndex = DrawerList.length - 1;

    return (
        <main
            className={
                `fixed overflow-hidden z-10 bg-gray-900 bg-opacity-25 inset-0 transform ease-in-out ${isOpen ? 'block' : 'hidden'}`

            }
        >
            <section
                className={
                    " w-screen max-w-[16rem] left-0 absolute bg-black  h-full shadow-xl"

                }
            >
                <article className="relative w-screen max-w-[16rem] flex flex-col  h-full">
                    <header className="p-6 font-bold text-lg text-white flex justify-center bg-[rgb(33,33,33)]">
                        <button type="button" class="text-white bg-[rgb(243,107,33)]  focus:ring-4 focus:outline-none  font-medium rounded-lg text-lg px-4 py-2.5 text-center">Ragister</button>
                    </header>
                    {children}
                    <div className="h-full">
                        <ul className="font-medium">
                            {DrawerList?.map((item, index) => (
                                <li key={index} className={index === lastItemIndex ? 'border-b bg-green-500' : 'border-b'}>
                                    <a
                                        href={item.link}
                                        className={index === lastItemIndex ? 'flex items-center p-3 text-gray-900 dark:text-white  hover:bg-green-500' : 'flex items-center p-3 text-gray-900 dark:text-white group hover:bg-[rgb(243,107,33)]'}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <img src={index === hoveredIndex ? item.icon2 : item.icon1} alt={item.title} className="h-5 w-5" />
                                        <span className="ms-3 text-sm text-white">{item.title}</span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>

                </article>
            </section>
            <section
                className=" w-screen h-full cursor-pointer "
                onClick={() => {
                    setIsOpen(false);
                }}
            ></section>
        </main>
    );
}
