import { useState, useEffect } from "react";
import { MdCancel } from "react-icons/md";
import DownloadModalImage from "../../../../src/Assest/images/DownloadModal/download-app-image.png"

function DownloadModal() {
  const [modalOpen, setModalOpen] = useState(
    localStorage.getItem("modalOpen") === "true"
  );



  const closeModal = () => {
    setModalOpen(false);
    localStorage.setItem("modalOpen", false);
  };
  

  return (
    <>
      {modalOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 px-2"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="relative w-[600px] bg-white rounded-lg shadow-lg">
            <img
              className="w-full h-full object-cover rounded-lg"
              src={DownloadModalImage}
            />
            <button
              className="absolute top-0 right-0 mt-2 mr-2 text-white"
              onClick={closeModal}
            >
              <MdCancel className="w-8 h-8" />
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default DownloadModal;
