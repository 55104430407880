// import cricket from '../../../Assest/images/game/cricket.webp'
import cricket from '../../../Assest/images/game/cricket.webp'
import soccer from '../../../Assest/images/game/soccer.webp'
import tennis from '../../../Assest/images/game/tennis.webp'
import Election from "../../../Assest/images/SubHeaderImage/Election.webp";


export const DrawerListMatch = [
  {
    icon: cricket,
    title: "Cricket",
    text: "Cricket",
    count: "9",
    link: "/app/sportsbook/page/",
    sportId: 4,
  },
  {
    icon: soccer,
    title: "Soccer",
    text: "Soccer",
    count: "32",
    link: "/app/sportsbook/page/",
    sportId: 1,
  },
  {
    icon: tennis,
    title: "Tennis",
    text: "Tennis",
    count: "7",
    link: "/app/sportsbook/page/",
    sportId: 2,
  },
  {
    icon: Election,
    title: "Election",
    text: "Election",
    link: "/app/sportsbook/page/",
    sportId: 2003,
  }
  
];