import React from "react";

import NavigatePathComponent from "../../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent";
import { Header } from "../../../component/Public/Header/Header";
import Drawer from "../../../component/Public/Drawer/Drawer";


const TermCondition = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (

   
    <div className="slider hota hai wo hatan hai bootm se  wo header se hai">
      <div>
        <div className="bg-black h-full flex flex-col">
          <div className="">
            <Header setIsOpen={setIsOpen} />
          </div>
          <NavigatePathComponent showPathName={"Term & Condition"}/>
          <Drawer isOpen={isOpen} setIsOpen={setIsOpen} />
          <section className="w-full  max-w-7xl mx-auto justify-items-center justify-center  mt-10 mb-20">
            <div className="text-white text-opcitiy-70">
              <h2 className="text-lg font-bold">I. Crick Expo CLUB </h2><br />
              <span> By subscribing/registering to/on our theCrick Expo.io website, and its sub-domains (collectively referred to as “Crick Expo site”) and/or any services including, registering and playing betting games, participating in betting contests and tournaments, provided on the Crick Expo site, you agree that you have read, understood and have agreed to be bound by this Terms and Conditions, regardless of how you subscribe to or use the services.
              </span>
              <br />
              <br />
              <span> Please note that this Privacy Policy will be agreed between you and Crick Expo. (‘We’, ‘Us’ or ‘Our’, as appropriate). This Privacy Policy is an integrated part of Crick Expo’s Terms and Conditions. We may periodically make changes to this Privacy Policy and will notify you of these changes by posting the modified terms on our platforms. We recommend that you revisit this Privacy Policy regularly.Please read the Terms carefully and if you do not accept the Terms, do not use, visit or access any part (including, but not limited to, sub-domains, source code and/or website APIs, whether visible or not) of the Crick Expo site. The Terms shall also apply to all telephone betting and betting or gaming via mobile devices including downloadable applications to a mobile device (as if references to your use of the Website were references to your use of our telephone betting and/or mobile devices betting facilities). “We”, “us”, “our”, “Crick Expo” shall mean Win Ventures NV and/or any of its affiliated entities. For the purpose of these Terms and Conditions, wherever the context so require "you" or "your" or "User" or “Participant” shall mean any natural or legal person who has agreed to become a member of the Crick Expo site by visiting the Crick Expo site as registered User or a person who has used the Crick Expo site either through browsing or otherwise. Where you play any game, or place a bet or wager, using the Website, you accept and agree to be bound by, the Rules which apply to the applicable products available on the Website from time to time.</span>
              <br />
              <span>Crick Expo one stop shop for leisure gambling games including Baccarat, Teen Patti, Roulette, Poker, Blackjack and sports betting through which it offers cricket based, football, tennis, horse racing based online betting games along with live casino betting. The Crick Expo site is neither affiliated by nor associated to any sort of private or government Sports leagues and tournaments until and unless expressly stated by Crick Expo. In addition to this, the Crick Expo app is not related and does not claim any official status with any of the official or non-official sports teams and/or sportspersons.
              </span>
              <br /><br />

              <h2 className="text-lg font-bold">II. USER REGISTRATION </h2><br />
              <span>To participate in Crick Expo activities, you must register by creating a Crick Expo club account by providing your true information. In the event you wish to play on the Crick Expo site you shall create an account with us. You shall do so after completing the registration process by: </span>
              1.Providing true, accurate, correct and complete information as prompted by the applicable registration form.
              2.Maintaining and updating true, accurate, correct and complete information provided by you during the registration process.

              <span>You shall be required to choose a username and password. You shall be solely responsible for maintaining the confidentiality of your account username and password. You shall be solely responsible for the registration information provided by you during submissions and the consequences of posting or publishing them. Crick Expo is only acting as a repository of data and makes no guarantee as to the validity, accuracy, or legal status of any information / submissions.</span><br />
              <span>If, at any time, Crick Expo believes that your account and password is being misused in any manner, or that the information provided by you during the above stated registration process is not true, inaccurate or incomplete, then Crick Expo reserves the right to terminate/cancel or suspend your account and block your access to the Crick Expo site. You shall be solely and exclusively responsible for any and/or all the activities that are carried through your account. You agree to notify Crick Expo of any unauthorized use of your account and/or any breach of security please contact us at chat. Crick Expo shall not be liable for any loss that you may incur as a result of another person(s) using your account username and/or password and / or your failure to comply with this section. You shall be solely and exclusively liable for all the losses to Crick Expo and/or other claims, damages, fines due to such an unauthorized use.
                If Crick Expo charges you a platform fee (facilitation fee) in respect of any Crick Expo services, Crick Expo shall, without delay, refund such platform fee in the event of suspension and/or termination of your account or Crick Expo services on account of any fault on the part of Crick Expo. It is hereby clarified that no refund shall be payable if such suspension and/or termination is affected due to any breach or failure to adhere any of these Terms and Conditions, Privacy Policy and/or any other rules by the you, the User, or a person(s) accessing your account by using your username and password; or any circumstances beyond the reasonable control of Crick Expo.</span> <br /><br />

              <h2 className="text-lg font-bold">III. REGISTRATION AND PARTICIPANTS</h2><br />
              <span>The games/tournaments/contests available on the Crick Expo site are open only to persons aged eighteen (18) years or above at the date of usage and/or registration, who are participating from jurisdiction where gaming on Crick Expo is permitted. Employees of Crick Expo, Play Ventures NV, their associated, affiliated and subsidiary companies and their families, agents, game sponsors, and any person connected with Crick Expo are excluded from participating in the game and/or winning a prize. By taking part in a game (excluding administering the game) on the Crick Expo site, Users/Participants warrant that all information submitted by them is true, accurate and complete proof of age may be required. Where this is not the case, we reserve the right to suspend the account and treat any deposits into the gambling account as being invalid (and any winnings arising from such deposit as void). Where an account is suspended, the relevant customer may contact us at chat seeking details regarding the same. The sole discretion to declare a User ineligible to participate shall vest with Crick Expo. Users/Participants who wish to participate shall have a valid email address. In order to register for the betting games, Users/Participants are required to accurately provide the following information:</span><br />
              <ul className="list-disc list-inside px-2">
                <li>Full Name</li>
                <li>E-mail address</li>
                <li>Password</li>
                <li>Gender</li>
                <li>Date of birth</li>
              </ul><br /><br />
              <span> As part of the registration process, we may supply your information details to authorized credit reference agencies to confirm your identity and payment card details. You agree that we may process such information in connection with your registration. Participants may open only one account; in case we identify any customer with more than one account we reserve the right to treat any such accounts as one joint account. Participant must keep their registration and account details up to date. This, and your account information, may be amended in the personal section of the Website. If you require any assistance, please contact us at chat.</span><br /><br />


              <h2 className="text-lg font-bold"> IV. ACCOUNT DETAILS </h2><br />
              <span>Crick Expo allows all its users to choose their own Username and Password combination for their account. Users must keep this information secret and confidential as you are responsible for all bets/wagers placed on your account and any other activities taking place on your account. Bets will stand if your Username and Password have been entered correctly or if your account has been accessed via Touch ID, Fingerprint log in, Face ID, Passcode, subject to there being sufficient funds in the account.<br />
                If, at any time, you feel a third party is aware of your Username, Password you should change it immediately via the Crick Expo site. In case you forget part or all of your combination, contact us at chat or avail the Chat support made available for Users on the Platform. If you activate Touch ID, Fingerprint log in, Face ID, Passcode and feel that a third party could be accessing your account via any of these methods then you should immediately disable Touch ID, Fingerprint log in, Face ID, Passcode from all of your devices and contact us at chat. You are responsible for the security of your device(s) and for all bets/wagers placed on your account and any other activities taking place on your account.<br />
                If you nominate another person as an authorized user of your account, you shall be responsible for all transactions such person makes using the relevant account details. Should you lose your account details or feel that someone else may have your account details, please contact us at chat. Please note that cardholder details and any other sensitive data should never be sent to us by unencrypted email. The current balance and transaction history of your account may be viewed at any time once you have logged into your account on the Website.</span><br /><br />


              <h2 className="text-lg font-bold">  V. SUSPENSION AND CLOSURE </h2><br />
              Reach out to us at chat in case of closure of accounts. Any negative balance on your behalf will be immediately due and payable to Crick Expo, and your account will not be closed until the relevant amount owed to Crick Expo is paid in full. Crick Expo reserves the right to close or suspend your account at any time and for any reason including any violation of laws, if a user is participating on the Crick Expo site illegally. Without limiting the preceding sentence, Crick Expo shall be entitled to close or suspend your account if:
              i.Crick Expo considers that you have used the Website in a fraudulent manner or for illegal and/or unlawful or improper purposes;
              ii. Crick Expo considers that you have used the Website in an unfair manner, have deliberately cheated or taken unfair advantage of Crick Expo or any of its customers or if your account is being used for the benefit of a third party;
              iii. Crick Expo considers that you have opened or are using any additional accounts to conceal your activity or to avoid measures put in place on other active or inactive accounts;
              iv. Crick Expo considers that you have deliberately provided incomplete or inaccurate information when registering with the site or during of our verification processes;
              v. Crick Expo is requested to do so by the police, any governmental or other regulatory authority, court or other authority of competent jurisdiction, law or applicable regulation;
              vi. Crick Expo considers that any of the events referred to in (i) to (iv) above may have occurred or are likely to occur; or
              vii. you become bankrupt;
              viii. you are in breach of Crick Expo’s Responsible Gaming Policy and/or Crick Expo determines that your continued participation may be detrimental to your health;
              ix. your account is deemed to be dormant and its balance is, or reaches zero or is otherwise closed in accordance with the Terms and Conditions listed herein.
              If Crick Expo closes or suspends your account for any of the reasons referred to in (i) to (viii) above, you shall be liable for any and all claims, losses, liabilities, damages, costs and expenses incurred or suffered by Crick Expo (together “Claims”) arising therefrom and shall indemnify and hold Crick Expo harmless on demand for such Claims. In the circumstances referred to in (i) to (iv) above (“Prohibited Events”), Crick Expo shall also be entitled to void any bets or other activity and withhold and/or retain any and all amounts which would otherwise have been paid or payable to you (including any winnings, Bet Credits or bonus payments) that are attributable to a Prohibited Event or up to an amount no greater than any amount previously withdrawn by you where that amount withdrawn is attributable to a Prohibited Event.
              VI. FINANCES
              A. Deposits And Wagers
              You may only bet/wager with the amount of cleared funds held in your account. Accordingly, if you want to place bets or participate in gaming, you must deposit monies into your account. By depositing funds into your account, you direct us and we agree to hold them, along with any winnings, for the sole and specific purpose of using them (i) to place your sporting and gaming stakes; and (ii) settling any fees or charges that you might incur in connection with the use of our services (Purpose). We shall be entitled to suspend or close your account if we reasonably consider or have reason to believe that you are depositing funds without any intention to place sporting and/or gaming stakes. In such circumstances we may also report this to relevant authorities.
              For payment methods that require a specific account holder, you should only deposit funds into your gambling account from a source where you are the named account holder. In such cases where Crick Expo identifies that the named account holder differs from that which holds the Crick Expo account, we reserve the right to treat any deposits into the gambling account as being invalid (and any winnings arising from such deposit as void).
              For payment methods that require a specific account holder, you should only deposit funds into your gambling account from a source where you are the named account holder. In such cases where Crick Expo identifies that the named account holder differs from that which holds the Crick Expo account, we reserve the right to treat any deposits into the gambling account as being invalid (and any winnings arising from such deposit as void).
              All funds that we hold for you are held in separate designated customer bank accounts to be used solely for the Purpose and the bank has acknowledged this. We must return the funds to you if they are not used for the Purpose. The bank has acknowledged that your funds are held in those accounts solely for the Purpose. The bank has confirmed that it will not mix customers’ funds in those accounts with any other, including our own, and that it has no claim on the funds itself. In view of the above, in the event of our insolvency, your funds are protected. The funds would still belong to you and would not fall into the hands of the liquidator.
              All funds that we hold for you are held in separate designated customer bank accounts to be used solely for the Purpose and the bank has acknowledged this. We must return the funds to you if they are not used for the Purpose. The bank has acknowledged that your funds are held in those accounts solely for the Purpose. The bank has confirmed that it will not mix customers’ funds in those accounts with any other, including our own, and that it has no claim on the funds itself. In view of the above, in the event of our insolvency, your funds are protected. The funds would still belong to you and would not fall into the hands of the liquidator.
              You agree that we shall be entitled to retain any interest which might accrue on monies held in your Crick Expo account. No credit will be offered by any employee of Crick Expo , and all bets must be supported by sufficient funds in the customer account. Crick Expo reserves the right to void any bet/wager which may have inadvertently been accepted when the account did not have sufficient funds to cover the bet/wager. Should funds be credited to a customer's account in error it is the customer's responsibility to inform Crick Expo without delay, Crick Expo will recover such funds by account adjustment. Unless otherwise stated, all examples given on the Crick Expo site are given in Euro(s) and/or the consequent local currency depending on the geographical location of the Users.
              If Crick Expo considers that you are using the Website for any reason other than the authorized purpose, Crick Expo shall be entitled to close or suspend your account and you shall:
              a. be liable for any and all claims incurred arising therefrom; and
              b. indemnify Crick Expo on demand for such claims.
              In addition, Crick Expo shall be entitled to withhold and/or retain any and all amounts earned or received by you as a result of or in connection with your ‘unauthorized’ use of the Website including activities which are not for an authorized purpose. Crick Expo does not charge for deposits made by Debit/Credit Card, however please be aware that some card issuers consider betting transactions as ‘cash’ payments and therefore may charge you a cash advance fee. Please check with your card issuer for further details. Any and all bank charges and other chargeback fee, shall solely be the liability of the User/yours.
              B. Withdrawals
              All withdrawals will be processed to the payment account from which the deposits were made. Withdrawal payments can only be made in the name of and to the registered account holder.
              For most payment types, withdrawals can be processed by clicking 'Withdraw' on the Website, subject to there being sufficient funds in your betting account. There is no set maximum withdrawal amount per day.
              If the value of a deposit is not played through in full before a withdrawal is requested, Crick Expo reserves the right to make a charge to the customer’s account to cover all reasonable costs relating to both the deposit and withdrawal. If necessary, the value of the withdrawal requested may be reduced accordingly.
              If the deposit method of the account is paysafecard and the value of a deposit is not played through in full before a withdrawal is requested, or there is any evidence of a series of bets/wagers placed which results in guaranteed customer profits irrespective of the outcome, or where all the betting/wagering is determined to be low risk, Crick Expo reserves the right to request a receipt showing proof of purchase of the voucher before any withdrawals are processed.
              C. Other
              If we incur any charge-backs, reversals or other charges in respect of your account, we reserve the right to charge you for the relevant amounts incurred. We may, at any time, set off any positive balance on your account against any amounts owed by you to Crick Expo or any company within the Crick Expo group.
              You are responsible for reporting your winnings and losses to the tax and/or other authorities in your jurisdiction. Internet gambling may be illegal in the jurisdiction in which you are located. If so, you are not authorized to use your payment card to complete the transaction. However, any bets/wagers accepted from such jurisdictions will stand - win or lose.
              VII. BETTING PROCEDURES
              A. Placing Bets/Wagers
              It is the responsibility of the customer to ensure details of their bets/wagers are correct. Once bets/wagers have been placed they may not be cancelled by the customer. Bets can only be changed by the customer using our Edit Bet feature, where this is available. Crick Expo reserves the right to cancel any bet/wager at any time.
              Your funds will be allocated to bets/wagers in the order they are placed and will not be available for any other use. Crick Expo reserves the right to void and/or reverse any transactions made after a bet/wager has been placed involving allocated funds, either at the time or retrospectively.
              B. Bet/Wager Confirmation
              Bets/wagers will not be valid if there are insufficient funds in your account. In case of telephone bets/wagers 'acceptance confirmed' will be deemed only after an operator has completed a full read back of the bet/wager details and confirmed to the customer that the bet/wager has been successfully processed and accepted.
              A bet/wager that you request will only be valid once accepted by Crick Expo’s servers. Each valid bet/wager will receive a unique transaction code. We shall not be liable for the settlement of any bets/wagers which are not issued with a unique transaction code. If you are unsure about the validity of a bet/wager, please check your account history, or Contact Us.
              Notwithstanding the above, if bets/wagers are placed via a credit or debit card they are not valid until we have received payment in full. The bet/wager will be automatically void if we have not received the payment prior to the relevant event commencing.
              Should a dispute arise, you and Crick Expo agree that the Crick Expo transaction log database will be the ultimate authority in such matters.
              C. Offers
              Where any term of an offer or promotion is breached or there is any evidence of a series of bets placed by a customer or group of customers, which due to enhanced payments, Free Bets, risk free bets, Bet Credits or any other promotional offer results in guaranteed customer profits irrespective of the outcome, whether individually or as part of a group, Crick Expo may reclaim the enhanced payment, Free Bet, risk free bet or Bet Credit element of such offers and/or void any bet funded by the Free Bet or Bet Credits. In addition, where there is evidence of such activity, Crick Expo may levy an administration charge on the customer up to the value of the Bet Credits, Free Bet, risk free bet or enhanced payment towards the administrative costs incurred in identifying and taking action against the activity.
              Crick Expo may reclaim any bonus amount, Free Bets, Bet Credits or enhanced payments that have been awarded in error. All customer offers are limited to one per person. If Crick Expo has reasonable grounds to suspect that a bonus or offer is being claimed by or for the benefit of the same person more than once or by a group of people then it may withdraw the availability of any offer or all offers to that customer or group of customers and/or void any bet funded by the bonus or offer and remove any winnings from such bet.
              Crick Expo may, at any time, make minor amendments to the terms and conditions of any offer or promotion to correct typographical errors or to improve on clarity or customer experience and may cancel any offer or promotion for legal or regulatory reasons. Crick Expo reserves the right to amend the terms of or cancel any customer offer or promotion at any time.
              D. Settlement And Payouts
              All bets and wagers are subject to the Betting Coverage provisions (including maximum winnings) set out in Appendix Two to these Terms and Conditions.
              Crick Expo reserves the right to suspend a market and/or cancel any bet/wager anytime. When a market is suspended any bets entered will be rejected. Crick Expo also reserves the right to cease betting on any markets at any time without notice. Should a customer include a non-runner or void selection in a multiple bet/parlay, the bet will be settled on the remaining selections. This includes selections that have been made void where ‘Void if player does not start’ is selected at bet placement.
              Winnings from settled bets/wagers are added to the balance of your betting account. Any funds/winnings credited to an account in error are not available for use, and Crick Expo reserves the right to void any transactions involving such funds and/or withdraw the relevant amount from your account and/or reverse the transaction, either at the time or retrospectively.
              Crick Expo reserves the right to withhold payment and to declare bets on an event void if we have evidence that the following has occurred: (i) the integrity of the event has been called into question; (ii) the price(s) or pool has been manipulated; or (iii) match rigging has taken place. Evidence of the above may be based on the size, volume or pattern of bets placed with Crick Expo across any or all of our betting channels. A decision given by the relevant governing body of the sport in question (if any) will be conclusive. If any customer owes any money to Crick Expo for any reason, we have the right to take that into account before making any payments to that customer.
              Where there is evidence of a series of bets each containing the same selection(s) having been placed by or for the same individual or syndicate of individuals, Crick Expo reserves the right to make bets void or withhold payment of returns pending the outcome of any subsequent investigation.
              For events where there is no official 'off' declared, the advertised start time of the event will be deemed the 'off'. If for any reason a bet is inadvertently accepted after an event or match has commenced (other than live In-Play betting clearly indicated on the Website), bets will stand providing the final outcome is not known, and that no participant/team has gained any material advantage (e.g., a score, sending off for the other team, etc.) at the time the bet was placed. If the outcome of an event/market is known Crick Expo reserves the right to void the bet, win or lose. Disputes over times bets are placed will be settled using the transaction log. All times stated on the Website and/or referred to by Crick Expo staff relate to Sri Lanka Standard Time (SLST) unless stated otherwise.
              In-Play betting - where we have reason to believe that a bet is placed after the outcome of an event is known, or after the selected participant or team has gained a material advantage (e.g., a score, sending off for the other team etc.) we reserve the right to void the bet, win or lose. If for any reason we are unable to validate the outcome of a particular market (e.g., due to loss of live pictures), all bets will be void, unless settlement of bets is already determined.
              Where a customer gives ambiguous instructions, Crick Expo reserves the right to split the number of monies staked between the potential outcomes. Where this is impractical, we reserve the right to void the whole of that bet. In any event, the decision of Crick Expo is final.
              VIII. USE OF THE Crick Expo SITE
              All materials provided on the Crick Expo site, including but not limited to all information, materials, functions, text, logos, designs, graphics, images, sounds, software, documents, products and services (collectively, the "Materials"), and the selection, arrangement and display thereof, are the copyrighted works of Crick Expo and/or its vendors and/or suppliers. All Materials herein and Crick Expo’s software are the exclusive properties of Crick Expo. Except as stated herein, none of the Materials may be modified, copied, reproduced, distributed, republished, downloaded, displayed, sold, compiled, posted or transmitted in any form or by any means, including but not limited to, electronic, mechanical, photocopying, recording or other means, without the prior written permission of Crick Expo.
              For payment methods that require a specific account holder, you should only deposit funds into your gambling account from a source where you are the named account holder. In such cases where Crick Expo identifies that the named account holder differs from that which holds the Crick Expo account, we reserve the right to treat any deposits into the gambling account as being invalid (and any winnings arising from such deposit as void).
              Subject to your compliance with these Terms and Conditions, Crick Expo hereby grants you the right to access and use the Crick Expo site only for personal and non-commercial purposes. You undertake that you shall not use the Crick Expo site for any commercial purposes and/or in any way that is unlawful, or harms us or any other person or entity, as determined in our sole discretion.
              You may post/share links/images/text content on the Crick Expo site on social media platforms like Facebook, Twitter, Google+, Snapchat, Instagram, YouTube or any other social media sharing platform with appropriate link-back to the original source.
              When the Crick Expo site provides for the download of any particular Material including any software, you may download such Material and/or software to a computer or mobile device (as applicable) for your personal, non-commercial use only, provided that you shall:
              keep a record of and not amend copyright and other proprietary notices;
              make no modifications to, and do not rent, lease, loan, sell, distribute, copy (except to create a single copy for your own back-up purposes), or create any derivative works based on the Crick Expo site or the Material including the software, in whole or in part, and;
              not transfer any Material and/or software and/or any part thereof to any other computer and/or mobile device. Any commercial or business use of the Crick Expo site or any Material including the software is expressly prohibited.
              Except as expressly provided above, nothing contained herein shall be construed as conferring, by implication, estoppel or otherwise, any license or right under any patent, trademark, design or copyright of the Crick Expo intellectual properties. You acknowledge and agree that nothing in these Terms and Conditions shall have the effect of transferring the ownership of any copyrights, trademarks, service marks, trade names, source code or other proprietary rights on the Crick Expo site or Material or software or any part thereof to you or any third party.
              IX. PROHIBITED USE AND Crick Expo POLICY
              a. Prohibited Use.
              As a condition of your use of the Crick Expo site, you shall not use the Crick Expo site for any purpose that is unlawful or prohibited under these Terms and Conditions or under any relevant laws, statutes, order, ordinances and regulations. The duty to verify whether the use of the Crick Expo site is legal in your jurisdiction is your sole and exclusive responsibility. You shall not display, upload, modify, publish, transmit, update or share any information on the Crick Expo site that is:
              promoting illegal or tortuous activities or conduct that is abusive, threatening, obscene, defamatory or libelous;
              attempting to circumvent, disable or otherwise interfere with security-related features of Crick Expo or features that prevent or restrict use or copying of any content or enforce limitations on the use of Crick Expo or the content therein;
              using cheats, exploits, automation software, bots, hacks, mods or any other unauthorized software designed to modify or interfere with the Crick Expo site, and taking advantage of cheats or exploits;
              using any software that intercepts, mines, or otherwise collects information about other Users or copies and stores any Proprietary Materials;
              interfering with, disrupting, or creating an undue burden on the Crick Expo site or the networks connected to the Crick Expo site;
              attempting to impersonate another User or person;
              soliciting personal information from anyone under the eighteen (18) years of age;
              using the account, username, or password of another account holder at any time or disclosing the User account password to any third party or permitting any third party to access the User Account;
              using any information obtained from the Crick Expo site in order to harass, abuse, or harm another person;
              using any unfair, misleading or deceptive content intended to draw traffic to one’s profile;
              using the Crick Expo site and/or software in a manner inconsistent with any and all applicable laws and regulations;
              sub-license, rent, lease, sell, trade, gift, bequeath or other transfer of the User account associated with the User account to anyone;
              access or use of a third-party account that have been sub-licensed, rented, leased, sold, traded, gifted, bequeathed, or otherwise transferred from the holder or the original account creator; and
              using the Crick Expo site and/or Software and/or materials for any commercial purpose.
              b. Crick Expo Policy.
              We at Crick Expo consider the Crick Expo of online betting game/tournament/contest of utmost importance. In order to prevent any form of fraud or unfair play in our games/contests/tournaments or on our site - all User actions including - deposits/identity verification/Betting Games/Tournaments/Contests are monitored to ensure a safe, legal and fair environment for you (the Users/Participants). Every single betting game/tournament/contest shall be a fair individual effort. Hence, as part of the Crick Expo policy to prevent any form of fraud & in-game collusion all User/Participant actions are monitored and recorded. Any detection of a breach of our Crick Expo policy, such as and not limited to, fraudulent deposits under a stolen identity or collusion in gameplay, shall result in swift and serious action taken by Crick Expo against the suspected User/Participant.
              X. LEGALITY:
              The User’s/Participant’s use of the Crick Expo site and software is also subject to relevant legislations which apply to the User on the basis of the location from which he/she accesses the Crick Expo site.
              In case wherein detailed audits on the end of Crick Expo , any User/Participant is found to be a resident of any of a jurisdiction/territory in which gaming/gambling or any aspect of it is restricted/prohibited and it is found that the user is hiding such details from Crick Expo at the time of registration then actions mentioned under these Terms and Conditions including (but not limited to) blocking of account, account reset, account deletion or deactivation shall be initiated. If the laws of these jurisdictions change and the residents of these jurisdictions are allowed to participate in the paid versions of games offered on the Crick Expo Site, the Users/Participants shall be treated as new registrations and in no case, previous winnings shall be considered and any claim of such sort will not be entertained in any case.
              The User/Participant agrees that Crick Expo shall not be held liable if laws applicable to the User/Participant restricts or prohibits his/her participation.
              Crick Expo makes no representations or warranties, implicit or explicit, as to the User’s legal right to participate nor shall any person affiliated or claiming affiliation with the User/Participant have authority to make any such representations or warranties.
              The Crick Expo site and service are hosted in Curacao and are intended for and directed to Users/Participants all over the world (except where the same is prohibited under local applicable laws ‘Excluded Regions’). Users/ Participants are advised to check the local laws prior to participating on the Crick Expo site. If you are a User/Participant accessing the Crick Expo site and service from any region with laws or regulations governing personal data collection, use, and disclosure, your continued use of Crick Expo site and service you consent to transfer your personal information to Crick Expo as per the laws and regulations applicable at that time.
              XI. USER CONDUCT
              You agree to abide by these Terms and Conditions and all other rules, regulations of the Crick Expo Website and/or App. In the event you do not abide by these Terms and Conditions and all other rules, regulations, Crick Expo may, at its sole and exclusive discretion, take necessary remedial action, including but not limited to:
              restricting, suspending, or terminating your access to all or any part of Crick Expo services;
              deactivating or deleting your account and all related information and files on the account. Any winnings amount on the date of deactivation or deletion shall be transferred to the User account in Crick Expo database subject to a processing fee (if any) applicable on such transfers as set out herein; or
              refraining from awarding any prize(s) to such User.
              You agree to provide true, accurate, current and complete information at the time of registration and at all other times (as required by Crick Expo). You further agree to update and keep updated your registration information as and when required by Crick Expo.
              You shall not register or operate more than one User account with Crick Expo.
              You shall provide all the necessary Mandatory Information Documents (“MIDs”) as and when required by Crick Expo as per compliance of the Laws of Curacao and its regulations.
              You agree to ensure that you can receive all communication from Crick Expo by marking e-mails from Crick Expo as part of your "safe senders" list. Crick Expo shall not be held liable if any e-mail remains unread as a result of such an e-mail being delivered to your junk and/or spam folder.
              Any password issued by Crick Expo to you shall not be revealed to anyone else. You shall strictly avoid using anyone else's password. You shall be responsible for maintaining the confidentiality of your account and password. You agree to immediately notify Crick Expo of any unauthorized use of your account or any other breach of security.
              You agree to exit/log-out of your accounts at the end of each session. Crick Expo shall not be responsible for any loss and/or damage that may result if you fail to comply with these requirements.
              You agree not to use cheats, exploits, automation, software, bots, hacks or any unauthorized third-party software designed to modify or interfere with Crick Expo services and/or Crick Expo experience or assist in such activity.
              You agree not to copy, modify, rent, lease, loan, sell, assign, distribute, reverse engineer, grant a security interest in, or otherwise transfer any right to the technology or software underlying Crick Expo or Crick Expo services.
              You agree that without Crick Expo 's express written consent, you shall not modify or cause to modify any files or software that are a part of Crick Expo 's services.
              You agree that you shall not indulge in collusive play with other Users/Participants on the Crick Expo site in any manner.
              You agree not to disrupt, overburden, or aid or assist in the disruption or overburdening of (a) any computer or server used to offer or support Crick Expo or the Crick Expo services (each a "Server"); or (b) enjoyment of the Crick Expo services by any other User/Participant or person.
              You agree not to institute, assist or become involved in any type of attack, including without limitation to the distribution of a virus, denial of service, or other attempts to disrupt the Crick Expo services or any other person's use or enjoyment of the Crick Expo services.
              You shall not attempt to gain unauthorized access to the User accounts, servers or networks connected to the Crick Expo services by any means other than the User interface provided by Crick Expo , including but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software that is part of the Crick Expo services.
              If you choose a username that, in Crick Expo 's opinion is considered to be obscene, indecent, abusive or that might subject Crick Expo to public disparagement or scorn, Crick Expo reserves the right, without prior notice, to change such username and intimate you or delete such username from Crick Expo , deny you access to Crick Expo , or any combination of these options.
              Unauthorized access to the Crick Expo site shall be considered a breach of these Terms and Conditions, Privacy Policy and a violation of the applicable laws and regulations of Curacao. You agree not to access the Crick Expo site by any means other than through the interface that is provided by Crick Expo for use in accessing the Crick Expo site. You agree not to use any automated means, including, without limitation, agents, robots, scripts, or spiders, to access, monitor, or copy any part of our site, except those that has been approved in advance and in writing by us.
              Use of the Crick Expo site shall be subject to existing laws & regulations of Curacao and legal processes. Nothing contained in these Terms and Conditions, shall limit the Crick Expo 's right to comply with governmental, court, and law- enforcement requests or requirements relating to User’s use of Crick Expo
              Users may contact chat with problems or questions, as appropriate.
              Users agree not to institute, assist or become involved in any type of attack, including without limitation to distribution of a virus, denial of service, or other attempts to disrupt the Crick Expo Services or any other person's use or enjoyment of the Crick Expo Services.
              Users shall not attempt to gain unauthorized access to other User accounts on Crick Expo by any means, including but not limited to, by circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other person to circumvent or modify, any security, technology, device, or software that underlies or is part of the Crick Expo services.
              Users provide their acceptance for not using the Crick Expo platform for any sort of inadequate activities including (but not limited to): to engage in any obscene, offensive, indecent, racial, communal, anti-national, objectionable, defamatory or abusive action or communication; to harass, stalk, threaten, or otherwise, violate any legal rights of other individuals; to transmit files that contain viruses, corrupted files, or any other similar software or programs that may damage or adversely affect the operation of another person's computer, Crick Expo site, any software, hardware, or telecommunications equipment; to advertise, offer or sell any goods or services for any commercial purpose on theCrick Expo.io without written consent of Crick Expo; to Transmit content regarding services, products, surveys, contests, pyramid schemes, spam, unsolicited advertising or promotional materials, or chain letters.
              XII. PARTICIPATION IN CONTESTS
              By entering a Contest, User/Participant agrees to be bound by these Terms and the decisions of Crick Expo. Subject to the terms and conditions stipulated herein below, the Company, at its sole discretion, may disqualify any User/Participant from a Contest, refuse to award benefits or prizes and require the return of any prizes, if the User engages in unfair conduct, which the Company deems to be improper, unfair or otherwise adverse to the operation of the Contest or is in any way detrimental to other Users/Participants which includes, but is not limited to:
              Falsifying ones’ own personal information (including, but not limited to, name, email address, bank account details and/or any other information or documentation as may be requested by Crick Expo to enter a contest and/or claim a prize/winning.
              Engaging in any type of financial fraud or misrepresentation including unauthorized use of credit/debit instruments, payment wallet accounts etc. to enter a Contest or claim a prize. It is expressly clarified that the onus to prove otherwise shall solely lie on the User.
              Colluding with any other User(s) / Participant(s) or engaging in any type of syndicate play.
              Any violation of Contest rules or the Terms and Conditions.
              Accumulating points or prizes through unauthorized methods such as automated bots, or other automated means.
              Using automated means (including but not limited to harvesting bots, robots, parser, spiders or screen scrapers) to obtain, collect or access any information on the Website or of any User/Participant for any purpose
              Tampering with the administration of a Contest or trying to in any way tamper with the computer programs or any security measure associated with a Contest;
              Obtaining other Users’/Participants’ information without their express consent and/or knowledge and/or spamming other Users/Participants (Spamming may include but shall not be limited to send unsolicited emails to Users/Participants, sending bulk emails to Crick Expo Users/Participants, sending unwarranted email content either to selected Users/Participants or in bulk); or
              Abusing the Website in any way. It is clarified that in case a User/Participant is found to be in violation of this policy, Crick Expo reserves its right to initiate appropriate Civil/Criminal remedies as it may be advised other than forfeiture and/or recovery of prize money if any.
              XIII. INTELLECTUAL PROPERTY
              Unless otherwise stated, copyright and/or all intellectual property rights in all materials on the Crick Expo site (including but not limited to text, audio, video or graphical images, or technology, the look and feel of the Crick Expo site), trademarks and logos appearing on the Crick Expo site are the properties of Crick Expo and are owned & controlled by us and/or by other parties that have licensed their materials to us. You shall not copy, reproduce, republish, upload, post, transmit or distribute the Crick Expo site materials in any way, including by e-mail and/or other electronic means and whether directly or indirectly and/or assist any other person to do the same. Without the prior written consent of the owner of the materials, modification, use of the materials on any other web site and/or networked computer environment and/or use of the materials for any purpose other than personal, non-commercial use shall be considered a violation of the copyrights, trademarks and other proprietary rights of Crick Expo and shall be prohibited.
              Any use of the materials other than for personal and non-commercial purposes shall be considered as commercial use for the purposes of this clause. You agree not to use any framing techniques to enclose any trademark or logo or other proprietary information of Crick Expo or remove, conceal or obliterate any copyright or other proprietary notice or any credit-line or date-line on other mark or source identifier included on the Crick Expo site, including without limitation, the size, color combinations, design, font, location or style of all proprietary marks. Any infringement shall be vigorously defended and pursued to the extent permitted by applicable law.
              XIV. LIMITATIONS OF LIABILITY
              Users shall access the Crick Expo Services provided on Crick Expo voluntarily and at their own risk. Crick Expo shall, under no circumstances be held responsible or liable on account of any loss or damage sustained (including but not limited to any accident, injury, death, loss of property) by Users or any other person or entity during the course of access to the Crick Expo Services (including participation in the Contest(s)) or as a result of acceptance of any prize.
              By entering the contests and accessing the Crick Expo Services provided therein, Users hereby release from and agree to indemnify Crick Expo , and/ or any of its directors, employees, partners, associates and licensors, from and against all liability, cost, loss or expense arising out their access to the Crick Expo Services including (but not limited to) personal injury and damage to property and whether direct, indirect, consequential, foreseeable, due to some negligent act or omission on their part, or otherwise. Crick Expo accepts no liability, whether jointly or severally, for any errors or omissions, whether on behalf of itself or third parties in relation to the prizes.
              Users shall be solely responsible for any consequences which may arise due to their access of Crick Expo Services by conducting an illegal act or due to non-conformity with these Terms and Conditions and other rules and regulations in relation to Crick Expo Services, including provision of incorrect address or other personal details. Users also undertake to indemnify Crick Expo and their respective officers, directors, employees and agents on the happening of such an event (including without limitation cost of attorney, legal charges etc.) on full indemnity basis for any loss/damage suffered by Crick Expo on account of such act on the part of the Users.
              Users shall indemnify, defend, and hold Crick Expo harmless from any third party/entity/organization claims arising from or related to such User's engagement with the Crick Expo or participation in any Contest. In no event shall Crick Expo be liable to any User for acts or omissions arising out of or related to User's engagement with the Crick Expo or his/her participation in any Contest(s). In consideration of Crick Expo allowing Users to access the Crick Expo Services, to the maximum extent permitted by law, the Users waive and release each and every right or claim, all actions, causes of actions (present or future) each of them has or may have against Crick Expo , its respective agents, directors, officers, business associates, group companies, sponsors, employees, or representatives for all and any injuries, accidents, or mishaps (whether known or unknown) or (whether anticipated or unanticipated) arising out of the provision of Crick Expo Services or related to the Contests or the prizes of the Contests.
              XV. THIRD PARTY SITES, SERVICES AND PRODUCTS
              Crick Expo may contain links to other Internet sites owned and operated by third parties. Users' use of each of those sites is subject to the conditions, if any, posted by the sites. Crick Expo does not exercise control over any Internet sites apart from Crick Expo and cannot be held responsible for any content residing in any third-party Internet site. Crick Expo 's inclusion of third-party content or links to third-party Internet sites is not an endorsement by Crick Expo of such third-party Internet site.
              Users' correspondence, transactions/offers or related activities with third parties, including payment providers and verification service providers, are solely between the User and that third party. Users' correspondence, transactions and usage of the services/offers of such third party shall be subject to the terms and conditions, policies and other service terms adopted/implemented by such third party, and the User shall be solely responsible for reviewing the same prior to transacting or availing of the services/offers of such third party. User agrees that Crick Expo will not be responsible or liable for any loss or damage of any sort incurred as a result of any such transactions/offers with third parties. Any questions, complaints, or claims related to any third-party product or service should be directed to the appropriate vendor.
              Crick Expo contains content that is created by Crick Expo as well as content provided by third parties. Crick Expo does not guarantee the accuracy, integrity, quality of the content provided by third parties and such content may not relied upon by the Users in utilizing the Crick Expo Services provided on Crick Expo including while participating in any of the contests hosted on Crick Expo.
              XVI. PRIVACY POLICY
              All information collected from Users, such as registration and credit card information, is subject to Crick Expo 's Privacy Policy which is available at Privacy Policy.
              XVII. CONTACTING WINNERS
              Winners shall be contacted by Crick Expo either through the e-mail address provided at the time of registration or through either Calls or Short Messaging Service (SMS) on their registered Phone Number.
              Crick Expo shall not permit a Winner to withdraw his/her prize(s)/accumulated winnings unless some government approved ID have been received and verified within the time-period stipulated by Crick Expo. The User represents and warrants that the documents provided in the course of the verification process are true copies of the original documents to which they relate.
              Participants are required to provide proper and complete details at the time of registration. Crick Expo shall not be responsible for communications errors, commissions or omissions including those of the Participants due to which the results may not be communicated to the Winner.
              In the event that a Participant has been declared a Winner on the Crick Expo site but has not received any communication from Crick Expo , such Participant may contact Crick Expo within the time specified on the webpage.
              XVIII. VERIFICATION PROCESS
              Only those Winners who successfully complete the verification process and provide the required documents within the time limit specified by Crick Expo shall be permitted to withdraw/receive their accumulated winnings (or any part thereof). Crick Expo shall not entertain any claims or requests for extension of time for submission of documents.
              Crick Expo shall scrutinize all documents submitted and may, at its sole and absolute discretion, disqualify any Winner from withdrawing his accumulated winnings (or any part thereof).
              XIX. TAXES PAYABLE
              All prizes shall be subject to withholding tax implications payable by you. Crick Expo shall not be responsible for checking the laws of your jurisdiction pertaining to withholding tax, double taxation arrangements amongst others. The Winners shall be responsible for payment of any other applicable taxes, including but not limited to, income tax, gift tax, etc. in respect of the prize money.
              XX. MISCELLANEOUS
              The decision of Crick Expo with respect to the awarding of prizes shall be final, binding and non-contestable. If it is found that a Participant playing the paid formats of the Contest(s) is a resident of any jurisdiction from where participation is prohibited, Crick Expo shall disqualify such Participant and forfeit any prize won by such Participant. Further Crick Expo may, at its sole and absolute discretion, suspend or terminate such Participant's account with Crick Expo. Any amount remaining unused in the User's Game Account or Winnings Account on the date of deactivation or deletion shall be reimbursed to the User by an online transfer to the User's bank account on record with Crick Expo , subject to the processing fee (if any) applicable on such transfers as set out herein
              If it is found that a Participant playing the paid formats of the Contest(s) is under the age of eighteen (18), Crick Expo shall be entitled, at its sole and absolute discretion, to disqualify such Participant and forfeit his/her prize. Further, Crick Expo may, at its sole and absolute discretion, suspend or terminate such Participant's account.
              To the extent permitted by law, Crick Expo makes no representations or warranties as to the quality, suitability or merchantability of any prizes and shall not be liable in respect of the same. Crick Expo may, at its sole and absolute discretion, vary or modify the prizes being offered to winners. Participants shall not raise any claim against Crick Expo or question its right to modify such prizes being offered, prior to closure of the Contest. Crick Expo will not bear any responsibility for the transportation or packaging of prizes to the respective winners. Crick Expo shall not be held liable for any loss or damage caused to any prizes at the time of such transportation. The Winners shall bear the shipping, courier or any other delivery cost in respect of the prizes. The Winners shall bear all transaction charges levied for delivery of cash prizes. All prizes are non-transferable and non-refundable. Prizes cannot be exchanged / redeemed for cash or kind. No cash claims can be made in lieu of prizes in kind.
              XXI. PUBLICITY
              Acceptance of a prize by the Winner constitutes permission for Crick Expo , and its affiliates to use the Winner's name, likeness, voice and comments for advertising and promotional purposes in any media worldwide for purposes of advertising and trade without any further permissions or consents and / or additional compensation whatsoever.
              The Winners further undertake that they will be available for promotional purposes as planned and desired by Crick Expo without any charge. The exact dates remain the sole discretion of Crick Expo. Promotional activities may include but not be limited to press events, internal meetings and ceremonies/functions.
              XXII. LIMITATION OF LIABILITY
              In no event shall Crick Expo its officers, directors, employees, or agents, be liable to you for any direct, indirect, incidental, special, punitive, or consequential damages whatsoever resulting from any:
              errors, mistakes, or inaccuracies of content;
              personal injury or property damage, of any nature whatsoever, resulting from your access to and use of our service;
              any unauthorized access to or use of our secure servers and/or any and all personal information and/or financial information stored therein;
              any interruption and/or cessation of transmission to or from our servers;
              any bugs, viruses, trojan horses, or the like, which may be transmitted to or through our service by any third party;
              any errors or omissions in any content or for any loss or damage of any kind incurred as a result of your use of any content posted, emailed, transmitted, or otherwise made available via the Crick Expo client, whether based on warranty, contract, tort, or any other legal theory, and whether or not Crick Expo is advised of the possibility of such damages; and/or
              the disclosure of information pursuant to these Terms and Conditions or privacy policy of Crick Expo. The foregoing limitation of liability shall apply to the extent permitted by law in the applicable jurisdiction.
              You specifically acknowledge that Crick Expo shall not be liable for any defamatory, offensive, or illegal conduct of any third party and that the risk of any harm or damage from the foregoing shall rest entirely with you.
              XXIII. TECHNICAL FAILURES AND DISCONNECTION POLICY
              It is possible that you may face disruptions, including, but not limited to errors, disconnection or inferences in communication in the Internet services, software or hardware that you have used to avail our services. Crick Expo shall not be liable for any network outage or any problems/interruptions with network connectivity including network, Internet services, computer hardware or software failures of any kind in the disruption in the services as the same is beyond Crick Expo’s control. You take full responsibility with complete knowledge for any risk of loss or damages caused due to interruption of services for any such reasons.
              You agree that Crick Expo shall not be held responsible and/or liable for any interruption in a Crick Expo game and/or tournament and/or contest caused at the User’s end due to an incoming call, message, chat, video call or any other interruption on the mobile phone device/tablet device. In case of any interruption specified above in a Crick Expo game and/or tournament and/or Contest on the User’s mobile phone device/tablet device, the game and/or tournament and/or contest shall still continue, and the User shall assume complete responsibility for such interruptions in between the games and/or tournaments and/or contest.
              XXIV. INDEMNITY
              You agree to defend, indemnify and hold harmless Crick Expo , its officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from:
              your use of and access to the Crick Expo site;
              your violation of any term of these Terms and Conditions;
              your violation of any third party right, including without limitation any trademark, copyright, property, or any other privacy right;
              any violation of applicable law, rules, regulation, orders and ordinance. This defense and indemnification obligation shall survive these Terms and Conditions.
              XXV. GOVERNING LAW AND JURISDICTION
              Crick Expo is licensed to provide gambling services and products to you by Play Ventures NV who are sub-licensee’s of the Master Gaming license issued by the Ministry of Justice, Official Decree of the Central Government of Curaçao No 365/JAZ dated August 18, 1998 No 14 signed by the Minister of Justice laying down the Master License granted to Gaming Services Provider allowing the operation of Games of Chance on the International market via service lines. Thus, the relevant and applicable laws of Curacao are to be adhered to by the users under these Terms and Conditions.
              XXVI. DISPUTE RESOLUTION
              The courts or tribunals of competent jurisdiction at Curacao shall have jurisdiction to determine any and all disputes arising out of, or in connection with, the Crick Expo Services provided by Crick Expo (including the Contest(s)), the construction, validity, interpretation and enforceability of these Terms and Conditions, or the rights and obligations of the User(s) (including Participants) or Crick Expo , as well as the exclusive jurisdiction to grant interim or preliminary relief in case of any dispute referred to arbitration as given below. All such issues and questions shall be governed and construed in accordance with the laws of Curacao.
              In the event of any legal dispute (which may be a legal issue or question) which may arise, the party raising the dispute shall provide a written notification ("Notification") to the other party. On receipt of Notification, the parties shall first try to resolve the dispute through discussions. In the event that the parties are unable to resolve the dispute within fifteen (15) days of receipt of Notification, the dispute shall be settled by arbitration.
              The arbitration award will be final and binding on the Parties, and each Party will bear its own costs of arbitration and equally share the fees of the arbitrator unless the arbitral tribunal decides otherwise. The arbitrator shall be entitled to pass interim orders and awards, including the orders for specific performance and such orders would be enforceable in competent courts. The arbitrator shall give a reasoned award.
              Nothing contained in these Terms and Conditions shall prevent Crick Expo from seeking and obtaining interim or permanent equitable or injunctive relief, or any other relief available to safeguard Crick Expo 's interest prior to, during or following the filing of arbitration proceedings or pending the execution of a decision or award in connection with any arbitration proceedings from any court having jurisdiction to grant the same. The pursuit of equitable or injunctive relief shall not constitute a waiver on the part of Crick Expo to pursue any remedy for monetary damages through the arbitration described herein.
              XXVII. BREACH AND CONSEQUENCES
              If we have evidence of a breach of our Terms and Conditions through our investigations or reasonable belief that your continued access to our website/app (platform) is deleterious or detrimental to the interests of Crick Expo and/or our Users or the general public, then we reserve the right in our sole discretion to take one or all of the actions as mentioned below:
              Permanent suspension and/or termination of your user account on our platform;
              Forfeit the balance amount left in your account;
              Demand and order damages for breach of Crick Expo Terms and Conditions and take required civil actions to recover damages;
              Initiate steps of prosecution for damages and violation that is equivalent to offences in law;
              Cause restriction to access our games/tournaments/contests to Users who are suspected in cases of cheating or colluding;
              Bar you from playing or registering at Crick Expo in the future, as we reserve full discretion to restrict you from playing our online betting games/tournaments/contests.
              The action taken by us will be solely due to your breach of our Terms and Conditions; the action shall be final and decisive that will be binding on you. Any action taken by us will be without prejudice to our other rights and remedies that are mentioned and available in law or equity.
              XXVIII. TERMINATION
              We reserve the right, at our discretion, to immediately, with or without notice, suspend or terminate your registration, the Terms and Conditions and/or your access to all or any portion of the Crick Expo site and/or remove any registration information or User Content from the Crick Expo site. Upon termination or expiration of the Terms and Conditions, your obligations and Crick Expo 's rights and disclaimers survive, but your right to use the Crick Expo site immediately ceases.
              XXIX. DISCLAIMERS
              To the extent permitted under law, neither Crick Expo nor its parent/holding company, subsidiaries, affiliates, directors, officers, professional advisors, employees shall be responsible for the deletion, the failure to store, the mis-delivery, or the untimely delivery of any information or materials.
              To the extent permitted under law, Crick Expo shall not be responsible for any harm resulting from downloading or accessing any information or materials, the quality of servers, games, products, Crick Expo services or sites, cancellation of competition and prizes. Crick Expo disclaims any responsibility for, and if a User pays for access to one of Crick Expo 's Services the User will not be entitled to a refund as a result of, any inaccessibility that is caused by Crick Expo 's maintenance on the servers or the technology that underlies our sites, failures of Crick Expo 's service providers (including telecommunications, hosting, and power providers), computer viruses, natural disasters or other destruction or damage of our facilities, acts of nature, war, civil disturbance, or any other cause beyond our reasonable control. In addition, Crick Expo does not provide any warranty as to the content on the Crick Expo (s). Crick Expo (s) content is distributed on an "as is, as available" basis.
              Any materials accessed, downloaded or otherwise obtained through Crick Expo is done at the User's discretion, competence, acceptance and risk, and the User will be solely responsible for any potential damage to User's computer system or loss of data that results from a user’s download of any such materials.
              Crick Expo shall make best endeavors to ensure that the Crick Expo (s) is error-free and secure, however, neither Crick Expo nor any of its partners, licensors or associates makes any warranty that:
              the Crick Expo service(s) will meet Users' requirements,
              Crick Expo service(s) will be uninterrupted, timely, secure, or error free
              the results that may be obtained from the use of Crick Expo (s) will be accurate or reliable; and
              the quality of any products, Crick Expo Services, information, or other materials that Users purchase or obtain through Crick Expo site(s) will meet Users' expectations.
              In case Crick Expo discovers any error, including any error in the determination of Winners or in the transfer of amounts to a User's account, Crick Expo reserves the right (exercisable at its discretion) to rectify the error in such manner as it deems fit, including through a set-off of the erroneous payment from amounts due to the User or deduction from the User's account of the amount of erroneous payment. In case of exercise of remedies in accordance with this clause, Crick Expo agrees to notify the User of the error and of the exercise of the remedy(ies) to rectify the same.
              To the extent permitted under applicable law, in the event of suspension or closure of any Services, events or Contests, Users (including Participants) shall not be entitled to make any demands, claims, on any nature whatsoever.
              XXX. FORCE MAJEURE
              Neither party to these Terms and Conditions shall be liable for any loss and/or any failure to perform any obligation under these Terms and Conditions due to causes beyond its reasonable anticipation or control including real or potential labor disputes, governmental actions, war or threat of war, sabotage, civil unrest, demonstrations, fire, storm, flooding, explosion, earthquake, provisions or limitations of materials or resources, inability to obtain the relevant authorization, accident and defect in electricity or telecommunication network, among other events.
              Force Majeure and/or another event beyond Crick Expo ’s control hindering, delaying or complicating the maintenance of the Crick Expo Game/Tournament/Contest, shall entitle Crick Expo to suspend or limit the Crick Expo Game/Tournament/Contest until further notice.
              XXXI. ENTIRE AGREEMENT
              The Terms and Conditions and Privacy Policy, as amended from time to time, constitute the entire agreement between you and Crick Expo . If any provision of these Terms and Conditions is considered unlawful, void, or for any reason unenforceable by a court of competent jurisdiction, then that provision shall be deemed severable from these Terms and Conditions and shall not affect the validity and enforceability of any remaining provisions, which shall remain in full force and effect.
              Force Majeure and/or another event beyond Crick Expo ’s control hindering, delaying or complicating the maintenance of the Crick Expo Game/Tournament/Contest, shall entitle Crick Expo to suspend or limit the Crick Expo Game/Tournament/Contest until further notice.
              XXXII. ABILITY TO ACCEPT TERMS AND CONDITIONS
              The Terms and Conditions and Privacy Policy, as amended from time to time, constitute the entire agreement between you and Crick Expo . If any provision of these Terms and Conditions is considered unlawful, void, or for any reason unenforceable by a court of competent jurisdiction, then that provision shall be deemed severable from these Terms and Conditions and shall not affect the validity and enforceability of any remaining provisions, which shall remain in full force and effect.
              You further represent and warrant that you are not located in any of the Excluded Regions where any of the games offered on Crick Expo is prohibited to be offered and/or accessed local laws.
              XXXIII. ASSIGNMENT
              These Terms and Conditions, and any rights and licenses granted hereunder, may not be transferred or assigned by you, but may be assigned by Crick Expo without restriction.
              XXXIV. WAIVER
              No waiver of any terms of these Terms and Conditions shall be deemed a further or continuing waiver of such term or any other term and Crick Expo ’s failure to assert any right or provision under these Terms and Conditions shall not constitute a waiver of such right or provision.
              XXXV. RELATIONSHIP
              None of the provisions of the Terms and Conditions shall be deemed to constitute a partnership or agency between you and Crick Expo and you shall have no authority to bind Crick Expo in any manner, whatsoever. If you are registering on the Crick Expo site as a business entity, you represent that you have the authority to bind the entity to this Agreement.
            </div>
          </section>
        </div>
      </div>
    </div>
     
  )
}

export default TermCondition