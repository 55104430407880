import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import io from 'socket.io-client';
import FlipCountdown from '@rumess/react-flip-countdown';
import moment from "moment";
import WorliMatkarules from '../Casino/images/iw2-rules.webp';
import PlaceBetMobileBmx from "./casino_components/PlaceBetMobileBmx";
import ResultModelBmx from "./casino_components/ResultModelBmx";
import RoundedTabBmx from "./casino_components/RoundedTabBmx";
import PageHeaderTheme from "./casino_components/PageHeaderTheme";
import PlaceBetMobileBmxBet from "./casino_components/PlaceBetMobileBmxBet";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorTost from './casino_components/ErrorTost';
import CasinoTab from "./casino_components/CasinoTab";
import Desktop30 from "./casino_components/Desktop30";
import Mobile30 from "./casino_components/Mobile30";
import BetLockedRoundedBmx from "./casino_components/BetLockedRoundedBmx";
import { httpPost, httpPostBet } from "../../../../middelware/Http";
import Loader from "../../../../component/Loader/Loader";



class Worlimatka extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newCasinoModal: false,
      betOpen: false,
      backBetModal: false,
      backBetModalMobile: false,
      betSlipData: {},
      fieldsbet: {},
      errorsbet: {},
      count: 0,
      time: 7,
      casinoData: [],
      ResultModel: false,
      Result: false,
      backBetModalMobile: false,
      showLoader: true,
      result: {},
      casinoDetails: {},
      fetchData: "",
      socketURL: "",
      tvUrl: "",
      cacheURL: "",
      betStatus: "",
      cashinoStatus: "",
      eventId: "",
      minStake: "",
      maxStake: "",
      name: "",
      shortName: "worli2",
      betList: [],
      betresponse: [],
      newCasinoModal: false,
      BetPlaceDesktop: false,
      betFor: null,
      betOpen: false,
      myBetOpen: false,
      setting: {},
      posArray: {},
      currentRoundId: 0,
      oddsDifference: 0,
      totalProfitLoss: 0,
      activeTab: 1,
      activeTab3: 1,
      LoadingBet: false,
    };
    this.section1Ref = React.createRef();
    this.scrollTimeout = null;
    this.inputRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user && nextProps.user.betPlaceSucess) {
      return {
        ...nextProps,
        backBetModal: false,
        backBetModalMobile: false,
        betSlipData: {},
      }
    } else {
      return {
        ...nextProps,

      }
    }
  }

  handleCloseResult = () => {
    this.setState({ ResultModel: false, });
  };

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    if (value < 0 || isNaN(Number(value))) {
      value = 0;
    }

    if (value[0] === '0' && value.length > 1) {
      value = value.substring(1);
    }

    let { betSlipData } = this.state;
    betSlipData.stake = value;
    this.setState({
      betSlipData: betSlipData,
      [name]: Number(value),
    });
  };

  increaseCount = () => {
    const increasedCount = (parseFloat(this.state.count) + 0.01).toFixed(2);
    this.setState({ count: parseFloat(increasedCount) });
  };

  decreaseCount = () => {
    const decreasedCount = (parseFloat(this.state.count) - 0.01).toFixed(2);
    this.setState({ count: parseFloat(decreasedCount) });
  };

  componentDidMount() {

    let requestData = {
      eventId: 3054,
    }
    this.getDidMountData(requestData)
  }

  getDidMountData = async (requestData) => {
    this.setState({ showLoader: true })
    let getCasinoDetails = await httpPost(`casino/getDiamondCasinoByEventId`, requestData)
    if (!getCasinoDetails.error) {
      let casinoDetails = getCasinoDetails.data
      this.setState({
        casinoDetails: casinoDetails,
        fetchData: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : {},
        socketURL: casinoDetails && casinoDetails.socketURL ? casinoDetails.socketURL : {},
        tvUrl: casinoDetails && casinoDetails.videoUrl1 ? casinoDetails.videoUrl1 : "",
        cacheURL: casinoDetails && casinoDetails.cacheURL ? casinoDetails.cacheURL : {},
        betStatus: casinoDetails && casinoDetails.betStatus ? casinoDetails.betStatus : "",
        cashinoStatus: casinoDetails && casinoDetails.cashinoStatus ? casinoDetails.cashinoStatus : "",
        eventId: casinoDetails && casinoDetails?.eventId ? casinoDetails?.eventId : "",
        minStake: casinoDetails && casinoDetails.minStake ? casinoDetails.minStake : "",
        maxStake: casinoDetails && casinoDetails.maxStake ? casinoDetails.maxStake : "",
        name: casinoDetails && casinoDetails.name ? casinoDetails.name : "",
        shortName: casinoDetails && casinoDetails.shortName ? casinoDetails.shortName : "worli2",
        setting: casinoDetails && casinoDetails.setting ? casinoDetails.setting : {},
        oddsDifference: casinoDetails && casinoDetails.setting && casinoDetails.setting.oddsDifference ? casinoDetails.setting.oddsDifference : 0,
      })
      if (casinoDetails?.eventId) {
        this.betList(casinoDetails?.eventId)
      }

      // const socketPerm = casinoDetails.fetchData
      if (casinoDetails?.socketURL) {
        await this.callSocket(casinoDetails?.socketURL, casinoDetails?.shortName)
      }
      // else {
      //   await this.callCache(casinoDetails.cacheURL)
      // }
    } else {

    }
    this.setState({ showLoader: false })
  }

  callSocket = async (socketURL, shortName) => {
    const socket = io.connect(socketURL, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 99,
    });
    socket.emit('JoinRoom', shortName);
    socket.on(shortName, data => {
      this.setState({ currentRoundId: data && data.data && data.data.t1 && data.data.t1[0] && data.data.t1[0].mid ? data.data.t1[0].mid : 0 })
      this.setState({ casinoData: data })
      this.updatePos()
    })
  }

  callCache = async (cacheUrl) => {
    await this.getMarketCacheUrl(cacheUrl);
    this.cacheInterval = setInterval(async () => {
      await this.getMarketCacheUrl(cacheUrl);
    }, 1000);
  }

  getMarketCacheUrl = async (cacheURL) => {
    let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: cacheURL,
      headers: { 'Content-Type': 'application/json' },
    };
    axios.request(config).then((response) => {
      this.setState({ casinoData: response.data.data })
    }).catch((error) => {
      console.error('cache data url error ', error);
    });
  }

  updatePos = async () => {
    const { betList, currentRoundId } = this.state;
    if (betList && betList.length > 0) {
      const filteredBets = betList.filter((element) => element.roundId == currentRoundId);
      let pushPos = {};
      filteredBets && filteredBets.length > 0 && filteredBets.forEach((bet) => {
        const posArray = bet.posArray;
        Object.entries(posArray).forEach(([key, value]) => {
          pushPos[key] = (pushPos[key] || 0) + Number(value);
        });
      });

      this.setState({ posArray: pushPos });
    }
  }

  betList = async (eventId) => {
    let betReq = {
      "eventId": eventId,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    };

    let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)
    let totalProfitLoss = 0
    let betList = getCasinoDetails?.data?.casinoBetData

    if (betList && betList.length > 0) {
      betList.map((bet, key) => {
        let profitLoss = "Not Declare"
        let profitLossCount = 0
        if (bet.isDeclare) {
          profitLossCount = bet.profitLoss ? bet.profitLoss : 0
          profitLoss = bet.profitLoss
        }
        totalProfitLoss += Number(profitLossCount)
        betList[key].profitLoss = profitLoss
      })
    }
    if (getCasinoDetails) {
      this.setState({ betList: betList, totalProfitLoss: totalProfitLoss })
    }
  }

  componentWillUnmount() {
    clearInterval(this.cacheInterval);
    clearTimeout(this.timer);
    if (this.inputRef.current) {
      this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
    }
  }

  handleResultModel = (data) => {
    this.setState({ ResultModel: true, result: data });
  };

  handleBackOpen = (data) => {
    if (this.scrollTimeout) {
      clearInterval(this.scrollTimeout);
    }
    this.setState({ backBetModal: true, backBetModalMobile: true, betSlipData: { ...data, stake: "0" }, count: data.odds, time: 7, });
    this.scrollTimeout = setInterval(() => {
      this.setState(prevState => ({ time: prevState.time - 1 }), () => {
        if (this.state.time < 0) {
          clearInterval(this.scrollTimeout);
          this.setState({ backBetModal: false });
        }
      });
    }, 1000);
  };

  updateStackOnclic = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStackOnclick = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStake = (event) => {
    try {
      let { value } = event.target;
      let { betSlipData } = this.state;
      if (value.startsWith('0') && value.length > 1) {
        value = value.slice(1);
      }
      betSlipData.stake = value;
      this.setState({
        betSlipData: betSlipData,
      });
    } catch (error) {
      console.error('Error updating stake:', error);
    }
  };

  placeBet = async () => {
    const { casinoData } = this.state;
    this.setState({ LoadingBet: true })
    let { data } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let betObject = {
      "roundId": t1.mid,
      "sid": this.state.betSlipData.sid + "",
      "rate": (this.state.count - this.state.oddsDifference) + "",
      "amount": Number(this.state.betSlipData.stake),
      "casinoType": this.state.shortName ? this.state.shortName : "worli2",
      "eventId": 3054,
      "betFor": this.state.betSlipData.nat.split(' ')[0],
      "nat": this.state.betSlipData.nat.split(' ')[0]
    }

    const result = await httpPostBet("casino/casinoBetPlace", betObject);
    if (result) {
      if (result.error === false) {

        this.setState(
          {
            betresponse: result.data
          },
        );
         toast.success(<ErrorTost message={result.message} />, {
          autoClose: 1500,
        }); 
        this.betList(3054)
        // this.props.dispatch(userActions.getUserBalance());
      }
      else {
        toast.error(<ErrorTost message={result.message} />, {
          autoClose: 1500,
        });
      }
    }
    this.handleClose();
    this.setState({ LoadingBet: false })
  };

  handleClose = () => {
    this.setState({ newCasinoModal: false, betOpen: false, stakeopen: false, backBetModal: false, backBetModalMobile: false, });
  };

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  handleTabClick3 = (tabIndex) => {
    this.setState({ activeTab3: tabIndex });
  };


  render() {
    let clientNotification = JSON.parse(localStorage.getItem('notification'))
    const { casinoData, name, shortName, ResultModel, time, count, backBetModal, tvUrl, betList, showLoader, LoadingBet, posArray, oddsDifference, totalProfitLoss, activeTab, activeTab3 } = this.state;
    const { data, result } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let ENG = data && data.t2 && data.t2[0] ? data.t2[0] : {};
    return (
      <>

        {LoadingBet === true ?
          <>
            <PlaceBetMobileBmxBet
              betSlipData={this.state.betSlipData}
              time={time}
              count={count}
            />
          </> :
          <>
            {backBetModal && backBetModal === true ? (
              <PlaceBetMobileBmx
                betSlipData={this.state.betSlipData}
                updateStackOnclic={this.updateStackOnclic}
                placeBet={this.placeBet}
                handleClose={this.handleClose}
                section1Ref={this.section1Ref}
                time={time}
                inputChange={this.inputChange}
                decreaseCount={this.decreaseCount}
                increaseCount={this.increaseCount}
                count={count}
              />
            ) : null}
          </>
        }

        {showLoader ?
          <Loader active={showLoader === true} />
          :
          <div className={`w-full relative h-full  overflow-y-auto text-sm flex `}>
            {this.state &&
              ResultModel === true ? (
              <ResultModelBmx
                handleClose={this.handleCloseResult}
                name={name ? name : "Worli Matka"}
                shortName={shortName ? shortName : "worli2"}
                result={this.state.result}
              />
            ) : null}

            <div className="lg:flex block w-full scroll-md lg:space-x-1.5">

              {/* <div className="lg:w-[15%] w-full bg-fuchsia-200 lg:px-2 lg:block hidden"></div> */}

              <div className="lg:hidden block">
                <CasinoTab
                  activeTab={activeTab}
                  handleTabClick={this.handleTabClick}
                  ruleImage={WorliMatkarules}
                  t1={t1}
                  totalBet={betList && betList.length ? betList.length : "0"}
                />
              </div>

              <div className="w-full lg:flex">

                {activeTab === 1 ? (

                  <div className="w-full lg:flex block">

                    <div className="lg:w-[70%] w-full ">
                      <PageHeaderTheme
                        PageTitle={name ? name : "Worli Matka"}
                        ruleImage={WorliMatkarules}
                        t1={t1}
                      />
                      <div className="bg-black flex justify-between w-full relative md:text-sm text-[10px] xl:h-[460px] md:h-[300px] h-[200px] border border-[#cf4b04]">
                        <iframe src={tvUrl ? tvUrl : null} title=" " className='relative w-full  ' />
                        <div className=" flex justify-between">
                          <div className=" absolute top-0 left-0">
                            <div className="flex justify-start space-x-1 p-2">
                              <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="lg:h-10 h-7 lg:w-8 w-6" />
                              <img src={`/cards/${t1 && t1.C2 ? t1.C2 : 1}.png`} alt="card" className="lg:h-10 h-7 lg:w-8 w-6" />
                              <img src={`/cards/${t1 && t1.C3 ? t1.C3 : 1}.png`} alt="card" className="lg:h-10 h-7 lg:w-8 w-6" />
                            </div>
                          </div>

                          <div className="flex justify-end items-end absolute bottom-2 left-1">
                            <FlipCountdown
                              titlePosition='hidden'
                              hideYear
                              hideMonth
                              hideDay
                              hideHour
                              hideMinute
                              size="small"
                              endAtZero
                              endAt={new Date(Date.now() + 1000 * (t1 && t1.autotime ? t1.autotime : "")).toUTCString()}
                            />
                          </div>
                        </div>
                      </div>

                      {/* <div className="">
                        <WorliMatkaTab
                          activeTab3={activeTab3}
                          handleTabClick3={this.handleTabClick3}
                        />
                      </div> */}

                      <div className="relative mt-2 page_bg border border-[#cf4b04]">
                        <div className="flex justify-between items-center text-center py-1">
                          <p></p>
                          <p className="text-[14px] font-[600] darktext">{9.5 - oddsDifference}</p>
                          <p></p>
                        </div>

                        <div class="w-full grid grid-cols-5 divide-x divide-y">

                          <div>
                            <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '1 Single', sid: 1 })} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                              <p className="text-[40px] font-semibold">1</p>
                            </div>
                            <div className={`${posArray[1] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[1] ?? 0.00}</div>
                          </div>

                          <div>
                            <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '2 Single', sid: 2 })} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                              <p className="text-[40px] font-semibold">2</p>
                            </div>
                            <div className={`${posArray[2] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[2] ?? 0.00}</div>
                          </div>

                          <div>
                            <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '3 Single', sid: 3 })} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                              <p className="text-[40px] font-semibold">3</p>
                            </div>
                            <div className={`${posArray[3] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[3] ?? 0.00}</div>
                          </div>

                          <div>
                            <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '4 Single', sid: 4 })} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                              <p className="text-[40px] font-semibold">4</p>
                            </div>
                            <div className={`${posArray[4] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[4] ?? 0.00}</div>
                          </div>

                          <div>
                            <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '5 Single', sid: 5 })} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                              <p className="text-[40px] font-semibold">5</p>
                            </div>
                            <div className={`${posArray[5] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[5] ?? 0.00}</div>
                          </div>

                        </div>

                        <div class="w-full grid grid-cols-5 divide-x divide-y">

                          <div>
                            <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '6 Single', sid: 6 })} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                              <p className="text-[40px] font-semibold">6</p>
                            </div>
                            <div className={`${posArray[6] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[6] ?? 0.00}</div>
                          </div>

                          <div>
                            <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '7 Single', sid: 7 })} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                              <p className="text-[40px] font-semibold">7</p>
                            </div>
                            <div className={`${posArray[7] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[7] ?? 0.00}</div>
                          </div>

                          <div>
                            <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '8 Single', sid: 8 })} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                              <p className="text-[40px] font-semibold">8</p>
                            </div>
                            <div className={`${posArray[8] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[8] ?? 0.00}</div>
                          </div>

                          <div>
                            <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '9 Single', sid: 9 })} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                              <p className="text-[40px] font-semibold">9</p>
                            </div>
                            <div className={`${posArray[9] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[9] ?? 0.00}</div>
                          </div>

                          <div>
                            <div onClick={() => this.handleBackOpen({ data: ENG, type: "Yes", odds: 9.5, nat: '0 Single', sid: 0 })} class=" bg-[#72BBEF] flex justify-center items-center cursor-pointer p-4">
                              <p className="text-[40px] font-semibold">0</p>
                            </div>
                            <div className={`${posArray[0] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[16px] font-[500]`} > {posArray[0] ?? 0.00}</div>
                          </div>

                        </div>

                        {ENG && ENG.gstatus === '1' ? null : <BetLockedRoundedBmx />}
                      </div>

                      <div className=" pb-2 border border-[#cf4b04] bg-black/5 mt-2">
                        <RoundedTabBmx />
                        <div className="flex space-x-2 justify-end items-center py-1.5 px-2">
                          {result && result.length > 0 ? result.map((element, index) => (
                            <div onClick={() => this.handleResultModel(element)} className="result-color text-[#FFF523] w-7 h-7 cursor-pointer flex justify-center items-center rounded-full shadow-md border border-gray-700" >
                              {/* ${element && element.result && element.result === "1" ? "text-[#F75500]" : element && element.result && element.result === "2" ? "text-[#FFF523]" : "text-[#08c]"}  */}
                              <p className={`font-[600] text-[13px]`}>{element && element.result && element.result === "1" ? "R" : element && element.result && element.result === "0" ? "R" : element && element.result && element.result === "2" ? "R" : "R"}</p>
                            </div>
                          )) : null}
                        </div>
                      </div>

                    </div>

                    <Desktop30
                      LoadingBet={LoadingBet}
                      betSlipData={this.state.betSlipData}
                      updateStackOnclick={this.updateStackOnclick}
                      inputChange={this.inputChange}
                      decreaseCount={this.decreaseCount}
                      increaseCount={this.increaseCount}
                      placeBet={this.placeBet}
                      handleClose={this.handleClose}
                      count={this.state.count}
                      timerKey={this.state.timerKey}
                      remainingTime={this.state.remainingTime}
                      handleCountdown={this.handleCountdown}
                      updateStake={this.updateStake}
                      betList={betList}
                      time={time}
                      backBetModal={backBetModal}
                    />

                  </div>
                ) : null}

                {activeTab === 2 ? (
                  <Mobile30
                    betList={betList}
                  />
                ) : null}

              </div>
            </div>

          </div>

        }
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Worlimatka);