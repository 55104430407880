import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { DepositListFirst, DepositListThree, DepositListTwo } from "../Deposit/DepositJson";
import { httpPost, httpPostFormData } from "../../../middelware/Http";
import { PiFilesLight } from "react-icons/pi";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavigatePathComponent from "../../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent";
import { CONST } from "../../../apis/userApis";
import bankPayment from "../../../Assest/images/payment/bank-deposit.png"
import gpayPayment from "../../../Assest/images/payment/gpay.png"
import paytmPayment from "../../../Assest/images/payment/paytm.png"
import phonepayPayment from "../../../Assest/images/payment/phonepe.png"
import upi from "../../../Assest/images/payment/upi.png"
import { message } from "antd";




function Deposit() {

    const [active, setActive] = useState(false);
    // const [isFetching, setIsFetching] = useState(false);
    const [bankAcountData, setBankAcountData] = useState()
    const [bankAcountUpi, setBankAcountUpi] = useState()
    const [showAccount, setShowAccount] = useState()
    const [error, setError] = useState({});

    let domainSetting = JSON.parse(localStorage.getItem('clientdomainSetting'));
    useEffect(() => {
        if (domainSetting) {
            setBankAcountData(domainSetting?.account)
            setBankAcountUpi(domainSetting?.upi)
        }
        // AOS.init();
        // AOS.refresh();
        // fileUpload()
    }, []);



    const toggleAccordion = () => {
        setActive(!active);
    };



    const [payAccountFiel, setPayAccountFiel] = useState({
        amount: "",
        utrNo: "",
        img: ""
    });

    const payment = async (e) => {

        e.preventDefault();
        // setIsFetching(true);
        if (setErrohandleValidation()) {
            const data = {
                screenShotImg: `${CONST.BASE_IMAGE_DOMAIN}/${payAccountFiel.img}`,
                amount: payAccountFiel.amount,
                utrNo: payAccountFiel.utrNo,
            };

            let elementPositionDataResponse = await httpPost('website/depositReq', data);

            if (elementPositionDataResponse) {
                message.success(elementPositionDataResponse?.message)
                // const toastId = toast.success(elementPositionDataResponse?.message);
                // setTimeout(() => toast.dismiss(toastId), 1000);
                setPayAccountFiel(prevState => ({
                    ...prevState,
                    amount: '0',
                    utrNo: '0',
                    img: ""
                }));
            } else {
                message.error(elementPositionDataResponse?.message)
                // const toastId = toast.error(elementPositionDataResponse?.message);
                // setTimeout(() => toast.dismiss(toastId), 1000);

            }
        }
        // setIsFetching(false);
    };

    // const fileUpload = async (e) => {
    //     // let { name } = e.target;
    //     const file = e.target.files[0];
    //     if (file) {
    //         const formData = new FormData();
    //         formData.append('image', file);
    //         try {

    //             let fileData = await httpPostFormData('website/fileUpload', formData);
    //             const toastId = toast.success(fileData?.message);
    //             setTimeout(() => toast.dismiss(toastId), 1000);

    //             setPayAccountFiel(prevState => ({
    //                 ...prevState,
    //                 img: fileData.data.imageName // Updating img property with image name
    //             }));
    //             setError(prevError => ({ ...prevError, img: "" }));
    //         } catch (error) {
    //             const toastId = toast.error('Error uploading file:');
    //             setTimeout(() => toast.dismiss(toastId), 1000);
    //             console.error('Error uploading file:', error);
    //         }
    //     } else {
    //         const errorMessage = 'Please select a valid file.';
    //         setPayAccountFiel(prevState => ({
    //             ...prevState,
    //             errorMessage,

    //         }));
    //     }
    // }
    const fileUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('image', file);
            
            try {
                let fileData = await httpPostFormData('website/fileUpload', formData);
                message.success(fileData?.message)
                // const toastId = toast.success(fileData?.message);
                // setTimeout(() => toast.dismiss(toastId), 1000);
    
                setPayAccountFiel(prevState => ({
                    ...prevState,
                    img: fileData?.data?.imageName
                }));
                setError(prevError => ({ ...prevError, img: "" }));
            } catch (error) {
                // More detailed error handling
                message.error('Error uploading file: ' + (error.response?.data?.message || error.message))
                // const toastId = toast.error('Error uploading file: ' + (error.response?.data?.message || error.message));
                // setTimeout(() => toast.dismiss(toastId), 1000);
                console.error('Error uploading file:', error);
    
                // Optional: Handle specific error codes
                if (error.response) {
                    console.error('Response error:', error.response);
                    if (error.response.status === 502) {
                        console.error('Server returned a 502 Bad Gateway error.');
                    }
                    // You can add more specific error handling based on status codes
                } else {
                    console.error('Error message:', error.message);
                }
            }
        } else {
            const errorMessage = 'Please select a valid file.';
            setPayAccountFiel(prevState => ({
                ...prevState,
                errorMessage,
            }));
        }
    };
    


    const inputChange = (e) => {
        const { name, value } = e.target;
        setPayAccountFiel(prevState => ({ ...prevState, [name]: value }));
        setError(prevError => ({ ...prevError, [name]: "" }));
    };

    const setErrohandleValidation = () => {
        let errors = {};
        let formIsValid = true;

        if (!payAccountFiel.utrNo || payAccountFiel.utrNo.length !== 12) {
            formIsValid = false;
            errors.utrNo = 'UTR No must be 12 digits.';
        }

        if (!payAccountFiel.amount) {
            formIsValid = false;
            errors.amount = 'Amount Cannot Be Blank.';
        }

        if (!payAccountFiel.utrNo) {
            formIsValid = false;
            errors.utrNo = 'New UTR No Cannot Be Blank.';
        }

        if (!payAccountFiel.img) {
            formIsValid = false;
            errors.img = 'Img Cannot Be Blank.';
        }

        setError(errors);
        return formIsValid;
    };

    const updateStackOnclic = (value) => {
        setPayAccountFiel(prevState => ({ ...prevState, amount: value }));
        setError(prevError => ({ ...prevError, amount: "" }));
    };

    const predefinedValues = ["500", "1000", "2000", "3000", "4000", "5000", "10000", "20000"];

    const paymentImage = [
        { imgs: bankPayment, title: "bank" },
        { imgs: upi, title: "bhimUpi" },
        { imgs: paytmPayment, title: "paytm" },
        { imgs: gpayPayment, title: "googlePay" },
        { imgs: phonepayPayment, title: "phonePay" },
    ];


    const [selectedMethod, setSelectedMethod] = useState(null);
    // const [selectedMethodData, setSelectedMethodData] = useState(null);

    const handleMethodClick = async (method) => {
        setSelectedMethod((prevMethod) => (prevMethod === method ? null : method));
        const filteredData = bankAcountUpi[method];
        setShowAccount(filteredData ? { filteredData } : {});
    };

    const bankAcountShowOnly = () => {
        setSelectedMethod(null)
    }


    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            payment(e);
        }
    };


    return (
        <>
            <NavigatePathComponent showPathName={"Choose Deposit Method"} />
            <section className="w-full bg-black max-w-7xl mx-auto h-fit flex justify-center py-4">
                <div className="relative mx-auto text-center w-full max-w-5xl bg-trasparent afterFooter shadow-xl ring-1 ring-gray-900/5 sm:rounded-md">
                    <div className="grid md:grid-cols-3 lg:grid-cols-5 grid-cols-3 items-center ">


                        <div onClick={() => bankAcountShowOnly()} className={`flex-1 md:w-1/4 w-1/2 p-4 ${selectedMethod === 'bank' ? "opacity-50" : ''}`}>
                            {paymentImage?.map((item, index) => (
                                <div key={index} className="lg:w-40 w-28 flex justify-center items-center">
                                    {item.title === 'bank' && <img src={item.imgs} alt={item.title} className='h-16  lg:w-[200px] w-[100px] border px-2 py-2 bg-gradient-to-r from-yellow-500 rounded-md to-red-500 border-yellow-200' />}
                                </div>
                            ))}
                        </div>
                        {bankAcountUpi ? Object.keys(bankAcountUpi).map((method) => (
                            <div key={method} className={`flex-1 md:w-1/4 w-1/2 p-4 ${selectedMethod !== method && ""}`} >
                                {paymentImage?.map((item, index) => (
                                    <div key={index} className="lg:w-40 w-24 flex justify-center items-center" onClick={() => handleMethodClick(method)}>
                                        {method === item.title && <img src={item.imgs} alt={item.title} className='h-16  lg:w-[200px] w-[100px] bg-gradient-to-r from-yellow-500 rounded-md to-red-500 border px-2 py-2 border-yellow-200' />}
                                    </div>
                                ))}
                            </div>
                        )) : null}

                    </div>

                    <div className="flex lg:flex-row flex-col lg:divide-x-2 divide-x-red-700 md:mt-10 mt-4">
                        <div className="flex-1 h-full lg:px-10 px-4">
                            {selectedMethod ? (
                                <>
                                    <p className="w-full flex justify-center items-center text-white text-sm font-bold uppercase ">{selectedMethod}</p>
                                    <div className="w-full py-4 flex justify-center items-center text-white">
                                        {/* {/ <p>Pay for  {showAccount?.filteredData?.imageName}</p > /} */}

                                        < img
                                            src={showAccount?.filteredData?.image}
                                            alt="QR Code"
                                            title="QR Code"
                                            className="md:h-52 h-52 md:w-[200px] w-60"
                                        />

                                    </div>
                                    <div className="lg:border-0 border px-4 py-2 space-y-6 text-white bg-[#272727]">
                                        <div className="flex justify-between items-center">
                                            <div>Mobile Number</div>
                                            <div className="flex gap-2 items-center"><span>{showAccount?.filteredData?.mobNo}</span> <PiFilesLight size={25} className="text-gray-600" /></div>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <div>UPI id</div>
                                            <div className="flex gap-2 items-center"><span>{showAccount?.filteredData?.upiId}</span> <PiFilesLight size={25} className="text-gray-600" /></div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <p className="w-full flex pb-2 justify-center items-center text-white text-sm font-bold uppercase">Bank Account </p>
                                    <div className="lg:border-0 border px-4 py-2 space-y-6 text-white bg-[#272727]">
                                        <div className="flex justify-between items-center">
                                            <div>Account Number</div>

                                            <div className="flex gap-2 items-center"><span>{domainSetting?.account?.accountNumber}</span> <PiFilesLight size={25} className="text-gray-600" /></div>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <div>Account Holder</div>

                                            <div className="flex gap-2 items-center"><span>{domainSetting?.account?.acHolderName}</span> <PiFilesLight size={25} className="text-gray-600" /></div>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <div>IFSC Code</div>

                                            <div className="flex gap-2 items-center"><span>{domainSetting?.account?.ifscCode}</span> <PiFilesLight size={25} className="text-gray-600" /></div>
                                        </div>

                                        <div className="flex justify-between items-center">
                                            <div>Branch Name</div>

                                            <div className="flex gap-2 items-center"><span>{domainSetting?.account?.branchName}</span> <PiFilesLight size={25} className="text-gray-600" /></div>
                                        </div>
                                    </div>
                                </>
                            )}



                            <div className="">
                                <div className="duration-200 mt-4 ease-out transition transform origin-bottom py-2 px-2 ">
                                    <div className="text-red-600 text-start text-base font-bold">Please Note: </div>
                                    <ul className="text-sm font-medium tracking-wide list-disc text-start text-white leading-4 space-y-2 rounded-2xl px-6 py-2">
                                        <li> Deposit money only in the above available account to get the fastest credit & avoid possible delays.</li>
                                        <li>After Deposit, upload your deposit slip screenshot to receive balance.</li>
                                        <li>Requested amount should be the same as deposit slip amount for smooth deposit process.</li>
                                        <li>NFT receiving time is from 40 minutes to 2 hours.</li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="flex-1 lg:px-10 px-4 lg:py-1 py-2">
                            <div className="justify-start bg-[#272727] px-3">
                                <label className="block mb-2 text-sm font-medium text-white text-start dark:text-white" htmlFor="file_input">Upload file</label>
                                <div className="flex flex-col w-full">
                                    <input className={`border p-1 focus:outline-none text-sm text-[#f36c21] font-bold w-full  ${error.img ? 'border-red-500 border-2 w-full' : ''}`}
                                        type="file"
                                        accept="image/*"
                                        onChange={(event) => fileUpload(event)}
                                    />
                                    {error.img && <div className="text-red-600 font-bold text-sm mb-1 text-left">{error.img}</div>}

                                </div>


                                <div className="flex justify-start items-center text-sm font-bold text-white mt-4">Amount</div>
                                <div className="bg-white flex divide-x divide-gray-300 mt-2">
                                    <input
                                        className={`focus:outline-none px-2 py-2 ${error.amount ? 'border-red-500 border-2 w-full' : ''}`}
                                        type="text"
                                        placeholder="0"
                                        id="amount"
                                        name="amount"
                                        value={payAccountFiel.amount}
                                        onChange={inputChange}
                                        onKeyPress={handleKeyPress}
                                    />
                                </div>
                                {error.amount && (
                                    <div className="text-red-600 text-sm mb-1 font-bold bg-red text-left">
                                        {error.amount}
                                    </div>
                                )}



                                <div className="grid grid-cols-3 gap-3 pt-2">
                                    {predefinedValues.map(value => (
                                        <button key={value} className="rounded px-1 py-2 text-xs bg-[#f36c21] font-bold" onClick={() => updateStackOnclic(value)}>{value}</button>
                                    ))}
                                </div>

                                <div className="mt-5">
                                    <div className="flex justify-start items-center text-white py-2 text-sm">Unique Transaction Reference</div>
                                    <div className="bg-white flex divide-x divide-gray-300">
                                        <input className={`focus:outline-none px-2 py-2 ${error.utrNo ? 'border-red-500 border-2 w-full' : ''}`}
                                            type="text" 
                                            placeholder="0"
                                            value={payAccountFiel.utrNo}
                                            onChange={inputChange}
                                            id="utrNo"
                                            name="utrNo"
                                            maxLength={12}
                                            onKeyPress={handleKeyPress}
                                        />
                                    </div>
                                    {error.utrNo && <div className="text-red-600 font-bold text-sm mb-1 text-left">{error.utrNo}</div>}
                                </div>
                                <div className="py-4 flex justify-start mt-4">
                                    <button className="bg-orange-600 rounded-sm px-2 py-2 text-sm font-bold uppercase text-black w-32" onClick={payment}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="px-2 space-y-6">
                        <div className="px-4 py-2 border border-[#F4DA7B] rounded-lg m-4">
                            <ul className="text-start py-1 px-4  text-white list-disc ">
                                {DepositListFirst.map((res, i) => (
                                    <React.Fragment key={i}>
                                        {res.children.map((item, index) => (
                                            <li key={index} className="py-0.5 text-xs font-normal text-wrap leading-5 tracking-normal">
                                                {item.title}
                                            </li>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </ul>
                        </div>

                        <div className="py-2">
                            <p className="text-white text-sm font-light text-start">Please Notes:</p>
                            <ul className="text-start py-1 px-2 text-white list-disc ml-4">
                                {DepositListTwo.map((res, i) => (
                                    <React.Fragment key={i}>
                                        {res.children.map((item, index) => (
                                            <li key={index} className="py-0.5 text-xs font-light text-wrap leading-5 tracking-normal">
                                                {item.title}
                                            </li>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </ul>
                        </div>

                        <div className="py-3 md:flex w-full ">
                            <div className="flex-1">
                                <ul className="text-start py-1 px-2 text-white list-disc ml-4">
                                    {DepositListThree.map((res, i) => (
                                        <React.Fragment key={i}>

                                            {res.children.map((item, index) => (
                                                <li key={index} className="py-0.5 text-xs font-light text-wrap leading-5 tracking-normal">
                                                    {item.title}
                                                </li>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </ul>

                                <div class=" py-2 overflow-x-auto px-2">
                                    <table class="w-full max-w-lg text-sm  text-gray-500 dark:text-gray-400">
                                        <thead class=" w-full text-xs text-gray-200 uppercase bg-[#f36c21] dark:[#f36c21] dark:text-white">
                                            <tr>
                                                <th scope="col" class="px-2 py-2"> Deposit Amount </th>
                                                <th scope="col" class="px-2 py-2">Bonus</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y ">
                                            <tr class=" dark:bg-gray-800 dark:border-gray-700 text-white bg-[#616161]">
                                                <th scope="row" class="px-2 py-2 font-normal text-white whitespace-nowrap dark:text-white"> ₹300 - ₹5,000 </th>
                                                <td class="px-2 py-2"> 300%</td>

                                            </tr>
                                            <tr class="bg-[#616161]  dark:bg-gray-500 dark:border-gray-700 text-white">
                                                <th scope="row" class="px-2 py-2 font-normal text-white whitespace-nowrap dark:text-white">{" "}₹5,001 - ₹10,000{" "}</th>
                                                <td class="px-2 py-2">200%</td>

                                            </tr>
                                            <tr class="bg-[#616161] dark:bg-gray-800 text-white">
                                                <th scope="row" class="px-2 py-2 font-normal text-white whitespace-nowrap dark:text-white">₹10,001 - ₹1,000,000</th>
                                                <td class="px-2 py-2"> 100% </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="flex-1 " >
                                <div className=" bg-[#1D1D1E] w-full rounded-t-lg flex justify-between items-center px-3 py-3" onClick={() => toggleAccordion()}>
                                    <span className="text-white text-sm font-normal leading-4 capitalize">condition</span>
                                    <span className={`absolute right-3  transition-transform transform duration-200 ${active ? 'rotate-180' : ''}`}>
                                        <MdKeyboardArrowDown size={20} className="text-white" />
                                    </span>
                                </div>
                                {active && (
                                    <>
                                        <div className="duration-200  ease-out transition transform origin-bottom py-2 px-2">
                                            <ul className="text-xs font-medium tracking-wide list-disc list-inside text-start text-white leading-4 space-y-2">
                                                <li>Bonus is granted only on the first deposit of each registered user.</li>
                                                <li>Criteria for bonus activation:</li>
                                                <ul className="pl-4 py-2">
                                                    <li>To activate the bonus the user must place session/ PSB bets worth X of the bonus/ deposit amount. Eg: if the bonus awarded is ₹1000 and the turnover criteria to redeem the bonus is 2x of the bonus amount, the user must place session and PSB bets worth 2x (1000) = 20,000.</li>
                                                    <li>Please Note:</li>
                                                    <ul style={{ listStyle: "circle" }} className="py-2 px-4">
                                                        <li>The turnover criteria to redeem the bonus is different for each type of bonus.</li>
                                                        <li>The turnover criteria is subject to change. Please check the Promotions page on the site for updated info.</li>
                                                    </ul>
                                                </ul>

                                                <li>If a user only deposits and attempts to withdraw the money without placing a single bet, 100% of the amount will be withheld due to suspicious activity. If this is repeated, no withdrawal will be given to the user</li>

                                                <li>Turnover in SPORTSBOOK and PREMIUM CRICKET will be considered only on bets above 1.35 odds.</li>

                                                <li>Turnover on session bets will only be considered on the "NO" option.</li>
                                            </ul>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default Deposit

