
import img9 from "../../../Assest/images/SlotGamesImages/img9.webp";
import img10 from "../../../Assest/images/SlotGamesImages/img10.webp";
import img11 from "../../../Assest/images/SlotGamesImages/img11.webp";
import img12 from "../../../Assest/images/SlotGamesImages/img12.webp";
import img13 from "../../../Assest/images/SlotGamesImages/img13.webp";
import img14 from "../../../Assest/images/SlotGamesImages/img14.webp";
import img15 from "../../../Assest/images/SlotGamesImages/img15.webp";
import img16 from "../../../Assest/images/SlotGamesImages/img16.webp";
import img17 from "../../../Assest/images/SlotGamesImages/img17.webp";
import img18 from "../../../Assest/images/SlotGamesImages/img18.webp";
import img19 from "../../../Assest/images/SlotGamesImages/img19.webp";
import img20 from "../../../Assest/images/SlotGamesImages/img20.webp";
import img21 from "../../../Assest/images/SlotGamesImages/img21.webp";
import img22 from "../../../Assest/images/SlotGamesImages/img22.webp";
import img23 from "../../../Assest/images/SlotGamesImages/img23.webp";




function SlotGames() {
    const sliderImage = [

        { imgs: img9, title: "img9" },
        { imgs: img10, title: "img10" },
        { imgs: img11, title: "img11" },
        { imgs: img12, title: "img12" },
        { imgs: img13, title: "img13" },
        { imgs: img14, title: "img14" },
        { imgs: img15, title: "img15" },
        { imgs: img16, title: "img16" },
        { imgs: img17, title: "img17" },
        { imgs: img18, title: "img18" },
        { imgs: img19, title: "img19" },
        { imgs: img20, title: "img20" },
        { imgs: img21, title: "img21" },
        { imgs: img22, title: "img22" },
        { imgs: img23, title: "img23" }
    ];

    return (
        <div className=' w-full h-full flex justify-center items-center' >
            <div className='w-[95%] afterFooter  m-5'>

                <div className='grid grid-cols-2 md:grid-cols-4 gap-2'>

                    {sliderImage.map((image, index) => (
                        <div key={index} className="">
                            <img src={image.imgs} alt={image.title} className='w-[500px] lg:w-[480px] rounded-md border-2 border-yellow-200 border-solid' />
                        </div>
                    ))}

                </div>
            </div>


        </div>
    )
}

export default SlotGames
