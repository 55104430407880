import image03 from "../../../Assest/images/DashBoard/03.png"
import image04 from "../../../Assest/images/DashBoard/04.png"
import image05 from "../../../Assest/images/DashBoard/05.png"

export const PromotionJson = [
  {
    question: "First Deposit Bonus",
    answer: `<h2>300% First Deposit Bonus</h2></br>
        Get up to 300% bonus on your first deposit as a Crick Expo Club member. From Deposit of RS 300-5000, Bonus will be 300% From Deposit of RS 5001- 10000, Bonus will be 200% From Deposit of RS 10001- 1000000, Bonus will be 100%.
        
        </br> </br>
        <span>NOTE</span>:- A 30x turnover of the bonus amount must be completed to activate the bonus.
        A New User will not receive the First Deposit Bonus if the same Device (computer, laptop, mobile) is used to make multiple accounts at Crick Expo Club
        </br> </br> 
        <span>NOTE</span>:- Maximum Bonus you can receive for First Deposit Bonus is 50000`,
    open: true,
    img: image05,
  },

  {
    question: "Referral Bonus",
    answer: `<h2>Referral Bonus</h2><br>
      Share your personal referral code with your friend to start their Crick Expo Club membership and get a 15% bonus of every single deposit they make thereafter!
      <br><br>
      Note: A. The bonus will be authorized once the referred player makes a 3x turnover.
      <br><br>
      B.To redeem the bonus amount, the user must complete a turnover of 35x on the premium sportsbook.
      <br><br>
      C. Bonus will be credited in the form of Crick Expo points and can be used to place bets or play games across the Crick Expo platform but cannot be withdrawn.
      <br><br>
      D. If a Club member is found creating multiple accounts or abusing the referral bonus in any other way, all the accounts in question will be blocked and Team Crick Expo is not liable to pay the Club member the withstanding amount.
      <br><br>
      <span>NOTE</span>:-Maximum Bonus you can receive for Referral Bonus is 50000`,
    img: image04,
    open: false
  },
  {
    question: "Crypto Deposit Bonus",
    answer: `<h2>Crypto Deposit Bonus</h2></br>
    10% of the Crypto deposit is awarded as a bonus. A 3x turnover of the deposit amount must be completed to activate the bonus. Bets placed in Premium sportsbook will be counted as turnover.`,
    img: image03,
    open: false
  }
]