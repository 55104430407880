import React from "react";
import { connect } from "react-redux";
import FlipCountdown from '@rumess/react-flip-countdown';
import axios from "axios";
import io from 'socket.io-client';
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorTost from './casino_components/ErrorTost';
import { BsSuitSpadeFill, BsSuitClubFill } from "react-icons/bs";
import { ImDiamonds } from "react-icons/im";
import { AiFillHeart } from "react-icons/ai";
import "./casino.css";
import BetLocked from "./casino_components/BetLocked";
import PlaceBetMobileBmx from "./casino_components/PlaceBetMobileBmx";
import ResultModelBmx from "./casino_components/ResultModelBmx";
import RoundedTabBmx from "./casino_components/RoundedTabBmx";
import PageHeaderTheme from "./casino_components/PageHeaderTheme";
import MyBetTabBmx from "./casino_components/MyBetTabBmx";
import BetListTableBmx from "./casino_components/BetListTableBmx";
import PlaceBetMobileBmxBet from "./casino_components/PlaceBetMobileBmxBet";
import BetLockedRoundedBmx from "./casino_components/BetLockedRoundedBmx";
import CasinoTab from "./casino_components/CasinoTab";
import AmarAkbarAnthonyrules from './images/aaa-rules.jpg';
import Desktop30 from "./casino_components/Desktop30";
import Mobile30 from "./casino_components/Mobile30";
import { httpPost, httpPostBet } from "../../../../middelware/Http";
import Loader from "../../../../component/Loader/Loader";
import { message } from "antd";

class Amarakbaranthony extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backBetModal: false,
      offset: 0,
      betSlipData: {},
      fieldsbet: {},
      errorsbet: {},
      count: 0,
      time: 7,
      casinoData: [],
      ResultModel: false,
      Result: false,
      backBetModalMobile: false,
      showLoader: false,
      result: {},
      casinoDetails: {},
      fetchData: "",
      socketURL: "",
      tvUrl: "",
      cacheURL: "",
      betStatus: "",
      cashinoStatus: "",
      eventId: "",
      minStake: "",
      maxStake: "",
      name: "",
      shortName: "aaa",
      betList: [],
      betresponse: [],
      newCasinoModal: false,
      BetPlaceDesktop: false,
      betFor: null,
      betOpen: false,
      myBetOpen: false,
      setting: {},
      posArray: {},
      currentRoundId: 0,
      oddsDifference: 0,
      totalProfitLoss: 0,
      activeTab: 1,
      LoadingBet: false,
      isModalOpen: false,
      isModalOpen2: false,
      isModalOpen3: false,
      isModalOpen4: false,
      isModalOpen5: false,
    };
    this.section1Ref = React.createRef();
    this.scrollTimeout = null;
    this.inputRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.user && nextProps.user.betPlaceSucess) {
      return {
        ...nextProps,
        backBetModal: false,
        backBetModalMobile: false,
        betSlipData: {},
      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  increaseCount = () => {
    const increasedCount = (parseFloat(this.state.count) + 0.01).toFixed(2);
    this.setState({ count: parseFloat(increasedCount) });
  };

  decreaseCount = () => {
    const decreasedCount = (parseFloat(this.state.count) - 0.01).toFixed(2);
    this.setState({ count: parseFloat(decreasedCount) });
  };

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    if (value < 0 || isNaN(Number(value))) {
      value = 0;
    }

    if (value[0] === '0' && value.length > 1) {
      value = value.substring(1);
    }

    let { betSlipData } = this.state;
    betSlipData.stake = value;
    this.setState({
      betSlipData: betSlipData,
      [name]: Number(value),
    });
  };

  handleBackOpen = (data) => {
    if (this.scrollTimeout) {
      clearInterval(this.scrollTimeout);
    }
    this.setState({ backBetModal: true, backBetModalMobile: true, betSlipData: { ...data, stake: "0" }, count: data.odds, time: 7, });
    this.scrollTimeout = setInterval(() => {
      this.setState(prevState => ({ time: prevState.time - 1 }), () => {
        if (this.state.time <= 0) {
          clearInterval(this.scrollTimeout);
          this.setState({ backBetModal: false });
        }
      });
    }, 1000);

  };

  handleResultModel = (data) => {
    this.setState({ ResultModel: true, result: data });
  };

  updateStackOnclic = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStackOnclick = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStake = (event) => {
    try {
      let { value } = event.target;
      let { betSlipData } = this.state;
      if (value.startsWith('0') && value.length > 1) {
        value = value.slice(1);
      }
      betSlipData.stake = value;
      this.setState({
        betSlipData: betSlipData,
      });
    } catch (error) {
      console.error('Error updating stake:', error);
    }
  };

  placeBet = async () => {
    
    
    const { casinoData } = this.state;
    this.setState({ LoadingBet: true })
    let { data } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let betObject = {
      "roundId": t1.mid,
      "sid": this.state.betSlipData.data.sid,
      "rate": this.state.count + "",
      "amount": Number(this.state.betSlipData.stake),
      "casinoType": this.state.shortName ? this.state.shortName : "aaa",
      "eventId": 3056,
      "betFor": this.state.betSlipData.betFor + "",
      "isLay": this.state.betSlipData.type === "No" ? true : false,
    }

    const result = await httpPostBet("casino/casinoBetPlace", betObject);

    if (result) {
      if (result.error === false) {

        this.setState(
          {
            betresponse: result.data
          },
        );
        message.success(result.message)
        // toast.success(<ErrorTost message={result.message} />, {
        //   autoClose: 1500,
        // });
        this.betList(3056)
        // this.props.dispatch(userActions.getUserBalance());
      }
      else {
        message.error(result.message)
        // toast.error(<ErrorTost message={result.message} />, {
        //   autoClose: 1500,
        // });
      }
    }
    this.handleClose();
    this.setState({ LoadingBet: false })
  };

  componentDidMount() {

    let requestData = {
      eventId: 3056,
    }
    this.getDidMountData(requestData)
  }

  getDidMountData = async (requestData) => {
    this.setState({ showLoader: true })
    let getCasinoDetails = await httpPost(`casino/getDiamondCasinoByEventId`, requestData)
    if (!getCasinoDetails.error) {
      let casinoDetails = getCasinoDetails.data
      this.setState({
        casinoDetails: casinoDetails,
        fetchData: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : {},
        socketURL: casinoDetails && casinoDetails.socketURL ? casinoDetails.socketURL : {},
        tvUrl: casinoDetails && casinoDetails.videoUrl1 ? casinoDetails.videoUrl1 : "",
        cacheURL: casinoDetails && casinoDetails.cacheURL ? casinoDetails.cacheURL : {},
        betStatus: casinoDetails && casinoDetails.betStatus ? casinoDetails.betStatus : "",
        cashinoStatus: casinoDetails && casinoDetails.cashinoStatus ? casinoDetails.cashinoStatus : "",
        eventId: casinoDetails && casinoDetails?.eventId ? casinoDetails?.eventId : "",
        minStake: casinoDetails && casinoDetails.minStake ? casinoDetails.minStake : "",
        maxStake: casinoDetails && casinoDetails.maxStake ? casinoDetails.maxStake : "",
        name: casinoDetails && casinoDetails.name ? casinoDetails.name : "",
        shortName: casinoDetails && casinoDetails.shortName ? casinoDetails.shortName : "aaa",
        setting: casinoDetails && casinoDetails.setting ? casinoDetails.setting : {},
        oddsDifference: casinoDetails && casinoDetails.setting && casinoDetails.setting.oddsDifference ? casinoDetails.setting.oddsDifference : 0,
      })
      if (casinoDetails?.eventId) {
        this.betList(casinoDetails?.eventId)
      }

      // const socketPerm = casinoDetails.fetchData
      if (casinoDetails?.socketURL) {
        await this.callSocket(casinoDetails?.socketURL, casinoDetails?.shortName)
      }
      // else {
      //   await this.callCache(casinoDetails.cacheURL)
      // }
    } else {

    }
    this.setState({ showLoader: false })
  }

  callSocket = async (socketURL, shortName) => {
    const socket = io.connect(socketURL, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 99,
    });
    socket.emit('JoinRoom', shortName);
    socket.on(shortName, data => {
      this.setState({ currentRoundId: data && data.data && data.data.t1 && data.data.t1[0] && data.data.t1[0].mid ? data.data.t1[0].mid : 0 })
      this.setState({ casinoData: data })
      this.updatePos()
    })
  }

  callCache = async (cacheUrl) => {
    await this.getMarketCacheUrl(cacheUrl);
    this.cacheInterval = setInterval(async () => {
      await this.getMarketCacheUrl(cacheUrl);
    }, 2000);
  }


  getMarketCacheUrl = async (cacheURL) => {
    let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: cacheURL,
      headers: { 'Content-Type': 'application/json' },
    };
    axios.request(config).then((response) => {
      this.setState({ casinoData: response.data.data })
    }).catch((error) => {
      console.error('cache data url error ', error);
    });
  }

  updatePos = async () => {
    const { betList, currentRoundId } = this.state;
    if (betList && betList.length > 0) {
      const filteredBets = betList.filter((element) => element.roundId == currentRoundId);
      let pushPos = {};
      filteredBets && filteredBets.length > 0 && filteredBets.forEach((bet) => {
        const posArray = bet.posArray;
        Object.entries(posArray).forEach(([key, value]) => {
          pushPos[key] = (pushPos[key] || 0) + Number(value);
        });
      });

      this.setState({ posArray: pushPos });
    }
  }

  betList = async (eventId) => {
    let betReq = {
      "eventId": eventId,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    };

    let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)
    let totalProfitLoss = 0
    let betList = getCasinoDetails?.data?.casinoBetData

    if (betList && betList.length > 0) {
      betList.map((bet, key) => {
        let profitLoss = "Not Declare"
        let profitLossCount = 0
        if (bet.isDeclare) {
          profitLossCount = bet.profitLoss ? bet.profitLoss : 0
          profitLoss = bet.profitLoss
        }
        totalProfitLoss += Number(profitLossCount)
        betList[key].profitLoss = profitLoss
      })
    }
    if (getCasinoDetails) {
      this.setState({ betList: betList, totalProfitLoss: totalProfitLoss })
    }
  }


  componentWillUnmount() {
    clearInterval(this.cacheInterval);
    clearTimeout(this.timer);
    if (this.inputRef.current) {
      this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
    }
  }

  handleClose = () => {
    this.setState({ backBetModal: false, backBetModalMobile: false });
  };

  handleCloseResult = () => {
    this.setState({ ResultModel: false, });
  };

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  handleToggleModal = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  handleToggleModal2 = () => {
    this.setState((prevState) => ({ isModalOpen2: !prevState.isModalOpen2 }));
  };

  handleToggleModal3 = () => {
    this.setState((prevState) => ({ isModalOpen3: !prevState.isModalOpen3 }));
  };

  handleToggleModal4 = () => {
    this.setState((prevState) => ({ isModalOpen4: !prevState.isModalOpen4 }));
  };

  handleToggleModal5 = () => {
    this.setState((prevState) => ({ isModalOpen5: !prevState.isModalOpen5 }));
  };

  render() {
    let clientNotification = JSON.parse(localStorage.getItem('notification'))
    const { casinoData, name, shortName, ResultModel, time, count, backBetModal, tvUrl, betList, showLoader, LoadingBet, posArray, oddsDifference, totalProfitLoss, activeTab, isModalOpen, isModalOpen2, isModalOpen3, isModalOpen4, isModalOpen5, minStake, maxStake } = this.state;
    let { data, result } = casinoData ? casinoData : {};
    let Amar = data && data.t2 && data.t2[0] ? data.t2[0] : {};
    let Akbar = data && data.t2 && data.t2[1] ? data.t2[1] : {};
    let Anthony = data && data.t2 && data.t2[2] ? data.t2[2] : {};
    let Even = data && data.t2 && data.t2[3] ? data.t2[3] : {};
    let Odd = data && data.t2 && data.t2[4] ? data.t2[4] : {};
    let Red = data && data.t2 && data.t2[5] ? data.t2[5] : {};
    let Black = data && data.t2 && data.t2[6] ? data.t2[6] : {};
    let CardA = data && data.t2 && data.t2[7] ? data.t2[7] : {};
    let Card2 = data && data.t2 && data.t2[8] ? data.t2[8] : {};
    let Card3 = data && data.t2 && data.t2[9] ? data.t2[9] : {};
    let Card4 = data && data.t2 && data.t2[10] ? data.t2[10] : {};
    let Card5 = data && data.t2 && data.t2[11] ? data.t2[11] : {};
    let Card6 = data && data.t2 && data.t2[12] ? data.t2[12] : {};
    let Card7 = data && data.t2 && data.t2[13] ? data.t2[13] : {};
    let Card8 = data && data.t2 && data.t2[14] ? data.t2[14] : {};
    let Card9 = data && data.t2 && data.t2[15] ? data.t2[15] : {};
    let Card10 = data && data.t2 && data.t2[16] ? data.t2[16] : {};
    let CardJ = data && data.t2 && data.t2[17] ? data.t2[17] : {};
    let CardQ = data && data.t2 && data.t2[18] ? data.t2[18] : {};
    let CardK = data && data.t2 && data.t2[19] ? data.t2[19] : {};
    let Under7 = data && data.t2 && data.t2[20] ? data.t2[20] : {};
    let Over7 = data && data.t2 && data.t2[21] ? data.t2[21] : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    return (
      <>
        {LoadingBet === true ?
          <>
            <PlaceBetMobileBmxBet
              betSlipData={this.state.betSlipData}
              time={time}
              count={count}
            />
          </> :
          <>
            {backBetModal && backBetModal === true ? (
              <PlaceBetMobileBmx
                betSlipData={this.state.betSlipData}
                updateStackOnclic={this.updateStackOnclic}
                placeBet={this.placeBet}
                handleClose={this.handleClose}
                section1Ref={this.section1Ref}
                time={time}
                inputChange={this.inputChange}
                decreaseCount={this.decreaseCount}
                increaseCount={this.increaseCount}
                count={count}
              />
            ) : null}
          </>
        }

        {showLoader ?
          <Loader active={showLoader === true} />
          :
          <div className={`w-full relative h-full  overflow-y-auto text-sm flex "}`}>
            {ResultModel &&
              ResultModel === true ? (
              <ResultModelBmx
                handleClose={this.handleCloseResult}
                name={name ? name : "Amar Akbar Anthony"}
                shortName={shortName ? shortName : "aaa"}
                result={this.state.result}
              />
            ) : null}
            <div className="lg:flex block w-full lg:pt-1 pt-0 lg:space-x-2 my-5">
              <div className="lg:hidden block">
                <CasinoTab
                  activeTab={activeTab}
                  handleTabClick={this.handleTabClick}
                  ruleImage={AmarAkbarAnthonyrules}
                  t1={t1}
                  totalBet={betList && betList.length ? betList.length : "0"}
                />
              </div>

              <div className="w-full lg:flex">


                {activeTab === 1 ? (
                  <div className="w-full lg:flex block">

                    <div className="lg:w-[70%] w-full ">
                      <PageHeaderTheme
                        PageTitle={name ? name : "AMAR AKBAR ANTHONY"}
                        ruleImage={AmarAkbarAnthonyrules}
                        t1={t1}
                      />
                      <div className="bg-black flex justify-between w-full relative md:text-sm text-[10px] xl:h-[500px] md:h-[300px] h-[200px] border border-[#cf4b04]">
                        <iframe src={tvUrl ? tvUrl : null} title=" " className='relative w-full  ' />
                        <div className=" flex justify-between">
                          <div className="absolute top-0 left-0">
                            <div className="flex justify-start space-x-1 p-2">
                              <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="h-10 w-8 border-[1px] border-yellow-300" />
                            </div>
                          </div>
                          <div className="flex justify-end items-end absolute bottom-2 right-1">
                            <FlipCountdown
                              titlePosition='hidden'
                              hideYear
                              hideMonth
                              hideDay
                              hideHour
                              hideMinute
                              endAtZero
                              size='small'
                              endAt={new Date(Date.now() + 1000 * (t1 && t1.autotime ? t1.autotime : null)).toUTCString()}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="">

                        <div className="odds-bet lg:block hidden mt-2">

                          {/* <div className="py-2 px-4 relative">
                            <div className="text-black flex justify-end items-center cursor-pointer " onClick={this.handleToggleModal}>
                              <FaInfoCircle className="hover:text-[#007bff] " size={16} />
                            </div>

                            {isModalOpen && (
                              <div className="modal bg-black/60 xl:w-[15%] lg:w-[30%] absolute z-50 right-4">
                                <div className="modal-content">
                                  <div className="flex justify-center items-center font-normal lg:text-[15px] text-[14px] py-1.5 white-text px-4 whitespace-nowrap">
                                    <span className="font-[500]">MIN</span>:{minStake} <span className="font-[500]">&nbsp; MAX</span>:{maxStake}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div> */}

                          <div className="grid grid-cols-3 space-x-4 px-4 pb-4 pt-1 mt-1 border border-[#cf4b04]">

                            <div className="">
                              <div className="flex justify-center items-center ">
                                <p className="text-[16px] font-semibold gray-text " >
                                  A. {Amar && Amar.nat ? Amar.nat : Amar.nation ? Amar.nation : "Amar"}</p>
                              </div>

                              <div className="flex justify-center items-center space-x-1.5">
                                <div className="w-full cursor-pointer relative">
                                  <div className="match_bg_blue_index-0 w-full text-center py-3.5 relative" onClick={() => this.handleBackOpen({ data: Amar, type: "Yes", odds: Amar.b1, nat: Amar.nat ? Amar.nat : Amar.nation, betFor: "aaa" }, this.section1Ref)}>
                                    <div className="text-[16px] font-[600] gray-text leading-4">{Amar && Amar.b1 ? Amar.b1 : "0.00"}</div>
                                  </div>
                                  {Amar.gstatus === "ACTIVE" ? null : <BetLocked />}
                                </div>
                                <div className="w-full cursor-pointer relative">
                                  <div onClick={() => this.handleBackOpen({ data: Amar, type: "No", odds: Amar.l1, nat: Amar.nat ? Amar.nat : Amar.nation, betFor: "aaa" }, this.section1Ref)} className="match_bg_pink_index-0 w-full text-center py-3.5 cursor-pointer relative">
                                    <div className="text-[16px] font-[600] gray-text leading-4">{Amar && Amar.l1 ? Amar.l1 : "0.00"}</div>
                                  </div>
                                  {Amar.gstatus === "ACTIVE" ? null : <BetLocked />}
                                </div>
                              </div>
                              <div className={`${posArray[Amar.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[14px] font-[500] pt-1 `} > {posArray[Amar.sid] ?? 0.00}</div>
                            </div>

                            <div className="">
                              <div className="flex justify-center items-center ">
                                <p className="text-[16px] font-semibold gray-text">
                                  B. {Akbar && Akbar.nat ? Akbar.nat : Akbar.nation ? Akbar.nation : "Akbar"}</p>
                              </div>
                              <div className="flex justify-center items-center space-x-1.5">
                                {Akbar.gstatus === "ACTIVE" ?
                                  <div className=" match_bg_blue_index-0 w-full text-center py-3.5 relative cursor-pointer" onClick={() => this.handleBackOpen({ data: Akbar, type: "Yes", odds: Akbar.b1, nat: Akbar.nat ? Akbar.nat : Akbar.nation, betFor: "aaa" }, this.section1Ref)}>
                                    <div className="text-[16px] font-[600] gray-text leading-4">{Akbar && Akbar.b1 ? Akbar.b1 : "0.00"}</div>
                                  </div>
                                  :
                                  <div className="match_bg_blue_index-0 w-full text-center py-3.5 relative cursor-pointer">
                                    <div className="text-[16px] font-[600] gray-text leading-4">{Akbar && Akbar.b1 ? Akbar.b1 : "0.00"}</div>
                                    <BetLockedRoundedBmx />
                                  </div>
                                }

                                {Akbar.gstatus === "ACTIVE" ?
                                  <div onClick={() => this.handleBackOpen({ data: Akbar, type: "No", odds: Akbar.l1, nat: Akbar.nat ? Akbar.nat : Akbar.nation, betFor: "aaa" }, this.section1Ref)} className="match_bg_pink_index-0 w-full text-center py-3.5 cursor-pointer relative">
                                    <div className="text-[16px] font-[600] gray-text leading-4">{Akbar && Akbar.l1 ? Akbar.l1 : "0.00"}</div>
                                  </div>
                                  :
                                  <div className="match_bg_pink_index-0 w-full text-center py-3.5 cursor-pointer relative">
                                    <div className="text-[16px] font-[600] gray-text leading-4">{Akbar && Akbar.l1 ? Akbar.l1 : "0.00"}</div>
                                    <BetLockedRoundedBmx />
                                  </div>
                                }
                              </div>
                              <div className={`${posArray[Akbar.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[14px] font-[500] pt-1`} > {posArray[Akbar.sid] ?? 0.00}</div>
                            </div>

                            <div className="">
                              <div className="flex justify-center items-center ">
                                <p className="text-[16px] font-semibold gray-text">
                                  C. {Anthony && Anthony.nat ? Anthony.nat : Anthony.nation ? Anthony.nation : "Anthony"}</p>
                              </div>
                              <div className="flex justify-center items-center space-x-1.5">
                                {Anthony.gstatus === "ACTIVE" ?
                                  <div className=" match_bg_blue_index-0 w-full text-center py-3.5 relative cursor-pointer" onClick={() => this.handleBackOpen({ data: Anthony, type: "Yes", odds: Anthony.b1, nat: Anthony.nat ? Anthony.nat : Anthony.nation, betFor: "aaa" }, this.section1Ref)}>
                                    <div className="text-[16px] font-[600] gray-text leading-4">{Anthony && Anthony.b1 ? Anthony.b1 : "0.00"}</div>
                                  </div>
                                  :
                                  <div className=" match_bg_blue_index-0 w-full text-center py-3.5 relative cursor-pointer">
                                    <div className="text-[16px] font-[600] gray-text leading-4">{Anthony && Anthony.b1 ? Anthony.b1 : "0.00"}</div>
                                    <BetLockedRoundedBmx />
                                  </div>
                                }

                                {Anthony.gstatus === "ACTIVE" ?
                                  <div onClick={() => this.handleBackOpen({ data: Anthony, type: "No", odds: Anthony.l1, nat: Anthony.nat ? Anthony.nat : Anthony.nation, betFor: "aaa" }, this.section1Ref)} className=" match_bg_pink_index-0 w-full text-center py-3.5 cursor-pointer relative">
                                    <div className="text-[16px] font-[600] gray-text leading-4">{Anthony && Anthony.l1 ? Anthony.l1 : "0.00"}</div>
                                  </div>
                                  :
                                  <div className=" match_bg_pink_index-0 w-full text-center py-3.5 cursor-pointer relative">
                                    <div className="text-[16px] font-[600] gray-text leading-4">{Anthony && Anthony.l1 ? Anthony.l1 : "0.00"}</div>
                                    <BetLockedRoundedBmx />
                                  </div>
                                }

                              </div>
                              <div className={`${posArray[Anthony.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[14px] font-[500] pt-1`} > {posArray[Anthony.sid] ?? 0.00}</div>
                            </div>

                          </div>

                        </div>

                        <div className="lg:space-y-3 space-y-5">

                          <div className=" lg:hidden block pt-1.5 divide-y divide-[#c7c8ca] border-[1px] border-[#c7c8ca]">

                            {/* <div className="text-left text-[14px] font-[600] px-2 lg:hidden block">
                              <span className="font-[500]">MIN</span>:{minStake} <span className="font-[500]">&nbsp; MAX</span>:{maxStake}
                            </div> */}

                            <div className="w-full text-center border border-[#cf4b04]">
                              <div className="grid grid-cols-4 divide-x divide-[#c7c8ca]">

                                <div className="h-full bg-white capitalize text-gray-800 font-medium py-2 px-2 col-span-2 w-full text-left">
                                  <p className="text-[14px] font-semibold" >
                                    <span>A.</span> {Amar && Amar.nat ? Amar.nat : Amar.nation ? Amar.nation : "Amar"}</p>
                                  <div className={`${posArray[Amar.sid] < 0 ? "text-red-500" : "text-green-800"}`} > {posArray[Amar.sid]}</div>
                                </div>

                                {Amar.gstatus === "ACTIVE" ?
                                  <div className=" match_bg_blue_index-0 w-full text-center py-2.5 relative cursor-pointer" onClick={() => this.handleBackOpen({ data: Amar, type: "Yes", odds: Amar.b1, nat: Amar.nat ? Amar.nat : Amar.nation, betFor: "aaa" }, this.section1Ref)}>
                                    <div className="text-[14px] font-[600] gray-text leading-4">{Amar && Amar.b1 ? Amar.b1 : "0.00"}</div>
                                  </div>
                                  :
                                  <div className=" match_bg_blue_index-0 w-full text-center py-2.5 relative cursor-pointer">
                                    <div className="text-[14px] font-[600] gray-text leading-4">{Amar && Amar.b1 ? Amar.b1 : "0.00"}</div>
                                    <BetLockedRoundedBmx />
                                  </div>
                                }

                                {Amar.gstatus === "ACTIVE" ?
                                  <div onClick={() => this.handleBackOpen({ data: Amar, type: "No", odds: Amar.l1, nat: Amar.nat ? Amar.nat : Amar.nation, betFor: "aaa" }, this.section1Ref)} className=" match_bg_pink_index-0 w-full text-center py-2.5 cursor-pointer relative">
                                    <div className="text-[14px] font-[600] gray-text leading-4">{Amar && Amar.l1 ? Amar.l1 : "0.00"}</div>
                                  </div>
                                  :
                                  <div className=" match_bg_pink_index-0 w-full text-center py-2.5 cursor-pointer relative">
                                    <div className="text-[14px] font-[600] gray-text leading-4">{Anthony && Anthony.l1 ? Anthony.l1 : "0.00"}</div>
                                    <BetLockedRoundedBmx />
                                  </div>
                                }

                              </div>
                            </div>

                            <div className="w-full text-center border border-[#cf4b04]">
                              <div className="grid grid-cols-4 divide-x divide-[#c7c8ca]">

                                <div className="h-full capitalize bg-white text-gray-800 font-medium py-2 px-2 col-span-2 w-full text-left">
                                  <p className="text-[14px] font-semibold">
                                    <span>B.</span> {Akbar && Akbar.nat ? Akbar.nat : Akbar.nation ? Akbar.nation : "Akbar"}</p>
                                  <div className={`${posArray[Akbar.sid] < 0 ? "text-red-500" : "text-green-800"}`} > {posArray[Akbar.sid]}</div>
                                </div>

                                {Akbar.gstatus === "ACTIVE" ?
                                  <div className="match_bg_blue_index-0 w-full text-center py-2.5 relative cursor-pointer" onClick={() => this.handleBackOpen({ data: Akbar, type: "Yes", odds: Akbar.b1, nat: Akbar.nat ? Akbar.nat : Akbar.nation, betFor: "aaa" }, this.section1Ref)}>
                                    <div className="text-[14px] font-[600] gray-text leading-4">{Akbar && Akbar.b1 ? Akbar.b1 : "0.00"}</div>
                                  </div>
                                  :
                                  <div className="match_bg_blue_index-0 w-full text-center py-2.5 relative cursor-pointer">
                                    <div className="text-[14px] font-[600] gray-text leading-4">{Akbar && Akbar.b1 ? Akbar.b1 : "0.00"}</div>
                                    <BetLockedRoundedBmx />
                                  </div>
                                }

                                {Akbar.gstatus === "ACTIVE" ?
                                  <div onClick={() => this.handleBackOpen({ data: Akbar, type: "No", odds: Akbar.l1, nat: Akbar.nat ? Akbar.nat : Akbar.nation, betFor: "aaa" }, this.section1Ref)} className="match_bg_pink_index-0 w-full text-center py-2.5 cursor-pointer relative">
                                    <div className="text-[14px] font-[600] gray-text leading-4">{Akbar && Akbar.l1 ? Akbar.l1 : "0.00"}</div>
                                  </div>
                                  :
                                  <div className="match_bg_pink_index-0 w-full text-center py-2.5 cursor-pointer relative">
                                    <div className="text-[14px] font-[600] gray-text leading-4">{Akbar && Akbar.l1 ? Akbar.l1 : "0.00"}</div>
                                    <BetLockedRoundedBmx />
                                  </div>
                                }

                              </div>
                            </div>

                            <div className="w-full text-center border border-[#cf4b04]">
                              <div className="grid grid-cols-4 divide-x divide-[#c7c8ca]">

                                <div className="h-full text-[14px] bg-white capitalize text-gray-800 font-medium py-2 px-2 col-span-2 w-full text-left">
                                  <p className="text-[14px] font-semibold">
                                    <span>C.</span> {Anthony && Anthony.nat ? Anthony.nat : Anthony.nation ? Anthony.nation : "Anthony"}</p>
                                  <div className={`${posArray[Anthony.sid] < 0 ? "text-red-500" : "text-green-800"}`} > {posArray[Anthony.sid]}</div>
                                </div>

                                {Anthony.gstatus === "ACTIVE" ?
                                  <div className="match_bg_blue_index-0 w-full text-center py-2.5 relative cursor-pointer" onClick={() => this.handleBackOpen({ data: Anthony, type: "Yes", odds: Anthony.b1, nat: Anthony.nat ? Anthony.nat : Anthony.nation, betFor: "aaa" }, this.section1Ref)}>
                                    <div className="text-[14px] font-[600] gray-text leading-4">{Anthony && Anthony.b1 ? Anthony.b1 : "0.00"}</div>
                                  </div>
                                  :
                                  <div className="match_bg_blue_index-0 w-full text-center py-2.5 relative cursor-pointer">
                                    <div className="text-[14px] font-[600] gray-text leading-4">{Anthony && Anthony.b1 ? Anthony.b1 : "0.00"}</div>
                                    <BetLockedRoundedBmx />
                                  </div>
                                }

                                {Anthony.gstatus === "ACTIVE" ?
                                  <div onClick={() => this.handleBackOpen({ data: Anthony, type: "No", odds: Anthony.l1, nat: Anthony.nat ? Anthony.nat : Anthony.nation, betFor: "aaa" }, this.section1Ref)} className="match_bg_pink_index-0 w-full text-center py-2.5 cursor-pointer relative">
                                    <div className="text-[14px] font-[600] gray-text leading-4">{Anthony && Anthony.l1 ? Anthony.l1 : "0.00"}</div>
                                  </div>
                                  :
                                  <div className="match_bg_pink_index-0 w-full text-center py-2.5 cursor-pointer relative">
                                    <div className="text-[14px] font-[600] gray-text leading-4">{Anthony && Anthony.l1 ? Anthony.l1 : "0.00"}</div>
                                    <BetLockedRoundedBmx />
                                  </div>
                                }

                              </div>
                            </div>

                          </div>

                          <div className="grid grid-cols-3 lg:gap-3.5 gap-2 ">

                            <div className=" py-2.5 lg:px-2.5 px-1.5 odds-bet space-y-3 border border-[#cf4b04]">

                              {/* <div className="px-2 relative">
                                <div className="lg:text-black text-[#52796f] flex justify-end items-center cursor-pointer " onClick={this.handleToggleModal2}>
                                  <FaInfoCircle className="hover:text-[#007bff] " size={16} />
                                </div>

                                {isModalOpen2 && (
                                  <div className="modal bg-black/60 absolute z-50 right-4">
                                    <div className="modal-content">
                                      <div className="md:w-44 w-24 text-center font-normal lg:text-[15px] text-[12px] py-1.5 white-text px-4 ">
                                        <span className="font-[500]">MIN</span>:{minStake} <span className="font-[500]">&nbsp; MAX</span>:{maxStake}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div> */}

                              <div className="space-y-[2px]">
                                <div className="flex justify-center items-center ">
                                  <p className="lg:text-[16px] text-[14px] font-semibold gray-text ">{Even && Even.b1 ? Even.b1 : "0.00"}</p>
                                </div>
                                <div className="relative">
                                  <div className="even-background w-full text-center cursor-pointer py-3 rounded" onClick={() => this.handleBackOpen({ data: Even, type: "Yes", odds: Even.b1, nat: Even.nat ? Even.nat : Even.nation, betFor: "oddEven" }, this.section1Ref)}>
                                    <div className="lg:text-[16px] text-[14px] font-bold leading-4 text-white capitalize">{Even && Even.nat ? Even.nat : Even.nation ? Even.nation : "Even"}</div>
                                  </div>
                                  {Even && Even.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                                </div>
                                <div className={`${posArray[Even.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[14px] font-semibold`} > {posArray[Even.sid] ?? 0.00}</div>
                              </div>

                              <div className="space-y-[2px]">
                                <div className="flex justify-center items-center ">
                                  <p className="lg:text-[16px] text-[14px] font-semibold gray-text ">{Odd && Odd.b1 ? Odd.b1 : "0.00"}</p>
                                </div>
                                <div className="relative">
                                  <div className="even-background w-full text-center cursor-pointer py-3 rounded"
                                    onClick={() => this.handleBackOpen({ data: Odd, type: "Yes", odds: Odd.b1, nat: Odd.nat ? Odd.nat : Odd.nation, betFor: "oddEven" }, this.section1Ref)}>
                                    <div className="lg:text-[16px] text-[14px] font-bold leading-4 text-white capitalize">{Odd && Odd.nat ? Odd.nat : Odd.nation ? Odd.nation : "Odd"}</div>
                                  </div>
                                  {Odd && Odd.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                                </div>
                                <div className={`${posArray[Odd.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[14px] font-semibold`} > {posArray[Odd.sid] ?? 0.00}</div>
                              </div>

                            </div>

                            <div className=" py-2.5 lg:px-2.5 px-1.5 odds-bet space-y-3 border border-[#cf4b04]">

                              {/* <div className="px-2 relative">
                                <div className="lg:text-black text-[#52796f] flex justify-end items-center cursor-pointer " onClick={this.handleToggleModal3}>
                                  <FaInfoCircle className="hover:text-[#007bff]" size={16} />
                                </div>

                                {isModalOpen3 && (
                                  <div className="modal bg-black/60 absolute z-50 right-4">
                                    <div className="modal-content">
                                      <div className="md:w-44 w-24 text-center font-normal lg:text-[15px] text-[12px] py-1.5 white-text px-4 ">
                                        <span className="font-[500]">MIN</span>:{minStake} <span className="font-[500]">&nbsp; MAX</span>:{maxStake}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div> */}

                              <div className="space-y-[2px]">
                                <div className="flex justify-center items-center ">
                                  <p className="lg:text-[16px] text-[14px] font-semibold gray-text ">{Red && Red.b1 ? Red.b1 : "0.00"}</p>
                                </div>
                                <div className="relative">
                                  <div class=" even-background py-3 rounded flex justify-center items-center cursor-pointer space-x-0.5"
                                    onClick={() => this.handleBackOpen({ data: Red, type: "Yes", odds: Red.b1, nat: Red.nat ? Red.nat : Red.nation, betFor: "color" }, this.section1Ref)}>
                                    <span className="text-[#FE0000]">
                                      <AiFillHeart size={18} />
                                    </span>
                                    <span className="text-[#FE0000]">
                                      <ImDiamonds size={18} />
                                    </span>
                                  </div>
                                  {Red && Red.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                                </div>
                                <div className={`${posArray[Red.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[14px] font-semibold`} > {posArray[Red.sid] ?? 0.00}</div>
                              </div>

                              <div className="space-y-[2px]">
                                <div className="flex justify-center items-center ">
                                  <p className="lg:text-[16px] text-[14px] font-semibold gray-text ">{Black && Black.b1 ? Black.b1 : "0.00"}</p>
                                </div>
                                <div className="relative">
                                  <div class=" even-background py-3 rounded flex justify-center items-center cursor-pointer space-x-0.5"
                                    onClick={() => this.handleBackOpen({ data: Black, type: "Yes", odds: Black.b1, nat: Black.nat ? Black.nat : Black.nation, betFor: "color" }, this.section1Ref)}>
                                    <span className="text-[#000000]">
                                      <BsSuitSpadeFill size={18} />
                                    </span>
                                    <span className="text-[#000000]">
                                      <BsSuitClubFill size={18} />
                                    </span>
                                  </div>
                                  {Black && Black.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                                </div>
                                <div className={`${posArray[Black.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[14px] font-semibold`} > {posArray[Black.sid] ?? 0.00}</div>
                              </div>

                            </div>

                            <div className=" py-2.5 lg:px-2.5 px-1.5 odds-bet space-y-3 border border-[#cf4b04]">

                              {/* <div className="px-2 relative">
                                <div className="lg:text-black text-[#52796f] flex justify-end items-center cursor-pointer " onClick={this.handleToggleModal4}>
                                  <FaInfoCircle className="hover:text-[#007bff] " size={16} />
                                </div>

                                {isModalOpen4 && (
                                  <div className="modal bg-black/60 absolute z-50 right-4">
                                    <div className="modal-content">
                                      <div className="md:w-44 w-24 text-center font-normal lg:text-[15px] text-[12px] py-1.5 white-text px-4 ">
                                        <span className="font-[500]">MIN</span>:{minStake} <span className="font-[500]">&nbsp; MAX</span>:{maxStake}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div> */}

                              <div className="space-y-[2px]">
                                <div className="flex justify-center items-center ">
                                  <p className="lg:text-[16px] text-[14px]  font-semibold gray-text ">{Under7 && Under7.b1 ? Under7.b1 : "0.00"}</p>
                                </div>
                                <div className="relative">
                                  <div class=" even-background py-3 rounded flex justify-center items-center cursor-pointer space-x-0.5"
                                    onClick={() => this.handleBackOpen({ data: Under7, type: "Yes", odds: Under7.b1, nat: Under7.nat ? Under7.nat : Under7.nation, betFor: "color" }, this.section1Ref)}>
                                    <div className="lg:text-[16px] text-[14px] font-bold leading-4 text-white capitalize">{Under7 && Under7.nat ? Under7.nat : Under7.nation ? Under7.nation : "Under 7"}</div>
                                  </div>
                                  {Under7 && Under7.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                                </div>
                                <div className={`${posArray[Under7.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[14px] font-semibold`} > {posArray[Under7.sid] ?? 0.00}</div>
                              </div>

                              <div className="space-y-[2px]">
                                <div className="flex justify-center items-center ">
                                  <p className="lg:text-[16px] text-[14px] font-semibold gray-text ">{Over7 && Over7.b1 ? Over7.b1 : "0.00"}</p>
                                </div>
                                <div className="relative">
                                  <div class=" even-background py-3 rounded flex justify-center items-center cursor-pointer space-x-0.5"
                                    onClick={() => this.handleBackOpen({ data: Over7, type: "Yes", odds: Over7.b1, nat: Over7.nat ? Over7.nat : Over7.nation, betFor: "color" }, this.section1Ref)}>
                                    <div className="lg:text-[16px] text-[14px] font-bold leading-4 text-white capitalize">{Over7 && Over7.nat ? Over7.nat : Over7.nation ? Over7.nation : "Over 7"}</div>
                                  </div>
                                  {Over7 && Over7.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                                </div>
                                <div className={`${posArray[Over7.sid] < 0 ? "text-red-500" : "text-green-800"} flex justify-center items-center text-[14px] font-semibold`} > {posArray[Over7.sid] ?? 0.00}</div>
                              </div>

                            </div>

                          </div>

                        </div>

                        <div className="p-2 odds-bet mt-2 border border-[#cf4b04]">

                          {/* <div className="px-2 relative lg:block hidden">
                            <div className="text-black flex justify-end items-center cursor-pointer " onClick={this.handleToggleModal5}>
                              <FaInfoCircle className="hover:text-[#007bff] " size={18} />
                            </div>

                            {isModalOpen5 && (
                              <div className="modal bg-black/60 xl:w-[15%] lg:w-[30%] absolute z-50 right-4">
                                <div className="modal-content">
                                  <div className="flex justify-center items-center font-normal lg:text-[15px] text-[14px] py-1.5 white-text px-4 whitespace-nowrap">
                                    <span className="font-[500]">MIN</span>:{minStake} <span className="font-[500]">&nbsp; MAX</span>:{maxStake}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div> */}

                          {/* <div className="lg:hidden flex justify-end items-center font-normal text-[12px] py-1.5 black-text px-2 whitespace-nowrap">
                            <span className="font-[500]">MIN</span>:{minStake} <span className="font-[500]">&nbsp; MAX</span>:{maxStake}
                          </div> */}

                          <p className="flex justify-center items-center gray-text text-[16px] py-2 font-bold">12</p>
                          <div class="w-11/12 py-1 mx-auto grid grid-cols-7 lg:flex justify-center items-center md:space-x-1 space-x-0 gap-2.5 text-center ">
                            <span className="">
                              <div className="relative">
                                <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                  { data: CardA, type: "Yes", odds: CardA.b1, nat: CardA.nat ? CardA.nat : CardA.nation, betFor: "cards" }, this.section1Ref)}>
                                  <img src="/images/1.jpg" alt="" className="w-10 h-12" />
                                </div>
                                {CardA.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                              </div>
                              <p className={`${posArray[CardA.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[CardA.sid] ?? 0.00}</p>
                            </span>
                            <span className="">
                              <div className="relative">
                                <div className="justify-center flex-col flex items-center"
                                  onClick={() => this.handleBackOpen({ data: Card2, type: "Yes", odds: Card2.b1, nat: Card2.nat ? Card2.nat : Card2.nation, betFor: "cards" }, this.section1Ref)}>
                                  <img src="/images/2.jpg" alt="" className="w-10 h-12" />
                                </div>
                                {Card2.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                              </div>
                              <p className={`${posArray[Card2.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Card2.sid] ?? 0.00}</p>
                            </span>
                            <span className="">
                              <div className="relative">
                                <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                  { data: Card3, type: "Yes", odds: Card3.b1, nat: Card3.nat ? Card3.nat : Card3.nation, betFor: "cards" }, this.section1Ref)}>
                                  <img src="/images/3.jpg" alt="" className="w-10 h-12" />
                                </div>
                                {Card3.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                              </div>
                              <p className={`${posArray[Card3.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Card3.sid] ?? 0.00}</p>
                            </span>
                            <span className="">
                              <div className="relative">
                                <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                  { data: Card4, type: "Yes", odds: Card4.b1, nat: Card4.nat ? Card4.nat : Card4.nation, betFor: "cards" }, this.section1Ref)}>
                                  <img src="/images/3.jpg" alt="" className="w-10 h-12" />
                                </div>
                                {Card4.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                              </div>
                              <p className={`${posArray[Card4.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Card4.sid] ?? 0.00}</p>
                            </span>
                            <span className="">
                              <div className="relative">
                                <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                  { data: Card5, type: "Yes", odds: Card5.b1, nat: Card5.nat ? Card5.nat : Card5.nation, betFor: "cards" }, this.section1Ref)}>
                                  <img src="/images/5.jpg" alt="" className="w-10 h-12" />
                                </div>
                                {Card5.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                              </div>
                              <p className={`${posArray[Card5.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Card5.sid] ?? 0.00}</p>
                            </span>
                            <span className="">
                              <div className="relative">
                                <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                  { data: Card6, type: "Yes", odds: Card6.b1, nat: Card6.nat ? Card6.nat : Card6.nation, betFor: "cards" }, this.section1Ref)}>
                                  <img src="/images/6.jpg" alt="" className="w-10 h-12" />
                                </div>
                                {Card6.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                              </div>
                              <p className={`${posArray[Card6.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Card6.sid] ?? 0.00}</p>
                            </span>
                            <span className="">
                              <div className="relative">
                                <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                  { data: Card7, type: "Yes", odds: Card7.b1, nat: Card7.nat ? Card7.nat : Card7.nation, betFor: "cards" }, this.section1Ref)}>
                                  <img src="/images/7.jpg" alt="" className="w-10 h-12" />
                                </div>
                                {Card7.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                              </div>
                              <p className={`${posArray[Card7.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Card7.sid] ?? 0.00}</p>
                            </span>
                            <span className="">
                              <div className="relative">
                                <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                  { data: Card8, type: "Yes", odds: Card8.b1, nat: Card8.nat ? Card8.nat : Card8.nation, betFor: "cards" }, this.section1Ref)}>
                                  <img src="/images/8.jpg" alt="" className="w-10 h-12" />
                                </div>
                                {Card8.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                              </div>
                              <p className={`${posArray[Card8.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Card8.sid] ?? 0.00}</p>
                            </span>
                            <span className="">
                              <div className="relative">
                                <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                  { data: Card9, type: "Yes", odds: Card9.b1, nat: Card9.nat ? Card9.nat : Card9.nation, betFor: "cards" }, this.section1Ref)}>
                                  <img src="/images/9.jpg" alt="" className="w-10 h-12" />
                                </div>
                                {Card9.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                              </div>
                              <p className={`${posArray[Card9.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Card9.sid] ?? 0.00}</p>
                            </span>
                            <span className="">
                              <div className="relative">
                                <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                  { data: Card10, type: "Yes", odds: Card10.b1, nat: Card10.nat ? Card10.nat : Card10.nation, betFor: "cards" }, this.section1Ref)}>
                                  <img src="/images/10.jpg" alt="" className="w-10 h-12" />
                                </div>
                                {Card10.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                              </div>
                              <p className={`${posArray[Card10.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[Card10.sid] ?? 0.00}</p>
                            </span>
                            <span className="">
                              <div className="relative">
                                <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                  { data: CardJ, type: "Yes", odds: CardJ.b1, nat: CardJ.nat ? CardJ.nat : CardJ.nation, betFor: "cards" }, this.section1Ref)}>
                                  <img src="/images/11.jpg" alt="" className="w-10 h-12" />
                                </div>
                                {CardJ.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                              </div>
                              <p className={`${posArray[CardJ.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[CardJ.sid] ?? 0.00}</p>
                            </span>
                            <span className="">
                              <div className="relative">
                                <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                  { data: CardQ, type: "Yes", odds: CardQ.b1, nat: CardQ.nat ? CardQ.nat : CardQ.nation, betFor: "cards" }, this.section1Ref)}>
                                  <img src="/images/12.jpg" alt="" className="w-10 h-12" />
                                </div>
                                {CardQ.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                              </div>
                              <p className={`${posArray[CardQ.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[CardQ.sid] ?? 0.00}</p>
                            </span>
                            <span className="">
                              <div className="relative">
                                <div className="justify-center flex-col flex items-center" onClick={() => this.handleBackOpen(
                                  { data: CardK, type: "Yes", odds: CardK.b1, nat: CardK.nat ? CardK.nat : CardK.nation, betFor: "cards" }, this.section1Ref)}>
                                  <img src="/images/13.jpg" alt="" className="w-10 h-12" />
                                </div>
                                {CardK.gstatus === "ACTIVE" ? null : <BetLockedRoundedBmx />}
                              </div>
                              <p className={`${posArray[CardK.sid] < 0 ? "text-red-500" : "text-green-800"} font-semibold`} > {posArray[CardK.sid] ?? 0.00}</p>
                            </span>
                          </div>
                        </div>

                      </div>

                      <div className=" pb-2 bg-black/5 mt-0.5 border border-[#cf4b04]">
                        <RoundedTabBmx />
                        <div className="flex space-x-2 justify-end items-center py-1.5 px-2">
                          {result && result.length > 0 ? result.map((element, index) => (
                            <div onClick={() => this.handleResultModel(element)} className="result-color w-7 h-7 cursor-pointer flex justify-center items-center rounded-full shadow-md border border-gray-600 " >
                              <p className={`${element && element.result && element.result === "1" ? "text-[#F75500]" : element && element.result && element.result === "2" ? "text-[#FFF523]" : "text-[#33c6ff]"} font-[700] text-[14px]`}>{element && element.result && element.result === "1" ? "A" : element && element.result && element.result === "2" ? "B" : element && element.result && element.result === "3" ? "C" : "-"}</p>
                            </div>
                          )) : null}
                        </div>
                      </div>

                    </div>

                    <Desktop30
                      LoadingBet={LoadingBet}
                      betSlipData={this.state.betSlipData}
                      updateStackOnclick={this.updateStackOnclick}
                      inputChange={this.inputChange}
                      decreaseCount={this.decreaseCount}
                      increaseCount={this.increaseCount}
                      placeBet={this.placeBet}
                      handleClose={this.handleClose}
                      count={this.state.count}
                      timerKey={this.state.timerKey}
                      remainingTime={this.state.remainingTime}
                      handleCountdown={this.handleCountdown}
                      updateStake={this.updateStake}
                      betList={betList}
                      time={time}
                      backBetModal={backBetModal}
                    />

                  </div>

                ) : null}

                {activeTab === 2 ? (
                  <Mobile30
                    betList={betList}
                  />
                ) : null}


              </div>


            </div>
          </div>}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Amarakbaranthony);
