import {createStore, combineReducers,applyMiddleware} from 'redux'
import  {thunk}  from 'redux-thunk'
import { composeWithDevTools } from '@redux-devtools/extension';
import {authentication} from './redux/reducer/userReducer'

const rootReducer = combineReducers({
    authentication,
})

const intialState = {}
const middleware = [thunk]
const store = createStore(rootReducer,intialState,composeWithDevTools(applyMiddleware(...middleware)))

export default store