import React from "react";
import { connect } from "react-redux";
import FlipCountdown from '@rumess/react-flip-countdown';
import axios from "axios";
import io from 'socket.io-client';
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorTost from './casino_components/ErrorTost';
import "./casino.css";
import BetLocked from "./casino_components/BetLocked";
import PlaceBetMobileBmx from "./casino_components/PlaceBetMobileBmx";
import ResultModelBmx from "./casino_components/ResultModelBmx";
import RoundedTabBmx from "./casino_components/RoundedTabBmx";
import PageHeaderTheme from "./casino_components/PageHeaderTheme";
import PlaceBetMobileBmxBet from "./casino_components/PlaceBetMobileBmxBet";
import CasinoTab from "./casino_components/CasinoTab";
import Desktop30 from "./casino_components/Desktop30";
import Mobile30 from "./casino_components/Mobile30";
import Teenpattittestrules from './images/testrules.jpg';
import { httpPost, httpPostBet } from "../../../../middelware/Http";
import Loader from "../../../../component/Loader/Loader";
import { message } from "antd";


class Teenpattittest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backBetModal: false,
      offset: 0,
      betSlipData: {},
      fieldsbet: {},
      errorsbet: {},
      count: 0,
      time: 7,
      casinoData: [],
      ResultModel: false,
      backBetModalMobile: false,
      showLoader: false,
      result: {},
      casinoDetails: {},
      fetchData: "",
      socketURL: "",
      tvUrl: "",
      cacheURL: "",
      betStatus: "",
      cashinoStatus: "",
      eventId: "",
      minStake: "",
      maxStake: "",
      name: "",
      shortName: "teen9",
      betList: [],
      betresponse: [],
      setting: {},
      posArray: {},
      currentRoundId: 0,
      oddsDifference: 0,
      totalProfitLoss: 0,
      activeTab: 1,
      LoadingBet: false,
    };
    this.section1Ref = React.createRef();
    this.scrollTimeout = null;
    this.inputRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users && nextProps.users.betPlaceSucess) {
      return {
        ...nextProps,
        backBetModal: false,
        backBetModalMobile: false,
        betSlipData: {},
      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  increaseCount = () => {
    const increasedCount = (parseFloat(this.state.count) + 0.01).toFixed(2);
    this.setState({ count: parseFloat(increasedCount) });
  };

  decreaseCount = () => {
    const decreasedCount = (parseFloat(this.state.count) - 0.01).toFixed(2);
    this.setState({ count: parseFloat(decreasedCount) });
  };


  handleBackOpen = (data) => {
    if (this.scrollTimeout) {
      clearInterval(this.scrollTimeout);
    }
    this.setState({ backBetModal: true, backBetModalMobile: true, betSlipData: { ...data, stake: "0" }, count: data.odds, time: 7, });
    this.scrollTimeout = setInterval(() => {
      this.setState(prevState => ({ time: prevState.time - 1 }), () => {
        if (this.state.time <= 0) {
          clearInterval(this.scrollTimeout);
          this.setState({ backBetModal: false });
        }
      });
    }, 1000);

  };

  componentDidMount() {

    let requestData = {
      eventId: 3048,
    }
    this.getDidMountData(requestData)
    // this.socketConnection()
  }

  getDidMountData = async (requestData) => {
    this.setState({ showLoader: true })
    let getCasinoDetails = await httpPost(`casino/getDiamondCasinoByEventId`, requestData)
    if (!getCasinoDetails.error) {
      let casinoDetails = getCasinoDetails.data
      this.setState({
        casinoDetails: casinoDetails,
        fetchData: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : {},
        socketURL: casinoDetails && casinoDetails.socketURL ? casinoDetails.socketURL : {},
        tvUrl: casinoDetails && casinoDetails.videoUrl1 ? casinoDetails.videoUrl1 : "",
        cacheURL: casinoDetails && casinoDetails.cacheURL ? casinoDetails.cacheURL : {},
        betStatus: casinoDetails && casinoDetails.betStatus ? casinoDetails.betStatus : "",
        cashinoStatus: casinoDetails && casinoDetails.cashinoStatus ? casinoDetails.cashinoStatus : "",
        eventId: casinoDetails && casinoDetails?.eventId ? casinoDetails?.eventId : "",
        minStake: casinoDetails && casinoDetails.minStake ? casinoDetails.minStake : "",
        maxStake: casinoDetails && casinoDetails.maxStake ? casinoDetails.maxStake : "",
        name: casinoDetails && casinoDetails.name ? casinoDetails.name : "",
        shortName: casinoDetails && casinoDetails.shortName ? casinoDetails.shortName : "teen9",
        setting: casinoDetails && casinoDetails.setting ? casinoDetails.setting : {},
        oddsDifference: casinoDetails && casinoDetails.setting && casinoDetails.setting.oddsDifference ? casinoDetails.setting.oddsDifference : 0,
      })
      if (casinoDetails?.eventId) {
        this.betList(casinoDetails?.eventId)
      }

      // const socketPerm = casinoDetails.fetchData
      if (casinoDetails?.socketURL) {
        await this.callSocket(casinoDetails?.socketURL, casinoDetails?.shortName)
      }
      // else {
      //   await this.callCache(casinoDetails.cacheURL)
      // }
    } else {

    }
    this.setState({ showLoader: false })
  }

  callSocket = async (socketURL, shortName) => {
    const socket = io.connect(socketURL, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 99,
    });
    socket.emit('JoinRoom', shortName);
    socket.on(shortName, data => {
      this.setState({ currentRoundId: data && data.data && data.data.t1 && data.data.t1[0] && data.data.t1[0].mid ? data.data.t1[0].mid : 0 })
      this.setState({ casinoData: data })
      this.updatePos()
    })
  }

  // callCache = async (cacheUrl) => {
  //   await this.getMarketCacheUrl(cacheUrl);
  //   this.cacheInterval = setInterval(async () => {
  //     await this.getMarketCacheUrl(cacheUrl);
  //   }, 1000);
  // }


  // getMarketCacheUrl = async (cacheURL) => {
  //   let config = {
  //     method: 'GET',
  //     maxBodyLength: Infinity,
  //     url: cacheURL,
  //     headers: { 'Content-Type': 'application/json' },
  //   };
  //   axios.request(config).then((response) => {
  //     this.setState({ casinoData: response.data.data })
  //   }).catch((error) => {
  //     console.error('cache data url error ', error);
  //   });
  // }



  updatePos = async () => {
    const { betList, currentRoundId } = this.state;
    if (betList && betList.length > 0) {
      const filteredBets = betList.filter((element) => element.roundId == currentRoundId);
      let pushPos = {};
      filteredBets.forEach((bet) => {
        const posArray = bet.posArray;
        Object.entries(posArray).forEach(([key, value]) => {
          pushPos[key] = (pushPos[key] || 0) + Number(value);
        });
      });

      this.setState({ posArray: pushPos });
    }
  }


  betList = async (eventId) => {
    let betReq = {
      "eventId": eventId,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    };

    let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)
    let totalProfitLoss = 0
    let betList = getCasinoDetails?.data?.casinoBetData

    if (betList && betList.length > 0) {
      betList.map((bet, key) => {
        let profitLoss = "Not Declare"
        let profitLossCount = 0
        if (bet.isDeclare) {
          profitLossCount = bet.profitLoss ? bet.profitLoss : 0
          profitLoss = bet.profitLoss
        }
        totalProfitLoss += Number(profitLossCount)
        betList[key].profitLoss = profitLoss
      })
    }
    if (getCasinoDetails) {
      this.setState({ betList: betList, totalProfitLoss: totalProfitLoss })
    }
  }


  componentWillUnmount() {
    clearInterval(this.cacheInterval);
    clearTimeout(this.timer);
    if (this.inputRef.current) {
      this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
    }
  }

  handleResultModel = (data) => {
    this.setState({ ResultModel: true, result: data });

  };

  updateStackOnclic = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStackOnclick = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStake = (event) => {
    try {
      let { value } = event.target;
      let { betSlipData } = this.state;
      if (value.startsWith('0') && value.length > 1) {
        value = value.slice(1);
      }
      betSlipData.stake = value;
      this.setState({
        betSlipData: betSlipData,
      });
    } catch (error) {
      console.error('Error updating stake:', error);
    }
  };

  placeBet = async () => {
    const { casinoData } = this.state;
    this.setState({ LoadingBet: true })
    let { data } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let betObject = {
      "roundId": t1.mid,
      "sid": this.state.betSlipData.sid,
      "rate": this.state.count + "",
      "amount": Number(this.state.betSlipData.stake),
      "casinoType": this.state.shortName ? this.state.shortName : "teen9",
      "eventId": 3048,
      "betFor": this.state.betSlipData.betFor + "",
      "nat": this.state.betSlipData.nat + "",
    }

    const result = await httpPostBet("casino/casinoBetPlace", betObject);
    if (result) {
      if (result.error === false) {

        this.setState(
          {
            betresponse: result.data
          },
        );
        message.success(result.message)
        // toast.success(<ErrorTost message={result.message} />);
        this.betList(3048)
        // this.props.dispatch(userActions.getUserBalance());
      }
      else {
        // toast.error(<ErrorTost message={result.message} />);
        message.error(result.message)
      }
    }
    this.handleClose();
    this.setState({ LoadingBet: false })
  };


  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    if (value < 0 || isNaN(Number(value))) {
      value = 0;
    }

    if (value[0] === '0' && value.length > 1) {
      value = value.substring(1);
    }

    let { betSlipData } = this.state;
    betSlipData.stake = value;
    this.setState({
      betSlipData: betSlipData,
      [name]: Number(value),
    });
  };

  handleClose = () => {
    this.setState({ backBetModal: false, backBetModalMobile: false });
  };

  handleCloseResult = () => {
    this.setState({ ResultModel: false, });
  };

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  render() {
    let clientNotification = JSON.parse(localStorage.getItem('notification'))
    const { casinoData, name, shortName, ResultModel, time, count, backBetModal, tvUrl, betList, showLoader, LoadingBet, posArray, oddsDifference, totalProfitLoss, activeTab, minStake, maxStake } = this.state;
    let { data, result } = casinoData ? casinoData : {};

    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let Winner = data && data.t2 && data.t2[0] ? data.t2[0] : {};
    let Pair = data && data.t2 && data.t2[1] ? data.t2[1] : {};
    let Flush = data && data.t2 && data.t2[2] ? data.t2[2] : {};
    let Straight = data && data.t2 && data.t2[3] ? data.t2[3] : {};
    let Trio = data && data.t2 && data.t2[4] ? data.t2[4] : {};
    let StraightFlush = data && data.t2 && data.t2[5] ? data.t2[5] : {};


    return (
      <>

        {LoadingBet === true ?
          <>
            <PlaceBetMobileBmxBet
              betSlipData={this.state.betSlipData}
              time={time}
              count={count}
            />
          </> :
          <>
            {backBetModal && backBetModal === true ? (
              <PlaceBetMobileBmx
                betSlipData={this.state.betSlipData}
                updateStackOnclic={this.updateStackOnclic}
                placeBet={this.placeBet}
                handleClose={this.handleClose}
                section1Ref={this.section1Ref}
                time={time}
                inputChange={this.inputChange}
                decreaseCount={this.decreaseCount}
                increaseCount={this.increaseCount}
                count={count}
              />
            ) : null}
          </>
        }

        {showLoader ?
          <Loader active={showLoader === true} />
          :
          <div className={`w-full relative h-full   overflow-y-auto text-sm flex`}>
            {ResultModel &&
              ResultModel === true ? (
              <ResultModelBmx
                handleClose={this.handleCloseResult}
                name={name ? name : "Teenpatti Test"}
                shortName={shortName ? shortName : "teen9"}
                result={this.state.result}
              />
            ) : null}



            <div className="lg:flex block w-full scroll-md lg:space-x-1.5">

              {/* <div className="lg:w-[15%] w-full bg-fuchsia-200 lg:px-2 lg:block hidden"></div> */}


              <div className="lg:hidden block">
                <CasinoTab
                  activeTab={activeTab}
                  handleTabClick={this.handleTabClick}
                  ruleImage={Teenpattittestrules}
                  t1={t1}
                  totalBet={betList && betList.length ? betList.length : "0"}
                />
              </div>

              <div className="w-full lg:flex">

                {activeTab === 1 ? (
                  <div className="w-full lg:flex block">

                    <div className="lg:w-[70%] w-full ">
                      <PageHeaderTheme
                        PageTitle={name ? name : "Teenpatti Test"}
                        ruleImage={Teenpattittestrules}
                        t1={t1}
                      />

                      <div className="bg-black flex justify-between w-full relative md:text-sm text-[10px] xl:h-[460px] md:h-[300px] h-[200px]">
                        <iframe src={tvUrl ? tvUrl : null} title=" " className='relative w-full border border-[#cf4b04]' />
                        <div className="absolute top-0 left-0">
                          <div className="w-full p-1">
                            <div className="text-white text-[11px] font-semibold">TIGER</div>
                            <div className="flex space-x-1 justify-start">
                              <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="lg:h-10 h-7 lg:w-8 w-6 border-[1px] border-yellow-300" />
                              <img src={`/cards/${t1 && t1.C2 ? t1.C2 : 1}.png`} alt="card" className="lg:h-10 h-7 lg:w-8 w-6 border-[1px] border-yellow-300" />
                              <img src={`/cards/${t1 && t1.C3 ? t1.C3 : 1}.png`} alt="card" className="lg:h-10 h-7 lg:w-8 w-6 border-[1px] border-yellow-300" />
                            </div>
                            <div className="text-white text-[11px] font-semibold">LION</div>
                            <div className="flex space-x-1 justify-start">
                              <img src={`/cards/${t1 && t1.C4 ? t1.C4 : 1}.png`} alt="card" className="lg:h-10 h-7 lg:w-8 w-6 border-[1px] border-yellow-300" />
                              <img src={`/cards/${t1 && t1.C5 ? t1.C5 : 1}.png`} alt="card" className="lg:h-10 h-7 lg:w-8 w-6 border-[1px] border-yellow-300" />
                              <img src={`/cards/${t1 && t1.C6 ? t1.C6 : 1}.png`} alt="card" className="lg:h-10 h-7 lg:w-8 w-6 border-[1px] border-yellow-300" />
                            </div>
                            <div className="text-white text-[11px] font-semibold">DRAGON</div>
                            <div className="flex space-x-1 justify-start">
                              <img src={`/cards/${t1 && t1.C7 ? t1.C7 : 1}.png`} alt="card" className="lg:h-10 h-7 lg:w-8 w-6 border-[1px] border-yellow-300" />
                              <img src={`/cards/${t1 && t1.C8 ? t1.C8 : 1}.png`} alt="card" className="lg:h-10 h-7 lg:w-8 w-6 border-[1px] border-yellow-300" />
                              <img src={`/cards/${t1 && t1.C9 ? t1.C9 : 1}.png`} alt="card" className="lg:h-10 h-7 lg:w-8 w-6 border-[1px] border-yellow-300" />
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-end items-end absolute bottom-2 right-1">
                          <FlipCountdown
                            titlePosition='hidden'
                            hideYear
                            hideMonth
                            hideDay
                            hideHour
                            hideMinute
                            endAtZero
                            size='small'
                            endAt={new Date(Date.now() + 1000 * (t1 && t1.autotime ? t1.autotime : null)).toUTCString()}
                          />
                        </div>
                      </div>

                      <div className="border border-[#cf4b04] white-text divide-y divide-[#c7c8ca] mt-1 ">

                        {/* <div className="grid lg:grid-cols-7 grid-cols-6 text-center divide-x divide-[#c7c8ca]  font-semibold text-[12px] gray-text">
                          <div className="lg:col-span-4 col-span-2 w-full flex justify-center items-center py-[2px]"></div>
                          <div className="lg:col-span-3 col-span-4 w-full flex justify-center items-center py-[2px] betdetail-header">Back</div>
                        </div> */}

                        <div className="grid grid-cols-5 text-center divide-x divide-[#c7c8ca]  gray-text font-semibold lg:text-[16px] text-[14px]">
                          <div className="col-span-2 w-full flex justify-center items-center py-[3px]"></div>
                          <div className="grid grid-cols-3 col-span-3 divide-x divide-[#c7c8ca]" >
                            <div className="w-full flex justify-center items-center py-[3px] betdetail-header">Tiger</div>
                            <div className="w-full flex justify-center items-center py-[3px] betdetail-header">Lion</div>
                            <div className="w-full flex justify-center items-center py-[3px] betdetail-header">Dragon</div>
                          </div>
                        </div>


                        <div className="w-full text-center colour_back_odds ">
                          <div className="grid grid-cols-5 divide-x divide-[#c7c8ca]" >
                            <div className="col-span-2 h-full lg:text-[16px] text-[14px] white-bg capitalize text-gray-800 font-semibold py-2.5 px-2 w-full flex justify-between items-center">
                              <div>{Winner && Winner.nat ? Winner.nat : Winner.nation ? Winner.nation : "Winner "}</div>
                              {/* <div className={`${posArray[Winner.sid] < 0 ? "text-red-500" : "text-green-800"}`} > {posArray[Winner.sid]}</div> */}
                            </div>
                            <div className="grid grid-cols-3 col-span-3 divide-x divide-[#c7c8ca]" >
                              <div className="flex justify-end items-center divide-x divide-[#c7c8ca]  w-full">
                                {Winner && Winner.tstatus == "True" ?
                                  <div className="w-full betdetail-header cursor-pointer " onClick={() => this.handleBackOpen({ data: Winner, type: "Yes", odds: Winner.trate - oddsDifference, nat: Winner.nat ? Winner.nat : Winner.nation, sid: Winner.tsection, betFor: "tiger" }, this.section1Ref)}>
                                    <div className=" text-center py-2.5" >
                                      <span className="text-[14px] font-[600] gray-text">{Winner && Winner.trate ? (Winner.trate - oddsDifference) : "-"}</span><br />
                                    </div>
                                  </div> :
                                  <div className="w-full betdetail-header cursor-pointer " >
                                    <div className=" text-center py-2.5 relative">
                                      <span className="text-[14px] font-[600] gray-text">{Winner && Winner.trate ? (Winner.trate - oddsDifference) : "-"}</span><br />
                                      <BetLocked />
                                    </div>
                                  </div>}
                              </div>
                              <div className="flex justify-end items-center divide-x divide-[#c7c8ca]  w-full">
                                {Winner && Winner.lstatus === "True" ?
                                  <div className="w-full betdetail-header cursor-pointer " onClick={() => this.handleBackOpen({ data: Winner, type: "Yes", odds: Winner.lrate - oddsDifference, nat: Winner.nat ? Winner.nat : Winner.nation, sid: Winner.lsection, betFor: "lion" }, this.section1Ref)}>
                                    <div className=" text-center py-2.5" >
                                      <span className="text-[14px] font-[600] gray-text">{Winner && Winner.lrate ? (Winner.lrate - oddsDifference) : "-"}</span><br />
                                    </div>
                                  </div> :
                                  <div className="w-full betdetail-header cursor-pointer " >
                                    <div className=" text-center py-2.5 relative">
                                      <span className="text-[14px] font-[600] gray-text">{Winner && Winner.lrate ? (Winner.lrate - oddsDifference) : "-"}</span><br />
                                      <BetLocked />
                                    </div>
                                  </div>}
                              </div>
                              <div className="flex justify-end items-center divide-x divide-[#c7c8ca]  w-full">
                                {Winner && Winner.dstatus === "True" ?
                                  <div className="w-full betdetail-header cursor-pointer " onClick={() => this.handleBackOpen({ data: Winner, type: "Yes", odds: Winner.drate - oddsDifference, nat: Winner.nat ? Winner.nat : Winner.nation, sid: Winner.dsectionid, betFor: "dragon" }, this.section1Ref)}>
                                    <div className=" text-center py-2.5" >
                                      <span className="text-[14px] font-[600] gray-text">{Winner && Winner.drate ? (Winner.drate - oddsDifference) : "-"}</span><br />
                                    </div>
                                  </div> :
                                  <div className="w-full betdetail-header cursor-pointer " >
                                    <div className=" text-center py-2.5 relative">
                                      <span className="text-[14px] font-[600] gray-text">{Winner && Winner.drate ? (Winner.drate - oddsDifference) : "-"}</span><br />
                                      <BetLocked />
                                    </div>
                                  </div>}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="w-full text-center colour_back_odds ">
                          <div className="grid grid-cols-5 divide-x divide-[#c7c8ca]" >
                            <div className="col-span-2 h-full lg:text-[16px] text-[14px] white-bg capitalize text-gray-800 font-semibold py-2.5 px-2 w-full flex justify-between items-center">
                              <div>{Pair && Pair.nat ? Pair.nat : Pair.nation ? Pair.nation : "Pair "}</div>
                              {/* <div className={`${posArray[Pair.sid] < 0 ? "text-red-500" : "text-green-800"}`} > {posArray[Pair.sid]}</div> */}
                            </div>
                            <div className="grid grid-cols-3 col-span-3 divide-x divide-[#c7c8ca]" >
                              <div className="flex justify-end items-center divide-x divide-[#c7c8ca]  w-full">
                                {Pair && Pair.tstatus == "True" ?
                                  <div className="w-full betdetail-header cursor-pointer " onClick={() => this.handleBackOpen({ data: Pair, type: "Yes", odds: Pair.trate - oddsDifference, nat: Pair.nat ? Pair.nat : Pair.nation, sid: Pair.tsection, betFor: "tiger" }, this.section1Ref)}>
                                    <div className=" text-center py-2.5" >
                                      <span className="text-[14px] font-[600] gray-text">{Pair && Pair.trate ? (Pair.trate - oddsDifference) : "-"}</span><br />
                                    </div>
                                  </div> :
                                  <div className="w-full betdetail-header cursor-pointer " >
                                    <div className=" text-center py-2.5 relative">
                                      <span className="text-[14px] font-[600] gray-text">{Pair && Pair.trate ? (Pair.trate - oddsDifference) : "-"}</span><br />
                                      <BetLocked />
                                    </div>
                                  </div>}
                              </div>
                              <div className="flex justify-end items-center divide-x divide-[#c7c8ca]  w-full">
                                {Pair && Pair.lstatus === "True" ?
                                  <div className="w-full betdetail-header cursor-pointer " onClick={() => this.handleBackOpen({ data: Pair, type: "Yes", odds: Pair.lrate - oddsDifference, nat: Pair.nat ? Pair.nat : Pair.nation, sid: Pair.lsection, betFor: "lion" }, this.section1Ref)}>
                                    <div className=" text-center py-2.5" >
                                      <span className="text-[14px] font-[600] gray-text">{Pair && Pair.lrate ? (Pair.lrate - oddsDifference) : "-"}</span><br />
                                    </div>
                                  </div> :
                                  <div className="w-full betdetail-header cursor-pointer " >
                                    <div className=" text-center py-2.5 relative">
                                      <span className="text-[14px] font-[600] gray-text">{Pair && Pair.lrate ? (Pair.lrate - oddsDifference) : "-"}</span><br />
                                      <BetLocked />
                                    </div>
                                  </div>}
                              </div>
                              <div className="flex justify-end items-center divide-x divide-[#c7c8ca]  w-full">
                                {Pair && Pair.dstatus === "True" ?
                                  <div className="w-full betdetail-header cursor-pointer " onClick={() => this.handleBackOpen({ data: Pair, type: "Yes", odds: Pair.drate - oddsDifference, nat: Pair.nat ? Pair.nat : Pair.nation, sid: Pair.dsectionid, betFor: "dragon" }, this.section1Ref)}>
                                    <div className=" text-center py-2.5" >
                                      <span className="text-[14px] font-[600] gray-text">{Pair && Pair.drate ? (Pair.drate - oddsDifference) : "-"}</span><br />
                                    </div>
                                  </div> :
                                  <div className="w-full betdetail-header cursor-pointer " >
                                    <div className=" text-center py-2.5 relative">
                                      <span className="text-[14px] font-[600] gray-text">{Pair && Pair.drate ? (Pair.drate - oddsDifference) : "-"}</span><br />
                                      <BetLocked />
                                    </div>
                                  </div>}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="w-full text-center colour_back_odds ">
                          <div className="grid grid-cols-5 divide-x divide-[#c7c8ca]" >
                            <div className="col-span-2 h-full lg:text-[16px] text-[14px] white-bg capitalize text-gray-800 font-semibold py-2.5 px-2 w-full flex justify-between items-center">
                              <div>{Flush && Flush.nat ? Flush.nat : Flush.nation ? Flush.nation : "Flush "}</div>
                              {/* <div className={`${posArray[Flush.sid] < 0 ? "text-red-500" : "text-green-800"}`} > {posArray[Flush.sid]}</div> */}
                            </div>
                            <div className="grid grid-cols-3 col-span-3 divide-x divide-[#c7c8ca]" >
                              <div className="flex justify-end items-center divide-x divide-[#c7c8ca]  w-full">
                                {Flush && Flush.tstatus == "True" ?
                                  <div className="w-full betdetail-header cursor-pointer " onClick={() => this.handleBackOpen({ data: Flush, type: "Yes", odds: Flush.trate - oddsDifference, nat: Flush.nat ? Flush.nat : Flush.nation, sid: Flush.tsection, betFor: "tiger", betFor: "lion" }, this.section1Ref)}>
                                    <div className=" text-center py-2.5" >
                                      <span className="text-[14px] font-[600] gray-text">{Flush && Flush.trate ? (Flush.trate - oddsDifference) : "-"}</span><br />
                                    </div>
                                  </div> :
                                  <div className="w-full betdetail-header cursor-pointer " >
                                    <div className=" text-center py-2.5 relative">
                                      <span className="text-[14px] font-[600] gray-text">{Flush && Flush.trate ? (Flush.trate - oddsDifference) : "-"}</span><br />
                                      <BetLocked />
                                    </div>
                                  </div>}
                              </div>
                              <div className="flex justify-end items-center divide-x divide-[#c7c8ca]  w-full">
                                {Flush && Flush.lstatus === "True" ?
                                  <div className="w-full betdetail-header cursor-pointer " onClick={() => this.handleBackOpen({ data: Flush, type: "Yes", odds: Flush.lrate - oddsDifference, nat: Flush.nat ? Flush.nat : Flush.nation, sid: Flush.lsection, betFor: "lion" }, this.section1Ref)}>
                                    <div className=" text-center py-2.5" >
                                      <span className="text-[14px] font-[600] gray-text">{Flush && Flush.lrate ? (Flush.lrate - oddsDifference) : "-"}</span><br />
                                    </div>
                                  </div> :
                                  <div className="w-full betdetail-header cursor-pointer " >
                                    <div className=" text-center py-2.5 relative">
                                      <span className="text-[14px] font-[600] gray-text">{Flush && Flush.lrate ? (Flush.lrate - oddsDifference) : "-"}</span><br />
                                      <BetLocked />
                                    </div>
                                  </div>}
                              </div>
                              <div className="flex justify-end items-center divide-x divide-[#c7c8ca]  w-full">
                                {Flush && Flush.dstatus === "True" ?
                                  <div className="w-full betdetail-header cursor-pointer " onClick={() => this.handleBackOpen({ data: Flush, type: "Yes", odds: Flush.drate - oddsDifference, nat: Flush.nat ? Flush.nat : Flush.nation, sid: Flush.dsectionid, betFor: "dragon" }, this.section1Ref)}>
                                    <div className=" text-center py-2.5" >
                                      <span className="text-[14px] font-[600] gray-text">{Flush && Flush.drate ? (Flush.drate - oddsDifference) : "-"}</span><br />
                                    </div>
                                  </div> :
                                  <div className="w-full betdetail-header cursor-pointer " >
                                    <div className=" text-center py-2.5 relative">
                                      <span className="text-[14px] font-[600] gray-text">{Flush && Flush.drate ? (Flush.drate - oddsDifference) : "-"}</span><br />
                                      <BetLocked />
                                    </div>
                                  </div>}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="w-full text-center colour_back_odds ">
                          <div className="grid grid-cols-5 divide-x divide-[#c7c8ca]" >
                            <div className="col-span-2 h-full lg:text-[16px] text-[14px] white-bg capitalize text-gray-800 font-semibold py-2.5 px-2 w-full flex justify-between items-center">
                              <div>{Straight && Straight.nat ? Straight.nat : Straight.nation ? Straight.nation : "Straight "}</div>
                              {/* <div className={`${posArray[Straight.sid] < 0 ? "text-red-500" : "text-green-800"}`} > {posArray[Straight.sid]}</div> */}
                            </div>
                            <div className="grid grid-cols-3 col-span-3 divide-x divide-[#c7c8ca]" >
                              <div className="flex justify-end items-center divide-x divide-[#c7c8ca]  w-full">
                                {Straight && Straight.tstatus == "True" ?
                                  <div className="w-full betdetail-header cursor-pointer " onClick={() => this.handleBackOpen({ data: Straight, type: "Yes", odds: Straight.trate - oddsDifference, nat: Straight.nat ? Straight.nat : Straight.nation, sid: Straight.tsection, betFor: "tiger" }, this.section1Ref)}>
                                    <div className=" text-center py-2.5" >
                                      <span className="text-[14px] font-[600] gray-text">{Straight && Straight.trate ? (Straight.trate - oddsDifference) : "-"}</span><br />
                                    </div>
                                  </div> :
                                  <div className="w-full betdetail-header cursor-pointer " >
                                    <div className=" text-center py-2.5 relative">
                                      <span className="text-[14px] font-[600] gray-text">{Straight && Straight.trate ? (Straight.trate - oddsDifference) : "-"}</span><br />
                                      <BetLocked />
                                    </div>
                                  </div>}
                              </div>
                              <div className="flex justify-end items-center divide-x divide-[#c7c8ca]  w-full">
                                {Straight && Straight.lstatus === "True" ?
                                  <div className="w-full betdetail-header cursor-pointer " onClick={() => this.handleBackOpen({ data: Straight, type: "Yes", odds: Straight.lrate - oddsDifference, nat: Straight.nat ? Straight.nat : Straight.nation, sid: Straight.lsection, betFor: "lion" }, this.section1Ref)}>
                                    <div className=" text-center py-2.5" >
                                      <span className="text-[14px] font-[600] gray-text">{Straight && Straight.lrate ? (Straight.lrate - oddsDifference) : "-"}</span><br />
                                    </div>
                                  </div> :
                                  <div className="w-full betdetail-header cursor-pointer " >
                                    <div className=" text-center py-2.5 relative">
                                      <span className="text-[14px] font-[600] gray-text">{Straight && Straight.lrate ? (Straight.lrate - oddsDifference) : "-"}</span><br />
                                      <BetLocked />
                                    </div>
                                  </div>}
                              </div>
                              <div className="flex justify-end items-center divide-x divide-[#c7c8ca]  w-full">
                                {Straight && Straight.dstatus === "True" ?
                                  <div className="w-full betdetail-header cursor-pointer " onClick={() => this.handleBackOpen({ data: Straight, type: "Yes", odds: Straight.drate - oddsDifference, nat: Straight.nat ? Straight.nat : Straight.nation, sid: Straight.dsectionid, betFor: "dragon" }, this.section1Ref)}>
                                    <div className=" text-center py-2.5" >
                                      <span className="text-[14px] font-[600] gray-text">{Straight && Straight.drate ? (Straight.drate - oddsDifference) : "-"}</span><br />
                                    </div>
                                  </div> :
                                  <div className="w-full betdetail-header cursor-pointer " >
                                    <div className=" text-center py-2.5 relative">
                                      <span className="text-[14px] font-[600] gray-text">{Straight && Straight.drate ? (Straight.drate - oddsDifference) : "-"}</span><br />
                                      <BetLocked />
                                    </div>
                                  </div>}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="w-full text-center colour_back_odds ">
                          <div className="grid grid-cols-5 divide-x divide-[#c7c8ca]" >
                            <div className="col-span-2 h-full lg:text-[16px] text-[14px] white-bg capitalize text-gray-800 font-semibold py-2.5 px-2 w-full flex justify-between items-center">
                              <div>{Trio && Trio.nat ? Trio.nat : Trio.nation ? Trio.nation : "Trio "}</div>
                              {/* <div className={`${posArray[Trio.sid] < 0 ? "text-red-500" : "text-green-800"}`} > {posArray[Trio.sid]}</div> */}
                            </div>
                            <div className="grid grid-cols-3 col-span-3 divide-x divide-[#c7c8ca]" >
                              <div className="flex justify-end items-center divide-x divide-[#c7c8ca]  w-full">
                                {Trio && Trio.tstatus == "True" ?
                                  <div className="w-full betdetail-header cursor-pointer " onClick={() => this.handleBackOpen({ data: Trio, type: "Yes", odds: Trio.trate - oddsDifference, nat: Trio.nat ? Trio.nat : Trio.nation, sid: Trio.tsection, betFor: "tiger" }, this.section1Ref)}>
                                    <div className=" text-center py-2.5" >
                                      <span className="text-[14px] font-[600] gray-text">{Trio && Trio.trate ? (Trio.trate - oddsDifference) : "-"}</span><br />
                                    </div>
                                  </div> :
                                  <div className="w-full betdetail-header cursor-pointer " >
                                    <div className=" text-center py-2.5 relative">
                                      <span className="text-[14px] font-[600] gray-text">{Trio && Trio.trate ? (Trio.trate - oddsDifference) : "-"}</span><br />
                                      <BetLocked />
                                    </div>
                                  </div>}
                              </div>
                              <div className="flex justify-end items-center divide-x divide-[#c7c8ca]  w-full">
                                {Trio && Trio.lstatus === "True" ?
                                  <div className="w-full betdetail-header cursor-pointer " onClick={() => this.handleBackOpen({ data: Trio, type: "Yes", odds: Trio.lrate - oddsDifference, nat: Trio.nat ? Trio.nat : Trio.nation, sid: Trio.lsection, betFor: "lion" }, this.section1Ref)}>
                                    <div className=" text-center py-2.5" >
                                      <span className="text-[14px] font-[600] gray-text">{Trio && Trio.lrate ? (Trio.lrate - oddsDifference) : "-"}</span><br />
                                    </div>
                                  </div> :
                                  <div className="w-full betdetail-header cursor-pointer " >
                                    <div className=" text-center py-2.5 relative">
                                      <span className="text-[14px] font-[600] gray-text">{Trio && Trio.lrate ? (Trio.lrate - oddsDifference) : "-"}</span><br />
                                      <BetLocked />
                                    </div>
                                  </div>}
                              </div>
                              <div className="flex justify-end items-center divide-x divide-[#c7c8ca]  w-full">
                                {Trio && Trio.dstatus === "True" ?
                                  <div className="w-full betdetail-header cursor-pointer " onClick={() => this.handleBackOpen({ data: Trio, type: "Yes", odds: Trio.drate - oddsDifference, nat: Trio.nat ? Trio.nat : Trio.nation, sid: Trio.dsectionid, betFor: "dragon" }, this.section1Ref)}>
                                    <div className=" text-center py-2.5" >
                                      <span className="text-[14px] font-[600] gray-text">{Trio && Trio.drate ? (Trio.drate - oddsDifference) : "-"}</span><br />
                                    </div>
                                  </div> :
                                  <div className="w-full betdetail-header cursor-pointer " >
                                    <div className=" text-center py-2.5 relative">
                                      <span className="text-[14px] font-[600] gray-text">{Trio && Trio.drate ? (Trio.drate - oddsDifference) : "-"}</span><br />
                                      <BetLocked />
                                    </div>
                                  </div>}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="w-full text-center colour_back_odds ">
                          <div className="grid grid-cols-5 divide-x divide-[#c7c8ca]" >
                            <div className="col-span-2 h-full lg:text-[16px] text-[14px] white-bg capitalize text-gray-800 font-semibold py-2.5 px-2 w-full flex justify-between items-center">
                              <div>{StraightFlush && StraightFlush.nat ? StraightFlush.nat : StraightFlush.nation ? StraightFlush.nation : "StraightFlush "}</div>
                              {/* <div className={`${posArray[StraightFlush.sid] < 0 ? "text-red-500" : "text-green-800"}`} > {posArray[StraightFlush.sid]}</div> */}
                            </div>
                            <div className="grid grid-cols-3 col-span-3 divide-x divide-[#c7c8ca]" >
                              <div className="flex justify-end items-center divide-x divide-[#c7c8ca]  w-full">
                                {StraightFlush && StraightFlush.tstatus == "True" ?
                                  <div className="w-full betdetail-header cursor-pointer " onClick={() => this.handleBackOpen({ data: StraightFlush, type: "Yes", odds: StraightFlush.trate - oddsDifference, nat: StraightFlush.nat ? StraightFlush.nat : StraightFlush.nation, sid: StraightFlush.tsection, betFor: "tiger" }, this.section1Ref)}>
                                    <div className=" text-center py-2.5" >
                                      <span className="text-[14px] font-[600] gray-text">{StraightFlush && StraightFlush.trate ? (StraightFlush.trate - oddsDifference) : "-"}</span><br />
                                    </div>
                                  </div> :
                                  <div className="w-full betdetail-header cursor-pointer " >
                                    <div className=" text-center py-2.5 relative">
                                      <span className="text-[14px] font-[600] gray-text">{StraightFlush && StraightFlush.trate ? (StraightFlush.trate - oddsDifference) : "-"}</span><br />
                                      <BetLocked />
                                    </div>
                                  </div>}
                              </div>
                              <div className="flex justify-end items-center divide-x divide-[#c7c8ca]  w-full">
                                {StraightFlush && StraightFlush.lstatus === "True" ?
                                  <div className="w-full betdetail-header cursor-pointer " onClick={() => this.handleBackOpen({ data: StraightFlush, type: "Yes", odds: StraightFlush.lrate - oddsDifference, nat: StraightFlush.nat ? StraightFlush.nat : StraightFlush.nation, sid: StraightFlush.lsection, betFor: "lion" }, this.section1Ref)}>
                                    <div className=" text-center py-2.5" >
                                      <span className="text-[14px] font-[600] gray-text">{StraightFlush && StraightFlush.lrate ? (StraightFlush.lrate - oddsDifference) : "-"}</span><br />
                                    </div>
                                  </div> :
                                  <div className="w-full betdetail-header cursor-pointer " >
                                    <div className=" text-center py-2.5 relative">
                                      <span className="text-[14px] font-[600] gray-text">{StraightFlush && StraightFlush.lrate ? (StraightFlush.lrate - oddsDifference) : "-"}</span><br />
                                      <BetLocked />
                                    </div>
                                  </div>}
                              </div>
                              <div className="flex justify-end items-center divide-x divide-[#c7c8ca]  w-full">
                                {StraightFlush && StraightFlush.dstatus === "True" ?
                                  <div className="w-full betdetail-header cursor-pointer " onClick={() => this.handleBackOpen({ data: StraightFlush, type: "Yes", odds: StraightFlush.drate - oddsDifference, nat: StraightFlush.nat ? StraightFlush.nat : StraightFlush.nation, sid: StraightFlush.dsectionid, betFor: "dragon" }, this.section1Ref)}>
                                    <div className=" text-center py-2.5" >
                                      <span className="text-[14px] font-[600] gray-text">{StraightFlush && StraightFlush.drate ? (StraightFlush.drate - oddsDifference) : "-"}</span><br />
                                    </div>
                                  </div> :
                                  <div className="w-full betdetail-header cursor-pointer " >
                                    <div className=" text-center py-2.5 relative">
                                      <span className="text-[14px] font-[600] gray-text">{StraightFlush && StraightFlush.drate ? (StraightFlush.drate - oddsDifference) : "-"}</span><br />
                                      <BetLocked />
                                    </div>
                                  </div>}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div className=" pb-2 bg-black/5 mt-2 border border-[#cf4b04]">
                        <RoundedTabBmx />
                        <div className="flex space-x-2 justify-end items-center py-1.5 px-2">
                          {result && result.length > 0 ? result.map((element, index) => (
                            <div onClick={() => this.handleResultModel(element)} className="result-color w-7 h-7 cursor-pointer flex justify-center items-center rounded-full shadow-md border border-gray-700" >
                              <p className={`font-[700] text-[13px] ${element?.result === "31" ? "text-[#33c6ff]" : element?.result === "11" ? "text-[#F75500] " : element?.result === "21" ? "text-[#FFF523] " : "text-white"}`}>
                                {element && element.result === '11' ? "T" : element && element.result === '21' ? "L" : element && element.result === '31' ? "D" : "-"}
                              </p>
                            </div>
                          )) : null}
                        </div>
                      </div>

                    </div>

                    <Desktop30
                      LoadingBet={LoadingBet}
                      betSlipData={this.state.betSlipData}
                      updateStackOnclick={this.updateStackOnclick}
                      inputChange={this.inputChange}
                      decreaseCount={this.decreaseCount}
                      increaseCount={this.increaseCount}
                      placeBet={this.placeBet}
                      handleClose={this.handleClose}
                      count={this.state.count}
                      timerKey={this.state.timerKey}
                      remainingTime={this.state.remainingTime}
                      handleCountdown={this.handleCountdown}
                      updateStake={this.updateStake}
                      betList={betList}
                      time={time}
                      backBetModal={backBetModal}
                    />

                  </div>

                ) : null}


                {activeTab === 2 ? (
                  <Mobile30
                    betList={betList}
                  />
                ) : null}


              </div>



            </div>
          </div >}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Teenpattittest);