import bet_pnl from '../../../Assest/images/rightsidebar/bet_pnl.webp'
import bet_pnl_white from '../../../Assest/images/rightsidebar/bet_pnl_white.webp'
import change_no from '../../../Assest/images/rightsidebar/change_no.webp'
import change_no_white from '../../../Assest/images/rightsidebar/change_no_white.webp'
import open_bets from '../../../Assest/images/rightsidebar/open_bets.webp'
import open_bets_white from '../../../Assest/images/rightsidebar/open_bets_white.webp'
import profile from '../../../Assest/images/rightsidebar/profile.webp'
import profile_white from '../../../Assest/images/rightsidebar/profile_white.webp'
import reset_password from '../../../Assest/images/rightsidebar/reset_password.webp'
import reset_password_white from '../../../Assest/images/rightsidebar/reset_password_white.webp'
import settings from '../../../Assest/images/rightsidebar/settings.webp'
import settings_white from '../../../Assest/images/rightsidebar/settings_white.webp'
import transactions from '../../../Assest/images/rightsidebar/transactions.webp'
import transactions_white from '../../../Assest/images/rightsidebar/transactions_white.webp'
import wallet from '../../../Assest/images/rightsidebar/wallet.webp'
import wallet_white from '../../../Assest/images/rightsidebar/wallet_white.webp'



export const SidebarRightList = [
  {
    title: 'Betting P&L',
    link: '/app/profitloss',
    icon1: bet_pnl,
    icon2: bet_pnl_white
  },
  {
    title: 'D&W Transactions',
    link: '/app/deposit-widrawal-transaction',
    icon1: transactions,
    icon2: transactions_white,
  },
  {
    title: 'Account Statement',
    link: '/app/account-statment',
    icon1: transactions,
    icon2: transactions_white,
  },
  {
    title: 'Profile',
    link: '/app/profile',
    icon1: profile,
    icon2: profile_white,
  },
  {
    title: 'My Wallet',
    link: '/app/wallet',
    icon1: wallet,
    icon2: wallet_white,
  },
  {
    title: 'Reset Password',
    link: '/app/reset-password',
    icon1: reset_password,
    icon2: reset_password_white
  },
  {
    title: 'Change Mobile No',
    link: "/app/change-mobileno",
    icon1: change_no,
    icon2: change_no_white
  },
  {
    title: 'Open Bets',
    link: '/app/bets',
    icon1: open_bets_white,
    icon2: open_bets_white
  },
  {
    title: 'Stake Setting',
    link: '/app/setting',
    icon1: settings,
    icon2: settings_white,
  },
  // {
  //   title: 'Notification',
  //   link: '/affillate-partner',
  //   icon1: affiliate,
  //   icon2: affiliatewhite,
  // },
  // {
  //   title: 'Rules & Regulations',
  //   link: '/term-conditon',
  //   icon1: termsandconditions,
  //   icon2: termsandconditionswhite
  // },
  // {
  //   title: 'Feedback',
  //   link: '#',
  //   icon1: onlinebettingapp,
  //   icon2: onlinebettingapp
  // },

];