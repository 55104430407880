import React from 'react';
import BetPlaceDesktopCasinoloader from './BetPlaceDesktopCasinoloader';
import BetPlaceDesktopCasino from './BetPlaceDesktopCasino';
import BetListTableBmx from './BetListTableBmx';
import cardA from "../../Casino/images/Acard.png";
import card2 from "../../Casino/images/2card.png";
import card3 from "../../Casino/images/card3.png";
import card4 from "../../Casino/images/card4.png";
import card6 from "../../Casino/images/card6.png";
import card10 from "../../Casino/images/card10.png";
import cardK from "../../Casino/images/cardK.png";

import ball1 from "../../Casino/images/1ball.png";
import ball2 from "../../Casino/images/2ball.png";
import ball3 from "../../Casino/images/3ball.png";
import ball4 from "../../Casino/images/4ball.png";
import ball6 from "../../Casino/images/6ball.png";
import ball0 from "../../Casino/images/0ball.png";
import wicket from "../../Casino/images/wicket.png";



export default function Desktop30(props) {
    const { LoadingBet, betSlipData, updateStackOnclick, inputChange, time, decreaseCount, increaseCount, placeBet, handleClose, count, timerKey, remainingTime, handleCountdown, updateStake, betList, backBetModal, eventId } = props;

    return (
        <div className="lg:w-[30%] w-full  lg:px-1.5 lg:block hidden">

            <div className="red-header white-text text-[14px] px-2 py-[6px] rounded-t-[4px]">
                <span className="font-medium tracking-wide">
                    Place Bet
                </span>
            </div>

            {LoadingBet === true ?
                <>
                    <BetPlaceDesktopCasinoloader
                        betSlipData={betSlipData}
                        time={time}
                        count={count}
                    />
                </> :
                <>
                    {backBetModal && backBetModal === true ? (
                        <BetPlaceDesktopCasino
                            betSlipData={betSlipData}
                            updateStackOnclick={updateStackOnclick}
                            inputChange={inputChange}
                            decreaseCount={decreaseCount}
                            increaseCount={increaseCount}
                            placeBet={placeBet}
                            handleClose={handleClose}
                            count={count}
                            timerKey={timerKey}
                            remainingTime={remainingTime}
                            handleCountdown={handleCountdown}
                            updateStake={updateStake}
                        />
                    ) : null}
                </>
            }

            <div className="red-header white-text text-[14px] px-2 py-[6px] rounded-t-[4px] mt-3">
                <span className="font-medium tracking-wide">
                    My Bet
                </span>
            </div>

            <div className="pb-20">

                <div className="space-y-[1px] bg-gray-200 pb-1 rounded">
                    {/* <MyBetTabBmx totalProfitLoss={totalProfitLoss} /> */}
                    <BetListTableBmx betList={betList} />
                </div>
                {eventId == 3060 ?
                    <div className='w-full'>
                        <div className="red-header white-text text-[14px] px-2 py-[6px] font-medium tracking-wide mt-3">
                            ENGLAND vs RSA Inning's Card Rules
                        </div>
                        <div className='overflow-x-auto w-full'>
                            <table className="table w-full bg-[#f7f7f7] black-text border border-[#c7c8ca] divide-y divide-[#c7c8ca]">
                                <thead>
                                    <tr className=''>
                                        <th className="text-left">Cards</th>
                                        <th className="text-center">Count</th>
                                        <th className="text-right">Value</th>
                                    </tr>
                                </thead>
                                <tbody className='divide-y divide-[#c7c8ca] '>
                                    <tr>
                                        <td className='flex justify-start items-center'><img src={cardA} className='super-over-rule-img' /><span className="ms-2">X</span></td>
                                        <td className="text-center">5</td>
                                        <td className="flex justify-end items-center"><img src={ball1} className='super-over-rule-img' /></td>
                                    </tr>
                                    <tr>
                                        <td className='flex justify-start items-center'><img src={card2} className='super-over-rule-img' /><span className="ms-2">X</span></td>
                                        <td className="text-center">5</td>
                                        <td className="flex justify-end items-center"><img src={ball2} className='super-over-rule-img' /></td>
                                    </tr>
                                    <tr>
                                        <td className='flex justify-start items-center'><img src={card3} className='super-over-rule-img' /><span className="ms-2">X</span></td>
                                        <td className="text-center">5</td>
                                        <td className="flex justify-end items-center"><img src={ball3} className='super-over-rule-img' /></td>
                                    </tr>
                                    <tr>
                                        <td className='flex justify-start items-center'><img src={card4} className='super-over-rule-img' /><span className="ms-2">X</span></td>
                                        <td className="text-center">5</td>
                                        <td className="flex justify-end items-center"><img src={ball4} className='super-over-rule-img' /></td>
                                    </tr>
                                    <tr>
                                        <td className='flex justify-start items-center'><img src={card6} className='super-over-rule-img' /><span className="ms-2">X</span></td>
                                        <td className="text-center">5</td>
                                        <td className="flex justify-end items-center"><img src={ball6} className='super-over-rule-img' /></td>
                                    </tr>
                                    <tr>
                                        <td className='flex justify-start items-center'><img src={card10} className='super-over-rule-img' /><span className="ms-2">X</span></td>
                                        <td className="text-center">5</td>
                                        <td className="flex justify-end items-center"><img src={ball0} className='super-over-rule-img' /></td>
                                    </tr>
                                    <tr>
                                        <td className='flex justify-start items-center'><img src={cardK} className='super-over-rule-img' /><span className="ms-2">X</span></td>
                                        <td className="text-center">5</td>
                                        <td className="text-end flex justify-end items-center">
                                            <span>Wicket</span>
                                            <img src={wicket} className='super-over-rule-img' />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    :
                    eventId == 3051 ?
                        <div className='w-full'>
                            <div className="red-header white-text text-[14px] px-2 py-[6px] font-medium tracking-wide mt-3">
                                Rules
                            </div>
                            <div className='overflow-x-auto w-full'>
                                <table className="table w-full bg-[#f7f7f7] darktext text-[14px] border border-[#c7c8ca] divide-y divide-[#c7c8ca]">
                                    <thead>
                                        <tr className='bg-white'>
                                            <th colspan="3" class="text-center font-[600]">Bonus 1 (2 Cards Bonus)</th>
                                        </tr>
                                    </thead>
                                    <tbody className='divide-y divide-[#c7c8ca]'>
                                        <tr className='divide-x divide-[#c7c8ca] '>
                                            <td className='text-left'><span className="ms-2">Pair (2-10)	</span></td>
                                            <td className="text-left">1 To 3 </td>
                                        </tr>
                                        <tr className='divide-x divide-[#c7c8ca] '>
                                            <td className='text-left'><span className="ms-2">A/Q or A/J Off Suited		</span></td>
                                            <td className="text-left">1 To 5 </td>
                                        </tr>
                                        <tr className='divide-x divide-[#c7c8ca] '>
                                            <td className='text-left'><span className="ms-2">Pair (JQK)		</span></td>
                                            <td className="text-left">1 To 10 </td>
                                        </tr>
                                        <tr className='divide-x divide-[#c7c8ca] '>
                                            <td className='text-left'><span className="ms-2">A/K Off Suited		</span></td>
                                            <td className="text-left">1 To 15 </td>
                                        </tr>
                                        <tr className='divide-x divide-[#c7c8ca] '>
                                            <td className='text-left'><span className="ms-2">A/Q or A/J Suited		</span></td>
                                            <td className="text-left">1 To 20 </td>
                                        </tr>
                                        <tr className='divide-x divide-[#c7c8ca] '>
                                            <td className='text-left'><span className="ms-2">A/K Suited		</span></td>
                                            <td className="text-left">1 To 25 </td>
                                        </tr>
                                        <tr className='divide-x divide-[#c7c8ca] '>
                                            <td className='text-left'><span className="ms-2">A/A		</span></td>
                                            <td className="text-left">1 To 30 </td>
                                        </tr>

                                    </tbody>
                                </table>
                                <table className="table w-full bg-[#f7f7f7] darktext text-[14px] border-r border-l border-b border-[#c7c8ca] divide-y divide-[#c7c8ca]">
                                    <thead>
                                        <tr className='bg-white'>
                                            <th colspan="3" class="text-center font-[600]">Bonus 2 (7 Cards Bonus) </th>
                                        </tr>
                                    </thead>
                                    <tbody className='divide-y divide-[#c7c8ca]'>
                                        <tr className='divide-x divide-[#c7c8ca] '>
                                            <td className='text-left'><span className="ms-2">Three of a Kind		</span></td>
                                            <td className="text-left">1 To 3 </td>
                                        </tr>
                                        <tr className='divide-x divide-[#c7c8ca] '>
                                            <td className='text-left'><span className="ms-2">Three of a Kind		</span></td>
                                            <td className="text-left">1 To 4 </td>
                                        </tr>
                                        <tr className='divide-x divide-[#c7c8ca] '>
                                            <td className='text-left'><span className="ms-2">Flush	</span></td>
                                            <td className="text-left">1 To 6 </td>
                                        </tr>
                                        <tr className='divide-x divide-[#c7c8ca] '>
                                            <td className='text-left'><span className="ms-2">Full House		</span></td>
                                            <td className="text-left">1 To 8 </td>
                                        </tr>
                                        <tr className='divide-x divide-[#c7c8ca] '>
                                            <td className='text-left'><span className="ms-2">Four of a Kind		</span></td>
                                            <td className="text-left">1 To 30 </td>
                                        </tr>
                                        <tr className='divide-x divide-[#c7c8ca] '>
                                            <td className='text-left'><span className="ms-2">Straight Flush		</span></td>
                                            <td className="text-left">1 To 50 </td>
                                        </tr>
                                        <tr className='divide-x divide-[#c7c8ca] '>
                                            <td className='text-left'><span className="ms-2">Royal Flush			</span></td>
                                            <td className="text-left">1 To 100 </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        :
                        eventId == 3030 ?
                            <div className='w-full'>
                                <div className="red-header white-text text-[14px] px-2 py-[6px] font-medium tracking-wide mt-3">
                                    Rules
                                </div>
                                <div className='overflow-x-auto w-full'>
                                    <table className="table w-full bg-[#f7f7f7] darktext text-[14px] border border-[#c7c8ca] divide-y divide-[#c7c8ca]">
                                        <thead>
                                            <tr className='bg-white'>
                                                <th colspan="3" class="text-center font-[600]">Pair Plus </th>
                                            </tr>
                                        </thead>
                                        <tbody className='divide-y divide-[#c7c8ca]'>
                                            <tr className='divide-x divide-[#c7c8ca] '>
                                                <td className='text-left'><span className="ms-2">Pair</span></td>
                                                <td className="text-left">1 To 1 </td>
                                            </tr>
                                            <tr className='divide-x divide-[#c7c8ca] '>
                                                <td className='text-left'><span className="ms-2">Flush	</span></td>
                                                <td className="text-left">1 To 4 </td>
                                            </tr>
                                            <tr className='divide-x divide-[#c7c8ca] '>
                                                <td className='text-left'><span className="ms-2">Straight</span></td>
                                                <td className="text-left">1 To 6 </td>
                                            </tr>
                                            <tr className='divide-x divide-[#c7c8ca] '>
                                                <td className='text-left'><span className="ms-2">Trio	</span></td>
                                                <td className="text-left">1 To 30 </td>
                                            </tr>
                                            <tr className='divide-x divide-[#c7c8ca] '>
                                                <td className='text-left'><span className="ms-2">Straight Flush			</span></td>
                                                <td className="text-left">1 To 40 </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            :
                            eventId == 3049 ?
                                <div className='w-full'>
                                    <div className="red-header white-text text-[14px] px-2 py-[6px] font-medium tracking-wide mt-3">
                                        Rules
                                    </div>
                                    <div className='overflow-x-auto w-full'>
                                        <table className="table w-full bg-[#f7f7f7] darktext text-[14px] border border-[#c7c8ca] divide-y divide-[#c7c8ca]">
                                            <thead>
                                                <tr className='bg-white'>
                                                    <th colspan="3" class="text-center font-[600]">Pair Plus </th>
                                                </tr>
                                            </thead>
                                            <tbody className='divide-y divide-[#c7c8ca]'>
                                                <tr className='divide-x divide-[#c7c8ca] '>
                                                    <td className='text-left'><span className="ms-2">Pair</span></td>
                                                    <td className="text-left">1 To 1 </td>
                                                </tr>
                                                <tr className='divide-x divide-[#c7c8ca] '>
                                                    <td className='text-left'><span className="ms-2">Flush	</span></td>
                                                    <td className="text-left">1 To 4 </td>
                                                </tr>
                                                <tr className='divide-x divide-[#c7c8ca] '>
                                                    <td className='text-left'><span className="ms-2">Straight</span></td>
                                                    <td className="text-left">1 To 6 </td>
                                                </tr>
                                                <tr className='divide-x divide-[#c7c8ca] '>
                                                    <td className='text-left'><span className="ms-2">Trio	</span></td>
                                                    <td className="text-left">1 To 30 </td>
                                                </tr>
                                                <tr className='divide-x divide-[#c7c8ca] '>
                                                    <td className='text-left'><span className="ms-2">Straight Flush			</span></td>
                                                    <td className="text-left">1 To 40 </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                :
                                null

                }
            </div>

        </div>
    );
}