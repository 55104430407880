import React, { useState } from 'react';
import RulesModelBmx from "./RulesModelBmx";
import { IoInformationCircle } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';


export default function PageHeaderTheme(props) {
    const { PageTitle, t1, ruleImage, minStake, maxStake } = props;
    const [rulesModal, setRulesModal] = useState(false);

    const navigate = useNavigate()

    const handleOpenRules = () => {
        setRulesModal(true)
    };

    // const handleClose = () =>{
    //     setRulesModal(false)
    // }

    return (
        <div className='w-full '>
            {rulesModal ?
                <RulesModelBmx ruleImage={ruleImage} PageTitle={PageTitle} setRulesModal={setRulesModal} />
                : null}
            <div className="bg-[#f36b21] lg:flex justify-between items-center p-[6px] hidden">
                <span className="flex space-x-2 text-white w-full whitespace-nowrap">
                    <span className="uppercase font-bold text-center text-xs lg:text-base">
                        {PageTitle}
                    </span>
                    <span onClick={() => handleOpenRules()} className='font-medium uppercase text-[12px] cursor-pointer underline mt-[2px]'>Rules</span>
                    <span className='px-3 font-medium uppercase text-[12px] cursor-pointer underline mt-[2px]' onClick={() => navigate('/app/casino')}>back</span>
                </span>
                <span className="text-white text-sm lg:text-[16px] whitespace-nowrap flex font-medium justify-end items-center space-x-1">
                    <span>
                        Round ID : {1 && t1.mid ? t1.mid : null}
                        {minStake && maxStake && (
                            <span className="font-[500] white-text text-[14px]">
                                <span> |&nbsp;MIN</span>:{minStake} |<span>&nbsp; MAX</span>:{maxStake}
                            </span>
                        )}
                    </span>
                </span>
            </div>

            <div className="red-header lg:hidden flex justify-between items-center py-2 px-1.5">
                <span className="space-x-2 text-white w-full whitespace-nowrap">
                    <span className="uppercase font-bold text-center text-[14px]">
                        {PageTitle}
                    </span>
                    <span onClick={() => handleOpenRules()} className='font-medium lg:block hidden capitalize text-[12px] cursor-pointer underline'>Rules</span>
                </span>
                <span className="text-white text-sm lg:text-[16px] whitespace-nowrap lg:flex hidden font-medium justify-end items-center space-x-1">
                    <span>
                        Round ID : {1 && t1.mid ? t1.mid : null}
                    </span>
                </span>
                <div>                {minStake && maxStake && (
                    <span className='font-[500] whitespace-nowrap white-text text-[14px]'>
                        <span>MIN:{minStake} | MAX</span>:{maxStake}
                    </span>
                )}
                </div>
            </div>

        </div>
    );
}