import React from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { FaTimes } from 'react-icons/fa';

export default function PlaceBetMobileBmxBet(props) {
    const { betSlipData, count, time } = props;
    return (

        <div className='w-full z-50 top-0 fixed justify-center items-center px-2 h-screen bg-black/30 lg:hidden block '>
            <div className='flex justify-center items-center w-full'>
                <div className={`lg:w-[41%] w-full z-50 mx-auto ${betSlipData.type === "No"
                    ? "match_bg_pink_index-0 "
                    : "match_bg_blue_index-0"
                    } `}>
                    <div className=" w-full h-full flex justify-between rules-header p-2 items-center border-b-[1px] border-white">
                        <h2 className="text-white text-[16px] font-medium">
                            Placebet
                        </h2>
                        <div className='text-center'>
                            <FaTimes className='text-white cursor-pointer ' size={20} />
                        </div>
                    </div>
                    <div class="flex justify-between items-center space-x-1 text-black p-1.5">
                        <div className='p-1 font-[600] text-[14px]'>
                            {betSlipData.nat}
                        </div>
                        <div className=" rounded flex divide-x divide-gray-300 white-text">
                            <span className="py-[3px] px-2 font-bold cursor-pointer bg-[#52796f]/60 text-[18px]" >-</span>
                            <span className="py-[3px] px-6 bg-white text-sm black-text">
                                {betSlipData && betSlipData.odds ? betSlipData.odds : betSlipData.rate ? betSlipData.rate : ""}
                            </span>
                            <span className="py-[3px] px-2 font-bold cursor-pointer bg-[#52796f]/60 text-[18px]">+</span>
                        </div>
                        {/* <div className='bg-black white-text px-2 py-1'>
                            <CountdownCircleTimer
                                isPlaying
                                duration={7}
                                colors={['#000000']}
                                colorsTime={[7]}
                                size={20}
                                strokeWidth={0}
                                className="bg-white p-0"

                            >
                                {({ remainingTime }) => remainingTime === 7 ? time : time}
                            </CountdownCircleTimer>
                        </div> */}
                    </div>

                    <div className="grid grid-cols-3 gap-2 px-2 py-2.5 pb-4">
                        <div className="bg-white flex divide-x divide-gray-300 border-[2px] border-b-[#555]  border-r-[#555] border-t-black border-l-black">
                            <input className="focus:outline-none text-sm w-full text-start px-2 py-[2px]" type="number" placeholder="0" value={betSlipData.stake} />
                        </div>
                        <button className="rules-header p-1.5 text-sm text-white font-semibold flex justify-center items-center">Submit
                            <div className="px-4 white-text h-2 flex justify-center items-center cursor-pointer border-[#d4d3e8] rounded-none font-extrabold text-sm" type="submit">
                                <div className=" flex items-center justify-center absolute bg-transparent">
                                    <div className="flex items-center justify-center bg-transparent w-full">
                                        <div className="w-5 h-5 rounded-full animate-spin 
                        border-[5px] border-solid border-[#ffffff] border-t-transparent"></div>
                                    </div>
                                </div>
                            </div>

                        </button>
                        <div className='font-medium text-base text-center'>
                            0
                        </div>
                        <button class="p-1.5 text-sm red-header text-white font-bold" >500</button>
                        <button class="p-1.5 text-sm red-header text-white font-bold">1000</button>
                        <button class="p-1.5 text-sm red-header text-white font-bold" >2000</button>
                        <button class="p-1.5 text-sm red-header text-white font-bold" >3000</button>
                        <button class="p-1.5 text-sm red-header text-white font-bold" >4000</button>
                        <button class="p-1.5 text-sm red-header text-white font-bold" >5000</button>
                        <button class="p-1.5 text-sm red-header text-white font-bold" >10000</button>
                        <button class="p-1.5 text-sm red-header text-white font-bold" >20000</button>
                    </div>

                </div>
            </div>
        </div>


        // <div className='lg:hidden block'>
        //     <div className='bg-[#E9E9E9]  ' >
        //         <div className='flex justify-between items-center '>
        //             <button className="colour_sky text-white rounded-none text-[15px] font-bold text-lg uppercase h-[30px] text-center w-full px-2" >Cancel</button>
        //             <button className="colour_sky text-white rounded-none text-[15px] font-bold text-lg uppercase h-[30px] w-full flex justify-center items-center relative"
        //             >Done
        //                 <div className="px-4 white-text h-2 flex justify-center items-center cursor-pointer border-[#d4d3e8] rounded-none font-extrabold text-sm" type="submit">
        //                     <div className=" flex items-center justify-center absolute bg-transparent">
        //                         <div className="flex items-center justify-center bg-transparent w-full">
        //                             <div className="w-5 h-5 rounded-full animate-spin 
        //                   border-[5px] border-solid border-[#ffffff] border-t-transparent"></div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </button>
        //         </div>
        //         <div className='flex justify-between items-center py-1 whitespace-nowrap space-x-4 bg-black text-white'>
        //             <div className='text-sm flex justify-center items-center w-full font-bold pl-2'>{betSlipData && betSlipData.nat ? betSlipData.nat : null}</div>
        //             <div className='flex justify-center items-center text-base'>
        //                 <div className='bg-black white-text px-2'>
        //                     <CountdownCircleTimer
        //                         isPlaying
        //                         duration={7}
        //                         colors={['#000000']}
        //                         colorsTime={[7]}
        //                         size={20}
        //                         strokeWidth={0}
        //                         className="bg-white p-0"

        //                     >
        //                         {({ remainingTime }) => remainingTime === 7 ? time : time}
        //                     </CountdownCircleTimer>
        //                 </div>

        //             </div>
        //             <div className='text-start flex justify-center items-center w-full'>
        //                 <span> RATE : {betSlipData.odds}</span></div>
        //         </div>
        //     </div>
        //     <div className='border-[1px] border-gray-400 w-full'>
        //         <input type="number" autoFocus
        //             autoCapitalize="none" className=" w-full black-text bg-[#E9E9E9] text-base focus:outline-none cursor-pointer placeholder-black px-1" placeholder="Amount" name="stake" list="stake" inputMode="numeric" />
        //     </div>
        // </div>





        // <div className='w-full z-50 fixed top-2 px-2 h-screen bg-black/30 lg:hidden block'>
        //     <div className={`w-full bg-white z-50 mx-auto ${betSlipData.type === "No"
        //         ? "match_bg_pink_index-0 "
        //         : "match_bg_blue_index-0"
        //         } `}>
        //         <div className=" w-full h-full flex justify-between colour_sky p-2 items-center">
        //             <h2 className="text-white text-lg">
        //                 Placebet
        //             </h2>
        //             <div className='text-center pl-2' onClick={() => handleClose()}>
        //                 <IoCloseSharp className='text-white cursor-pointer ' size={24} />
        //             </div>
        //         </div>
        //         <div class="flex justify-between items-center space-x-1 text-black border-b border-[#cccccc] px-2">
        //             <div className='p-1 font-medium text-base'>
        //                 {betSlipData.nat}
        //             </div>
        //             <div className="bg-white rounded flex divide-x divide-gray-300">
        //                 <span className="py-1 px-2 font-bold cursor-pointer colour_sky" onClick={decreaseCount}>-</span>
        //                 <span className="py-1 px-6 text-sm">{count && count ? count : 0}</span>
        //                 <span className="py-1 px-2 font-bold cursor-pointer colour_sky" onClick={increaseCount}>+</span>
        //             </div>
        //         </div>

        //         <div className="grid grid-cols-3 gap-2 px-3 py-2">
        //             <div className="bg-white flex divide-x divide-gray-300">
        //                 <input className="focus:outline-none text-sm w-20 text-start px-2 py-1" type="number" placeholder="0" value={betSlipData.stake} onChange={inputChange} />
        //             </div>
        //             <button className="colour_sky px-2 py-1 text-sm text-white font-semibold" onClick={() => placeBet()}>Submit</button>
        //             <div className='font-medium text-base text-center'>
        //                 0
        //             </div>
        //             <button class="px-1.5 py-1 text-xs colour_sky text-white font-bold" onClick={() => updateStackOnclic("500")}>500</button>
        //             <button class="px-1.5 py-1 text-xs colour_sky text-white font-bold" onClick={() => updateStackOnclic("1000")}>1000</button>
        //             <button class="px-1.5 py-1 text-xs colour_sky text-white font-bold" onClick={() => updateStackOnclic("2000")}>2000</button>
        //             <button class="px-1.5 py-1 text-xs colour_sky text-white font-bold" onClick={() => updateStackOnclic("3000")}>3000</button>
        //             <button class="px-1.5 py-1 text-xs colour_sky text-white font-bold" onClick={() => updateStackOnclic("4000")}>4000</button>
        //             <button class="px-1.5 py-1 text-xs colour_sky text-white font-bold" onClick={() => updateStackOnclic("5000")}>5000</button>
        //             <button class="px-1.5 py-1 text-xs colour_sky text-white font-bold" onClick={() => updateStackOnclic("10000")}>10000</button>
        //             <button class="px-1.5 py-1 text-xs colour_sky text-white font-bold" onClick={() => updateStackOnclic("20000")}>20000</button>
        //         </div>

        //     </div>
        // </div>
    );
}

