import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import { IoCloseSharp } from "react-icons/io5";
import { websiteName } from "../../globleConstent/membership/membership"

export const MarqueeNotifection = () => {
    const [showMarquee, setShowMarquee] = useState(true);
    const handleClose = () => {
        setShowMarquee(false);
    };

    let domainSetting = JSON.parse(localStorage.getItem('clientdomainSetting'));


    return (
        <>
            {showMarquee && (
                <>
                    <div className="bg-black text-white px-4 py-1 flex justify-between items-center">
                        <Marquee speed={100} >
                            <span className="text-sm capitalize">
                                {domainSetting ? domainSetting?.clientNotification : null}
                            </span>
                        </Marquee>
                        <button
                            onClick={handleClose}
                            className="text-white hover:text-gray-200 focus:outline-none"
                        >
                            <IoCloseSharp />
                        </button>
                    </div>


                </>
            )}
        </>
    );
};
