import { userConstent } from '../constent/userConstent'
import { userApis } from '../../apis/userApis'
import { toast } from 'react-toastify';
import { message } from 'antd';



export const userAction = {
  login,
  logout,
  sportMatchList,
  changePassword,
  userUpdate,
  userBalance,
  save_bet,
  save_bet_fancy,
  userPositionByMarketId,
  domainSettingByDomainName,
  getUserLedger,
  accountStatement


};

function login(data) {
  return dispatch => {
    dispatch(request({ data }));

    return userApis.login(data)
      .then(
        user => {
          // localStorage.setItem('authentication', JSON.stringify(user));
          localStorage.setItem('modalOpen', true);
          dispatch(success(user));
          message.success("User logged in successfully.")
          // const toastId = toast.success("User logged in successfully.");
          // setTimeout(() => toast.dismiss(toastId), 1000);
          return Promise.resolve(user);

        },
        error => {
          dispatch(failure(error));
          message.error(error)
          // const toastId = toast.error(error);
          // setTimeout(() => toast.dismiss(toastId), 1000);
          return Promise.reject(error);

        }
      );
  };

  function request(user) { return { type: userConstent.USER_LOGIN_REQUEST, user } }
  function success(user) { return { type: userConstent.USER_LOGIN_SUCCESS, user } }
  function failure(error) { return { type: userConstent.USER_LOGIN_FAILEAR, error } }
}

function sportMatchList(data) {
  return dispatch => {
    dispatch(request({ data }));

    return userApis.sportMatchList(data)
      .then(
        user => {
          dispatch(success(user));
          localStorage.setItem('MatchListData', JSON.stringify(user));
          // localStorage.setItem('MatchListFlag', true);
          return Promise.resolve(user);

        },
        error => {
          dispatch(failure(error));
          return Promise.reject(error);

        }
      );
  };

  function request(user) { return { type: userConstent.SPORT_MATCHLIST_REQUEST, user } }
  function success(user) { return { type: userConstent.SPORT_MATCHLIST_SUCCESS, user } }
  function failure(error) { return { type: userConstent.SPORT_MATCHLIST_FAILEAR, error } }
}


function changePassword(data, navigate) {
  return dispatch => {
    dispatch(request());
    userApis.changePassword(data)
      .then(
        users => {
          let message = users && users.changePassword ? users.changePassword : null;
          message.success(message)
          // const toastId = toast.success(message);
          // setTimeout(() => toast.dismiss(toastId), 1000);
          dispatch(success(users));
          navigate("/login")
          localStorage.removeItem('clientUser');
          // localStorage.removeItem('authentication');
          localStorage.removeItem('modalOpen');
          localStorage.removeItem('clientBalance');
          localStorage.removeItem('clientExposure');

        },
        error => {
          dispatch(failure(error))
          if (error) {
            message.error(error)
            // const toastId = toast.error(error);
            // setTimeout(() => toast.dismiss(toastId), 1000);
          } else {
            message.error("Somting Went Wrong Please Try Again")
            // toast.error("Somting Went Wrong Please Try Again");
          }
        }
      );
  };
  function request() { return { type: userConstent.CHANGE_PASSWORD_REQUEST } }
  function success(users) { return { type: userConstent.CHANGE_PASSWORD_SUCCESS, users } }
  function failure(error) { return { type: userConstent.CHANGE_PASSWORD_FAILURE, error } }
}


function userUpdate
  (data) {
  return dispatch => {
    dispatch(request());
    userApis.userUpdate
      (data)
      .then(
        users => {
          message.success(users?.userUpdate?.message)
          // const toastId = toast.success(users.userUpdate.message);
          // setTimeout(() => toast.dismiss(toastId), 1000);
          dispatch(success(users));

        },
        error => {
          message.error(error)
          // const toastId = toast.error(error);
          // setTimeout(() => toast.dismiss(toastId), 1000);
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstent.USER_UPDATE_REQUEST } }
  function success(users) { return { type: userConstent.USER_UPDATE_SUCCESS, users } }
  function failure(error) { return { type: userConstent.USER_UPDATE_FAILURE, error } }
}

function userBalance(data) {
  return dispatch => {
    dispatch(request());
    userApis.userBalance(data).then(
      users => {
        dispatch(success(users));

      },
      error => {
        // dispatch(alertActions.error(error));
        dispatch(failure(error))

      }
    );
  };
  function request() { return { type: userConstent.USER_BALANCE_REQUEST } }
  function success(users) { return { type: userConstent.USER_BALANCE_SUCCESS, users } }
  function failure(error) { return { type: userConstent.USER_BALANCE_FAILURE, error } }
}


function userPositionByMarketId(data) {
  return dispatch => {
    dispatch(request());
    userApis.userPositionByMarketId(data)
      .then(
        users => {
          dispatch(success(users));
        },
        error => {
          // dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstent.USER_POSITION_BY_MARKETID_REQUEST } }
  function success(users) { return { type: userConstent.USER_POSITION_BY_MARKETID_SUCCESS, users } }
  function failure(error) { return { type: userConstent.USER_POSITION_BY_MARKETID_FAILURE, error } }
}


function save_bet(data, fetchBetLists, userPositionByMarketId, marketId) {
  return dispatch => {
    dispatch(request());
    userApis.save_bet(data)
      .then(
        users => {

          dispatch(success(users));
          userPositionByMarketId(marketId);
          fetchBetLists()

          // dispatch(alertActions.success("Bet has been saved successfully."));
        },
        error => {
          // dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstent.SAVE_BET_REQUEST } }
  function success(users) { return { type: userConstent.SAVE_BET_SUCCESS, users } }
  function failure(error) { return { type: userConstent.SAVE_BET_FAILURE, error } }
}


function save_bet_fancy(data, fetchBetLists, userPositionByMarketId, marketId) {
  return dispatch => {
    dispatch(request());
    userApis.save_bet_fancy(data)
      .then(
        users => {
          dispatch(success(users));
          userPositionByMarketId(marketId);
          fetchBetLists()
          // dispatch(alertActions.success("Bet has been saved successfully."));
        },
        error => {
          // dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstent.SAVE_BET_FANCY_REQUEST } }
  function success(users) { return { type: userConstent.SAVE_BET_FANCY_SUCCESS, users } }
  function failure(error) { return { type: userConstent.SAVE_BET_FANCY_FAILURE, error } }
}

function domainSettingByDomainName(data) {
  return dispatch => {
    dispatch(request());
    userApis.domainSettingByDomainName(data)
      .then(
        users => {
          dispatch(success(users));
        },
        error => {
          // dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstent.DOMAIN_SETTING_BY_DOMAIN_NAME_REQUEST } }
  function success(users) { return { type: userConstent.DOMAIN_SETTING_BY_DOMAIN_NAME_SUCCESS, users } }
  function failure(error) { return { type: userConstent.DOMAIN_SETTING_BY_DOMAIN_NAME_FAILURE, error } }
}


function getUserLedger
  (data) {
  return dispatch => {
    dispatch(request());
    userApis.getUserLedger
      (data)
      .then(
        users => {
          dispatch(success(users));
        },
        error => {
          // dispatch(alertActions.error(error));
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstent.USER_LEDGER_REQUEST } }
  function success(users) { return { type: userConstent.USER_LEDGER_SUCCESS, users } }
  function failure(error) { return { type: userConstent.USER_LEDGER_FAILURE, error } }
}

function accountStatement
  (data) {
  return dispatch => {
    dispatch(request());
    userApis.accountStatement
      (data)
      .then(
        users => {
          dispatch(success(users));
        },
        error => {
          message.error(error)
          // const toastId = toast.error(error);
          // setTimeout(() => toast.dismiss(toastId), 1000);
          dispatch(failure(error))
        }
      );
  };
  function request() { return { type: userConstent.USER_ACCOUNT_DETAILS_REQUEST } }
  function success(users) { return { type: userConstent.USER_ACCOUNT_DETAILS_SUCCESS, users } }
  function failure(error) { return { type: userConstent.USER_ACCOUNT_DETAILS_FAILURE, error } }
}



function logout() {
  userApis.logout();
  return { type: userConstent.LOGOUT };
}
