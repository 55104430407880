import React from 'react';

export default function Loader() {


    return (
        <>
            <div className='fixed w-full h-full  z-40 overflow-hidden mt-0 pt-4 right-0 flex justify-center items-start overflow-y-auto bg-black/50 md:p-0 p-1'>
                <div className="h-full w-full  flex items-center justify-center">
                    <div className="flex items-center justify-center p-6 py-2 bg-transparent w-full">
                        <div className="w-20 h-20 rounded-full animate-spin 
                    border-[11px] border-solid border-[#f36c21] border-t-transparent"></div>
                    </div>

                </div>
            </div>
        </>
    );
}