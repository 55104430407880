
import img1 from "../../../Assest/images/SlotGamesImages/img1.png";
import img2 from "../../../Assest/images/SlotGamesImages/img2.png";
import img3 from "../../../Assest/images/SlotGamesImages/img3.jpg";
import img4 from "../../../Assest/images/SlotGamesImages/img4.jpeg";
import img5 from "../../../Assest/images/SlotGamesImages/img5.jpg";
import img6 from "../../../Assest/images/SlotGamesImages/img6.jpg";
import img7 from "../../../Assest/images/SlotGamesImages/img7.png"
import img8 from "../../../Assest/images/SlotGamesImages/img8.png"
import {useNavigate } from "react-router-dom";
const sliderImage = [
    { imgs: img1, title: "img1", gameId: 900000 },
    { imgs: img2, title: "img2", gameId: 201153 },
    { imgs: img3, title: "img3", gameId: 100000 },
    { imgs: img5, title: "img5", gameId: 600113 },
    { imgs: img6, title: "img6", gameId: 500001 },
    { imgs: img4, title: "img4", gameId: 201206 },
    { imgs: img7, title: "img7", gameId: 400000 },
    { imgs: img8, title: "img8", gameId: 600000 },

];

function LiveCasinoHome() {
    const navigate = useNavigate();
    
    const handalClickNavigate = (url) => {
        navigate(url)
    }
    return (
        <>
            

            <div className=' border  rounded-lg flex justify-center items-center ' >
                <div className=' m-5'>
                    <div className='grid grid-cols-2 md:grid-cols-4 gap-2'>
                        {sliderImage.map((item, index) => (
                            <div key={index} className="">
                                <div onClick={() => handalClickNavigate("/login")}>
                                    <img src={item.imgs} alt={item.title} className='w-[500px]  lg:w-[480px] rounded-md border-2 border-yellow-200 border-solid' />
                                </div>
                            </div>
                        ))}

                    </div>
                </div>


            </div>
        </>
    )
}

export default LiveCasinoHome




