import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { httpPost } from '../../../middelware/Http';
import { message } from 'antd';

export default function FancyLadder(props) {
    
    const { handleClose, positionData, marketId } = props;
    const [positionDataArray, setPositionDataArray] = useState([]);


    useEffect(() => {
        getCasinoData();
    }, []);
    // positionDataArray

    const getCasinoData = async () => {
        try {
            let elementPositionData = {
                "marketId": marketId,
                "selectionId": positionData.positionData.session_id?.toString()
            };
            let elementPositionDataResponse = await httpPost('sports/getSessionPositionBySelectionId', elementPositionData);
            if (elementPositionDataResponse && elementPositionDataResponse.data) {
                const newDataArray = Object.entries(elementPositionDataResponse.data).map(([run, position]) => ({ run, position }));
                setPositionDataArray(newDataArray);
                if (newDataArray.length <= 0) {
                    message.error("No Run Position Available!")
                    // const toastId = toast.error("No Run Position Available!");
                    // setTimeout(() => toast.dismiss(toastId), 1000);
                    setTimeout(() => handleClose(), 1000);
                }
            }
        } catch (error) {
            console.error("Error fetching Fancy Position Data data:", error);
        }
    };
    return (
        <div className='fixed w-full  h-full top-0 z-40 overflow-hidden mt-0 pt-4 flex justify-center items-center overflow-y-auto bg-black/30 md:p-0 p-1 left-0' onClick={handleClose}>
            <div class="bg-white lg:max-w-md max-w-xs w-full mx-auto rounded-b-3xl py-4" onClick={(e) => e.stopPropagation()}>
                <div className="ztop flex text-center justify-center items-center px-3 py-2 w-full">
                    <span className="text-[15px] font-bold text-black text-center">{positionData.positionData.session_name}</span>
                </div>
                <div className=''>
                    <table className="w-full border-none text-center font-normal text-sm">
                        <thead className="rounded-t ">
                            <tr>
                                <th scope="col" className='tableWidHeader'>RUN</th>
                                <th scope="col" className='tableWidHeader'>P&L</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y py-0.5">
                            {positionDataArray.length > 0 ? (
                                positionDataArray.map((data, index) => (
                                    <tr key={index}>
                                        <td>{data.run}</td>
                                        <td className={`${data.position < 0 ? "text-red-500" : "text-green-500"}`}>{data.position}</td>
                                    </tr>
                                ))
                            ) : (

                                <tr>
                                    <td>LOADING</td>
                                    <td className="text-[#3C763D]"></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="text-[#f36c21] px-6 cursor-pointer" onClick={() => handleClose()}>
                        close
                    </div>
                </div>
            </div>
        </div>
    );
}
