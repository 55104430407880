import React, { useState, useEffect } from "react";
import { userAction } from "../../../redux/action/userAction";
import { useDispatch, useSelector } from "react-redux";
import { MdHome, MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import cricket from "../../../Assest/images/game/cricket.webp";

const Sidebar = ({ marketId }) => {
  const [matchNames, setMatchNames] = useState([]);
  const { sportmatchlist } = useSelector((state) => state.authentication);

  useEffect(() => {
    dispatch(userAction.sportMatchList());
  }, []);

  useEffect(() => {
    if (sportmatchlist && sportmatchlist.data) {
      setMatchNames(sportmatchlist.data);
    }
  }, [sportmatchlist]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/");
  };
  const handelClickViewMatch = (element) => {
    navigate(`/viewMatch/${element.marketId}/${element.eventId}`);
    window.location.reload()
  };
  return (
    <>
      <div className=" ">
        <div className="py-4 items-center ">
          <div className=" transition duration-0 hover:duration-150 group border-r-8 border-black px-2 py-1 flex border-0 justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]">
            <div className="py-1 flex gap-2 items-center">
              <span className="">
                <MdHome size={30} className="text-white" />
              </span>
              <span className="text-sm font-normal px-2 text-white group-hover:text-[#f36c21]">
                Sports
              </span>
            </div>
          </div>
          <div className="bg-[#f36c21] ">
            <div className="py-1 flex gap-1 items-center">
              <span className="px-2 py-1">
                <img src={cricket} alt="cricket" className="h-5 w-5" />
              </span>
              <span className="text-sm font-normal px-1 text-white">
                Cricket
              </span>
            </div>
          </div>
          <div className="bg-[#272727] ">
            <div className="py-1 flex gap-1 items-center">
              <span className="px-2 py-1">
                <MdOutlineKeyboardArrowLeft
                  className="text-white font-bold cursor-pointer"
                  size={25}
                  onClick={() => handleOnClick()}
                />
              </span>
              <span className="text-sm font-normal px-1 text-white">
                {" "}
                Previous{" "}
              </span>
            </div>
          </div>
          {matchNames && matchNames.length && matchNames.length > 0
            ? matchNames?.map((mList, index) => (
              <div
                key={index}
                className={`${mList.marketId === marketId
                    ? "bg-[rgb(243,107,33)] border-[rgb(243,107,33)] border-r-8"
                    : "transition duration-150 hover:duration-150 group border-r-8 border-black px-2 py-1 flex justify-between w-full hover:bg-gradient-to-t from-black via-black to-gray-900 hover:border-[rgb(243,107,33)]"
                  }`}
                onClick={() => handelClickViewMatch(mList)}
              >
                <div className="py-1 px-2 text-white truncate gap-2">
                  <span className="text-sm font-normal px-2 ">
                    {mList.matchName}
                  </span>
                </div>
              </div>
            ))
            : null}
        </div>
      </div>
    </>
  );
};
export default Sidebar;
