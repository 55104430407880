import React from "react";
import { connect } from "react-redux";
import FlipCountdown from '@rumess/react-flip-countdown';
import axios from "axios";
import io from 'socket.io-client';
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorTost from './casino_components/ErrorTost';
import "./casino.css";
import Teenpattit20rules from './images/tponeday-rules.jpg';
import BetLocked from "./casino_components/BetLocked";
import PlaceBetMobileBmx from "./casino_components/PlaceBetMobileBmx";
import ResultModelBmx from "./casino_components/ResultModelBmx";
import RoundedTabBmx from "./casino_components/RoundedTabBmx";
import PageHeaderTheme from "./casino_components/PageHeaderTheme";
import PlaceBetMobileBmxBet from "./casino_components/PlaceBetMobileBmxBet";
import CasinoTab from "./casino_components/CasinoTab";
import Desktop30 from "./casino_components/Desktop30";
import Mobile30 from "./casino_components/Mobile30";
import { httpPost, httpPostBet } from "../../../../middelware/Http";
import Loader from "../../../../component/Loader/Loader";
import { message } from "antd";



class Teenpattit20 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backBetModal: false,
      offset: 0,
      betSlipData: {},
      fieldsbet: {},
      errorsbet: {},
      count: 0,
      time: 7,
      casinoData: [],
      ResultModel: false,
      backBetModalMobile: false,
      showLoader: false,
      result: {},
      casinoDetails: {},
      fetchData: "",
      socketURL: "",
      tvUrl: "",
      cacheURL: "",
      betStatus: "",
      cashinoStatus: "",
      eventId: "",
      minStake: "",
      maxStake: "",
      name: "",
      shortName: "teen20",
      betList: [],
      betresponse: [],
      setting: {},
      posArray: {},
      currentRoundId: 0,
      oddsDifference: 0,
      totalProfitLoss: 0,
      activeTab: 1,
      LoadingBet: false,
    };
    this.section1Ref = React.createRef();
    this.scrollTimeout = null;
    this.inputRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users && nextProps.users.betPlaceSucess) {
      return {
        ...nextProps,
        backBetModal: false,
        backBetModalMobile: false,
        betSlipData: {},
      }
    } else {
      return {
        ...nextProps,

      }
    }
  }
  increaseCount = () => {
    const increasedCount = (parseFloat(this.state.count) + 0.01).toFixed(2);
    this.setState({ count: parseFloat(increasedCount) });
  };

  decreaseCount = () => {
    const decreasedCount = (parseFloat(this.state.count) - 0.01).toFixed(2);
    this.setState({ count: parseFloat(decreasedCount) });
  };


  handleBackOpen = (data) => {
    if (this.scrollTimeout) {
      clearInterval(this.scrollTimeout);
    }
    this.setState({ backBetModal: true, backBetModalMobile: true, betSlipData: { ...data, stake: "0" }, count: data.odds, time: 7, });
    this.scrollTimeout = setInterval(() => {
      this.setState(prevState => ({ time: prevState.time - 1 }), () => {
        if (this.state.time <= 0) {
          clearInterval(this.scrollTimeout);
          this.setState({ backBetModal: false });
        }
      });
    }, 1000);

  };

  componentDidMount() {
    let requestData = {
      eventId: 3030,
    }
    this.getDidMountData(requestData)
    // this.socketConnection()
  }

  getDidMountData = async (requestData) => {
    this.setState({ showLoader: true })
    let getCasinoDetails = await httpPost(`casino/getDiamondCasinoByEventId`, requestData)
    if (!getCasinoDetails.error) {
      let casinoDetails = getCasinoDetails.data
      this.setState({
        casinoDetails: casinoDetails,
        fetchData: casinoDetails && casinoDetails.fetchData ? casinoDetails.fetchData : {},
        socketURL: casinoDetails && casinoDetails.socketURL ? casinoDetails.socketURL : {},
        tvUrl: casinoDetails && casinoDetails.videoUrl1 ? casinoDetails.videoUrl1 : "",
        cacheURL: casinoDetails && casinoDetails.cacheURL ? casinoDetails.cacheURL : {},
        betStatus: casinoDetails && casinoDetails.betStatus ? casinoDetails.betStatus : "",
        cashinoStatus: casinoDetails && casinoDetails.cashinoStatus ? casinoDetails.cashinoStatus : "",
        eventId: casinoDetails && casinoDetails?.eventId ? casinoDetails?.eventId : "",
        minStake: casinoDetails && casinoDetails.minStake ? casinoDetails.minStake : "",
        maxStake: casinoDetails && casinoDetails.maxStake ? casinoDetails.maxStake : "",
        name: casinoDetails && casinoDetails.name ? casinoDetails.name : "",
        shortName: casinoDetails && casinoDetails.shortName ? casinoDetails.shortName : "teen20",
        setting: casinoDetails && casinoDetails.setting ? casinoDetails.setting : {},
        oddsDifference: casinoDetails && casinoDetails.setting && casinoDetails.setting.oddsDifference ? casinoDetails.setting.oddsDifference : 0,
      })
      if (casinoDetails?.eventId) {
        this.betList(casinoDetails?.eventId)
      }

      // const socketPerm = casinoDetails.fetchData
      if (casinoDetails?.socketURL) {
        await this.callSocket(casinoDetails?.socketURL, casinoDetails?.shortName)
      }
      // else {
      //   await this.callCache(casinoDetails.cacheURL)
      // }
    } else {

    }
    this.setState({ showLoader: false })
  }

  callSocket = async (socketURL, shortName) => {
    const socket = io.connect(socketURL, {
      transports: ["websocket"],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 99,
    });
    socket.emit('JoinRoom', shortName);
    socket.on(shortName, data => {
      this.setState({ currentRoundId: data && data.data && data.data.t1 && data.data.t1[0] && data.data.t1[0].mid ? data.data.t1[0].mid : 0 })
      this.setState({ casinoData: data })
      this.updatePos()
    })
  }

  callCache = async (cacheUrl) => {
    await this.getMarketCacheUrl(cacheUrl);
    this.cacheInterval = setInterval(async () => {
      await this.getMarketCacheUrl(cacheUrl);
    }, 1000);
  }


  getMarketCacheUrl = async (cacheURL) => {
    let config = {
      method: 'GET',
      maxBodyLength: Infinity,
      url: cacheURL,
      headers: { 'Content-Type': 'application/json' },
    };
    axios.request(config).then((response) => {
      this.setState({ casinoData: response.data.data })
    }).catch((error) => {
      console.error('cache data url error ', error);
    });
  }



  updatePos = async () => {
    const { betList, currentRoundId } = this.state;
    if (betList && betList.length > 0) {
      const filteredBets = betList.filter((element) => element.roundId == currentRoundId);
      let pushPos = {};
      filteredBets.forEach((bet) => {
        const posArray = bet.posArray;
        Object.entries(posArray).forEach(([key, value]) => {
          pushPos[key] = (pushPos[key] || 0) + Number(value);
        });
      });

      this.setState({ posArray: pushPos });
    }
  }


  betList = async (eventId) => {
    let betReq = {
      "eventId": eventId,
      fromDate: moment().format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
    };

    let getCasinoDetails = await httpPost(`casino/diamondBetsList`, betReq)
    let totalProfitLoss = 0
    let betList = getCasinoDetails?.data?.casinoBetData

    if (betList && betList.length > 0) {
      betList.map((bet, key) => {
        let profitLoss = "Not Declare"
        let profitLossCount = 0
        if (bet.isDeclare) {
          profitLossCount = bet.profitLoss ? bet.profitLoss : 0
          profitLoss = bet.profitLoss
        }
        totalProfitLoss += Number(profitLossCount)
        betList[key].profitLoss = profitLoss
      })
    }
    if (getCasinoDetails) {
      this.setState({ betList: betList, totalProfitLoss: totalProfitLoss })
    }
  }


  componentWillUnmount() {
    clearInterval(this.cacheInterval);
    clearTimeout(this.timer);
    if (this.inputRef.current) {
      this.inputRef.current.removeEventListener('touchstart', this.handleTouchStart);
    }
  }

  handleResultModel = (data) => {
    this.setState({ ResultModel: true, result: data });

  };

  updateStackOnclic = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStackOnclick = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  updateStake = (event) => {
    try {
      let { value } = event.target;
      let { betSlipData } = this.state;
      if (value.startsWith('0') && value.length > 1) {
        value = value.slice(1);
      }
      betSlipData.stake = value;
      this.setState({
        betSlipData: betSlipData,
      });
    } catch (error) {
      console.error('Error updating stake:', error);
    }
  };

  placeBet = async () => {
    const { casinoData } = this.state;
    this.setState({ LoadingBet: true })
    let { data } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};
    let betObject = {
      "roundId": t1.mid,
      "sid": this.state.betSlipData.data.sid,
      "rate": this.state.count + "",
      "amount": Number(this.state.betSlipData.stake),
      "casinoType": this.state.shortName ? this.state.shortName : "teen20",
      "eventId": 3030,
      "betFor": this.state.betSlipData.data.nat + "",
    }

    const result = await httpPostBet("casino/casinoBetPlace", betObject);
    if (result) {
      if (result.error === false) {

        this.setState(
          {
            betresponse: result.data
          },
        );
        message.success(result.message)
        // toast.success(<ErrorTost message={result.message} />);
        this.betList(3030)
        // this.props.dispatch(userActions.getUserBalance());
      }
      else {
        message.error(result.message)
        // toast.error(<ErrorTost message={result.message} />);
      }
    }
    this.handleClose();
    this.setState({ LoadingBet: false })
  };


  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;

    if (value < 0 || isNaN(Number(value))) {
      value = 0;
    }

    if (value[0] === '0' && value.length > 1) {
      value = value.substring(1);
    }

    let { betSlipData } = this.state;
    betSlipData.stake = value;
    this.setState({
      betSlipData: betSlipData,
      [name]: Number(value),
    });
  };

  handleClose = () => {
    this.setState({ backBetModal: false, backBetModalMobile: false });
  };

  handleCloseResult = () => {
    this.setState({ ResultModel: false, });
  };

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  render() {
    let clientNotification = JSON.parse(localStorage.getItem('notification'))
    const { casinoData, name, shortName, ResultModel, time, count, backBetModal, tvUrl, betList, showLoader, LoadingBet, posArray, oddsDifference, totalProfitLoss, activeTab, minStake, maxStake } = this.state;
    let { data, result } = casinoData ? casinoData : {};
    let t1 = data && data.t1 && data.t1[0] ? data.t1[0] : {};

    let PlayerA = data && data.t2 && data.t2[0] ? data.t2[0] : {};
    let PairPlusA = data && data.t2 && data.t2[1] ? data.t2[1] : {};
    let PlayerB = data && data.t2 && data.t2[2] ? data.t2[2] : {};
    let PairPlusB = data && data.t2 && data.t2[3] ? data.t2[3] : {};



    return (
      <>

        {LoadingBet === true ?
          <>
            <PlaceBetMobileBmxBet
              betSlipData={this.state.betSlipData}
              time={time}
              count={count}
            />
          </> :
          <>
            {backBetModal && backBetModal === true ? (
              <PlaceBetMobileBmx
                betSlipData={this.state.betSlipData}
                updateStackOnclic={this.updateStackOnclic}
                placeBet={this.placeBet}
                handleClose={this.handleClose}
                section1Ref={this.section1Ref}
                time={time}
                inputChange={this.inputChange}
                decreaseCount={this.decreaseCount}
                increaseCount={this.increaseCount}
                count={count}
              />
            ) : null}
          </>
        }

        {showLoader ?
          <Loader active={showLoader === true} />
          :
          <div className={`w-full relative h-full  mt-2 bg-transparent overflow-y-auto text-sm flex`}>
            {ResultModel &&
              ResultModel === true ? (
              <ResultModelBmx
                handleClose={this.handleCloseResult}
                name={name ? name : "20-20 Teenpatti"}
                shortName={shortName ? shortName : "teen20"}
                result={this.state.result}
              />
            ) : null}

            <div className="lg:flex block w-full lg:pt-1 pt-0 scroll-md lg:space-x-1.5">

              {/* <div className="lg:w-[15%] w-full bg-fuchsia-200 lg:px-2 lg:block hidden"></div> */}


              <div className="lg:hidden block">
                <CasinoTab
                  activeTab={activeTab}
                  handleTabClick={this.handleTabClick}
                  ruleImage={Teenpattit20rules}
                  t1={t1}
                  totalBet={betList && betList.length ? betList.length : "0"}
                />
              </div>

              <div className="w-full lg:flex">

                {activeTab === 1 ? (
                  <div className="w-full lg:flex block">

                    <div className="lg:w-[70%] w-full  ">
                      <PageHeaderTheme
                        PageTitle={name ? name : "20-20 Teenpatti"}
                        ruleImage={Teenpattit20rules}
                        t1={t1}
                      />

                      <div className="bg-black flex justify-between w-full relative md:text-sm text-[10px] xl:h-[460px] md:h-[300px] h-[200px]">
                        <iframe src={tvUrl ? tvUrl : null} title=" " className='relative w-full' />
                        <div className="absolute top-0 left-0">
                          <div className="w-full p-1">
                            <div className="text-white font-semibold text-[12px]">PLAYER A</div>
                            <div className="flex space-x-1 justify-start">
                              <img src={`/cards/${t1 && t1.C1 ? t1.C1 : 1}.png`} alt="card" className="h-9 w-7 border-[1px] border-yellow-300" />
                              <img src={`/cards/${t1 && t1.C2 ? t1.C2 : 1}.png`} alt="card" className="h-9 w-7 border-[1px] border-yellow-300" />
                              <img src={`/cards/${t1 && t1.C3 ? t1.C3 : 1}.png`} alt="card" className="h-9 w-7 border-[1px] border-yellow-300" />
                            </div>
                            <div className="text-white font-semibold text-[12px]">PLAYER B</div>
                            <div className="flex space-x-1 justify-start">
                              <img src={`/cards/${t1 && t1.C4 ? t1.C4 : 1}.png`} alt="card" className="h-9 w-7 border-[1px] border-yellow-300" />
                              <img src={`/cards/${t1 && t1.C5 ? t1.C5 : 1}.png`} alt="card" className="h-9 w-7 border-[1px] border-yellow-300" />
                              <img src={`/cards/${t1 && t1.C6 ? t1.C6 : 1}.png`} alt="card" className="h-9 w-7 border-[1px] border-yellow-300" />
                            </div>
                          </div>
                        </div>

                        <div className="flex justify-end items-end absolute bottom-2 right-1">
                          <FlipCountdown
                            titlePosition='hidden'
                            hideYear
                            hideMonth
                            hideDay
                            hideHour
                            hideMinute
                            endAtZero
                            size='small'
                            endAt={new Date(Date.now() + 1000 * (t1 && t1.autotime ? t1.autotime : null)).toUTCString()}
                          />
                        </div>
                      </div>

                      <div className="border  white-text divide-y  mt-1.5">

                        <div className="grid grid-cols-6 text-center divide-x  black-text ">
                          <div className="col-span-3 w-full font-semibold text-[12px]  flex justify-start items-center p-1">
                            <span className="lg:hidden block">
                              <span className="font-[500] ">MIN</span>:{minStake} <span className="font-[500]">&nbsp; MAX</span>:{maxStake}</span>
                          </div>
                          <div class="w-full font-semibold text-[14px] lg:col-span-1 col-span-3 lg:flex justify-center items-center py-1 darktext odds-lgaai">Back</div>
                          <div class="col-span-2 hidden w-full font-semibold text-[14px] lg:flex justify-center items-center py-1 uppercase odds-lgaai"></div>
                        </div>

                        <div className="w-full colour_back_odds grid grid-cols-6 divide-x ">
                          <div className="col-span-3 h-full text-[14px] player-bg capitalize text-gray-800 font-semibold py-[2px] px-2 w-full flex justify-between items-center">
                            <div>{PlayerA && PlayerA.nat ? PlayerA.nat : PlayerA.nation ? PlayerA.nation : "Player A"}</div>
                            <div className={`${posArray[PlayerA.sid] < 0 ? "text-red-500" : "text-green-800"} text-[12px]`} >{posArray[PlayerA.sid]}</div>
                          </div>
                          <div className="w-full odds-lgaai cursor-pointer relative " >
                            <div className=" text-center py-[2px]" onClick={() => this.handleBackOpen({ data: PlayerA, type: "Yes", odds: PlayerA.rate - oddsDifference, nat: PlayerA.nat ? PlayerA.nat : PlayerA.nation }, this.section1Ref)}>
                              <span className="text-[14px] font-[600] gray-text">{PlayerA && PlayerA.rate ? (PlayerA.rate - oddsDifference) : "0"}</span><br />
                              <div className={`${posArray[PlayerA.sid] < 0 ? "text-red-500" : "text-green-800"} text-[12px] font-[600]`} >{posArray[PlayerA.sid]}</div>
                            </div>
                            {PlayerA && PlayerA.gstatus === '1' ? null : <BetLocked />}

                          </div>
                          <div className="col-span-2 w-full odds-lgaai cursor-pointer relative">
                            <div className=" text-center py-[2px]" onClick={() => this.handleBackOpen({ data: PairPlusA, type: "Yes", odds: PairPlusA.rate - oddsDifference, nat: PairPlusA.nat ? PairPlusA.nat : PairPlusA.nation }, this.section1Ref)} >
                              <span className="text-[14px] font-[600] gray-text">{PairPlusA && PairPlusA.nat ? PairPlusA.nat : PairPlusA.nation ? PairPlusA.nation : "A"}</span><br />
                              <span className="text-[13px] font-[600] gray-text">{PairPlusA && PairPlusA.rate ? (PairPlusA.rate - oddsDifference) : "-"}</span><br />
                            </div>
                            {PairPlusA && PairPlusA.gstatus === '1' ? null : <BetLocked />}
                          </div>
                        </div>

                        <div className="w-full colour_back_odds grid grid-cols-6 divide-x ">
                          <div className="col-span-3 h-full text-[14px] player-bg capitalize text-gray-800 font-semibold py-[2px] px-2 w-full flex justify-between items-center">
                            <div>{PlayerB && PlayerB.nat ? PlayerB.nat : PlayerB.nation ? PlayerB.nation : "Player B"}</div>
                            <div className={`${posArray[PlayerB.sid] < 0 ? "text-red-500" : "text-green-800"} text-[12px]`} > {posArray[PlayerB.sid]}</div>
                          </div>
                          <div className="w-full odds-lgaai cursor-pointer relative " >
                            <div className=" text-center py-[2px]" onClick={() => this.handleBackOpen({ data: PlayerB, type: "Yes", odds: PlayerB.rate - oddsDifference, nat: PlayerB.nat ? PlayerB.nat : PlayerB.nation }, this.section1Ref)}>
                              <span className="text-[14px] font-[600] gray-text">{PlayerB && PlayerB.rate ? (PlayerB.rate - oddsDifference) : "0"}</span><br />
                              <div className={`${posArray[PlayerB.sid] < 0 ? "text-red-500" : "text-green-800"} text-[12px] font-[600]`} >{posArray[PlayerB.sid]}</div>
                            </div>
                            {PlayerB && PlayerB.gstatus === '1' ? null : <BetLocked />}
                          </div>
                          <div className="col-span-2 w-full odds-lgaai relative" >
                            <div className=" text-center py-[2px] cursor-pointer" onClick={() => this.handleBackOpen({ data: PairPlusB, type: "Yes", odds: PairPlusB.rate - oddsDifference, nat: PairPlusB.nat }, this.section1Ref)}>
                              <span className="text-[14px] font-[600] gray-text">{PairPlusB && PairPlusB.nat ? PairPlusB.nat : PairPlusB.nation ? PairPlusB.nation : "B"}</span><br />
                              <span className="text-[13px] font-[600] gray-text">{PairPlusB && PairPlusB.rate ? (PairPlusB.rate - oddsDifference) : "-"}</span><br />
                            </div>
                            {PairPlusB && PairPlusB.gstatus === '1' ? null : <BetLocked />}
                          </div>
                        </div>

                      </div>

                      <div className=" pb-2  mt-2">
                        <RoundedTabBmx />
                        <div className="flex space-x-2 border border-[#f36b21] justify-end items-center py-1.5 px-2">
                          {result && result.length > 0 ? result.map((element, index) => (
                            <div
                              onClick={() => this.handleResultModel(element)}
                              className="result-color w-7 h-7 cursor-pointer flex justify-center items-center rounded-full shadow-md border border-gray-700"
                            >
                              <p
                                className={`font-[700] ${element && element.result === '3' ? "text-[#FFFF33] text-[14px]" : element && element.result === '1' ? "text-[#ff4500] text-[13px]" : "text-[#33c6ff]"}`}
                              >
                                {element && element.result === '3' ? "B" : element && element.result === '1' ? "A" : "T"}
                              </p>
                            </div>
                          )) : null}
                        </div>
                      </div>

                      <div className='w-full lg:hidden block pb-5'>
                        <div className="rules-header white-text text-[14px] px-2 py-[6px] font-medium tracking-wide mt-3">
                          Rules
                        </div>
                        <div className='overflow-x-auto w-full'>
                          <table className="table w-full bg-[#f7f7f7] darktext text-[14px] border border-[#c7c8ca] divide-y divide-[#c7c8ca]">
                            <thead>
                              <tr className='bg-white'>
                                <th colspan="3" class="text-center font-[600]">Pair Plus </th>
                              </tr>
                            </thead>
                            <tbody className='divide-y divide-[#c7c8ca]'>
                              <tr className='divide-x divide-[#c7c8ca] '>
                                <td className='text-left'><span className="ms-2">Pair</span></td>
                                <td className="text-left">1 To 1 </td>
                              </tr>
                              <tr className='divide-x divide-[#c7c8ca] '>
                                <td className='text-left'><span className="ms-2">Flush	</span></td>
                                <td className="text-left">1 To 4 </td>
                              </tr>
                              <tr className='divide-x divide-[#c7c8ca] '>
                                <td className='text-left'><span className="ms-2">Straight</span></td>
                                <td className="text-left">1 To 6 </td>
                              </tr>
                              <tr className='divide-x divide-[#c7c8ca] '>
                                <td className='text-left'><span className="ms-2">Trio	</span></td>
                                <td className="text-left">1 To 30 </td>
                              </tr>
                              <tr className='divide-x divide-[#c7c8ca] '>
                                <td className='text-left'><span className="ms-2">Straight Flush			</span></td>
                                <td className="text-left">1 To 40 </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>

                    </div>

                    <Desktop30
                      LoadingBet={LoadingBet}
                      betSlipData={this.state.betSlipData}
                      updateStackOnclick={this.updateStackOnclick}
                      inputChange={this.inputChange}
                      decreaseCount={this.decreaseCount}
                      increaseCount={this.increaseCount}
                      placeBet={this.placeBet}
                      handleClose={this.handleClose}
                      count={this.state.count}
                      timerKey={this.state.timerKey}
                      remainingTime={this.state.remainingTime}
                      handleCountdown={this.handleCountdown}
                      updateStake={this.updateStake}
                      betList={betList}
                      time={time}
                      backBetModal={backBetModal}
                      eventId={3030}
                    />

                  </div>

                ) : null}


                {activeTab === 2 ? (
                  <Mobile30
                    betList={betList}
                  />
                ) : null}


              </div>



            </div>
          </div>}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Teenpattit20);