import React from "react";


const FAQ = ({ faq, index, toggleFAQ }) => {

    

    const applyClasses = (htmlString) => {

        const tempElement = document.createElement("div");
        tempElement.innerHTML = htmlString;

        const h2Tags = tempElement.querySelectorAll("h2");
        h2Tags.forEach((h2Tag) => {
            h2Tag.classList.add("text-2xl", "font-bold","text-white");
        });
        const h3Tags = tempElement.querySelectorAll("span");
        h3Tags.forEach((h3Tag) => {

            h3Tag.classList.add("text-ms", "font-bold", "text-white");
        });
        return tempElement.innerHTML;
    };

    return (
        <div
            className={"faq " + (faq.open ? "open" : "")}
            key={index}
            onClick={() => toggleFAQ(index)}
        >

            <div className="faq-question">{faq.question}</div>
            <div className="faq-answer">
                <img src={faq.img} alt="promotionImg" className="h-72 w-full"/>
                <div
                    className="faq-answer"
                    dangerouslySetInnerHTML={{ __html: applyClasses(faq.answer) }}
                />
  

            </div>
        </div>
    );
};



export default FAQ;
