import React from "react";
import NavigatePathComponent from "../../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent";
import { websiteName } from "../../../globleConstent/membership/membership"
import img1 from "../../../Assest/images/Affiliate/247-customer-support.webp"
import img2 from "../../../Assest/images/Affiliate/easy-accessible-efficient.webp"
import img3 from "../../../Assest/images/Affiliate/hefty-commissions.webp"
import img4 from "../../../Assest/images/Affiliate/multitier-system.webp"
import img5 from "../../../Assest/images/Affiliate/offerings.webp"
import img6 from "../../../Assest/images/Affiliate/stay-up-to-date.webp"

const AffillatePartner = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <section className="slider hota hai wo hatan hai bootm se  wo header se hai">
      <NavigatePathComponent showPathName={"Affillate"} />
      <section class="w-full afterFooter max-w-7xl mx-auto justify-items-center justify-center  mt-5 px-2">

        <div className="flex text-4xl font-bold justify-center items-center text-white">
          Come win with us, partner!
        </div>
        <div className="w-full max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 justify-items-center justify-center gap-y-6 gap-x-6 mt-10 mb-5">
          <div class="w-full bg-[#1E1E1E] shadow-md border border-[#f36c21] rounded-xl h-40">
            <div className="px-4 pt-4  w-full flex items-center gap-2">
              <span className="h-12 w-12 rounded-full bg-[#f36c21] flex justify-center items-center">
                <img
                  src={img3}
                  alt="Product"
                  class="h-7 w-7 object-cover rounded-t-xl"
                />
              </span>
              <h3 className="text-lg font-normal text-gray-200 block capitalize">
                Hefty Commissions
              </h3>
            </div>
            <div className="px-4 py-1 w-full">
              <p className="text-ms tracking-tighter font-thin text-white text-opacity-70 block capitalize">
                We offer a commission rate upto 40% subject to differ based
                on number of clients and the estimated turnover.
              </p>
            </div>
          </div>
          <div class="w-full bg-[#1E1E1E] shadow-md border border-[#f36c21] rounded-xl h-40">
            <div className="px-4 pt-4  w-full flex items-center gap-2">
              <span className="h-12 w-12 rounded-full bg-[#f36c21] flex justify-center items-center">
                <img
                  src={img4}
                  alt="Product"
                  class="h-7 w-7 object-cover rounded-t-xl"
                />
              </span>
              <h3 className="text-lg font-normal text-gray-200 block capitalize">
                Multi Tier System
              </h3>
            </div>
            <div className="px-4 py-1 w-full">
              <p className="text-ms tracking-tighter font-thin text-white text-opacity-70 block capitalize">
                Know someone that knows everyone? Bring in an affiliate and
                earn commission on the players that come with them!
              </p>
            </div>
          </div>
          <div class="w-full bg-[#1E1E1E] shadow-md border border-[#f36c21] rounded-xl h-40">
            <div className="px-4 pt-4  w-full flex items-center gap-2">
              <span className="h-12 w-12 rounded-full bg-[#f36c21] flex justify-center items-center">
                <img
                  src={img5}
                  alt="Product"
                  class="h-7 w-7 object-cover rounded-t-xl"
                />
              </span>
              <h3 className="text-lg font-normal text-gray-200 block capitalize">
                Offerings
              </h3>
            </div>
            <div className="px-4 py-1 w-full">
              <p className="text-ms tracking-tighter font-thin text-white text-opacity-70 block capitalize">
                {websiteName} is the world’s biggest sports betting exchange with
                a range of live casino and live Indian card games and the
                best odds for sports betting!
              </p>
            </div>
          </div>
          <div class="w-full bg-[#1E1E1E] shadow-md border border-[#f36c21] rounded-xl h-40">
            <div className="px-4 pt-4  w-full flex items-center gap-2">
              <span className="h-12 w-12 rounded-full bg-[#f36c21] flex justify-center items-center">
                <img
                  src={img2}
                  alt="Product"
                  class="h-7 w-7 object-cover rounded-t-xl"
                />
              </span>
              <h3 className="text-lg font-normal text-gray-200 block capitalize">
                Easy, Accessible, Efficient
              </h3>
            </div>
            <div className="px-4 py-1 w-full">
              <p className="text-ms tracking-tighter font-thin text-white text-opacity-70 block capitalize">
                {websiteName} brings advanced online gambling to your fingertips
                in the most easy and user friendly way there is!
              </p>
            </div>
          </div>
          <div class="w-full bg-[#1E1E1E] shadow-md border border-[#f36c21] rounded-xl h-40">
            <div className="px-4 pt-4  w-full flex items-center gap-2">
              <span className="h-12 w-12 rounded-full bg-[#f36c21] flex justify-center items-center">
                <img
                  src={img1}
                  alt="Product"
                  class="h-7 w-7 object-cover rounded-t-xl"
                />
              </span>
              <h3 className="text-lg font-normal text-gray-200 block capitalize">
                Stay Up To Date
              </h3>
            </div>
            <div className="px-4 py-1 w-full">
              <p className="text-ms tracking-tighter font-thin text-white text-opacity-70 block capitalize">
                We believe with money matters, transparency is key! Access
                detailed data of the players’ activities and your downline’s
                performance in our affiliate backend.
              </p>
            </div>
          </div>
          <div class="w-full bg-[#1E1E1E] shadow-md border border-[#f36c21] rounded-xl h-40">
            <div className="px-4 pt-4  w-full flex items-center gap-2">
              <span className="h-12 w-12 rounded-full bg-[#f36c21] flex justify-center items-center">
                <img
                  src={img6}
                  alt="Product"
                  class="h-7 w-7 object-cover rounded-t-xl"
                />
              </span>
              <h3 className="text-lg font-normal text-gray-200 block capitalize">
                24*7 Customer Support
              </h3>
            </div>
            <div className="px-4 py-1 w-full">
              <p className="text-ms tracking-tighter font-thin text-white text-opacity-70 block capitalize">
                For everything else, there’s the dedicated Client Services
                team available round the clock!
              </p>
            </div>
          </div>
        </div>

        <div class="w-full rounded-xl overflow-x-auto">
          <table class="w-full text-sm rounded-xl text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class=" w-full text-xs text-gray-200 uppercase bg-[#f36c21] dark:[#f36c21] dark:text-white">
              <tr><th scope="col" class="px-6 py-3"> % </th>
                <th scope="col" class="px-6 py-3">Deposites</th>
                <th scope="col" class="px-6 py-3">Min User Active</th>
                <th scope="col" class="px-6 py-3">New users</th>
              </tr>
            </thead>
            <tbody>
              <tr class="  dark:bg-gray-800 dark:border-gray-700 text-white bg-[#616161]">
                <th scope="row" class="px-6 py-4 font-medium text-white whitespace-nowrap dark:text-white"> {" "}15 </th>
                <td class="px-6 py-4"> 200k to 2000k</td>
                <td class="px-6 py-4"> 5</td>
                <td class="px-6 py-4">3 </td>
              </tr>
              <tr class="bg-[#808080] hover:bg-[#616161] hover:border-b hover:border-t dark:bg-gray-500 dark:border-gray-700 text-white">
                <th scope="row" class="px-6 py-4 font-medium text-white whitespace-nowrap dark:text-white">{" "}20{" "}</th>
                <td class="px-6 py-4"> 2000k to 5000k</td>
                <td class="px-6 py-4"> 20 </td>
                <td class="px-6 py-4"> 10 </td>
              </tr>
              <tr class="bg-[#616161] dark:bg-gray-800 text-white">
                <th scope="row" class="px-6 py-4 font-medium text-white whitespace-nowrap dark:text-white"> {" "}  30</th>
                <td class="px-6 py-4"> 5000k to above </td>
                <td class="px-6 py-4"> 50</td>
                <td class="px-6 py-4"> 25</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="py-2 px-2 text-[#f36c21]">
          Note* :- Settlement 1st week of next month
        </div>
      </section>
    </section>
  );
};
export default AffillatePartner;
