import React from 'react'
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

function NavigatePathComponent({ showPathName }) {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    return (
        <div className="bg-[#272727]  w-full px-3 py-1 flex lg:justify-center justify-start">
            <div className="lg:w-3/4 flex items-center px-4">
                <div className="hover:bg-[#372D27] hover:rounded-full p-1">
                    <MdOutlineKeyboardArrowLeft
                        className="text-[#f36c21] font-bold cursor-pointer"
                        size={25}
                        onClick={() => goBack()}
                    />
                </div>
                <span className="px-1 text-white text-sm font-bold">
                    {showPathName && showPathName ? showPathName : ""}
                </span>
            </div>
        </div>
    )
}

export default NavigatePathComponent