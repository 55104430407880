import React, { useState, useEffect } from 'react'

function Blinking({ price, size, color, blinkColor, isLast }) {


    const [blink, setBlink] = useState(false);
    const [prevPrice, setPrevPrice] = useState(price);
    const [prevSize, setPrevSize] = useState(size);
    useEffect(() => {
        if (price !== prevPrice || size !== prevSize) {
            setBlink(true);
            const timeout = setTimeout(() => {
                setBlink(false);
                setPrevPrice(price);
                setPrevSize(size);
                setBlink(false);
            }, 500); // Blinking duration
            return () => clearTimeout(timeout);
        }
    }, [price, size, prevPrice, prevSize, color, blinkColor]);



    return (
        <div className={` flex justify-center items-center py-0.5 ${blink ? `bg-[#f8ff65]` : `${color}`} ${isLast ? 'rounded-br-2xl' : ''}`}>
            <div className='text-center  leading-3'>
                <span className="2xl:text-sm lg:text-sm text-xs font-bold">{price ? price : "-"}</span><br />
                <span className="2xl:text-xs lg:text-[10px] text-xs">{size ? size : "-"}</span>
            </div>
        </div>
    )
}

export default Blinking

