import React, { useEffect, useRef, useState } from "react";
import { httpPost } from "../../../middelware/Http.js";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { MdKeyboardArrowDown } from "react-icons/md";
import { BiSolidError } from "react-icons/bi";
import Blinking from "../../../component/Blinking/Blinking.js";
import { useDispatch } from "react-redux";
import axios from "axios";
import io from "socket.io-client";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { BetPlaceDesktop } from "../../../component/Auth/BetPlace/BetPlaceDesktop.js"
import { userAction } from "../../../redux/action/userAction.js";
import { TbLadder } from "react-icons/tb";
import moment from 'moment';
import SportListSidebar from "../../../component/Auth/SportListSidebar/SportListSidebar.js";
import FancyLadder from "../../../component/Auth/FancyLadder/FancyLadder.js";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BetPlaceMobile } from "../../../component/Auth/BetPlace/BetPlaceMobile.js";
import NavigatePathComponent from "../../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent.js";
import fastimg from "../../../../src/Assest/images/MAtchviewDetails/fast.055d02d.png"
import OpenBetsMobile from "../../../component/Auth/OpenBet/OpenBetMobile.js";
import { MdOutlineSportsScore } from "react-icons/md";
import { PiTelevisionDuotone, PiTelevisionFill } from "react-icons/pi";
import { RiEqualizerLine } from "react-icons/ri";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { LuScreenShare } from "react-icons/lu";
import { message } from "antd";

const MatchViewDeatils = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [inplayMatch, setInplayMatch] = useState({});
  const [scoreShow, setScoreShow] = useState(true);
  const [tvShow, setTvShow] = useState(false);
  const [betShow, setBetShow] = useState(true);
  const [betShowMobile, setBetShowMobile] = useState(false);
  const [matchScoreDetails, setMatchScoreDetails] = useState({});
  const [matchDetailsForSocketNew, setMatchDetailsForSocketNew] = useState({});
  const [finalSocket, setFinalSocketDetails] = useState({});
  const [isOpenRightSidebar, setIsOpenRightSidebar] = useState(false);
  const [hiddenRows, setHiddenRows] = useState([]);
  const [active, setActive] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [betSlipData, setBetSlipData] = useState({
    stake: '0',
    count: 0,
    teamname: '',
    teamData: null
  });

  const [fancyBetData, setFancyBetData] = useState([])
  const [oddsBetData, setOddsBetData] = useState([])
  const [returnDataObject, setReturnDataObject] = useState({})
  const [returnDataFancyObject, setReturnDataFancyObject] = useState({})
  const [fancypositionModal, setFancypositionModal] = useState(false);
  const [positionData, setPositionData] = useState({});
  const [betLoading, setBetLoading] = useState(false)
  const scrollRef = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  const [socketState, setSocketState] = useState(null);

  const [positionObj, setPositionObj] = useState({});
  const [positioBetData, setPositionBetData] = useState({});

  const [fancyPositionObj, setFancyPositionObj] = useState({});
  const [fancybetData, setFancybetData] = useState({});
  const [fullscreen,setFullScreen] = useState(false)





  // const [betPlaceModalMobile, setBetPlaceModalMobile] = useState(false);


  let { marketId, eventId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const gameDetailOtherPart = pathname.includes('viewMatchDetail');


  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      const threshold = 100;
      setIsFixed(scrollPosition > threshold);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [])


  let data = localStorage.getItem(`${marketId}_BookmakerData`)
  const setDataFromLocalstorage = async (marketId) => {
    if (data) {
      setMatchScoreDetails(JSON.parse(data).result);
    } else {
      setMatchScoreDetails("");
    }
  }


  useEffect(() => {
    setDataFromLocalstorage()
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && isConnected && inplayMatch?.data?.socketUrl) {
        callSocket(inplayMatch?.data?.socketUrl);
      } else if (document.visibilityState === 'hidden') {
        cleanupWebSocket();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    setupAsyncActions(marketId);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      cleanupWebSocket();
    };
  }, [eventId, marketId, isConnected]);

  const [oddsbetdata, setOddsbetData] = useState();
  const [incomletedFancy, setIncompletedFancy] = useState();
  const [compltedFancy, setCompletedFancy] = useState();



  useEffect(() => {
    if (positioBetData) {



      const sortedOddsBetData = positioBetData?.oddsBetData
        ? positioBetData?.oddsBetData
          .slice()
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        : [];

      const filteredFancyBetData = positioBetData?.fancyBetData
        ? positioBetData?.fancyBetData.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
        : [];

      const completeFancy =
        filteredFancyBetData && filteredFancyBetData.length > 0
          ? filteredFancyBetData.filter((element) => element.isDeclare === 1)
          : [];
      let showCompletedFancy = [];

      completeFancy.map((data, key) => {
        let pos = 0;
        if (data.decisionRun >= data.run && data.type === "Y") {
          pos = Math.round(data.amount * data.odds);
        } else if (data.decisionRun >= data.run && data.type === "N") {
          pos = Math.round(-1 * data.amount * data.odds);
        } else if (data.decisionRun < data.run && data.type === "Y") {
          pos = Math.round(-1 * data.amount);
        } else if (data.decisionRun < data.run && data.type === "N") {
          pos = Math.round(data.amount);
        }
        data.pos = pos;
        completeFancy[key].pos = pos

        showCompletedFancy.push(data);
      });


      const finalPositionInfo = {};
      sortedOddsBetData && sortedOddsBetData.forEach(item => {
        const positionInfo = item.positionInfo;

        for (const key in positionInfo) {
          if (positionInfo.hasOwnProperty(key)) {
            if (!finalPositionInfo[key]) {
              finalPositionInfo[key] = 0;
            }
            finalPositionInfo[key] += positionInfo[key];
          }
        }
      });



      let finalPositionInfoFancy = {};

      filteredFancyBetData.forEach(item => {
        const selectionId = item.selectionId;
        const loss = item.loss;

        if (finalPositionInfoFancy[selectionId]) {
          finalPositionInfoFancy[selectionId] += loss;
        } else {

          finalPositionInfoFancy[selectionId] = loss;
        }
      });


  

      setFancyPositionObj(finalPositionInfoFancy)
      setFancybetData(filteredFancyBetData);


      setPositionObj(finalPositionInfo)
      setOddsbetData(sortedOddsBetData);
      setCompletedFancy(showCompletedFancy);
      setIncompletedFancy(
        filteredFancyBetData && filteredFancyBetData.length > 0
          ? filteredFancyBetData.filter((element) => element.isDeclare === 0)
          : []
      );
    }
  }, [positioBetData]);




  const setupAsyncActions = async (marketId) => {
    await getMatchDataByMarketID(marketId);
    fetchBetLists();
    // userPositionByMarketId(marketId);
  };

  const cleanupWebSocket = () => {
    if (socketState) {
      socketState.disconnect();
      setSocketState(null);
    }
  };



  const getMatchDataByMarketID = async (marketId) => {
    try {
      const resData = {
        marketId: marketId,
      };
      const inplayMatchResponse = await httpPost("sports/sportByMarketId", resData);
      if (inplayMatchResponse && inplayMatchResponse.data) {
        setInplayMatch(inplayMatchResponse.data);
        const data = inplayMatchResponse?.data;

        if (inplayMatchResponse?.data?.socketPerm) {
          callSocket(inplayMatchResponse?.data?.socketUrl,);
        } else {
          callCache(inplayMatchResponse?.data?.cacheUrl);
        }

        // callSocket(inplayMatchResponse?.data?.socketUrl, inplayMatchResponse?.data?.socketPerm, inplayMatchResponse?.data?.cacheUrl);

      }
    } catch (error) {
      console.error("Error fetching inplay data:", error);
    }
    finally {
      setIsLoading(false);

    }
  };




  const callSocket = async (socketUrl) => {
    if (socketState && socketState.connected) {
      return;
    }
    try {
      const socket = io.connect(socketUrl, {
        transports: ["websocket"],
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 99,
      });

      socket.emit(`marketByEvent`, eventId);
      socket.on(eventId, (data) => {
        setMatchDetailsForSocketNew(JSON.parse(data));
        setIsConnected(true);
        filterData(JSON.parse(data));
      });


      socket.emit("JoinRoom", marketId);
      socket.on(marketId, (data) => {
        localStorage.setItem(`${marketId}_BookmakerData`, data)
        setMatchScoreDetails(JSON.parse(data).result);
      });
      socket.on('disconnect', () => {
        setIsConnected(false);
      });

      setSocketState(socket);

    }

    catch (error) {
      console.error("Error in socket connection:", error);
    }
  };


  const callCache = async (cacheUrl) => {
    try {
      const interval = setInterval(async () => {
        await getMarketCacheUrl(cacheUrl);
      }, 1000);
      return () => clearInterval(interval);
    } catch (error) {
      console.error("Error calling cache:", error);
    }
  };

  const getMarketCacheUrl = async (cacheUrl) => {
    try {
      // if (!cacheUrl) {
      //   console.error("Cache URL is undefined or null");
      //   return; // Exit early if cacheUrl is undefin
      // }

      const response = await axios.get(cacheUrl);
      localStorage.setItem(`${marketId}_BookmakerData`, JSON.stringify(response.data))
      setMatchScoreDetails(response.data.result);
    } catch (error) {
      console.error("Error fetching cache data:", error);
    }
  };



  const filterData = (matchDetailsForSocketNew) => {


    try {

      if (!matchDetailsForSocketNew || matchDetailsForSocketNew.length === 0) {
        throw new Error("No data available to filter.");
      }

      const criteria = ['Tied Match', 'Match Odds', 'To Win the Toss'];

      const filteredData = Array.isArray(matchDetailsForSocketNew) ? matchDetailsForSocketNew.filter(item => criteria.includes(item.marketType)) : [];

      if (filteredData.length > 0) {
        const filteredDataObject = [];
        filteredData.forEach(item => {
          filteredDataObject[item.marketType] = item;
        });

        setFinalSocketDetails(filteredDataObject)

      } else {
        console.error("No data matched the criteria.");
      }
    } catch (error) {
      console.error("Error filtering data:", error);
    }
  };






  const handleOnClick = () => {
    navigate("/");
  };

  // const handelScoreModal = () => {
  //   setScoreShow(!scoreShow);
  // };

  const handelScoreModal = () => {
    setScoreShow(true);
    setTvShow(false);
    setBetShowMobile(false)
  };
  const handelTvModal = () => {
    setTvShow(!tvShow);
    setScoreShow(true);
    setBetShowMobile(false)

  };

  const handelAllClossModal = () => {
    setTvShow(false);
    setScoreShow(!scoreShow);

  };


  const openBets = () => {
    setBetShow(true);
  };

  const openBetsClose = () => {
    setBetShow(false);
  };




  const toggleAccordion = (index) => {
    setActive((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };



  // bets Palce Modal write 

  const handleBackOpen = (data) => {
    // setBetPlaceModalMobile(true)
    if (data) {
      setBetShow(false);
      setBetSlipData({
        ...data,
        stake: '0',
        count: data.odds,
        teamname: data.name,
        teamData: data.teamData
      });
    }
  };

  const toggleRowVisibility = (id) => {
    if (hiddenRows.includes(id)) {
      setHiddenRows(hiddenRows.filter(rowId => rowId !== id));
    } else {
      setHiddenRows([...hiddenRows, id]);
    }
  };

  const userPositionByMarketId = async () => {

    const positionByMarketId = {
      marketId: marketId,
    };
    try {
      const userPositionData = await httpPost('sports/userPositionByMarketId', positionByMarketId);
      if (userPositionData) {
        const getUserPositionItem = userPositionData.data;
        let oddsPos = [];
        let sessPos = [];
        let returnDataObject = {};
        let returnDataFancyObject = {};
        if (getUserPositionItem?.oddsPosition) {
          oddsPos = getUserPositionItem.oddsPosition;
          oddsPos.forEach(data => {
            const hasKey = returnDataObject.hasOwnProperty(data._id);
            if (!hasKey) {
              returnDataObject[data._id] = data.totalPosition.toFixed(2);
              setReturnDataObject(returnDataObject);

            }
          });
        }

        if (getUserPositionItem?.sessionPosition) {
          sessPos = getUserPositionItem.sessionPosition;
          sessPos.forEach(data => {
            const hasKey = returnDataFancyObject.hasOwnProperty(data._id);
            if (!hasKey) {
              returnDataFancyObject[data._id] = data.position;
              setReturnDataFancyObject(returnDataFancyObject);
            }
          });
        }
      }
    } catch (error) {
      console.error('Error fetching user position:', error);
      throw error;
    }
  };

  const placeBet = async () => {
    try {
      const betObject = {
        "odds": betSlipData.count + "",
        "amount": betSlipData.stake,
        "selectionId": betSlipData.selectionId + "",
        "marketId": marketId + "",
        "eventId": eventId,
        "betFor": betSlipData.betFor + "",
        "run": betSlipData.run ? betSlipData.run + "" : "0",
        "oddsType": betSlipData.oddsType === "Match Odds" ? "matchOdds" : betSlipData.oddsType === "Tied Match" ? "tiedMatch" : betSlipData.oddsType + "",
        "type": betSlipData.betType + "",
      };

      if (betSlipData.oddsType === "bookmaker" || betSlipData.oddsType === "fancy") {
        // Do something if needed
      } else {
        betObject["betfairMarketId"] = betSlipData.betfairMarketId + "";
      }
      setBetLoading(true)
      // this.setState({ betLoading: true, betPlaceModal: false })
      if (betSlipData.oddsType === "fancy") {
        let saveBetOdds = await httpPost("sports/sessionBetPlaced", betObject);
        setBetLoading(false)
        setBetShow(true)
        // setBetPlaceModalMobile(false)

        if (!saveBetOdds.error) {
          // const toastId = toast.success("Success! Your bet has been placed");
          // setTimeout(() => toast.dismiss(toastId), 1000);
         message.success("Success! Your bet has been placed");
          // await userPositionByMarketId()
          await fetchBetLists()
          await matchOddsPos()
          dispatch(userAction.userBalance());
          // toast.success(saveBetOdds.message, {
          //   autoClose: 500,
          // });
        } else {
          // const toastId = toast.error("Sorry! your bet couldn't be placed.");
          // setTimeout(() => toast.dismiss(toastId), 1000);

          message.error("Sorry, your bet couldn't be placed.");
        }
      } else {
        let saveBetOdds = await httpPost("sports/oddBetPlaced", betObject);

        setBetLoading(false)
        setBetShow(true);
        // setBetPlaceModalMobile(false);

        if (!saveBetOdds.error) {
          message.success("Success! Your bet has been placed");
      
          // await userPositionByMarketId()
          await fetchBetLists()
          await matchOddsPos()
          dispatch(userAction.userBalance());

          // toast.success(saveBetOdds.message, {
          //   autoClose: 500,
          // });
        } else {
          message.error("Sorry! your bet couldn't be placed.");
         
          // toast.success("Sorry, your bet couldn't be placed.");
        }
      }

    } catch (error) {
      console.error('Error placing bet:', error);
    }
  };


  const fetchBetLists = async () => {
    try {

      const BetListData = {
        fancyBet: true,
        isDeclare: false,
        oddsBet: true,
        marketId: marketId,
      };

      const userBetHistory = await httpPost('sports/betsList', BetListData);

      if (userBetHistory && userBetHistory.data) {
        const { fancyBetData, oddsBetData } = userBetHistory.data;
        const filteredFancyBetData = fancyBetData ? fancyBetData.filter(element => element.isDeclare === 0).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
        const sortedOddsBetData = oddsBetData ? oddsBetData.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
        setFancyBetData(filteredFancyBetData)
        setOddsBetData(sortedOddsBetData)
        setPositionBetData(userBetHistory.data)
        // return { fancyBetData: filteredFancyBetData, oddsBetData: sortedOddsBetData };
      }
    } catch (error) {
      console.error('Error fetching bet lists:', error);
      throw error;
    }
  };


  const matchOddsPos = async () => {
    let matchOddsPos = await httpPost('reports/matchOddsRunningPos');
    if (matchOddsPos) {
      localStorage.setItem('matchOddsRunningPos', JSON.stringify(matchOddsPos.data));
    }
  }



  const handleFancyPositionModal = (data) => {

    try {
      setFancypositionModal(!fancypositionModal);
      setPositionData(data);
    } catch (error) {
      console.error('Error handling fancy position modal:', error);
    }
  };

  const handleClose = () => {
    setFancypositionModal(false)
  };


  const closeRow = (id) => {
    setHiddenRows(hiddenRows.filter(rowId => rowId !== id));
  }



  const increaseCount = () => {
    try {
      setBetSlipData(prevData => {
        const newCount = parseFloat(prevData.count) + 0.01;
        return {
          ...prevData,
          count: newCount.toFixed(2)
        };
      });
    } catch (error) {
      console.error('Error increasing count:', error);
    }
  };
  const openBetInMobile = () => {
    setBetShowMobile(!betShowMobile)
    setTvShow(false);
    setScoreShow(false);
  }
  const decreaseCount = () => {
    try {
      setBetSlipData(prevData => {
        const newCount = parseFloat(prevData.count) - 0.01;
        return {
          ...prevData,
          count: newCount.toFixed(2)
        };
      });
    } catch (error) {
      console.error('Error decreasing count:', error);
    }
  };





  return (


    <section className="afterFooter">
      <div className="bg-[#272727] w-full px-3 flex justify-center">
        <NavigatePathComponent showPathName={inplayMatch && inplayMatch?.matchName
          ? inplayMatch?.matchName
          : ""} />

      </div>
      <div className="w-full  relative bg-black">
        <div className="flex w-full">
          <div className="lg:w-1/6  w-full hidden lg:block py-2">
            <div className="w-full bg-black flex justify-center">
              <div className="w-11/12 border-2 border-[#f36c21]  rounded-3xl">
                <SportListSidebar marketId={marketId} />
              </div>
            </div>
          </div>
          <div className="lg:w-7/12 w-full h-auto lg:px-1">
            {isLoading ? (
              <Skeleton count={2} />
            ) : (
              <>
                <div className="border-t-gray-700 bg-gray-600 w-full px-1 py-1 flex gap-1 justify-end">

                  <div className="text-white flex gap-1 items-left justify-end py-2 bg-[#272727] px-1 rounded-md lg:hidden"
                    onClick={() => openBetInMobile()}
                  >
                    <span className="text-xs gap-1 uppercase flex justify-center items-center text-white font-bold px-1">
                      <span><RiEqualizerLine size={18} /></span>
                      <span>Bet</span>
                    </span>
                  </div>  

                  <div className="text-white flex gap-1 items-left justify-end py-2 bg-[#272727] px-1 rounded-md "
                    onClick={() => setFullScreen(state=>!state)}
                  >
                    <span className="text-xs gap-1 uppercase flex justify-center items-center text-white font-bold px-1">
                      <span><LuScreenShare  size={18} /></span>
                      <span>{fullscreen ? "HS" : "FS"}</span>
                    </span>
                  </div> 
                  {inplayMatch.isTv && (
                  <div
                      className="text-white flex gap-1 items-center bg-[#272727]   rounded-md"
                      onClick={() => handelTvModal()}
                    >
                      <span className="text-xs  text-white font-bold px-4 flex justify-center items-center gap-1">
                        <span><PiTelevisionFill size={20} /></span>
                        <span>Tv</span>

                      </span>
                    </div>  )}



                  {inplayMatch.isScore && (
                    <div className="text-white flex gap-1 items-center justify-end py-2 bg-[#272727] px-1  rounded-md"
                      onClick={() => handelScoreModal()}
                    >
                      <span className="text-xs gap-1 uppercase flex justify-center items-center text-white font-bold px-1">
                        <span><MdOutlineSportsScore size={22} /></span>
                        <span>Score</span>
                      </span>
                    </div>
                  )}
                  
                  

                  <div
                    className="text-white flex gap-1 py-2 items-center bg-green-800 px-2  rounded-md"
                    onClick={() => handelAllClossModal()}
                  >
                    <span className=" flex justify-center gap-1 items-center uppercase">
                      {tvShow || scoreShow ? (
                        <>
                          <FaLockOpen size={18} className="text-white" />
                          <span className="text-ms text-white font-bold ">
                            open
                          </span>
                        </>


                      ) : (
                        <>
                          <FaLock size={18} className="text-white" />
                          <span className="text-ms text-white font-bold ">
                            Close
                          </span>
                        </>

                      )}
                    </span>

                  </div>
                </div>



                {inplayMatch.isTv ? <>
                  {tvShow && <div className="bg-white w-full h-72">
                    <div className="details">
                      <div className={`w-full relative md:text-sm text-[10px]`}>
                        <iframe src={inplayMatch && inplayMatch.tvUrl ? inplayMatch.tvUrl : ""} title=" " loading='lazy' className="w-[100%] h-[300px]" />
                      </div>
                    </div>
                  </div>}
                </>
                  : null}


                {inplayMatch.isScore ? <>
                  {scoreShow && <div className={`bg-white w-full ${fullscreen  ? "h-[220px]" : "h-[110px]"}`}>
                    <div className="details">
                      <div className={`w-full relative md:text-sm text-[10px]`}>
                        <iframe src={inplayMatch && inplayMatch.scoreIframe ? inplayMatch.scoreIframe : ""} title=" " loading='lazy' className={`w-[100%] ${fullscreen  ? "h-[220px]" : "h-[110px]"}`} />
                      </div>
                    </div>

                  </div>}
                </>
                  : null}

                {betShowMobile ? (
                  <OpenBetsMobile marketId={marketId} />
                ) : (

                  <div className="bg-[#1e1e1e] h-auto mb-2 overflow-y-auto py-2">
                    <div className=" py-2 px-2 space-y-4">
                      {inplayMatch?.isMatchOdds ? (
                        <>
                          {Object.values(finalSocket).map((element, index) => element.marketType === "Match Odds" && (
                            <div>
                              <div className="rounded-2xl divide-y divide-gray-300">
                                <header className="bg-[#333333] rounded-t-2xl">
                                  <div className=" items-center flex relative z-0">
                                    <div className="w-4/12 rounded-tl-2xl py-2 px-2 bg-[#f36c21] flex">
                                      <span className="uppercase items-center inline-flex text-sm font-bold text-white ">
                                        {element && element.marketType
                                          ? element.marketType
                                          : "NA"}
                                      </span>
                                      <span></span>
                                    </div>
                                    <div className="w-4/12"></div>
                                    <div className="w-4/12  bg-[#333333]  rounded-tr-2xl flex flex-wrap justify-center items-center">
                                      <div className=" w-full bg-[#333333] rounded-tr-2xl flex flex-wrap justify-center px-4 py-2 items-center">
                                        <span className="w-4/12 text-white text-sm font-normal text-center">
                                          1
                                        </span>
                                        <span className="w-4/12 text-white text-sm font-normal text-center">
                                          X
                                        </span>
                                        <span className="w-4/12 text-white text-sm font-normal text-center">
                                          2
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </header>

                                {element && element.runners && element.runners.length > 0 ? element.runners.map((elementtemp, index) => (
                                  <>
                                    <div
                                      className={` bg-white shadow-sm flex decoration-none whitespace-normal max-w-full `}
                                    >
                                      <div className="w-2/3 lg:w-1/2  flex px-4">
                                        <div className="w-11/12  py-1 leading-3 flex items-center text-slate-700">
                                          <span class="text-sm font-bold">
                                            <span>{elementtemp.selectionName} <br />
                                              <span key={index} className={positionObj[elementtemp.selectionId] > 0 ? 'text-green-500' : positionObj[elementtemp.selectionId] < 0 ? 'text-red-500' : 'black'} >
                                                {/* {returnDataObject[elementtemp.selectionId] !== 0 ? returnDataObject[elementtemp.selectionId] : "-"} */}

                                                {positionObj[elementtemp.selectionId] ? (Math.floor(Number(positionObj[elementtemp.selectionId]) * 100) / 100).toFixed(2) : 0}

                                              </span>
                                            </span>


                                          </span>
                                        </div>


                                      </div>
                                      <div className="w-1/3 lg:w-1/2 grid grid-cols-6 space-x-0.5 ">
                                        {elementtemp && elementtemp.ex && elementtemp.ex.availableToBack && elementtemp.ex.availableToBack.length > 0 ? (
                                          <>
                                            {elementtemp.ex.availableToBack.slice(1).map((tempData, index) => (
                                              <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden">
                                                <Blinking
                                                  price={tempData.price}
                                                  size={tempData.size}
                                                  color={"bg-[#a5d9fe]"}
                                                  blinkColor={"bg-[#CDEBEB]"}
                                                />
                                              </span>

                                            ))}
                                          </>
                                        ) : null}

                                        {elementtemp && elementtemp.ex && elementtemp.ex.availableToBack && elementtemp.ex.availableToBack.length > 0 ? (
                                          <>
                                            {elementtemp.ex.availableToBack.slice(0, 1).map((tempData, index) => (
                                              <>
                                                <span className="md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 py-1 lg:hidden block"
                                                  onClick={() => {
                                                    toggleRowVisibility(elementtemp.selectionId);

                                                    handleBackOpen({
                                                      data: tempData,
                                                      type: "Yes",
                                                      odds: tempData.price,
                                                      name: elementtemp.selectionName,
                                                      nameOther: element.runners,
                                                      betFor: "matchOdds",
                                                      oddsType: element.marketType,
                                                      betType: "L",
                                                      selectionId: elementtemp.selectionId,
                                                      teamData: tempData.price,
                                                      betfairMarketId: element.marketId,
                                                      price: elementtemp.ex.availableToLay[0].price,
                                                      size: elementtemp.ex.availableToLay[0].size,
                                                      position: returnDataObject,
                                                      newPosition: returnDataObject
                                                    });

                                                  }}
                                                >
                                                  <Blinking
                                                    price={tempData.price}
                                                    size={tempData.size}
                                                    color={"bg-[#a5d9fe]"}
                                                    blinkColor={"bg-[#CDEBEB]"}
                                                  />
                                                </span>
                                                <span className="lg:col-span-1 col-span-3 py-1 lg:block hidden"
                                                  onClick={() => {

                                                    handleBackOpen({
                                                      data: tempData,
                                                      type: "Yes",
                                                      odds: tempData.price,
                                                      name: elementtemp.selectionName,
                                                      nameOther: element.runners,
                                                      betFor: "matchOdds",
                                                      oddsType: element.marketType,
                                                      betType: "L",
                                                      selectionId: elementtemp.selectionId,
                                                      teamData: tempData.price,
                                                      betfairMarketId: element.marketId,
                                                      price: elementtemp.ex.availableToLay[0].price,
                                                      size: elementtemp.ex.availableToLay[0].size,
                                                      position: returnDataObject,
                                                      newPosition: returnDataObject
                                                    })
                                                  }}
                                                >
                                                  <Blinking
                                                    price={tempData.price}
                                                    size={tempData.size}
                                                    color={"bg-[#a5d9fe]"}
                                                    blinkColor={"bg-[#CDEBEB]"}
                                                  />
                                                </span>
                                              </>

                                            ))}
                                          </>
                                        ) : null}

                                        {elementtemp &&
                                          elementtemp.ex &&
                                          elementtemp.ex.availableToLay &&
                                          elementtemp.ex.availableToLay.length >
                                          0 ? (
                                          elementtemp.ex.availableToLay.map(
                                            (tempData, index) => (
                                              <>
                                                {index === 0 ? (
                                                  <>
                                                    <span className="md:col-span-2 sm:col-span-2 md:col-start-4 col-span-3 py-1 lg:hidden block"
                                                      onClick={() => {
                                                        toggleRowVisibility(elementtemp.selectionId);
                                                        handleBackOpen({
                                                          data: tempData,
                                                          type: "No",
                                                          odds: tempData.price,
                                                          name: elementtemp.selectionName,
                                                          nameOther: element.runners,
                                                          betFor: "matchOdds", oddsType: element.marketType,
                                                          betType: "K",
                                                          selectionId: elementtemp.selectionId,
                                                          teamData: tempData.price,
                                                          betfairMarketId: element.marketId,
                                                          price: elementtemp.ex.availableToBack[0].price,
                                                          size: elementtemp.ex.availableToBack[0].size,
                                                          position: returnDataObject,
                                                          newPosition: returnDataObject
                                                        })
                                                      }}
                                                    >
                                                      <Blinking
                                                        price={tempData.price}
                                                        size={tempData.size}
                                                        color={"bg-[#f8d0ce]"}
                                                        blinkColor={"bg-[#CDEBEB]"}
                                                      />
                                                    </span>

                                                    <span className="lg:col-span-1 col-span-3 py-1 lg:block hidden"
                                                      onClick={() => {

                                                        handleBackOpen({

                                                          data: tempData,
                                                          type: "No",
                                                          odds: tempData.price,
                                                          name: elementtemp.selectionName,
                                                          nameOther: element.runners,
                                                          betFor: "matchOdds", oddsType: element.marketType,
                                                          betType: "K",
                                                          selectionId: elementtemp.selectionId,
                                                          teamData: tempData.price,
                                                          betfairMarketId: element.marketId,
                                                          price: elementtemp.ex.availableToBack[0].price,
                                                          size: elementtemp.ex.availableToBack[0].size,
                                                          position: returnDataObject,
                                                          newPosition: returnDataObject
                                                        })
                                                      }}
                                                    >
                                                      <Blinking
                                                        price={tempData.price}
                                                        size={tempData.size}
                                                        color={"bg-[#f8d0ce]"}
                                                        blinkColor={"bg-[#CDEBEB]"}
                                                      />
                                                    </span>
                                                  </>
                                                ) : (
                                                  <>
                                                    <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden"

                                                    >
                                                      <Blinking
                                                        price={tempData.price}
                                                        size={tempData.size}
                                                        color={"bg-[#f8d0ce]"}
                                                        blinkColor={"bg-[#CDEBEB]"}
                                                      />
                                                    </span>
                                                  </>

                                                )}
                                              </>
                                            )
                                          )) : null}
                                      </div>
                                    </div>


                                    {hiddenRows.includes(elementtemp.selectionId) && (
                                      <BetPlaceMobile
                                        closeRow={closeRow}
                                        closeSec={elementtemp.selectionId}
                                        matchName={inplayMatch?.matchName}
                                        openBets={openBets}
                                        betSlipData={betSlipData}
                                        placeBet={placeBet}
                                        count={betSlipData.count}
                                        betLoading={betLoading}
                                        increaseCount={increaseCount}
                                        decreaseCount={decreaseCount}
                                      />
                                    )}


                                  </>
                                )) : null}
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}

                      {inplayMatch?.isBookmaker ? (
                        <div>
                          {matchScoreDetails &&
                            matchScoreDetails.team_data &&
                            matchScoreDetails.team_data.length > 0 ? (
                            <>
                              <header className="bg-[#333333] rounded-t-2xl">
                                <div className=" items-center flex relative z-0">
                                  <div className="w-2/3 flex ">
                                    <div className="w-1/2 rounded-tl-2xl py-3 bg-[#f36c21]  uppercase px-2 items-center inline-flex text-sm font-bold text-white">
                                      Book Maker
                                    </div>
                                    <div className="w-1/2 px-2 flex items-center">

                                      <img src={fastimg} alt="textd" className="h-5 w-5" />
                                    </div>
                                  </div>

                                  <div className="w-1/3 bg-[#333333]  rounded-tr-2xl flex flex-wrap justify-center items-center ">
                                    <div className="grid grid-cols-6 space-x-0.5 py-0.5 w-full bg-[#333333] rounded-tr-2xl  flex-wrap justify-center lg:px-4 px-1  items-center">
                                      <span className="md:block hidden"></span>
                                      <span className="md:block hidden"></span>
                                      <span className="lg:col-span-1 col-span-2 lg:col-start-1 text-[#98C8EA] text-sm font-bold text-right px-4">
                                        Yes
                                      </span>
                                      <span className="col-span-2  text-[#E5C0BE] text-sm font-bold text-center px-4">
                                        No
                                      </span>
                                      <span></span>
                                      <span></span>
                                    </div>
                                  </div>
                                </div>
                              </header>
                              {matchScoreDetails &&
                                matchScoreDetails.team_data &&
                                matchScoreDetails.team_data.length > 0
                                ? matchScoreDetails.team_data.map(
                                  (commList, index) => (
                                    <>
                                      <div
                                        key={index}
                                        className={` bg-white relative shadow-sm flex decoration-none whitespace-normal max-w-full h-11`}
                                      >
                                        <div className="w-2/3 lg:w-1/2 flex px-4">
                                          <div className="w-full   leading-3 flex items-center text-slate-700">
                                            <span class="text-sm font-bold">
                                              <span>{commList.team_name}

                                              </span><br />
                                              <span key={index} className={positionObj[commList?.selectionid] > 0 ? 'text-green-600' : positionObj[commList?.selectionid] < 0 ? 'text-red-600' : 'black'} >

                                                {/* {returnDataObject[commList.selectionid] !== 0 ? returnDataObject[commList.selectionid] : "-"} */}
                                                {positionObj[commList?.selectionid] ? (Math.floor(Number(positionObj[commList?.selectionid]) * 100) / 100).toFixed(2) : 0}
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                        <>
                                          <div className="w-1/3 lg:w-1/2 grid grid-cols-6 space-x-0.5 py-0.5">
                                            <span className=" lg:block hidden "></span>
                                            <span className=" lg:block hidden "></span>
                                            <span className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-3 lg:block hidden"
                                              onClick={() => handleBackOpen({ data: commList, nameOther: matchScoreDetails.team_data, type: "Yes", odds: commList.lgaai, name: commList.team_name, betFor: "odds", oddsType: "bookmaker", betType: "L", selectionId: commList.selectionid, teamData: commList.lgaai, betfairMarketId: marketId, price: (commList.khaai * 100), size: "0", position: returnDataObject, newPosition: returnDataObject })}>
                                              <Blinking
                                                price={commList.lgaai}
                                                size={commList.khaai}
                                                color={"bg-[#98C8EA]"}
                                                blinkColor={"bg-[#CDEBEB]"}
                                              />
                                            </span>

                                            <span className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 lg:hidden block "
                                              onClick={() => {
                                                toggleRowVisibility(commList.selectionid);
                                                handleBackOpen({ data: commList, nameOther: matchScoreDetails.team_data, type: "Yes", odds: commList.lgaai, name: commList.team_name, betFor: "odds", oddsType: "bookmaker", betType: "L", selectionId: commList.selectionid, teamData: commList.lgaai, betfairMarketId: marketId, price: (commList.khaai * 100), size: "0", position: returnDataObject, newPosition: returnDataObject })
                                              }}>
                                              <Blinking
                                                price={commList.lgaai}
                                                size={commList.khaai}
                                                color={"bg-[#98C8EA]"}
                                                blinkColor={"bg-[#CDEBEB]"}
                                              />
                                            </span>

                                            <span className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-4  lg:block hidden"
                                              onClick={() => handleBackOpen({ data: commList, nameOther: matchScoreDetails.team_data, type: "No", odds: commList.khaai, name: commList.team_name, betFor: "odds", oddsType: "bookmaker", betType: "K", selectionId: commList.selectionid, teamData: commList.khaai, betfairMarketId: marketId, price: (commList.lgaai * 100), size: "0", position: returnDataObject, newPosition: returnDataObject })}>
                                              <Blinking
                                                price={commList.khaai}
                                                size={commList.lgaai}
                                                color={"bg-[#E5C0BE]"}
                                              />
                                            </span>


                                            <span className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-4  lg:hidden block"
                                              onClick={() => {
                                                toggleRowVisibility(commList.selectionid);
                                                handleBackOpen({ data: commList, nameOther: matchScoreDetails.team_data, type: "No", odds: commList.khaai, name: commList.team_name, betFor: "odds", oddsType: "bookmaker", betType: "K", selectionId: commList.selectionid, teamData: commList.khaai, betfairMarketId: marketId, price: (commList.lgaai * 100), size: "0", position: returnDataObject, newPosition: returnDataObject })
                                              }}>
                                              <Blinking
                                                price={commList.khaai}
                                                size={commList.lgaai}
                                                color={"bg-[#E5C0BE]"}
                                              />
                                            </span>
                                            <span className=" lg:block hidden"></span>
                                          </div>
                                          {commList.lgaai === "0.00" ? (
                                            <div className="w-1/3 px-0.5 right-0 h-full  absolute bg-gray-600 border-gray-900  flex justify-center items-center">
                                              {/* <div className="relative bg-gray-600 border-gray-900 col-span-6 my-0.5 "></div> */}
                                              <div className="2xl:px-8 lg:px-8 px-2 text-nowrap font-bold text-orange-400 ">
                                                  SUSPENDED
                                                </div>
                                            </div>
                                          ) : null}
                                        </>
                                      </div>
                                      {index <
                                        matchScoreDetails.team_data
                                          .length -
                                        1 && <hr />}
                                      <>
                                        {hiddenRows.includes(commList.selectionid) && (
                                          <BetPlaceMobile
                                            closeRow={closeRow}
                                            closeSec={commList.selectionid}
                                            matchName={inplayMatch?.matchName}
                                            openBets={openBets}
                                            betSlipData={betSlipData}
                                            placeBet={placeBet}
                                            count={betSlipData.count}
                                            betLoading={betLoading}
                                            increaseCount={increaseCount}
                                            decreaseCount={decreaseCount}
                                          />
                                        )}
                                      </>
                                    </>
                                  )
                                )
                                : null}
                            </>
                          ) : null}
                        </div>
                      ) : null}

                      {inplayMatch?.isToss ? (
                        <div>
                          {matchScoreDetails &&
                            matchScoreDetails.toss_data &&
                            matchScoreDetails.toss_data.length > 0 ? (
                            <>
                              <header className="bg-[#333333] rounded-t-2xl">
                                <div className=" items-center flex relative z-0">
                                  <div className="w-2/3 flex ">
                                    <div className="w-1/2 rounded-tl-2xl py-3 bg-[#f36c21]  uppercase px-2 items-center inline-flex text-sm font-bold text-white">
                                      Toss Market
                                    </div>
                                    <div className="w-1/2 px-2 flex items-center">

                                      <img src={fastimg} alt="textd" className="h-5 w-5" />
                                    </div>
                                  </div>

                                  <div className="w-1/3 bg-[#333333]  rounded-tr-2xl flex flex-wrap justify-center items-center ">
                                    <div className="grid grid-cols-6 space-x-0.5 py-0.5 w-full bg-[#333333] rounded-tr-2xl  flex-wrap justify-center lg:px-4 px-1  items-center">
                                      <span className="md:block hidden"></span>
                                      <span className="md:block hidden"></span>
                                      <span className="lg:col-span-1 col-span-2 lg:col-start-1 text-[#98C8EA] text-sm font-bold text-right px-4">
                                        Yes
                                      </span>
                                      <span className="col-span-2 text-[#E5C0BE] text-sm font-bold text-center px-4">
                                        No
                                      </span>
                                      <span></span>
                                      <span></span>
                                    </div>
                                  </div>
                                </div>
                              </header>
                              {matchScoreDetails &&
                                matchScoreDetails.toss_data &&
                                matchScoreDetails.toss_data.length > 0
                                ? matchScoreDetails.toss_data.map(
                                  (commList, index) => (
                                    <>
                                      <div
                                        key={index}
                                        className={` bg-white relative shadow-sm flex decoration-none whitespace-normal max-w-full `}
                                      >
                                        <div className="w-2/3 lg:w-1/2 flex px-4">
                                          <div className="w-full leading-3 flex items-center text-slate-700">
                                            <span class="text-sm font-bold">
                                              <span>{commList.team_name}</span><br />
                                              <span key={index} className={positionObj[commList?.selectionId] > 0 ? 'text-green-500' : positionObj[commList?.selectionId] < 0 ? 'text-red-500' : 'black'} >
                                                {/* {returnDataObject[commList.selectionid] !== 0 ? returnDataObject[commList.selectionid] : "-"} */}
                                                {positionObj[commList?.selectionId] ? (Math.floor(Number(positionObj[commList?.selectionId]) * 100) / 100).toFixed(2) : 0}

                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                        <>
                                          <div className="w-1/3 lg:w-1/2 grid grid-cols-6 space-x-0.5 py-0.5">
                                            <span className=" lg:block hidden "></span>
                                            <span className=" lg:block hidden "></span>
                                            <span className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-3 lg:block hidden"
                                              onClick={() => handleBackOpen({
                                                data: commList, type: "Yes", odds: commList.lgaai, name: commList.team_name, betFor: "toss", oddsType: "toss", betType: "L", selectionId: commList.selectionid, betfairMarketId: marketId, price: (commList.khaai * 100), size: "100", position: returnDataObject, newPosition: returnDataObject, nameOther: matchScoreDetails.toss_data,
                                              })}>
                                              <Blinking
                                                price={parseFloat(commList.lgaai * 100).toFixed(2).replace(/\.?0+$/, '')}
                                                size={100}
                                                color={"bg-[#98C8EA]"}
                                                blinkColor={"bg-[#CDEBEB]"}
                                              />
                                            </span>

                                            <span className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 lg:hidden block"
                                              onClick={() => {
                                                toggleRowVisibility(commList.selectionid);
                                                handleBackOpen({
                                                  data: commList, type: "Yes", odds: commList.lgaai, name: commList.team_name, betFor: "toss", oddsType: "toss", betType: "L", selectionId: commList.selectionid, betfairMarketId: marketId, price: (commList.khaai * 100), size: "100", position: returnDataObject, newPosition: returnDataObject, nameOther: matchScoreDetails.toss_data,
                                                })
                                              }}>
                                              <Blinking
                                                price={parseFloat(commList.lgaai * 100).toFixed(2).replace(/\.?0+$/, '')}
                                                size={100}
                                                color={"bg-[#98C8EA]"}
                                                blinkColor={"bg-[#CDEBEB]"}
                                              />
                                            </span>
                                            <span className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-4  lg:block hidden"
                                              onClick={() => handleBackOpen({
                                                data: commList, type: "No", odds: commList.khaai, name: commList.team_name, betFor: "toss", oddsType: "toss", betType: "K", selectionId: commList.selectionid, betfairMarketId: marketId, price: (commList.lgaai * 100), size: "100", position: returnDataObject, newPosition: returnDataObject, nameOther: matchScoreDetails.toss_data,
                                              })}>
                                              <Blinking
                                                price={parseFloat(commList.khaai * 100).toFixed(2).replace(/\.?0+$/, '')}
                                                size={100}
                                                color={"bg-[#E5C0BE]"}
                                              />
                                            </span>

                                            <span className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-4  lg:hidden block"
                                              onClick={() => {
                                                toggleRowVisibility(commList.selectionid);
                                                handleBackOpen({
                                                  data: commList, type: "No", odds: commList.khaai, name: commList.team_name, betFor: "toss", oddsType: "toss", betType: "K", selectionId: commList.selectionid, betfairMarketId: marketId, price: (commList.lgaai * 100), size: "100", position: returnDataObject, newPosition: returnDataObject, nameOther: matchScoreDetails.toss_data,
                                                })
                                              }}>
                                              <Blinking
                                                price={parseFloat(commList.khaai * 100).toFixed(2).replace(/\.?0+$/, '')}
                                                size={100}
                                                color={"bg-[#E5C0BE]"}
                                              />
                                            </span>
                                            <span className=" lg:block hidden"></span>
                                          </div>
                                          {commList.lgaai === "0.00" ? (
                                           <div className="w-1/3 px-0.5 right-0 h-full  absolute bg-gray-600 border-gray-900  flex justify-center items-center">
                                           {/* <div className="relative bg-gray-600 border-gray-900 col-span-6 my-0.5 "></div> */}
                                           <div className="2xl:px-8 lg:px-8 px-2 text-nowrap font-bold text-orange-400 ">
                                               SUSPENDED
                                             </div>
                                         </div>
                                          ) : null}
                                        </>
                                      </div>
                                      {index <
                                        matchScoreDetails?.session?.length -
                                        1 && <hr />}
                                      <>
                                        {hiddenRows.includes(commList.selectionid) && (
                                          <BetPlaceMobile
                                            matchName={inplayMatch?.matchName}
                                            closeSec={commList.selectionid}
                                            closeRow={closeRow} openBets={openBets}
                                            betSlipData={betSlipData}
                                            placeBet={placeBet}
                                            count={betSlipData.count}
                                            betLoading={betLoading}
                                            increaseCount={increaseCount}
                                            decreaseCount={decreaseCount}
                                          />
                                        )}
                                      </>
                                    </>
                                  )
                                )
                                : null}
                            </>
                          ) : null}
                        </div>
                      ) : null}





                      {inplayMatch?.isFancy && (
                        <div>

                          {matchScoreDetails &&
                            matchScoreDetails?.session &&
                            matchScoreDetails?.session?.length > 0 ? (
                            <>
                              {matchScoreDetails.session.filter(commList => commList.com_perm == 'YES').length > 0 && (
                                <header className="bg-[#333333] rounded-t-2xl">
                                  <div className=" items-center flex relative z-0">
                                    <div className="w-2/3 flex ">
                                      <div className="w-1/2 rounded-tl-2xl py-3 bg-[#f36c21]  uppercase px-2 items-center inline-flex text-sm font-bold text-white">
                                        fancy
                                      </div>
                                      <div className="w-1/2 px-2 flex items-center">

                                        <img src={fastimg} alt="textd" className="h-5 w-5" />
                                      </div>
                                    </div>

                                    <div className="w-1/3 bg-[#333333]  rounded-tr-2xl flex flex-wrap justify-center items-center ">
                                      <div className="grid grid-cols-6 space-x-0.5 py-0.5 w-full bg-[#333333] rounded-tr-2xl  flex-wrap justify-center lg:px-4 px-5  items-center">
                                        <span className="md:block hidden"></span>
                                        <span className="md:block hidden"></span>
                                        <span className="lg:col-span-1 col-span-2 lg:col-start-1 text-[#E5C0BE] text-sm font-bold text-right px-4">
                                          NO
                                        </span>
                                        <span className="col-span-2  text-[#98C8EA] text-sm font-bold text-center px-4">
                                          YES
                                        </span>
                                        <span></span>
                                        <span></span>
                                      </div>
                                    </div>
                                  </div>
                                </header>)}
                              {matchScoreDetails && matchScoreDetails.session && matchScoreDetails.session.length > 0 ? (
                                matchScoreDetails.session
                                  .filter(commList => commList.com_perm == 'YES')
                                  .sort((a, b) => {
                                    const order = {
                                      "STRING": 100,
                                      "ONLY": 1,
                                      "OVER": 2,
                                      "FALL OF": 3,
                                      "RUN": 4,
                                      "PSHIP": 5,
                                      "BOUNDARIES": 6,
                                      "HOW": 7,
                                      "BALLS": 8,
                                    };

                                    const getSessionType = (sessionName) => {
                                      if (sessionName.includes("ONLY")) return "ONLY";
                                      if (sessionName.includes("OVER")) return "OVER";
                                      if (sessionName.includes("FALL OF")) return "FALL OF";
                                      if (sessionName.includes("RUN")) return "RUN";
                                      if (sessionName.includes("BOUNDARIES")) return "BOUNDARIES";
                                      if (sessionName.includes("HOW")) return "HOW";
                                      if (sessionName.includes("BALLS")) return "BALLS";
                                      return "STRING"; // Default to STRING if none match
                                    };

                                    const typeA = getSessionType(a.session_name);
                                    const typeB = getSessionType(b.session_name);

                                    // Compare based on the defined order
                                    let orderComparison = order[typeA] - order[typeB];

                                    // If types are the same, sort based on numeric value for "OVER"
                                    if (typeA === "OVER" && typeB === "OVER") {
                                      const numberA = parseInt(a.session_name.split(" ")[0]) || 0; // Extracting number before "OVER"
                                      const numberB = parseInt(b.session_name.split(" ")[0]) || 0; // Extracting number before "OVER"
                                      return numberA - numberB; // Compare based on numeric values
                                    }

                                    return orderComparison;
                                  })
                                  .map((commList, index) => (
                                    <React.Fragment key={index}>
                                      
                                      <div

                                        className={` bg-white relative shadow-sm flex decoration-none whitespace-normal max-w-full h-11`}
                                      >

                                        <div className="w-2/3 lg:w-1/2 flex px-4">
                                          <div className="w-full leading-3 flex items-center text-slate-700">
                                            <span className="text-sm truncate font-bold">
                                              <span>{commList.session_name}</span><br />
                                              <p key={index} className={isNaN(parseFloat(fancyPositionObj[commList?.session_id])) ? 'black' : parseFloat(fancyPositionObj[commList?.session_id]) > 0 ? 'text-green-500' : parseFloat(fancyPositionObj[commList?.session_id]) < 0 ? 'text-red-500' : 'black'}>
                                               {/* {isNaN(parseFloat(returnDataFancyObject[commList.session_id])) ? "" : parseFloat(returnDataFancyObject[commList.session_id]) !== 0 ? parseFloat(returnDataFancyObject[commList.session_id]).toFixed(2) : ""}  */}

                                                {fancyPositionObj[commList?.session_id] ? (Math.floor(Number(fancyPositionObj[commList?.session_id]) * 100) / 100).toFixed(2) : 0}
                                              </p>
                                            </span>
                                          </div>

                                          {commList.session_id in fancyPositionObj && (
                                            <span onClick={() => handleFancyPositionModal({ positionData: commList })} className="text-black lg:hidden block">
                                              <TbLadder size={20} className="text-gray-400" />
                                            </span>
                                          )}
                                        </div>
                                        <>

                                          <div className="w-1/3 lg:w-1/2 grid grid-cols-6 space-x-0.5 py-0.5">
                                            <span className=" lg:block hidden "></span>
                                            <span className=" lg:flex hidden justify-end m-auto">

                                              {commList.session_id in fancyPositionObj && (
                                                <span onClick={() => handleFancyPositionModal({ positionData: commList })} className="text-black flex justify-end">
                                                  <TbLadder size={20} className="text-gray-400" />
                                                </span>
                                              )}
                                            </span>
                                            <span className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-3 lg:block hidden"

                                              onClick={() => handleBackOpen({ data: commList, type: "No", odds: commList.oddsNo, name: commList.session_name, nameSession: commList.session_name, betFor: "fancy", oddsType: "fancy", betType: "N", run: commList.runsNo, selectionId: commList.session_id, price: commList.runsNo, size: commList.oddsNo, position: returnDataFancyObject })}
                                            >
                                              <Blinking
                                                price={commList.runsNo}
                                                size={(commList.oddsNo * 100).toFixed(2).replace(/\.00$/, '')}
                                                color={"bg-[#E5C0BE]"}
                                                blinkColor={"bg-[#CDEBEB]"}
                                              />
                                            </span>
                                            <span className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 lg:hidden block "

                                              onClick={() => {
                                                toggleRowVisibility(commList.session_id);
                                                handleBackOpen({
                                                  data: commList,
                                                  type: "No",
                                                  odds: commList.oddsNo,
                                                  name: commList.session_name,
                                                  nameSession: commList.session_name,
                                                  betFor: "fancy",
                                                  oddsType: "fancy",
                                                  betType: "N",
                                                  run: commList.runsNo,
                                                  selectionId: commList.session_id,
                                                  price: commList.runsNo,
                                                  size: commList.oddsNo,
                                                  position: returnDataFancyObject
                                                });
                                              }}>
                                              <Blinking
                                                price={commList.runsNo}
                                                size={(commList.oddsNo * 100).toFixed(2).replace(/\.00$/, '')}
                                                color={"bg-[#E5C0BE]"}
                                                blinkColor={"bg-[#CDEBEB]"}
                                              />
                                            </span>
                                            <span className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-4  lg:block hidden " onClick={() => handleBackOpen({ data: commList, type: "Yes", odds: commList.oddsYes, name: commList.session_name, nameSession: commList.session_name, betFor: "fancy", oddsType: "fancy", betType: "Y", run: commList.runsYes, selectionId: commList.session_id, betfairMarketId: marketId, price: commList.runsYes, size: commList.oddsYes, position: returnDataFancyObject })} >
                                              <Blinking
                                                price={commList.runsYes}
                                                size={(commList.oddsYes * 100).toFixed(2).replace(/\.00$/, '')}
                                                color={"bg-[#98C8EA]"}
                                              />

                                            </span>
                                            <span className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-4  lg:hidden block " onClick={() => {
                                              toggleRowVisibility(commList.session_id);
                                              handleBackOpen({ data: commList, type: "Yes", odds: commList.oddsYes, name: commList.session_name, nameSession: commList.session_name, betFor: "fancy", oddsType: "fancy", betType: "Y", run: commList.runsYes, selectionId: commList.session_id, betfairMarketId: marketId, price: commList.runsYes, size: commList.oddsYes, position: returnDataFancyObject })
                                            }
                                            }>
                                              <Blinking
                                                price={commList.runsYes}
                                                size={(commList.oddsYes * 100).toFixed(2).replace(/\.00$/, '')}
                                                color={"bg-[#98C8EA]"}
                                              />
                                            </span>

                                            <span className=" lg:block hidden"></span>
                                          </div>
                                          {commList &&
                                            commList.running_status &&
                                            (commList.running_status ===
                                              "SUSPENDED" ||
                                              commList.running_status === "CLOSE" ||
                                              commList.running_status ===
                                              "Ball Running") ? (
                                                <div className="w-1/3 px-0.5 right-0 h-full  absolute bg-gray-600 border-gray-900  flex justify-center items-center">
                                                {/* <div className="relative bg-gray-600 border-gray-900 col-span-6 my-0.5 "></div> */}
                                                <div className="2xl:px-8 lg:px-8 px-2 text-nowrap font-bold text-orange-400 ">
                                                   {commList.running_status}
                                                  </div>
                                              </div>
                                          ) : null}
                                        </>
                                      </div>

                                      {index < matchScoreDetails?.session?.length - 1 && <hr />}
                                      {hiddenRows.includes(commList.session_id) && (
                                        <BetPlaceMobile
                                          matchName={inplayMatch?.matchName}
                                          closeSec={commList.session_id}
                                          closeRow={closeRow} openBets={openBets}
                                          betSlipData={betSlipData}
                                          placeBet={placeBet}
                                          count={betSlipData.count}
                                          betLoading={betLoading}
                                          increaseCount={increaseCount}
                                          decreaseCount={decreaseCount}
                                        />
                                      )}
                                    </React.Fragment>
                                  )
                                  )) : null
                              }
                            </>
                          ) : null}

                        </div>
                      )}


                      {inplayMatch?.isFancy && (
                        <div>
                          {matchScoreDetails &&
                            matchScoreDetails?.session &&
                            matchScoreDetails?.session?.length > 0 ? (
                            <>
                              {matchScoreDetails.session.filter(commList => commList.com_perm == 'NO').length > 0 && (
                                <header className="bg-[#333333] rounded-t-2xl">
                                  <div className=" items-center flex relative z-0">
                                    <div className="w-2/3 flex ">
                                      <div className="w-1/2 rounded-tl-2xl py-3 bg-[#f36c21]  uppercase px-2 items-center inline-flex text-sm font-bold text-white">
                                        No Comm Fancy
                                      </div>
                                      <div className="w-1/2 px-2 flex items-center">

                                        <img src={fastimg} alt="textd" className="h-5 w-5" />
                                      </div>
                                    </div>

                                    <div className="w-1/3 bg-[#333333]  rounded-tr-2xl flex flex-wrap justify-center items-center ">
                                      <div className="grid grid-cols-6 space-x-0.5 py-0.5 w-full bg-[#333333] rounded-tr-2xl  flex-wrap justify-center lg:px-4 px-5  items-center">
                                        <span className="md:block hidden"></span>
                                        <span className="md:block hidden"></span>
                                        <span className="lg:col-span-1 col-span-2 lg:col-start-1 text-[#E5C0BE] text-sm font-bold text-right px-4">
                                          NO
                                        </span>
                                        <span className="col-span-2  text-[#98C8EA] text-sm font-bold text-center px-4">
                                          YES
                                        </span>
                                        <span></span>
                                        <span></span>
                                      </div>
                                    </div>
                                  </div>
                                </header>)}
                              {matchScoreDetails && matchScoreDetails.session && matchScoreDetails.session.length > 0 ? (
                                matchScoreDetails.session
                                  .filter(commList => commList.com_perm == 'NO')
                                  .sort((a, b) => {
                                    const order = {
                                      "STRING": 100,
                                      "ONLY": 1,
                                      "OVER": 2,
                                      "FALL OF": 3,
                                      "RUN": 4,
                                      "PSHIP": 5,
                                      "BOUNDARIES": 6,
                                      "HOW": 7,
                                      "BALLS": 8,
                                    };

                                    const getSessionType = (sessionName) => {
                                      if (sessionName.includes("ONLY")) return "ONLY";
                                      if (sessionName.includes("OVER")) return "OVER";
                                      if (sessionName.includes("FALL OF")) return "FALL OF";
                                      if (sessionName.includes("RUN")) return "RUN";
                                      if (sessionName.includes("BOUNDARIES")) return "BOUNDARIES";
                                      if (sessionName.includes("HOW")) return "HOW";
                                      if (sessionName.includes("BALLS")) return "BALLS";
                                      return "STRING"; // Default to STRING if none match
                                    };

                                    const typeA = getSessionType(a.session_name);
                                    const typeB = getSessionType(b.session_name);

                                    // Compare based on the defined order
                                    let orderComparison = order[typeA] - order[typeB];

                                    // If types are the same, sort based on numeric value for "OVER"
                                    if (typeA === "OVER" && typeB === "OVER") {
                                      const numberA = parseInt(a.session_name.split(" ")[0]) || 0; // Extracting number before "OVER"
                                      const numberB = parseInt(b.session_name.split(" ")[0]) || 0; // Extracting number before "OVER"
                                      return numberA - numberB; // Compare based on numeric values
                                    }

                                    return orderComparison;
                                  })
                                  .map((commList, index) => (
                                    <React.Fragment key={index}>
                                      <div

                                        className={` bg-white relative shadow-sm flex decoration-none whitespace-normal max-w-full h-11`}
                                      >

                                        <div className="w-2/3 lg:w-1/2 flex px-4">
                                          <div className="w-full leading-3 flex items-center text-slate-700">
                                            <span className="text-sm truncate font-bold">
                                              <span>{commList.session_name}</span><br />
                                              <p key={index} className={isNaN(parseFloat(fancyPositionObj[commList?.session_id])) ? 'black' : parseFloat(fancyPositionObj[commList?.session_id]) > 0 ? 'text-green-500' : parseFloat(fancyPositionObj[commList?.session_id]) < 0 ? 'text-red-500' : 'black'}>
                                              {fancyPositionObj[commList?.session_id] ? (Math.floor(Number(fancyPositionObj[commList?.session_id]) * 100) / 100).toFixed(2) : 0}
                                                {/* {isNaN(parseFloat(returnDataFancyObject[commList.session_id])) ? "" : parseFloat(returnDataFancyObject[commList.session_id]) !== 0 ? parseFloat(returnDataFancyObject[commList.session_id]).toFixed(2) : ""} */}
                                              </p>
                                            </span>
                                          </div>

                                          {commList.session_id in fancyPositionObj && (
                                            <span onClick={() => handleFancyPositionModal({ positionData: commList })} className="text-black lg:hidden block">
                                              <TbLadder size={20} className="text-gray-400" />
                                            </span>
                                          )}
                                        </div>
                                        <>

                                          <div className="w-1/3 lg:w-1/2 grid grid-cols-6 space-x-0.5 py-0.5">
                                            <span className=" lg:block hidden "></span>
                                            <span className=" lg:flex hidden justify-end m-auto">

                                              {commList.session_id in fancyPositionObj && (
                                                <span onClick={() => handleFancyPositionModal({ positionData: commList })} className="text-black flex justify-end">
                                                  <TbLadder size={20} className="text-gray-400" />
                                                </span>
                                              )}
                                            </span>
                                            <span className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-3 lg:block hidden"

                                              onClick={() => handleBackOpen({ data: commList, type: "No", odds: commList.oddsNo, name: commList.session_name, nameSession: commList.session_name, betFor: "fancy", oddsType: "fancy", betType: "N", run: commList.runsNo, selectionId: commList.session_id, price: commList.runsNo, size: commList.oddsNo, position: returnDataFancyObject })}
                                            >
                                              <Blinking
                                                price={commList.runsNo}
                                                size={(commList.oddsNo * 100).toFixed(2).replace(/\.00$/, '')}
                                                color={"bg-[#E5C0BE]"}
                                                blinkColor={"bg-[#CDEBEB]"}
                                              />
                                            </span>
                                            <span className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 lg:hidden block "

                                              onClick={() => {
                                                toggleRowVisibility(commList.session_id);
                                                handleBackOpen({
                                                  data: commList,
                                                  type: "No",
                                                  odds: commList.oddsNo,
                                                  name: commList.session_name,
                                                  nameSession: commList.session_name,
                                                  betFor: "fancy",
                                                  oddsType: "fancy",
                                                  betType: "N",
                                                  run: commList.runsNo,
                                                  selectionId: commList.session_id,
                                                  price: commList.runsNo,
                                                  size: commList.oddsNo,
                                                  position: returnDataFancyObject
                                                });
                                              }}>
                                              <Blinking
                                                price={commList.runsNo}
                                                size={(commList.oddsNo * 100).toFixed(2).replace(/\.00$/, '')}
                                                color={"bg-[#E5C0BE]"}
                                                blinkColor={"bg-[#CDEBEB]"}
                                              />
                                            </span>
                                            <span className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-4  lg:block hidden " onClick={() => handleBackOpen({ data: commList, type: "Yes", odds: commList.oddsYes, name: commList.session_name, nameSession: commList.session_name, betFor: "fancy", oddsType: "fancy", betType: "Y", run: commList.runsYes, selectionId: commList.session_id, betfairMarketId: marketId, price: commList.runsYes, size: commList.oddsYes, position: returnDataFancyObject })} >
                                              <Blinking
                                                price={commList.runsYes}
                                                size={(commList.oddsYes * 100).toFixed(2).replace(/\.00$/, '')}
                                                color={"bg-[#98C8EA]"}
                                              />

                                            </span>
                                            <span className=" md:col-span-2 sm:col-span-2 col-span-3 md:col-start-4  lg:hidden block " onClick={() => {
                                              toggleRowVisibility(commList.session_id);
                                              handleBackOpen({ data: commList, type: "Yes", odds: commList.oddsYes, name: commList.session_name, nameSession: commList.session_name, betFor: "fancy", oddsType: "fancy", betType: "Y", run: commList.runsYes, selectionId: commList.session_id, betfairMarketId: marketId, price: commList.runsYes, size: commList.oddsYes, position: returnDataFancyObject })
                                            }
                                            }>
                                              <Blinking
                                                price={commList.runsYes}
                                                size={(commList.oddsYes * 100).toFixed(2).replace(/\.00$/, '')}
                                                color={"bg-[#98C8EA]"}
                                              />
                                            </span>
                                            <span className=" lg:block hidden"></span>
                                          </div>
                                          {commList &&
                                            commList.running_status &&
                                            (commList.running_status ===
                                              "SUSPENDED" ||
                                              commList.running_status === "CLOSE" ||
                                              commList.running_status ===
                                              "Ball Running") ? (
                                                <div className="w-1/3 px-0.5 right-0 h-full  absolute bg-gray-600 border-gray-900  flex justify-center items-center">
                                                {/* <div className="relative bg-gray-600 border-gray-900 col-span-6 my-0.5 "></div> */}
                                                <div className="2xl:px-8 lg:px-8 px-2 text-nowrap font-bold text-orange-400 ">
                                                   {commList.running_status}
                                                  </div>
                                              </div>
                                          ) : null}
                                        </>
                                      </div>

                                      {index < matchScoreDetails?.session?.length - 1 && <hr />}
                                      {hiddenRows.includes(commList.session_id) && (
                                        <BetPlaceMobile
                                          matchName={inplayMatch?.matchName}
                                          closeSec={commList.session_id}
                                          closeRow={closeRow} openBets={openBets}
                                          betSlipData={betSlipData}
                                          placeBet={placeBet}
                                          count={betSlipData.count}
                                          betLoading={betLoading}
                                          increaseCount={increaseCount}
                                          decreaseCount={decreaseCount}
                                        />
                                      )}
                                    </React.Fragment>
                                  )
                                  )) : null
                              }
                            </>
                          ) : null}

                        </div>
                      )}


                      {inplayMatch?.isTieOdds ? (
                        <>
                          {
                            Object.values(finalSocket).map((elementMatchOdds, index) => elementMatchOdds.marketType === "Match Odds" && (
                              elementMatchOdds && elementMatchOdds.runners && elementMatchOdds.runners.length <= 2 ?
                                <>
                                  {
                                    Object.values(finalSocket).map((element, index) => element.marketType === "Tied Match" && (
                                      <div>
                                        <div className="rounded-2xl divide-y">
                                          <header className="bg-[#333333] rounded-t-2xl">
                                            <div className=" items-center flex relative z-0">
                                              <div className="w-4/12 rounded-tl-2xl py-2 px-2 bg-[#f36c21] flex">
                                                <span className="uppercase items-center inline-flex text-sm font-bold text-white">
                                                  {element && element.marketType
                                                    ? element.marketType
                                                    : "NA"}
                                                </span>
                                                <span></span>
                                              </div>
                                              <div className="w-4/12"></div>
                                              <div className="w-4/12  bg-[#333333]  rounded-tr-2xl flex flex-wrap justify-center items-center">
                                                <div className=" w-full bg-[#333333] rounded-tr-2xl flex flex-wrap justify-center px-4 py-2 items-center">
                                                  <span className="w-4/12 text-white text-sm font-normal text-center">
                                                    1
                                                  </span>
                                                  <span className="w-4/12 text-white text-sm font-normal text-center">
                                                    X
                                                  </span>
                                                  <span className="w-4/12 text-white text-sm font-normal text-center">
                                                    2
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </header>
                                          {element && element.runners && element.runners.length > 0 ? element.runners.map((elementtemp, index) => (
                                            <>
                                              <div
                                                className={` bg-white shadow-sm flex decoration-none whitespace-normal max-w-full `}
                                              >
                                                <div className="w-2/3 lg:w-1/2  flex px-4">
                                                  <div className="w-11/12  py-1 leading-3 flex items-center text-slate-700">
                                                    <span class="text-sm font-bold">
                                                      <span>{elementtemp.selectionName} <br />
                                                        <span key={index} className={positionObj[elementtemp?.selectionId] > 0 ? 'text-green-500' : positionObj[elementtemp?.selectionId] < 0 ? 'text-red-500' : 'black'} >
                                                          {/* {returnDataObject[elementtemp.selectionId] !== 0 ? returnDataObject[elementtemp.selectionId] : "-"} */}
                                                          {positionObj[elementtemp?.selectionId] ? (Math.floor(Number(positionObj[elementtemp?.selectionId]) * 100) / 100).toFixed(2) : 0}
                                                        </span>
                                                      </span>


                                                    </span>
                                                  </div>


                                                </div>
                                                <div className="w-1/3 lg:w-1/2 grid grid-cols-6 space-x-0.5 ">
                                                  {elementtemp && elementtemp.ex && elementtemp.ex.availableToBack && elementtemp.ex.availableToBack.length > 0 ? (
                                                    <>
                                                      {elementtemp.ex.availableToBack.slice(1).map((tempData, index) => (
                                                        <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden">
                                                          <Blinking
                                                            price={tempData.price}
                                                            size={tempData.size}
                                                            color={"bg-[#a5d9fe]"}
                                                            blinkColor={"bg-[#CDEBEB]"}
                                                          />
                                                        </span>

                                                      ))}
                                                    </>
                                                  ) : null}

                                                  {elementtemp && elementtemp.ex && elementtemp.ex.availableToBack && elementtemp.ex.availableToBack.length > 0 ? (
                                                    <>
                                                      {elementtemp.ex.availableToBack.slice(0, 1).map((tempData, index) => (
                                                        <>
                                                          <span className="md:col-span-2 sm:col-span-2 col-span-3 md:col-start-2 py-1 lg:hidden block"
                                                            onClick={() => {
                                                              toggleRowVisibility(elementtemp.selectionId);
                                                              handleBackOpen({ data: tempData, type: "Yes", nameOther: element.runners, odds: tempData.price, name: elementtemp.selectionName, betFor: "tiedMatch", oddsType: element.marketType, betType: "L", selectionId: elementtemp.selectionId, teamData: tempData.price, betfairMarketId: element.marketId, price: elementtemp.ex.availableToLay[0].price, size: elementtemp.ex.availableToLay[0].size, position: returnDataObject, newPosition: returnDataObject });
                                                            }}
                                                          >
                                                            <Blinking
                                                              price={tempData.price}
                                                              size={tempData.size}
                                                              color={"bg-[#a5d9fe]"}
                                                              blinkColor={"bg-[#CDEBEB]"}
                                                            />
                                                          </span>
                                                          <span className="lg:col-span-1 col-span-3 py-1 lg:block hidden"
                                                            onClick={() => {

                                                              handleBackOpen({ data: tempData, type: "Yes", nameOther: element.runners, odds: tempData.price, name: elementtemp.selectionName, betFor: "tiedMatch", oddsType: element.marketType, betType: "L", selectionId: elementtemp.selectionId, teamData: tempData.price, betfairMarketId: element.marketId, price: elementtemp.ex.availableToLay[0].price, size: elementtemp.ex.availableToLay[0].size, position: returnDataObject, newPosition: returnDataObject });
                                                            }}
                                                          >
                                                            <Blinking
                                                              price={tempData.price}
                                                              size={tempData.size}
                                                              color={"bg-[#a5d9fe]"}
                                                              blinkColor={"bg-[#CDEBEB]"}
                                                            />
                                                          </span>
                                                        </>

                                                      ))}
                                                    </>
                                                  ) : null}

                                                  {elementtemp &&
                                                    elementtemp.ex &&
                                                    elementtemp.ex.availableToLay &&
                                                    elementtemp.ex.availableToLay.length >
                                                    0 ? (
                                                    elementtemp.ex.availableToLay.map(
                                                      (tempData, index) => (
                                                        <>
                                                          {index === 0 ? (
                                                            <>
                                                              <span className="md:col-span-2 sm:col-span-2 md:col-start-4 col-span-3 py-1 lg:hidden block"
                                                                onClick={() => {
                                                                  toggleRowVisibility(elementtemp.selectionId);

                                                                  handleBackOpen({
                                                                    data: tempData,
                                                                    type: "No",
                                                                    odds: tempData.price,
                                                                    name: elementtemp.selectionName,
                                                                    nameOther: element.runners,
                                                                    betFor: "tiedMatch",
                                                                    oddsType: element.marketType,
                                                                    betType: "K",
                                                                    selectionId: elementtemp.selectionId,
                                                                    teamData: tempData.price,
                                                                    betfairMarketId: element.marketId,
                                                                    price: elementtemp.ex.availableToBack[0].price,
                                                                    size: elementtemp.ex.availableToBack[0].size,
                                                                    position: returnDataObject,
                                                                    newPosition: returnDataObject
                                                                  })
                                                                }}
                                                              >
                                                                <Blinking
                                                                  price={tempData.price}
                                                                  size={tempData.size}
                                                                  color={"bg-[#f8d0ce]"}
                                                                  blinkColor={"bg-[#CDEBEB]"}
                                                                />
                                                              </span>

                                                              <span className="lg:col-span-1 col-span-3 py-1 lg:block hidden"
                                                                onClick={() => {

                                                                  handleBackOpen({

                                                                    data: tempData,
                                                                    type: "No",
                                                                    odds: tempData.price,
                                                                    name: elementtemp.selectionName,
                                                                    nameOther: element.runners,
                                                                    betFor: "tiedMatch",
                                                                    oddsType: element.marketType,
                                                                    betType: "K",
                                                                    selectionId: elementtemp.selectionId,
                                                                    teamData: tempData.price,
                                                                    betfairMarketId: element.marketId,
                                                                    price: elementtemp.ex.availableToBack[0].price,
                                                                    size: elementtemp.ex.availableToBack[0].size,
                                                                    position: returnDataObject,
                                                                    newPosition: returnDataObject
                                                                  })
                                                                }}
                                                              >
                                                                <Blinking
                                                                  price={tempData.price}
                                                                  size={tempData.size}
                                                                  color={"bg-[#f8d0ce]"}
                                                                  blinkColor={"bg-[#CDEBEB]"}
                                                                />
                                                              </span>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden"

                                                              >
                                                                <Blinking
                                                                  price={tempData.price}
                                                                  size={tempData.size}
                                                                  color={"bg-[#f8d0ce]"}
                                                                  blinkColor={"bg-[#CDEBEB]"}
                                                                />
                                                              </span>
                                                            </>

                                                          )}
                                                        </>
                                                      )
                                                    )) : null}
                                                </div>
                                              </div>


                                              {hiddenRows.includes(elementtemp.selectionId) && (
                                                <BetPlaceMobile
                                                  closeRow={closeRow}
                                                  closeSec={elementtemp.selectionId}
                                                  matchName={inplayMatch?.matchName}
                                                  openBets={openBets}
                                                  betSlipData={betSlipData}
                                                  placeBet={placeBet}
                                                  count={betSlipData.count}
                                                  betLoading={betLoading}
                                                  increaseCount={increaseCount}
                                                  decreaseCount={decreaseCount}
                                                />
                                              )}


                                            </>
                                          )) : null}
                                        </div>
                                      </div>
                                    ))
                                  }
                                </> : null))}
                        </>
                      ) : null}



                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          <div ref={scrollRef} className={`lg:w-1/4 px-2 py-2   right-1 lg:block hidden overflow-hidden ${isFixed ? 'dddddd' : 'fffffffff'}`}>
            <div className="bg-[#1E1E1E] rounded-t-lg space-y-3 py-1">
              <div className="flex justify-center px-4  text-sm uppercase text-gray-500 font-base gap-4 items-center">
                <span className={`py-3 ${!betShow ? "border-b-2 border-b-[#f36c21] text-[#f36c21]" : ""}`}>BetSlip</span>
                <span className={`py-3 ${betShow ? "border-b-2 border-b-[#f36c21] text-[#f36c21]" : ""}`} onClick={() => openBets()}>open Bets </span>
              </div>
              {betShow && (
                <div className="px-3 space-y-3">
                  <div className=" justify-between  w-full  border-[#7A3C1D] border-2 rounded-2xl transition-transform transform duration-200" onClick={() => toggleAccordion(1)}>
                    <div className="w-full text-start flex px-2 py-2 text-sm font-normal cursor-pointer ">
                      <span className="text-white uppercase">Odds Bets</span>
                      <span className={`absolute right-3  h-5 w-5 rounded-full bg-[#f36c21] transition-transform transform duration-200 ${active[1] ? 'rotate-180' : ''}`} >
                        <MdKeyboardArrowDown size={20} className="text-white" />
                      </span>
                    </div>
                    {active[1] && (
                      <>
                        {oddsBetData && oddsBetData.length > 0 && oddsBetData.filter((e) => e.oddsType === "matchOdds").length > 0 ? (
                          <div className="table-responsive active">
                            <table className="bet_list_table bet_list_header_div w-[100%]  ">
                              <thead >
                                <tr className="bet_list_header px-5 text-xs text-gray-400 ">
                                  <th >Runner</th>
                                  <th>Rate</th>
                                  <th>Stake</th>
                                  <th>Profit/Loss</th>
                                  <th>Time/Date</th>
                                </tr>
                              </thead>
                              <tbody className="odd_table_body">
                                {oddsBetData && oddsBetData
                                  .filter(element => element.oddsType === "matchOdds")
                                  .map((element, index) => (
                                    <tr key={index} className={element.type === "K" ? 'bg-[#F8D0CE] m-bet-table rounded-b-md' : 'bg-[#A5D9FE] m-bet-table rounded-b-md'}>
                                      <td className="font-bold">{element.teamName}({element.oddsType})</td>
                                      <td className="">
                                        {parseFloat(Number(element.odds) + 1).toFixed(2).replace(/\.?0+$/, "")}
                                      </td>
                                      <td>{parseFloat(element.amount).toFixed(2).replace(/\.?0+$/, '')}</td>
                                      <td>{element.positionInfo && element.selectionId && element.positionInfo[element.selectionId] !== undefined ?
                                        parseFloat(element.positionInfo[element.selectionId]).toFixed(2).replace(/\.?0+$/, "") : 0}
                                      </td>
                                      <td>{element && element.createdAt ? moment(element.createdAt).utcOffset("+05:30").format("DD-MM-YYYY HH:mm:ss") : ''}</td>
                                    </tr>
                                  ))}
                              </tbody>

                            </table>
                          </div>
                        ) : (
                          <div className="  rounded-b-lg text-center p-6">
                            <div className="flex justify-center">
                              <BiSolidError size={60} className="text-[#F36C21]" />
                            </div>
                            <div className="text-center text-sm font-bold">
                              <span className="text-[#F36C21]">No Odds Bets!</span>
                            </div>
                          </div>

                        )}

                      </>

                    )}
                  </div>

                  <div className=" justify-between  w-full  border-[#7A3C1D] border-2 rounded-2xl transition-transform transform duration-200" onClick={() => toggleAccordion(3)}>
                    <div className="w-full text-start flex px-2 py-2 text-sm font-normal cursor-pointer ">
                      <span className="text-white uppercase">Bookmaker bets</span>
                      <span className={`absolute right-3  h-5 w-5 rounded-full bg-[#f36c21] transition-transform transform duration-200 ${active[3] ? 'rotate-180' : ''}`}>
                        <MdKeyboardArrowDown size={20} className="text-white" />
                      </span>
                    </div>

                    {active[3] && (
                      <>
                        {oddsBetData && oddsBetData.length > 0 && oddsBetData.filter((e) => e.oddsType !== "matchOdds").length > 0 ? (
                          <div className="table-responsive active">
                            <table className="bet_list_table bet_list_header_div w-[100%]  ">
                              <thead >
                                <tr className="bet_list_header px-5 text-xs text-gray-400 ">
                                  <th >Runner</th>
                                  <th>Rate</th>
                                  <th>Stake</th>
                                  <th>Profit/Loss</th>
                                  <th>Time/Date</th>
                                </tr>
                              </thead>
                              <tbody className="odd_table_body">
                                {oddsBetData && oddsBetData
                                  .filter(element => element.oddsType !== "matchOdds")
                                  .map((element, index) => (
                                    <tr key={index} className={element.type === "K" ? 'bg-[#F8D0CE] m-bet-table rounded-b-md' : 'bg-[#A5D9FE] m-bet-table rounded-b-md'}>
                                      <td className="font-bold">{element.teamName}({element.oddsType})</td>
                                      <td className="">
                                        {parseFloat(Number(element.odds) + 1).toFixed(2).replace(/\.?0+$/, "")}
                                      </td>
                                      <td>{parseFloat(element.amount).toFixed(2).replace(/\.?0+$/, '')}</td>
                                      <td>{element.positionInfo && element.selectionId && element.positionInfo[element.selectionId] !== undefined ?
                                        parseFloat(element.positionInfo[element.selectionId]).toFixed(2).replace(/\.?0+$/, "") : 0}
                                      </td>
                                      <td>{element && element.createdAt ? moment(element.createdAt).utcOffset("+05:30").format("DD-MM-YYYY HH:mm:ss") : ''}</td>
                                    </tr>
                                  ))}
                              </tbody>

                            </table>
                          </div>
                        ) : (
                          <div className="  rounded-b-lg text-center p-6">
                            <div className="flex justify-center">
                              <BiSolidError size={60} className="text-[#F36C21]" />
                            </div>
                            <div className="text-center text-sm font-bold">
                              <span className="text-[#F36C21]">No Bookmaker Bets!</span>
                            </div>
                          </div>

                        )}
                      </>
                    )}

                  </div>

                  <div className="justify-between w-full border-[#7A3C1D] border-2 rounded-2xl transition-transform transform duration-200" onClick={() => toggleAccordion(2)} >
                    <div className="w-full text-start flex px-2 py-2 text-sm font-normal cursor-pointer ">
                      <span className="text-white uppercase">Fancy Bets</span>
                      <span className={`absolute right-3 h-5 w-5 rounded-full bg-[#f36c21] transition-transform transform duration-200 ${active[2] ? 'rotate-180' : ''}`} >
                        <MdKeyboardArrowDown size={20} className="text-white" />
                      </span>
                    </div>


                    {active[2] && (
                      <>
                        {fancyBetData && fancyBetData.length > 0 ? (
                          <>
                            <div className="px-2 flex justify-start items-center gap-1 pb-2">
                              <input type="checkbox" id="checkbox" class="h-3 w-3  bg-transparent border border-gray-300 rounded-md " />

                              <label className="text-white text-xs "> Average Odds</label>
                            </div>

                            <div className="bet_list_header_div">
                              <table className="bet_list_table w-[100%]">
                                <thead>
                                  <tr className="bet_list_header text-xs text-gray-400">
                                    <th className="flex justify-center items-center">Runner</th>
                                    <th>Run</th>
                                    <th>Stake</th>
                                    <th>Profit/Loss</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {fancyBetData.map((element, index) => (
                                    <tr key={index} className={element.type === "N" ? 'bg-[#F8D0CE] m-bet-table' : 'bg-[#A5D9FE] m-bet-table'}>
                                      <td>{element.sessionName}</td>
                                      <td>{parseFloat(element.run).toFixed(2).replace(/\.?0+$/, "")}/{parseFloat(element.odds * 100).toFixed(2).replace(/\.?0+$/, "")}</td>
                                      <td>{parseFloat(element.amount).toFixed(2).replace(/\.?0+$/, '')}</td>
                                      <td>{Number.parseFloat(Math.abs(element && element.loss ? element.loss : 0)).toFixed(2).replace(/\.?0+$/, '')}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </>
                        ) : (
                          <div className="  rounded-b-lg text-center p-6">
                            <div className="flex justify-center">
                              <BiSolidError size={60} className="text-[#F36C21]" />
                            </div>
                            <div className="text-center text-sm font-bold">
                              <span className="text-[#F36C21]">No Fancy Bets!</span>
                            </div>
                          </div>

                        )}
                      </>
                    )}
                  </div>

                </div>
              )}
              <div>
                {!betShow && (
                  <div className="">
                    <BetPlaceDesktop
                      openBets={openBets}
                      closeRow={closeRow}
                      matchName={inplayMatch?.matchName}
                      betSlipData={betSlipData}
                      placeBet={placeBet}
                      count={betSlipData.count}
                      betLoading={betLoading}
                      increaseCount={increaseCount}
                      decreaseCount={decreaseCount}
                    />
                  </div>
                )}
              </div>

            </div>


          </div>
          {fancypositionModal &&
            <FancyLadder
              handleClose={handleClose}
              positionData={positionData}
              marketId={marketId}
            />
          }



        </div>
      </div>
    </section>

  );

};

export default MatchViewDeatils;