import React from 'react';
import "./casinoComponent.css";
// import { betChipsData } from '../../../_config';
import { FaTimes } from 'react-icons/fa';
import { betChipsData } from '../../../../../component/Auth/BetPlace/BetPlaceDesktop';

export default function BetPlaceDesktopCasinoloader(props) {
    const { updateStackOnclick, placeBet, handleClose, betSlipData, updateStake } = props;
    let betChipsDatas = JSON.parse(localStorage.getItem('betChipsData'));
    const myArray = betChipsDatas && betChipsDatas.length > 0 ? Object.keys(betChipsDatas) : Object.keys(betChipsData);

    return (
        <div className='position lg:block hidden'>
            <div className=' '>
                <div className="bet-header black-text- text-[13px] grid grid-cols-5 py-2 gap-2 font-medium">
                    <span></span>
                    <span>(Bet for)</span>
                    <span>Odds</span>
                    <span>Stake</span>
                    <span>Profit</span>
                </div>
                <div className={`${betSlipData.type === "No"
                    ? "match_bg_pink_index-0 "
                    : "match_bg_blue_index-0"
                    } `}>
                    <div className="grid grid-cols-5 px-2 gap-2 py-1.5 border-t-[1px] border-b-[1px] border-white">
                        <span className='flex justify-start items-center w-full black-text text-[18px] px-2'>
                            <FaTimes className='text-red-700 cursor-pointer' size={18} />
                        </span>
                        <span className='flex justify-start  items-center w-full black-text text-[14px] font-medium capitalize '>
                            {betSlipData.nat}
                        </span>
                        <input type="number" className="px-1 flex justify-start items-start w-[85%] border-[2px] border-white rounded-sm py-[2px] bg-white black-text" placeholder="0" value={betSlipData && betSlipData.odds ? betSlipData.odds : betSlipData.rate ? betSlipData.rate : "0"} />
                        <input type="text" className="px-1 flex justify-start items-start w-[85%] border-[2px] border-white rounded-sm py-[2px]  bg-white black-text" placeholder="0" value={betSlipData.stake} onChange={updateStake} />
                        <span className='flex justify-start px-2 items-center w-full black-text text-[16px] font-medium'>0</span>
                    </div>

                    <div className='grid xl:grid-cols-4 grid-cols-3 pt-1 px-2'>
                        {myArray && myArray.length > 0 ? myArray.map((element, index) => (
                            <div key={index} className="px-2 py-1 cursor-pointer">
                                <div className='bet-header black-text hover:text-white flex justify-center items-center rounded text-center h-[28px]' >{element}</div>
                            </div>)) : null}
                        {/* <div className='clearbtn' onClick={() => updateStackOnclick("0")}>C</div> */}
                    </div>
                    <div className='flex justify-between items-center py-1.5 px-4'>
                        <button className="red-color hover:bg-[#FC4242]/90 flex justify-center items-center rounded-md white-text text-[14px] px-2 py-1.5 h-[37px] w-[25%]" >Reset</button>
                        <button className="bg-[#0b7d36] hover:bg-[#0b7d36]/90 flex justify-center items-center rounded-md white-text text-[14px] px-2 py-1.5 h-[37px] w-[25%]" >
                            Submit
                            <div className="px-4 white-text h-2 flex justify-center items-center cursor-pointer border-[#d4d3e8] rounded-none font-extrabold text-sm" type="submit">
                                <div className=" flex items-center justify-center absolute bg-transparent">
                                    <div className="flex items-center justify-center bg-transparent w-full">
                                        <div className="w-5 h-5 rounded-full animate-spin 
                        border-[5px] border-solid border-[#ffffff] border-t-transparent"></div>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
}
