import React from 'react';
import BetListTableBmx from './BetListTableBmx';

export default function Desktop30(props) {
    const { betList, eventId } = props;

    return (
        <div className=" w-full bg-white lg:px-3 overflow-y-auto">

            <div className="red-header white-text text-[14px] px-2 py-[6px] rounded-t-[4px] mt-3 lg:block hidden">
                <span className="font-medium tracking-wide">
                    My Bet
                </span>
            </div>

            <div className="pb-20">
                <div className="space-y-[1px] bg-gray-200 pb-1 rounded">
                    {/* <MyBetTabBmx totalProfitLoss={totalProfitLoss} /> */}
                    <BetListTableBmx betList={betList} />
                </div>
            </div>

        </div>
    );
}