import React, { useState } from "react";

import DownloadApp from "../../../Assest/images/Download-App.png";
import Payment from "../../../component/payment/payment.js";
import GameProvider from "../../../component/GameProvider/GameProvider.js";
import { FooterNavSocial } from "../../../component/Footer/footerConstent.js";
import NavigatePathComponent from "../../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent.js";
import { Header } from "../../../component/Public/Header/Header.js";
import Drawer from "../../../component/Public/Drawer/Drawer.js";
import BRANDAMBESSDORIMG from "../../../Assest/images/BRAND_AMBESSDOR _IMG.png"


const AboutUs = () => {
    const [isOpen, setIsOpen] = useState(false);


    return (

        <div className="bg-black h-screen overflow-y-auto flex flex-col ">
            <div className="">
                <Header setIsOpen={setIsOpen} />
                <NavigatePathComponent showPathName={"About Us"} />
            </div>
            <Drawer isOpen={isOpen} setIsOpen={setIsOpen} />
            <img src={BRANDAMBESSDORIMG} alt="anboputus" />
            <section className="w-full h-screen max-w-7xl mx-auto justify-center items-center mt-5 ">
                <div className="text-white py-4">
                    <div className="flex justify-center items-center lg:py-4 py-2 text-3xl">About Us</div>
                    <div className="my-2 px-4 text-justify">
                        <span className="text-ms text-justify">Welcome to Crick Expo- India’s most trusted and popular betting exchange. Offering a wide variety of sports and entertainment leisure gambling, Crick Expo was designed to be a platform where winning money meets thrill, fun and excitement.

                        </span><br /><br />

                        <span><a href="#" className="text-[#f36c21]">Cricket</a> is the most loved sport on Crick Expo, shortly followed by <a href="#" className="text-[#f36c21]">Football</a>, <a href="#" className="text-[#f36c21]">Tennis </a>and 30+ other premium sports. A full fledged <a href="#" className="text-[#f36c21]">live casino</a> and <a href="#" className="text-[#f36c21]">Card games</a> complete with games such as <a href="#" className="text-[#f36c21]">Baccarat</a>, <a href="#" className="text-[#f36c21]">Teen Patti</a>, <a href="#" className="text-[#f36c21]">Poker</a>, <a href="#" className="text-[#f36c21]">Roulette</a>, <a href="#" className="text-[#f36c21]">Andar-Bahar</a> and numerous more is conducted by real dealers instead of bots, giving the player the chance to live the thrill of a live casino from the comfort of his home or even commute. All sports matches are streamed live on Crick Expo for the player’s ease to watch and win at the same time! Every new Club member is treated to a hefty bonus of 300% on the first deposit and a 50% bonus on the second.</span><br /><br /><br /><br />

                        <span>
                            Crick Expo endorses and encourages authentic gambling and provides state of the art security for the Club members to have fun while playing responsibly. The user can withdraw his winnings instantly from his Crick Expo account within minutes, at no extra charge! Download the Crick Expo app for a seamless and smooth experience on Crick Expo. Team Crick Expo prides itself on being a turning point in the world of safe online gambling and making genuine, transparent gambling accessible to all enthusiasts.
                        </span>
                    </div>

                    <div className="h-full w-full my-4 lg:py-6 py-2 px-2 ">
                        <div className=" w-full flex justify-center bg-[rgb(243,107,33)] ">
                            <div className=" xl:w-[64%] w-full flex py-4 md:gap-6 gap-3 items-center">
                                <div className="lg:w-2/5 w-1/2 flex items-center justify-center">
                                    <span>
                                        <img src={DownloadApp} alt="download" title="download" />
                                    </span>
                                </div>
                                <div className="lg:w-3/5 w-1/2 flex items-center">
                                    <div className="py-4">
                                        <span className="md:text-4xl text-2xl flex lg:justify-start justify-center font-semibold text-white uppercase leading-2 tracking-wide">
                                            <h2>Download The App</h2>
                                        </span>
                                        <div className="py-3 text-left text-base text-white font-medium">
                                            <h4>
                                                Now download the Crick Expo app and get easier, quicker
                                                access to your winnings!
                                            </h4>
                                        </div>
                                        <span>
                                            <a href="#">
                                                <button
                                                    type="button"
                                                    class=" py-2.5 px-5 me-2 mb-2 text-sm md:text-xl md:tracking-wide text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-200  focus:z-10 focus:ring-4  dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                                >
                                                    Download App
                                                </button>
                                            </a>
                                        </span>

                                        <p className="text-sm text-white ">
                                            Download now outside Google Playstore
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="lg:px-0 px-4 text-justify">
                        <span className="text-white">
                            <p> At Crick Expo, we have a zero tolerance policy towards any kind of fraudulent activities. Any user who is found violating any terms and conditions will find his account suspended from Crick Expo Club instantly. Crick Expo ensures the confidentiality of its users and the security of their information at all times. All safety measures are taken with regard to customer data and their transactions and no data is ever shared with a third party by Crick Expo.</p> <br />
                            <p>For all queries and site related questions, our client support team is available 24*7.</p> <br />
                            Follow us on social media for exciting contests and app updates!
                        </span>

                        <ul className="flex gap-2" >
                            {FooterNavSocial.map((item, index) => (
                                <li key={index} href={item.link} target="_blank" className="py-2 w-7 ">
                                    <img src={item.icon} alt={item.alt} className="h-7 w-7" />
                                </li>
                            ))}
                        </ul>

                    </div>
                    <div className="space-y-10">
                        <div className="w-full py-2">
                            <div className="flex justify-center items-center w-full py-4">
                                <h2 className="md:text-4xl text-xl text-white font-bold font-serif flex justify-center uppercase">
                                    GAME PROVIDERS
                                </h2>
                            </div>

                            <div className="card flex justify-center py-2">
                                <GameProvider />
                            </div>
                        </div>

                        <div className="w-full">
                            <div className="flex justify-center items-center w-ful">
                                <h2 className="md:text-4xl text-xl text-white font-bold font-serif flex justify-center uppercase">
                                    Payment method
                                </h2>
                            </div>

                            <div className="card flex justify-center">
                                <Payment />
                            </div>
                        </div>


                    </div>


                </div>
            </section>
        </div >


    );
};
export default AboutUs;
