import React, { useEffect, useState } from "react";
import { SubHeaderMenu, SubHeaderMobile } from "./subheaderConstent";
// import MoreGame from "../../moregame/moreGame";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const SubHeader = ({ setIsGame }) => {
  const { sportmatchlist } = useSelector((state) => state.authentication);
  const [sportIdsWithLength, setSportIdsWithLength] = useState([]);

  const localMatchlist = JSON.parse(localStorage.getItem('MatchListData'));
  useEffect(() => {
    const MatchListData = sportmatchlist ? sportmatchlist : localMatchlist?.sportlist;
    if (MatchListData && MatchListData?.data) {
      const filteredDataBySportId = {};
      MatchListData?.data?.forEach(item => {
        const sportId = item.sportId;
        if (!filteredDataBySportId[sportId]) {
          filteredDataBySportId[sportId] = [];
        }
        filteredDataBySportId[sportId].push(item);
      });
      const sportIdsLength = Object.keys(filteredDataBySportId).map(sportId => ({
        sportId,
        length: filteredDataBySportId[sportId].length
      }));
      setSportIdsWithLength(sportIdsLength);
    }
  }, [sportmatchlist]);

  const params = useParams()
  const navigate = useNavigate();
  let urlPageNumber = parseInt(params['*'].split('/')[2]);


  let urlPage = params['*'].split('/').pop();


  const handalClickNavigate = (url) => {
    navigate(url)
  }

  const { t } = useTranslation();
  // const [active, setAtive] = useState(false);

  return (
    <>
      <div className="w-full ">
        <div className="lg:flex lg:justify-center border-t border-t-gray-700 bg-gradient-to-t from-black bg-opacity-50 to-gray-800 overflow-x-hidden hidden">
          <div className="max-w-screen-xl flex flex-wrap items-center lg:justify-center mx-auto ">
            <div className="items-center justify-between w-full md:flex md:w-auto py-2">
              <ul className="flex xl:gap-7 lg:gap-4 h-10  gap-1 lg:px-0 px-2 ">
                {SubHeaderMenu?.map((item, index) => (
                  <li
                    key={index}
                    className={`border-0 rounded-full h-full xl:w-28 lg:w-20 w-20  flex gap-x-2 items-center justify-center group xl:hover:border-[#f36c21] xl:hover:border 
                    ${urlPageNumber === item.sportId || urlPage === item.page ? "" : null}
                    `}
                  >

                    <div
                      onClick={() => handalClickNavigate(item.link + item.sportId)}

                      className={`h-10 flex items-center py-2 md:text-white md:p-0  group-hover:text-[#f36c21] ${item.sportId == 2003 && "blink"}`}
                    >
                      <img
                        src={item.icon}
                        alt={item.text}
                        className="mx-2 xl:w-5 xl:h-5 lg:w-4 lg:h-4"
                      />

                      {sportIdsWithLength?.map((count, index) => {
                        if (item.sportId == count.sportId) {
                          return <sup key={index}> <div className="w-5 h-5 bg-orange-600 rounded-full flex justify-center items-center -ml-4" >
                            <span className="text-sm font-bold text-gray-300 flex justify-center items-center"> {count.length}</span>
                          </div></sup>
                        }
                        return null;
                      })}
                      <span className="text-sm font-normal capitalize whitespace-nowrap">
                        {/* {t(`subheader.${item.text.toLowerCase()}`)} */}
                        {item.text}
                      </span>


                    </div>
                  </li>
                ))}
              </ul>
            </div>

          </div>
        </div>

        <div className=" px-2 py-3 w-full lg:hidden block">
          <div className=" border-0 rounded-2xl bg-[#3d3d3d] flex justify-center items-center ">
            <ul className=" w-full flex  justify-center items-center">
              {SubHeaderMobile?.map((item, index) => (
                <li
                  key={index}
                  className={`md:px-5 px-3  md:py-2 py-1 h-14 group hover:border-[#f36c21]  w-full  ${index < 4 ? "border-r border-r-[#bbbaba]" : ""
                    }`}
                  onClick={() => {
                    if (index === SubHeaderMobile.length - 1) {
                      setIsGame(true);
                    }
                  }}
                  data-drawer-target="drawer-bottom-example"
                  data-drawer-show="drawer-bottom-example"
                  data-drawer-placement="bottom"
                  aria-controls="drawer-bottom-example"
                >
                  <div
                    onClick={() => handalClickNavigate(item.link + item.sportId)}
                    className={`md:text-white md:p-0 flex justify-center gap-x-2 group-hover:text-[#f36c21] ${item.sportId === 2003 ? "blink" : ""}`}
                  >
                    <img
                      src={item.icon}
                      alt={item.text}
                      className=" w-6 h-6 "
                    />
                    {sportIdsWithLength.map((count, index) => {
                      if (item.sportId == count.sportId) {
                        return <sup><div className="h-6 w-6 bg-orange-500 rounded-full flex justify-center items-center  -ml-3" key={index}>
                          <span className="text-xs font-bold text-white flex justify-center items-center">{count.length}</span>
                        </div></sup>
                      }
                      return null;
                    })}
                  </div>
                  <span className={`text-xs w-full capitalize flex justify-center py-1 text-white whitespace-nowrap} ${item.sportId === 111 || item.sportId === 'SPB-aviator' ? "inplay-blink" : ""}`}>
                    {item.text}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
export default SubHeader;
