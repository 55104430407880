import React, { useState } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NavigatePathComponent from "../../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent";
import { memberShip } from "../../../globleConstent/membership/membership";
import { Header } from "../../../component/Public/Header/Header";
import Drawer from "../../../component/Public/Drawer/Drawer";


const Lottery = () => {
  const [isOpen, setIsOpen] = useState(false);
  const settings2 = {
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: true,
    slidesToShow: 3,
    centerMode: true,

    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          infinite: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          slidesToScroll: 1,
        },
      },
    ],
  };



  return (

    <div className="bg-black h-screen overflow-y-auto flex flex-col">
      <div className="">
        <Header setIsOpen={setIsOpen} />
        <NavigatePathComponent showPathName={"Loyalty"} />
      </div>
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen} />
      <section className="w-full h-screen max-w-7xl mx-auto justify-center items-center mt-5">
        <div className="text-white w-full max-w-5xl m-auto py-4">
          <div className="">
            <Slider
              {...settings2}

              className="flex  items-center justify-center w-full overflow-hidden"
            >
              {memberShip?.map((card, index) => {
                return (
                  <div
                    key={index}
                    className="md:py-5 md:p-1 p-4  px-1 lg:space-y-4 w-full rounded-xl  group  relative "
                  >
                    <div className="bg-white  w-full rounded-xl md:py-2 ">
                      <div className="rounded-full flex justify-center py-2">
                        <span
                          className={`md:text-3xl px-14 text-sm md:-top-0 top-1  absolute py-1 flex justify-center text-black/80 bg-gradient-to-r ${card.backgroundColor} rounded-full flex justify-center`}
                          style={{ zIndex: 999 }}
                        >
                          {card.title}
                        </span>
                      </div>
                      <div className="flex gap-3 items-center justify-center ">
                        <span className="md:text-xl text-black text-sm md:font-medium uppercase">
                          {" "}
                          Turnover
                        </span>
                        <span className="text-black md:text-5xl text-3xl font-medium ">
                          {card.amount}
                        </span>
                      </div>

                      <h3 className="md:text-lg text-sm px-2 text-black md:font-medium">
                        {card.benefitTitle}
                      </h3>
                      <div className="text-gray-600 break-all transition duration-200 ease-in-out  lg:text-left text-justify">
                        <ul className="list-disc md:px-12 px-7 md:marker:text-2xl marker:text-lg marker:text-[rgb(243,107,33)] text-black md:text-sm md:font-medium font-semibold text-xs">
                          {card.benefits.map(
                            (benefit, i) => (
                              <li key={i} className="">
                                {benefit}
                              </li>
                            )
                          )}
                        </ul>
                        <p className="px-6 py-2 text-xs text-black">
                          {card.note}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>

            {/* </div> */}
          </div>

          <div className="">
            <div className="flex lg:px-10 px-2 items-center justify-center w-full overflow-hidden" >

              <div className="md:py-5 md:p-1 p-4  px-1 lg:space-y-4 w-full rounded-xl  group  relative ">
                <div className="bg-[#1E1E1E] border-2 border-[#F4DA7B]  w-full rounded-xl md:py-2 ">
                  <div className="rounded-full flex justify-start py-2">
                    <ul className="md:text-sm lg:px-14 px-6  text-white text-justify space-y-2 list-disc ">
                      <li>There will be no cap on the bonus amount. The same will be awarded as per the loyalty status.</li>
                      <li>It takes upto 5 hours for the system to upgrade your Loyalty Status once the turnover requirements have been met.</li>
                      <li>Club members are entitled to the bonus if and when they make two or less withdrawals per week. The third withdrawal will result in nullification of the loyalty bonus.</li>
                      <li>If the current average turnover is not maintained over a period of 6 months, you will be downgraded to the previous loyalty stage. Eg: If you are a Gold Club member and your average turnover is ₹1CR and over a period of 6 months your average turnover drops below ₹1CR you will be downgraded to the Silver Club membership.</li>
                      <li>Team Crick Expo withholds the rights to terminate or alter the offered promotions, turnover logics and all things related without prior notice.</li>
                      <li>In casino and card games, the bonus will not be awarded if a player places bets worth the same amount on the opposite bet in the same hand.</li>
                      <li>Turnover in SPORTSBOOK and PREMIUM CRICKET will be considered only on bets above 1.35 odds.</li>
                      <li>Turnover on session bets will only be considered on the "NO" option.</li>
                      <li>User will receive the lossback bonus only if the lossback bonus amount is above 100.</li>
                      <li>The lossback bonus will be awarded to the user after they complete more than 5 PnLs in Sports Exch and Sportsbook.</li>
                      <li>The lossback bonus will be awarded to the user after they complete more than 15 PnLs in Live Cards and Live Casino.</li>
                    </ul>

                  </div>
                  <div className="flex gap-3 md:px-4 px-1 py-4 mt-2 items-center justify-start relative overflow-x-auto">

                    <table class="w-full text-sm  rtl:text-right text-white dark:text-gray-400">
                      <thead class="text-xs text-white uppercase bg-[#EC6D23] dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" colspan="6" class="px-6 py-3 text-center">Maximum Bonus Amount</th>
                        </tr>
                      </thead>

                      <thead class="text-xs  uppercase  bg-[#757575] dark:text-gray-400">
                        <tr className="text-center">
                          <th scope="col" class="px-6 py-3">Loyalty Type</th>
                          <th scope="col" class="px-6 py-3">Deposit Bonus</th>
                          <th scope="col" class="px-6 py-3">PSB</th>
                          <th scope="col" class="px-6 py-3 md:block hidden">Lossback in Live<br />Cards & Casino</th>
                          {/* <th scope="col" class="px-6 py-3 md:block hidden">Lossback in Sportsbook</th> */}

                        </tr>
                      </thead>
                      <tbody>
                        <tr class="border-0 border-b text-center dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" class="px-6 py-4 font-medium whitespace-nowrap dark:text-white">SILVER(1K)</th>
                          <td class="px-6 py-4">₹50,000</td>
                          <td class="px-6 py-4">₹50,000</td>
                          <td class="px-6 py-4 md:block hidden">₹50,000</td>
                          <td class="px-6 py-4 md:block hidden">₹50,000</td>
                        </tr>
                        <tr class="border-0 border-b text-center dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" class="px-6 py-4 font-medium whitespace-nowrap dark:text-white">GOLD(10L)</th>
                          <td class="px-6 py-4">₹50,000</td>
                          <td class="px-6 py-4">₹50,000</td>
                          <td class="px-6 py-4 md:block hidden">₹50,000</td>
                          <td class="px-6 py-4 md:block hidden">₹50,000</td>
                        </tr>
                        <tr class="border-0 text-center dark:bg-gray-800 dark:border-gray-700">
                          <th scope="row" class="px-6 py-4 font-medium whitespace-nowrap dark:text-white">PLATINUM(1Cr)</th>
                          <td class="px-6 py-4">₹50,000</td>
                          <td class="px-6 py-4">₹50,000</td>
                          <td class="px-6 py-4 md:block hidden">₹50,000</td>
                          <td class="px-6 py-4 md:block hidden">₹50,000</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
          </div>


        </div>
      </section >
    </div >

  );
};
export default Lottery;
