import React from 'react';

export default function BgSkyButtonTheme2(props) {
    const { ele, handleBackOpen, data, section1Ref } = props;

    return (
        <div onClick={() => handleBackOpen(data, section1Ref)} className="absolute top-0 even-background rounded-[4px] w-full text-center text-[16px] py-3 font-semibold leading-4 text-white uppercase shadow shadow-gray-400 cursor-pointer flex justify-center items-center">
            {ele}
        </div>
    );
}