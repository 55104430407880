
import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { httpPost } from "../../../middelware/Http";
import moment from "moment";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NavigatePathComponent from "../../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent";

function ProfitLossDetails() {

    const [betseventid, setBetsEventId] = useState({});

    let { marketId } = useParams();
    const { getUserLedger } = useSelector(state => state.authentication);

    useEffect(() => {
        AOS.init();
        AOS.refresh();

    }, []);

    const groupByBetsForEventId = async () => {
        try {
            let reqData = {
                "marketId": marketId
            }
            const groupByBetsForEvent = await httpPost("user/completeLedgerDetails", reqData);
            setBetsEventId(groupByBetsForEvent.data);
        } catch (error) {
            console.error("Error fetching user bet history:", error);
        } finally {
            //   setIsLoading(false);
        }
    }

    useEffect(() => {
        groupByBetsForEventId();
    }, []);

    return (
        <>
            <NavigatePathComponent showPathName={"Profit Loss Details"} />
            <section className="w-full bg-black max-w-7xl mx-auto h-fit flex justify-center py-4">
                <div className="relative overflow-x-auto mx-auto text-center w-full max-w-7xl  shadow-xl ring-1 ring-gray-900/5 ">
                    {betseventid.oddsBetsData && betseventid.oddsBetsData && betseventid.oddsBetsData.length > 0 ? (
                        <>
                            <div className=" w-full flex items-center text-start">
                                <span className="lg:w-1/4 w-3/4 py-2 px-4 bg-[#1E1E1E] uppercase text-gray-500 dark:text-gray-400 border-b rounded-t-md font-bold">Odds profit loss</span>
                                <span className="lg:w-3/4 w-1/4 py-2"></span>
                            </div>
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead class="text-xs text-gray-500 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr className="bg-[#1E1E1E]">
                                        <th scope="col" class="px-6 lg:py-3 py-1.5">Time</th>
                                        <th scope="col" class="px-6 lg:py-3 py-1.5"> Selection</th>
                                        <th scope="col" class="px-6 lg:py-3 py-1.5">Type </th>
                                        <th scope="col" class="px-6 lg:py-3 py-1.5">Odds</th>
                                        <th scope="col" class="px-6 lg:py-3 py-1.5">Stake</th>
                                        <th scope="col" class="px-6 lg:py-3 py-1.5">Profit Loss</th>
                                        <th scope="col" class="px-6 lg:py-3 py-1.5">Status</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {betseventid.oddsBetsData.map((element, index) => (
                                        <tr class={`border-b text-gray-700 dark:border-gray-700 ${element.type === "K" ? "bg-[#F8D0CE]" : "bg-[#A5D9FE]"}`}>
                                            <th scope="row" class="px-6 lg:py-4 py-2 font-medium whitespace-nowrap ">{moment(element.createdAt).format('DD/MM/YYYY, h:mm:ss A')}</th>
                                            <td class="px-6 lg:py-4 py-2">{element.teamName}</td>
                                            <td class="px-6 lg:py-4 py-2">{element.type === "L" ? "LAGAI" : "KHAI"}</td>
                                            <td class="px-6 lg:py-4 py-2">{element.odds}</td>
                                            <td class="px-6 lg:py-4 py-2">{element.amount}</td>
                                            <td class={`px-6 lg:py-4 py-2 text-sm font-bold ${element.profitLoss > 0 ? "text-green-500" : "text-[#DC143C]"}`}>{element.profitLoss}</td>
                                            <td class="px-6 lg:py-4 py-2">{element.isDeclare == 1 ? "Declare" : "UnDeclare"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </>
                    ) : null}

                    {betseventid.sessionBetsData && betseventid.sessionBetsData && betseventid.sessionBetsData.length > 0 ? (
                        <>
                            <div className=" w-full flex items-center text-start pt-4">
                                <span className="lg:w-1/4 w-3/4 py-2 px-4 bg-[#1E1E1E] text-gray-500 dark:text-gray-400 border-b uppercase rounded-t-md font-bold">Fancy Profit Loss</span>
                                <span className="lg:w-3/4 w-1/4 py-2"></span>
                            </div>
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
                                <thead class="text-xs text-gray-500 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr className="bg-[#1E1E1E]">
                                        <th scope="col" class="px-6 lg:py-3 py-1.5">Time</th>
                                        <th scope="col" class="px-6 lg:py-3 py-1.5"> Selection</th>
                                        <th scope="col" class="px-6 lg:py-3 py-1.5">Type </th>
                                        <th scope="col" class="px-6 lg:py-3 py-1.5">Odds</th>
                                        <th scope="col" class="px-6 lg:py-3 py-1.5">Stake</th>
                                        <th scope="col" class="px-6 lg:py-3 py-1.5">Profit Loss</th>
                                        <th scope="col" class="px-6 lg:py-3 py-1.5">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {betseventid?.sessionBetsData.map((element, index) =>
                                        <tr class={`border-b text-gray-700 dark:border-gray-700 ${element.type === "N" ? "bg-[#F8D0CE]" : "bg-[#A5D9FE]"}`}>
                                            <th scope="row" class="px-6 lg:py-4 py-2 font-medium  whitespace-nowrap">{moment(element.createdAt).format('DD/MM/YYYY, h:mm:ss A')}</th>
                                            <td class="px-6 lg:py-4 py-2">{element.sessionName}</td>
                                            <td class="px-6 lg:py-4 py-2">{element.type === "N" ? "NOT" : "YES"}</td>
                                            <td class="px-6 lg:py-4 py-2">{element.odds}</td>
                                            <td class="px-6 lg:py-4 py-2">{element.amount}</td>
                                            <td class={`px-6 lg:py-4 py-2  text-sm font-bold ${element.profitLoss > 0 ? "text-green-500" : "text-[#DC143C]"}`}>{element.profitLoss}</td>
                                            <td class="px-6 lg:py-4 py-2">{element.isDeclare == 1 ? "Declare" : "UnDeclare"}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </>
                    ) : null}

                    {betseventid && (
                        <div className="text-white relative h-52 py-2 flex justify-end">
                            <div className=" md:right-auto w-full max-w-md absolute right-0 h-48 bg-white text-gray-600 text-sm font-bold uppercase">
                                <div className="py-2 space-y-1.5">
                                    <div className="flex justify-between items-center px-4 ">
                                        <span>Odds Profit Loss :</span>
                                        <span>{betseventid?.completeData?.clientOddsAmount}</span>
                                    </div>
                                    <div className="flex justify-between items-center px-4">
                                        <span>Bookmaker Commission :</span>
                                        <span>{betseventid?.completeData?.clientOddsComm + betseventid?.completeData?.clientSessionAmount}</span>
                                    </div>
                                    <div className="flex justify-between items-center px-4">
                                        <span>Session Profit Loss</span>
                                        <span>{betseventid?.completeData?.clientSessionAmount}</span>
                                    </div>
                                    <div className="flex justify-between items-center px-4">
                                        <span>Fancy Commission</span>
                                        <span>{betseventid?.completeData?.clientSessionComm}</span>
                                    </div>
                                    <div className="flex justify-between items-center px-4">
                                        <span>total</span>
                                        <span>{betseventid?.completeData?.clientSessionComm + betseventid?.completeData?.clientSessionAmount}</span>
                                    </div>
                                    <div className="flex justify-between items-center px-4">
                                        <span>total</span>
                                        <span>{betseventid?.completeData?.clientOddsComm + betseventid?.completeData?.clientOddsAmount}</span>
                                    </div>

                                    <div className="flex justify-between items-center px-4">
                                        <span></span>
                                        <span>
                                            Grand Total: {betseventid?.completeData?.clientOddsComm + betseventid?.completeData?.clientOddsAmount + betseventid?.completeData?.clientSessionComm + betseventid?.completeData?.clientSessionAmount}
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    )
}

export default ProfitLossDetails;
