import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userAction } from "../../../redux/action/userAction";
import { useDispatch } from "react-redux";
import { BsEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import NavigatePathComponent from "../Header/NavigatePathComponent/NavigatePathComponent";
import { toast } from 'react-toastify';
import { message } from "antd";

const ResetPassword = () => {
    const [fieldsChangePassword, setFieldsChangePassword] = useState({
        oldPassword: "",
        password: "",
        confirmPassword: ""
    });
    const [errorsChangePassword, setErrorsChangePassword] = useState({});
    const [isFetching, setIsFetching] = useState(false);
    let [disabledButton, setDisabledButton] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [inputFocused, setInputFocused] = useState(false);

    useEffect(() => {
        if (
            fieldsChangePassword.oldPassword &&
            fieldsChangePassword.password &&
            fieldsChangePassword.confirmPassword
        ) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [fieldsChangePassword]);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const inputChange = (e) => {
        const { name, value } = e.target;
        setFieldsChangePassword((prevState) => ({ ...prevState, [name]: value }));
        setErrorsChangePassword((prevState) => ({ ...prevState, [name]: '' }));
    };
    const changePassword = async (e) => {
        e.preventDefault();
        if (handleValidationChangePassword()) {
            setIsFetching(true);
            try {
                const changePasswordData = {
                    oldPassword: fieldsChangePassword.oldPassword,
                    password: fieldsChangePassword.password,
                    transactionPassword: fieldsChangePassword.oldPassword,
                };
                await dispatch(userAction.changePassword(changePasswordData, navigate));
            } catch (error) {
              
                if (error.response && error.response.data && error.response.data.changePassword) {
                    message.error(error?.response?.data?.changePassword)
                    // const toastId = toast.error(error.response.data.changePassword);
                    // setTimeout(() => toast.dismiss(toastId), 1000);
                    
                  } else {
                message.error("Something went wrong. Please try again.");
                    // const toastId = toast.error("Something went wrong. Please try again.");
                    // setTimeout(() => toast.dismiss(toastId), 1000);
                  }
            } finally {
                setIsFetching(false);
            }
        }
    };


    const handleValidationChangePassword = () => {
        let errors = {};
        let formIsValid = true;

        if (!fieldsChangePassword.oldPassword) {
            formIsValid = false;
            errors.oldPassword = 'Old Password Cannot Be Blank.';
        }

        if (!fieldsChangePassword.password) {
            formIsValid = false;
            errors.password = 'New Password Cannot Be Blank.';
        }

        if (!fieldsChangePassword.confirmPassword) {
            formIsValid = false;
            errors.confirmPassword = 'Confirm Password Cannot Be Blank';
        } else if (fieldsChangePassword.password !== fieldsChangePassword.confirmPassword) {
            formIsValid = false;
            errors.confirmPassword = 'Passwords and Confirm Password do not match';
        }

        setErrorsChangePassword(errors);
        return formIsValid;
    };
    const showInputData = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const handleInputFocus = () => {
        setInputFocused(true);
    };

    const handleInputBlur = () => {
        setInputFocused(false);
    };

    return (
        <>
            <NavigatePathComponent showPathName={"Reset Password"} />
            <section className="w-full  max-w-7xl mx-auto flex justify-center py-4 lg:px-4 px-2">
                <div className="relative mx-auto w-full max-w-md bg-[#1E1E1E] px-6 shadow-xl ring-1 ring-gray-900/5 sm:rounded-md sm:px-10">

                    <div className="w-full my-8">
                        <form onSubmit={changePassword}>
                            <div class="relative mt-6">
                                <div class="relative w-full min-w-[200px] h-10">
                                    <input
                                        class="peer w-full h-full bg-transparent text-white font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-500"
                                        placeholder=""
                                        value={fieldsChangePassword.oldPassword}
                                        onChange={inputChange}
                                        name="oldPassword"
                                        type={showPassword ? "text" : "password"}
                                        onFocus={handleInputFocus}
                                        onBlur={handleInputBlur}
                                    // type="password"
                                    />
                                    <label class="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-orange-500 before:border-blue-gray-200 peer-focus:before:!border-gray-500 after:border-blue-gray-200 peer-focus:after:!border-gray-500">Old Password
                                    </label>
                                    {errorsChangePassword.oldPassword && <span className="mt-1 text-sm text-red-400">{errorsChangePassword.oldPassword}</span>}
                                </div>
                            </div>
                            <div class="relative mt-6">
                                <div class="relative w-full min-w-[200px] h-10">
                                    <input
                                        class="peer w-full h-full bg-transparent text-white font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-500"
                                        placeholder=""
                                        name="password"
                                        value={fieldsChangePassword.password}
                                        onChange={inputChange}
                                        type={showPassword ? "text" : "password"}
                                        onFocus={handleInputFocus}
                                        onBlur={handleInputBlur}
                                    // type="password"
                                    />
                                    <label class="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-orange-500 before:border-blue-gray-200 peer-focus:before:!border-gray-500 after:border-blue-gray-200 peer-focus:after:!border-gray-500">New Password
                                    </label>
                                    {errorsChangePassword.password && <span className="mt-1 text-sm text-red-400">{errorsChangePassword.password}</span>}
                                </div>
                            </div>
                            <div class="relative mt-6">
                                <div class="relative w-full min-w-[200px] h-10">
                                    <input
                                        class="peer w-full h-full bg-transparent text-white font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-500"
                                        placeholder=""
                                        name="confirmPassword"
                                        value={fieldsChangePassword.confirmPassword}
                                        onChange={inputChange}
                                        type={showPassword ? "text" : "password"}
                                        onFocus={handleInputFocus}
                                        onBlur={handleInputBlur}
                                    // type="password"
                                    />
                                    <label class="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-orange-500 before:border-blue-gray-200 peer-focus:before:!border-gray-500 after:border-blue-gray-200 peer-focus:after:!border-gray-500">Confirm New Password
                                    </label>
                                    {errorsChangePassword.confirmPassword && <span className="mt-1 text-sm text-red-400">{errorsChangePassword.confirmPassword}</span>}
                                </div>

                            </div>


                            <div className="space-x-2 py-2 mt-8">
                                <button type="submit" disabled={disabledButton || isFetching} className={`w-full  text-base uppercase py-1 rounded-full tracking-widest ${disabledButton || isFetching ? "bg-gray-600 text-gray-300 font-bold" : "bg-[#ED7935] border-2 border-none text-gray-900 font-bold"}`}>
                                    {isFetching === true ?
                                        <div className="w-6 h-6 border-8 m-auto border-dashed rounded-full animate-spin border-white"></div>
                                        : "Reset Password"}
                                </button>

                            </div>
                        </form>


                    </div>
                    <button
                        onClick={showInputData}
                        className="absolute inset-y-0 right-0 flex py-1 h-3 px-2 text-white"
                    >
                        {showPassword ? (
                            <BsFillEyeSlashFill
                                size={20}
                                className={inputFocused ? "text-[#f36c21]" : ""}
                            />
                        ) : (
                            <BsEyeFill
                                size={20}
                                className={inputFocused ? "text-[#f36c21]" : ""}
                            />
                        )}
                    </button>
                </div>

            </section>
        </>
    );
};

export default ResetPassword;
