import React, { useEffect, useState } from "react";
import { PromotionJson } from "./PromotionsJson";
import { BsDot } from "react-icons/bs";
import FAQ from "../../../component/Common/Faqs/Faqs";
import { Header } from "../../../component/Public/Header/Header";
import Drawer from "../../../component/Public/Drawer/Drawer";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";


const Promotions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [faqs, setFaqs] = useState([])
  useEffect(() => {
    setFaqs(PromotionJson);
  }, [])

  const toggleFAQ = index => {

    setFaqs(
      faqs?.map((faq, i) => {

        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <div className="slider hota hai wo hatan hai bootm se  wo header se hai">
      <div>
        <div className="bg-black flex flex-col h-full min-h-[100vh] max-h-fit">
          <div className="">
            <Header setIsOpen={setIsOpen} />
            <div className='bg-[#272727]  w-full px-3 flex justify-center'>
              <div className="lg:w-3/4 flex items-center px-4 py-1">
                <div className="hover:bg-[#372D27] hover:rounded-full p-1">
                  <MdOutlineKeyboardArrowLeft className="text-[#f36c21] font-bold cursor-pointer" size={25} />
                </div>
                <span className='px-1 text-white text-sm font-normal'>Promotions</span>
              </div>
            </div>
          </div>
          <Drawer isOpen={isOpen} setIsOpen={setIsOpen} />

          <section class="w-full h-full max-w-7xl mx-auto justify-center items-center mt-5 mb-20">
            <div>
              <div class="faqs">
                {faqs.map((faq, index) => (
                  <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
                ))}
              </div>

              <div class="text-white text-center lg:px-2 px-4">
                <span class="flex justify-center py-2 items-center text-2xl font-medium">Rules And Regulations</span>
                <div class=" w-full py-2 flex justify-center items-center">
                  <div class="flex w-full max-w-2xl px-2 pt-5 pb-10 bg-[#1E1E1E] text-start  border rounded-xl border-[#F4DA7B]">
                    <span className=""><BsDot className="text-white" size={50} /></span>
                    <span>Crick Expo withholds the rights to terminate or alter the offered promotions, turnover logics and all things related without any prior notification.</span>

                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div>
    </div>

  );
};
export default Promotions;
