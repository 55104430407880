import React from "react";
import astropay from "../../Assest/images/payment/astropay.png";
import bankdeposit from "../../Assest/images/payment/bank-deposit.png";
import bitcoin from "../../Assest/images/payment/bitcoin.png";
import ethereum from "../../Assest/images/payment/ethereum.png";
import gpay from "../../Assest/images/payment/gpay.png";
import paypal from "../../Assest/images/payment/paypal.png";
import paytm from "../../Assest/images/payment/paytm.png";
import phonepe from "../../Assest/images/payment/phonepe.png";
import tether from "../../Assest/images/payment/tether.png";
import upi from "../../Assest/images/payment/upi.png";
import gmProviderImage1 from "../../../src/Assest/images/GameProvider/bcoongo.png"
import gmProviderImage2 from "../../../src/Assest/images/GameProvider/bet-games.png"
import gmProviderImage3 from "../../../src/Assest/images/GameProvider/bet-soft.png"
import gmProviderImage4 from "../../../src/Assest/images/GameProvider/evolution-gaming.png"
import gmProviderImage5 from "../../../src/Assest/images/GameProvider/ezugi.svg"
import gmProviderImage6 from "../../../src/Assest/images/GameProvider/ibetsoft.png"
import gmProviderImage7 from "../../../src/Assest/images/GameProvider/microgaming.png"
import gmProviderImage8 from "../../../src/Assest/images/GameProvider/xpg1.png"
import gmProviderImage9 from "../../../src/Assest/images/GameProvider/pgsoft.png"
import gmProviderImage10 from "../../../src/Assest/images/GameProvider/realtime-gaming.png"
import gmProviderImage11 from "../../../src/Assest/images/GameProvider/sa-gaming.png"
import gmProviderImage12 from "../../../src/Assest/images/GameProvider/sportsradar1.png"
import gmProviderImage13 from "../../../src/Assest/images/GameProvider/super-spades.png"
import gmProviderImage14 from "../../../src/Assest/images/GameProvider/vivo.jpg"

const GameProvider = () => {
  const paymentImage = [
   gmProviderImage1,gmProviderImage2,gmProviderImage3,gmProviderImage4, gmProviderImage5,gmProviderImage6,gmProviderImage7,gmProviderImage8,gmProviderImage9,gmProviderImage10,gmProviderImage11,gmProviderImage12,
   gmProviderImage13,gmProviderImage14
  ];

  return (
    <div className="flex justify-center">
      <div className="grid grid-cols-6 md:px-2 px-4 py-1 lg:gap-4 gap-2 md:grid-cols-10 sm:grid-cols-6 lg:grid-cols-6 xl:grid-cols-9 w-full">
        {paymentImage.map((imageUrl, index) => (
          <div
            key={index}
            className={`bg-[#000000] border-2 border-[#5F3225] rounded-md ${
                index === 9  ? "xl:col-start-3 " : ""
              }`}
          >
            
            <div className="flex justify-center items-center md:px-2 py-4 px-1 lg:h-20 h-6 ">
              <img src={imageUrl} alt={`Payment ${index + 1}`} className="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GameProvider;
